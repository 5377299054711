import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { performanceReportSlice } from './slices/preformance-report';

const persistConfig = {
  key: 'performanceReportAuth',
  storage,
}

export const performanceReportReducer = persistReducer(persistConfig, performanceReportSlice.reducer);
export * from './api/performance-report'