import { FC, memo } from "react";

import style from "./style.module.scss";

type PropertyDataRoomImagesProps = {
  images: string;
};
export const PropertyDatsRoomImages: FC<PropertyDataRoomImagesProps> = memo(
  ({ images }) => (
    <div className={style["data-room-images__container"]}>
      <div className={style["data-room-images__box"]}>
          {images ? <img src={images} alt="roomImage" /> : null}
      </div>
    </div>
  )
);
