import { FC, memo } from "react";
import { Link } from "react-router-dom";

import styles from "./style.module.scss";

interface CardList {
  content: string;
  link: string;
  to: string;
  target?: string;
}

interface SummaryCardBannerListProps {
  cardList: Array<CardList>;
}

export const SummaryCardBannerList:FC<SummaryCardBannerListProps> = memo(({cardList}) => {
    return(
      <ul className={styles.list}>
        {cardList.map(({ content, link, to, target }) => {
          return (
            <li className={styles['list__item']} key={content}>
              <span>{content}</span>
              {' '}
              { target ?
                <a href={to} target={target} className={styles.link}>
                  {link}
                </a>
                :
                <Link to={to} target={target} className={styles.link}>
                  {link}
                </Link>
              }
            </li>
          );
        })}
      </ul>
    )
});