import { PropertySectionAnchor } from "../constants/property-section-anchor";
import { PropertyTeaserType } from "store/properties/types";

export const getNonEmptySections = (property: PropertyTeaserType): PropertySectionAnchor[] => {
  return Object.values(PropertySectionAnchor).filter(a => !isEmptySection(a,property));
}

export const isEmptySection = (anchor: PropertySectionAnchor, property: PropertyTeaserType): boolean => {
  const predicate = predicates.get(anchor);
  // if we are not able to find the predicate we assume the section is empty
  return !predicate ? true : predicate(property);
}

const predicates: Map<PropertySectionAnchor, (property: PropertyTeaserType) => boolean> = new Map([
  [PropertySectionAnchor.INVESTMENT, (property: PropertyTeaserType) => {
    const { 
      investmentStrategy,
      details,
      location,
      about,
      assets,
      locations
    } = property;

    return (investmentStrategy?.length ?? 0) === 0 &&
      (details?.values?.filter(av => av.value).length ?? 0) === 0 &&
      (details?.secondaryKpi?.length ?? 0) === 0 &&
      (location?.descriptions?.length ?? 0) === 0 &&
      !about?.wysiwygDescription &&
      (about?.descriptions?.length ?? 0) === 0 &&
      (about?.values?.filter(av => av.value).length ?? 0) === 0 &&
      (assets?.length ?? 0) === 0 &&
      (about?.footnotes?.length ?? 0) === 0 &&
      (locations?.length ?? 0) === 0
  }],

  [PropertySectionAnchor.PARTNER, (property: PropertyTeaserType) => {
    const { securityAndRoles, locations } = property;

    return !(securityAndRoles?.aboutThePartner?.text?.length) &&
      !(securityAndRoles?.aboutThePartner?.image) &&
      !locations[0]
  }],
  [PropertySectionAnchor.LOCATION, (property: PropertyTeaserType) => {
    const { locations } = property;
    
    const { coords } = locations[0] || {};

    const { latitude =  0, longitude = 0 } = coords || {};
    
    return [latitude,longitude].some((adressValue) => !adressValue);
  }],
  [PropertySectionAnchor.RISK, (property: PropertyTeaserType) => {
    const { 
      risk,
    } = property;

    return (!risk?.riskMitigationFactors &&
      (risk?.riskMitigationImages?.length ?? 0) === 0 &&
      (risk?.documents?.length ?? 0) === 0
    );
  }],
  [PropertySectionAnchor.ROLES, (property: PropertyTeaserType) => {
    const { securityAndRoles } = property;

    if (!securityAndRoles) {
      return true;
    }

    const { roles } = securityAndRoles;

    return (roles?.filter(av => av.value).length ?? 0) === 0;
  }],
  [PropertySectionAnchor.SECURITY_INFORMATION, (/* property: PropertyTeaserType */) => {
    // const { securityAndRoles } = property;

    // if (!securityAndRoles) {
    //   return true;
    // }

    // const { security } = securityAndRoles;

    // if (!security) {
    //   return true;
    // }

    // return (security?.filter(av => av.name && av.value).length ?? 0) === 0;

    // because we want to show ISIN and Relink price from other source always in that section
    return false;
  }],
  [PropertySectionAnchor.DATA_ROOM, (property: PropertyTeaserType) => {
    const { dataRoom } = property;

    if (!dataRoom) {
      return true;
    }

    const { marketResearchText, documents, marketResearchImage } = dataRoom;

    return ((!marketResearchText) &&
      (documents?.length ?? 0) === 0 &&
      !marketResearchImage);
  }],
  [PropertySectionAnchor.INVESTOR_REPORT, (property: PropertyTeaserType) => {
    if (!property?.performanceReport?.isPasswordSetted || (property?.performanceReport?.isPasswordSetted as unknown as string) === 'false') {
      return true;
    }

    return ((property?.performanceReport?.documents?.length || 0) === 0);
  }],
  [
    PropertySectionAnchor.EDUCATION_AND_RESEARCH, (property : PropertyTeaserType) => {
      const { academyMaterials } = property;

      if (!academyMaterials) {
        return true;
      }

      return (academyMaterials?.length ?? 0 ) === 0;
    }
  ],
  [
    PropertySectionAnchor.DISCLAIMER, (property: PropertyTeaserType) => {
      const { disclaimers } = property;
  
      return (disclaimers?.length ?? 0) === 0;
    }
  ]
]);
