import { ChangeEvent, FC, memo } from 'react';
import { Input } from 'new-architecture/components/input';
import { FormikValues } from '../../types/formik-values';
import { FormikErrors } from 'formik';
import { WysiwygEditor } from 'new-architecture/components/wysiwyg-editor';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface PropertyShareModalBodyProps {
  values: FormikValues;
  handleChange: (event: ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

export const ContactEstatingModalBody: FC<PropertyShareModalBodyProps> = memo(({
  values,
  setFieldValue,
  handleChange,
}) => {
  const {
    message,
    name,
    fromEmail,
    phone,
  } = values;
  const { t: tContactModal } = useTranslate('modal');

  return (
    <div className={styles['modal-body']}>
      <div className={styles['modal-body__inputs-group']}>
        <div className={styles['modal-body__inputs-group__name']}>
          <Input
            label={tContactModal('modal.name')}
            inputAttributes={{
              placeholder: tContactModal('modal.receiver_of_email'),
              type: 'text',
              value: name,
              readOnly: true,
              disabled: true,
            }}
          />
        </div>
        <div className={styles['modal-body__inputs-group__email']}>
          <Input
            label={tContactModal('modal.email')}
            inputAttributes={{
              placeholder: tContactModal('modal.receiver_of_email'),
              type: 'email',
              value: fromEmail,
              readOnly: true,
              disabled: true,
            }}
          />
        </div>
        <div className={styles['modal-body__inputs-group__phone']}>
          <Input
            label={tContactModal('modal.phone')}
            inputAttributes={{
              placeholder: tContactModal('modal.telephone_number'),
              type: 'tel',
              value: phone,
              id: 'phone',
              onChange: handleChange
            }}
          />
        </div>
      </div>
      <div className={styles['modal-body__custom-fields']}>
        <div className={styles['custom-field-container']}>
          <div className={styles['custom-field-container__label']}>
            {tContactModal('modal.message')}
          </div>
          <WysiwygEditor
            value={message}
            onValueChange={(value) => setFieldValue('message', value)}
          />
        </div>
      </div>
    </div>
  );
});