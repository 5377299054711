import { FC, memo, useMemo } from 'react';
import { CustomField, LocationDescription, PropertyOptions } from 'store/properties/types';
import { PropertyCardDetailsStat } from '../property-card-details-stat';
import { emptyField } from '../../constants/empty-field';
import investorMultiple from "assets/icon_investormultiple.svg";
import investorIrr from "assets/Icon_investor_irr.svg";
import iconTarget from "assets/iconTarget.svg";
import investorCashFlow from "assets/Icon_investorcashflow.svg";
import { ReactComponent as LocationIcon } from "assets/location.svg";
import { TagDescription } from 'store/search';
import { PropertyCardDetailsTags } from '../property-card-details-tags';
import { useTranslate } from '@tolgee/react';

import styles from './style.module.scss';

interface PropertyCardDetailsProps {
  locations: Array<LocationDescription>;
  name: string;
  summary: Array<CustomField>;
  options: PropertyOptions;
  tags: Array<TagDescription>;
  onTagClick: (tag: TagDescription) => void;
  onPropertyClick: () => void;
  specialLocationDescription?: string;
}

export const PropertyCardDetails: FC<PropertyCardDetailsProps> = memo(({
  locations,
  name: fullName,
  summary,
  options,
  tags,
  onTagClick,
  onPropertyClick,
  specialLocationDescription
}) => {
  const cities = useMemo(() => specialLocationDescription || locations.map((location) => location.coords.city).join(', '), [locations, specialLocationDescription]);
  const { name, organisation } = useMemo(() => {
    const dividerIndex = fullName.indexOf('|');
    let name, organisation;
    if (~dividerIndex) {
      organisation = fullName.substring(0, dividerIndex).trim();
    }

    name = fullName.substring(dividerIndex + 1, fullName.length).trim();

    return { organisation, name };
  }, [fullName]);

  const searchValues = useMemo(() => {
    const searchValues = {
      'totalIssuanceVolume': '',
    };

    Object.keys(searchValues).forEach((key) => {
      searchValues[key] = summary.find(({ id }) => id === key)?.value || emptyField;
    });

    return searchValues;
  }, [summary]);

  const { t: tOpportunityCard } = useTranslate('opportunity_card');

  const stats = useMemo(() => {
    const searchValues = {
      'investorMultiple': '',
      'investorIRR': '',
      'targetHoldingPeriod': '',
      'investorCashflow': '',
    };

    Object.keys(searchValues).forEach((key) => {
      searchValues[key] = summary.find(({ id }) => id === key)?.value || emptyField;
    });

    const out: Array<
      {
        className: string;
        title: string;
        icon?: string;
        value: string;
        description: {
          label: string;
        };
      }> = [
      {
        className: styles['charts-container__investor-multiple'],
        title: tOpportunityCard('opportunity_card.investor_multiple'),
        icon: investorMultiple,
        value: searchValues['investorMultiple'],
        description: { label: tOpportunityCard('opportunity_card.investor_multiple.description') }
      },
      {
        className: styles['charts-container__investor-irr'],
        title: tOpportunityCard('opportunity_card.investor_irr'),
        icon: investorIrr,
        value: searchValues['investorIRR'],
        description: { label: tOpportunityCard('opportunity_card.investor_irr.description') }
      },
      {
        className: styles['charts-container__target-holding-period'],
        title: tOpportunityCard('opportunity_card.target_holding_period'),
        icon: iconTarget,
        value: searchValues['targetHoldingPeriod'],
        description: { label: tOpportunityCard('opportunity_card.target_holding_period.description') }
      },
      {
        className: styles['charts-container__investor-cashflow'],
        title: tOpportunityCard('opportunity_card.investor_cashflow'),
        icon: investorCashFlow,
        value: searchValues['investorCashflow'],
        description: { label: tOpportunityCard('opportunity_card.investor_cashflow.description') }
      }
    ];

    if (options.relincPrice) {
      out.push({
        className: styles['charts-container__relink-price'],
        title: tOpportunityCard('opportunity_card.relinc_price'),
        value: options.relincPrice,
        description: { label: tOpportunityCard('opportunity_card.relinc_price.description') }
      });
    }

    return out;
  }, [options.relincPrice, summary, tOpportunityCard]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1">
        <div className={styles['location']}>
          <div className={styles['location__icon-container']}>
            <LocationIcon />
          </div>
          <div className={styles['location__text-container']}>
            {cities || emptyField}
          </div>
        </div>
        <div className={styles['name']}>
          {organisation && (
              <>
                <div className={styles['name__name']}>
                  {organisation}
                </div>
                {' | '}
              </>
          )}
          <div
            className={styles['name__organization']}
            role="button"
            onClick={onPropertyClick}
          >
            {name}
          </div>
        </div>
      </div>
      <div className={styles['charts-container']}>
        {stats.map(({ className, title, icon, value, description }) => (
          <div className={className} key={className}>
            <PropertyCardDetailsStat
              description={description}
              title={title}
              icon={icon}
              value={value}
            />
          </div>
        ))}
        <div className={styles['charts-container__total-issuance-volume']}>
          <PropertyCardDetailsStat
            description={{ label: tOpportunityCard('opportunity_card.total_issuance_volume.description') }}
            title={tOpportunityCard('opportunity_card.total_issuance_volume')}
            value={searchValues["totalIssuanceVolume"]}
          />
        </div>
      </div>
      <div className='mt-3'>
        <PropertyCardDetailsTags tags={tags} onTagClick={onTagClick} />
      </div>
    </div>
  );
});
