import { FC, memo, useMemo } from "react";
import { Container } from "react-bootstrap"
import { InvestmentStrategy } from "./investment-strategy"
import { InvestmentOpportunityDetails } from "./investment-opportunity-details";
import { InvestmentFootnotes } from "./investment-footnotes";
import { InvestmentAboutTheProperty } from "./investment-about-the-property";
import { AssetTypeValue, CapitalStructureValues, FinancialStructureValues, PropertyTeaserType } from "store/properties/types";
import { useTranslate } from "@tolgee/react";

import styles from './style.module.scss';

interface PropertyInvestmentProps {
  property: PropertyTeaserType
}

export const PropertyInvestment: FC<PropertyInvestmentProps> = memo(({ property }) => {
  const { investmentStrategy, details, about, floorPlan } = property;
  const { footnotes, highlighted, secondary } = details;
  const { wysiwygDescription, values: aboutValues } = about;
  const { assets } = floorPlan;

  const { t: tEnums } = useTranslate('enums');
  const { t: tOpportunityDetailsInvestment } = useTranslate('opportunity_detail_page.investment');

  const existedFloorPlanImage = useMemo(() => (
    assets.find((img) => !!img)
  ), [assets]);

  const detailsValues = useMemo(() => [
    ...highlighted,
    {
      _id: 'assetType',
      id: 'assetType',
      name: tOpportunityDetailsInvestment('opportunity_detail_page.investment.asset_type', 'Asset Type'),
      value: secondary.assetType.map(key => tEnums(`enums.property_type.${key}`, AssetTypeValue[key])).join(', '),
    },
    {
      _id: 'capitalStructure',
      id: 'capitalStructure',
      name: tOpportunityDetailsInvestment('opportunity_detail_page.investment.capital_structure', 'Capital Structure'),
      value: tEnums(`enums.capital_structure.${secondary.capitalStructure}`, CapitalStructureValues[secondary.capitalStructure]),
    },
    {
      _id: 'financialStructure',
      id: 'financialStructure',
      name: tOpportunityDetailsInvestment('opportunity_detail_page.investment.financial_structure', 'Financiel Structure'),
      value: tEnums(`enums.financial_structure.${secondary.financialStructure}`, FinancialStructureValues[secondary.financialStructure]),
    },
    ...secondary.others
  ], [highlighted, secondary.assetType, secondary.capitalStructure, secondary.financialStructure, secondary.others, tEnums, tOpportunityDetailsInvestment]);

  const { t: tOpportunityDetailPageInvestment } = useTranslate('opportunity_detail_page.investment');

  const isVisible = useMemo(() => footnotes.find((el) => el.value.length), [footnotes]);
  return (
    <div>
      <Container>
        <InvestmentStrategy values={investmentStrategy.map(({ value }) => value)} />
      </Container>
      <div className={styles.strategy__wrapper}>
        <Container>
          <InvestmentOpportunityDetails values={detailsValues} />
          {
              isVisible && (
                <InvestmentFootnotes
                  title={tOpportunityDetailPageInvestment('opportunity_detail_page.investment.footnotes_title')}
                  values={footnotes.map(({ value }) => value)}
                />
            )
            }
        </Container> 
      </div> 
      <Container>
        <div className={styles['common__container']}>
          <InvestmentAboutTheProperty
            wysiwygDescription={wysiwygDescription}
            values={aboutValues}
            floorPlanImage={existedFloorPlanImage}
          />
        </div>
      </Container>
    </div>
  )
})

PropertyInvestment.displayName = 'OpportunityDetailsInvestments'