import { PropertyTeaserType } from 'store/properties/types';
import { FC, memo, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { PropertySubsectionTitle } from './property-subsection-title';
import { PropertyPartnersTable } from './property-partners-table';
import { ReactComponent as PeopleSvg } from "assets/ic_outline-people.svg";
import classNames from 'classnames';

import styles from './style.module.scss';

interface PropertyPartnersProps {
  property: PropertyTeaserType
}

const tableClass = classNames(styles.wrapper__partners);

export const PropertyPartners: FC<PropertyPartnersProps> = memo(({ property }) => {
  const { roles } = useMemo(() => {
    const { securityAndRoles } = property;
    const { roles, aboutThePartner } = securityAndRoles;
    const { text, image } = aboutThePartner || {};

    return { roles, text, image };
  }, [property]);

  return (
    <div className={styles.wrapper}>
      <Container>
        <PropertySubsectionTitle
          icon={<PeopleSvg />}
          title={"Roles"}
        />
      </Container>

      <Container
        className={
          styles['partners-table-container']
        }
      >
        <div className={tableClass}>
          <PropertyPartnersTable roles={roles} />
        </div>
      </Container>
    </div>
  );
})
