import { useTranslate } from "@tolgee/react";
import { FC, memo, useMemo } from "react"
import { SubscriptionStatus } from "store/properties/types";
import { propertyStatusColors } from "../../constans/property-status-colors";

import styles from './styles.module.scss'

interface PropertyStatusProps {
  status: SubscriptionStatus
}

export const PropertyStatus: FC<PropertyStatusProps> = memo(({ status }) => {
  const { color, backgroundColor } = useMemo(() => propertyStatusColors[status], [status]);
  const { t: tEnums } = useTranslate('enums');

  return (
    <div
      className={styles.status__container}
      style={{
        color,
        backgroundColor
      }}
    >
      {tEnums(`enums.subscription_status.${status}`) || status}
    </div>
  );
});

PropertyStatus.displayName = 'PropertyStatus'