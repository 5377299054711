import { memo, FC, useState, useCallback, useRef } from 'react';
import { ReactComponent as DownSvg } from 'assets/arrow-down.svg';
import { Spin } from 'antd';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/use-outside-click';

import styles from './style.module.scss';

interface PropertyActionsDropdownProps {
  actions: Array<{ label: string; action: () => void }>;
  isDoingAction: boolean;
  label: string;
}

export const PropertyActionsDropdown: FC<PropertyActionsDropdownProps> = memo(({
  actions,
  isDoingAction,
  label
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setIsDropDownOpen(false));

  const onDropDownOpen = useCallback(() => {
    if (isDoingAction) {
      return;
    }

    setIsDropDownOpen((prev) => !prev);
  }, [isDoingAction]);

  const doAction = useCallback((action: () => void) => {
    if (isDoingAction) {
      return;
    }
    setIsDropDownOpen(false);
    action();
  }, [isDoingAction]);

  return (
    <div 
      role="button"
      className={
        classNames(
          styles['button'],
          {[styles['button_open']]: isDropDownOpen}
        )
      }
      ref={containerRef}
    >
      <div 
        className={
          classNames(
            styles['button__content'],
            {[styles['button__content_open']]: isDropDownOpen}
          )
        }
      >
        <div 
          className={
            classNames(
              styles['button__text'],
              styles['button__hover-animated']
            )
          }
          onClick={onDropDownOpen}
        >
          {
            isDoingAction ?
            (
              <div
                className={styles['button__loading']}
              >
                Loading
                <Spin />
              </div>
            )
            :
            (
              <>{label}</>
            )
          }
        </div>
        <div
          className={
            classNames(
              styles['button__icon'],
              styles['button__hover-animated'],
              {[styles['button__icon_open']]: isDropDownOpen}
            )
          }
          onClick={onDropDownOpen}
        >
          <DownSvg />
        </div>
      </div>
      <div className={
          classNames(
            {[styles['button__dropdown_open']]: isDropDownOpen},
            styles['button__dropdown']
          )
        }
      > 
        {
          actions.map(({ label, action }) => (
            <div
              className={
                classNames(
                  styles['button__dropdown-item'],
                  styles[`button__dropdown-item-${label.substring(0,3).toLowerCase()}`]
                )
            }
              onClick={() => {
                doAction(action);
              }}
              key={label}
            >
              {label}
            </div>
          ))
        }
      </div>
    </div>
  )
});
