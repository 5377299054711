import { EducationMaterialsGroup } from "../types/education-materials/education-materials-group";
import { WhoAreYou } from "../types/user/who-are-you";
import { privateApi } from "./estatingApi";

export const EducationMaterialsApi = {
  queryEducationMaterialsGroups: async (whoAreYou: WhoAreYou | string) => {
    return new Promise<Array<EducationMaterialsGroup>>(async (resolve, reject) => {
      try {
        const response = await privateApi.get(`/education-materials-groups?who-are-you=${whoAreYou}`);
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to get groups'));
      }
    });
  },

  querySearchEducationMaterialsGroups: async (params?: Record<string, any>) => {
    return new Promise<Array<EducationMaterialsGroup>>(async (resolve, reject) => {
      try {
        const response = await privateApi.get(
          `/education-materials-groups`,
          { params }
        );
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(new Error(response?.data?.message || 'Something went wrong'));
      } catch (error: any) {
        reject(new Error(error?.response?.data?.message || 'Fail to get groups'));
      }
    });
  },

  async downloadMaterial(requestUrl: string, fileName: string) {
    return new Promise(async (resolve, reject) => {
      try {

        const response = await fetch(requestUrl, {
          method: "GET"
        })

        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
  
        document.body.appendChild(link);
  
        link.click();
        link.remove();
        resolve(null);
    } catch (err) {
        reject(err);
    }
    })
  }
};
