import classNames from "classnames";
import { ReactElement, useRef, useState } from "react";
import "./TopFilter.scss";
import { ReactComponent as HamburguerSvg } from "../../assets/hamburger.svg";
import { Modal } from "../Modal/Modal";
import ChevronDown from "../Icons/ChevronDown";
import useOnClickOutside from "../Hooks/useOnClickOutside";

export interface PropertiesTopFilterProps {
  items: {
    icon: ReactElement;
    key: string;
    name: string;
    counter: number;
  }[];
  defaultSelectedIndex: number;
  onChange: (key: string) => void;
}

interface MenuListProps extends PropertiesTopFilterProps {
  onSelectItem: (index: number) => void;
  selectedIndex: number;
}

function MobileMenu(props: MenuListProps & { onClose: () => void }) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, props.onClose);

  const onSelectItem = (index: number) => {
    props.onClose();
    props.onSelectItem(index);
  };

  return (
    <Modal>
      <div className="property-top-filter-mobile-menu">
        <div ref={ref} className="content">
          <MenuList {...props} onSelectItem={onSelectItem} />
          <button onClick={() => props.onClose()}>
            <ChevronDown width={25} height={25} strokeWidth={2.5} />
          </button>
        </div>
      </div>
    </Modal>
  );
}

function MenuList(props: MenuListProps) {
  return (
    <ul>
      {props.items.map((item, i) => (
        <li
          data-testid={`item-${i}`}
          className={classNames({ active: props.selectedIndex === i })}
          onClick={() => props.onSelectItem(i)}
          key={i}
        >
          <span className="active-sign"></span>
          <span className="content">
            {item.icon}
            {item.name} ({item.counter})
          </span>
        </li>
      ))}
    </ul>
  );
}

export default function PropertiesTopFilter(props: PropertiesTopFilterProps) {
  const [selectedIndex, setSelectedIndex] = useState(
    props.defaultSelectedIndex
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onSelectItem = (index: number) => {
    setSelectedIndex(index);
    props.onChange(props.items[index].key);
  };

  const currentItem = props.items[selectedIndex];

  return (
    <div className="property-top-filter">
      {currentItem && (
        <div className="property-top-filter-current-item">
          <span>
            {currentItem.name} ({currentItem.counter})
          </span>
          <button
            data-testid="mobile-menu"
            onClick={() => setMobileMenuOpen(true)}
          >
            <HamburguerSvg />
          </button>
          {mobileMenuOpen && (
            <MobileMenu
              onClose={() => setMobileMenuOpen(false)}
              onSelectItem={onSelectItem}
              selectedIndex={selectedIndex}
              {...props}
            />
          )}
        </div>
      )}
      <MenuList
        onSelectItem={onSelectItem}
        selectedIndex={selectedIndex}
        {...props}
      />
    </div>
  );
}
