import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ReactComponent as ShareSvg } from "assets/share-property.svg";
import { ReactComponent as DownloadArrow } from "assets/arrowDown.svg";
import classNames from "classnames";
import { EducationAndResearchContext } from "../property-education-and-research-card";
import { PropertyEducationAndResearchShareModal } from "../property-education-and-research-share-modal";
import { EducationMaterial } from "types/education-materials/education-material";
import { useTrackEducationAndResearchDownloadMutation, useTrackEducationAndResearchMaterialShareMutation, useTrackEducationAndResearchShareLinkCopyMutation } from "api/analitics";
import { useTranslate } from "@tolgee/react";
import { downloadFile } from "helpers/download-file";

import style from "./style.module.scss";

const buttonBoxClass = classNames(
  "d-flex align-items-center cursor-pointer",
  style["education-and-research__box"]
);
const buttonsBoxClass = classNames(
  "d-flex",
  style["education-and-research__button"]
);

export const PropertyEducationAndResearchButtons = memo(() => {
  const material = useContext(EducationAndResearchContext);
  const { t: tOpportunityDetailPageEducationAndResearch } = useTranslate('opportunity_detail_page.education_and_research');

  const [sharedMaterial, setSharedMaterial] =
    useState<null | EducationMaterial>(null);
  const [isCopyClicked, setIsCopyClicked] = useState<boolean>(false);

  const onHide = useCallback(() => {
    setSharedMaterial(null);
    setIsCopyClicked(false);
  }, []);

  const shareFileEncodedLink = useMemo(() => {
    if (!sharedMaterial) {
      return "";
    }

    return `${window.location.origin}/estating-academy/${sharedMaterial._id}`;
  }, [sharedMaterial]);
  
  const [trackCopyLink] = useTrackEducationAndResearchShareLinkCopyMutation();

  const onCopyDownloadMaterialLink = useCallback(async () => {
    try {
      trackCopyLink({
        fileName: sharedMaterial!.originalName,
        material: sharedMaterial!.name,
        materialId: sharedMaterial!._id
      });
      await navigator.clipboard.writeText(shareFileEncodedLink);
      setIsCopyClicked(true);
    } catch (err) {
      console.error(err);
    }
  }, [shareFileEncodedLink, sharedMaterial, trackCopyLink]);



  const [trackShareButtonClick] = useTrackEducationAndResearchMaterialShareMutation();

  const handleShare = useCallback((material: EducationMaterial) => {
    setSharedMaterial(material);
    trackShareButtonClick({ material: material.name, materialId: material._id});
  }, [setSharedMaterial, trackShareButtonClick]);

  const [trackDownload] = useTrackEducationAndResearchDownloadMutation();

  const onDownloadMaterial = useCallback(async () => {
    try {
      if (!material) {
        return;
      }

      trackDownload({
        material: material.name,
        materialId: material._id,
        fileName: material.originalName,
      });
    } catch (err) {
      console.error(err);
    }
  }, [material, trackDownload]);

  const buttonsArray = useMemo(() => [
    {
      name: tOpportunityDetailPageEducationAndResearch('opportunity_detail_page.education_and_research.button.share'),
      icon: <ShareSvg />,
      onClick: ((material: EducationMaterial) => handleShare(material))
    },
    {
      name: tOpportunityDetailPageEducationAndResearch('opportunity_detail_page.education_and_research.button.download'),
      icon: <DownloadArrow />,
      file: material?.file,
      onClick: (material: EducationMaterial) => {
        Promise.all([
          downloadFile(material.file, material.originalName),
          onDownloadMaterial()
        ]) 
      }
    },
  ], [handleShare, material?.file, onDownloadMaterial, tOpportunityDetailPageEducationAndResearch]);

  if (!material) {
    return null;
  }

  return (
    <div className={buttonsBoxClass}>
      {buttonsArray.map(({ name, icon, file, onClick }) => {
        return (
          <Fragment key={name}>
            {file ? (
              <div
                className={buttonBoxClass}
                onClick={() => onClick(material)}
              >
                {icon}
                <span className={style["education-and-research__title"]}>
                  {name}
                </span>
              </div>
            ) : (
              <div
                className={buttonBoxClass}
                onClick={() => onClick(material)}
              >
                {icon}
                <span className={style["education-and-research__title"]}>
                  {name}
                </span>
              </div>
            )}
          </Fragment>
        );
      })}
      <PropertyEducationAndResearchShareModal
        isCopyClicked={isCopyClicked}
        onHide={onHide}
        onCopyDownloadMaterialLink={onCopyDownloadMaterialLink}
        shareFileEncodedLink={shareFileEncodedLink}
        sharedMaterial={sharedMaterial}
      />
    </div>
  );
});
