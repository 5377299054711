import { FC, memo } from "react";
import styles from './style.module.scss';

interface SummaryCardBannerTextProps {
  cardText: Array<{ content: string; }>;
}

export const SummaryCardBannerText:FC<SummaryCardBannerTextProps> = memo(({cardText}) => {
  return(
    <div className={styles.bannerTextBox}>
      {cardText.map(({ content }) => 
        <div className={styles.container__item} key={content}>
          {content}
        </div>
      )}
    </div>
  );
});

SummaryCardBannerText.displayName = 'SummaryCardBannerText';
