import React from 'react'

type PrivacyPolicyProps = {}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {

    return (
        <div className='container py-6'>
            <h1 className='font-weight-bold mb-5 text-primary'>Privacy policy</h1>
            <div>
                <h2 className='my-4 text-primary'>1. INTRODUCTION</h2>
                <p>Estating S.A. is the holding company of Estating Group. This privacy policy will explain how our
                    organization uses the personal data we collect from you when you use our website or app.</p>

                <h3 className='my-3 font-weight-bold'>Scope</h3>
                <p>Estating S.A. takes its clients data protection seriously and treats data with confidentiality and
                    according to data protection laws. We will collect certain personal data if you visit Estating S.A.
                    websites/app. The following data privacy policy explains, which data Estating S.A. collects, for
                    which purposes and informs about your rights.</p>
                <p>If you choose to use our service, then you agree to the collection and use of information in relation
                    to this policy. The personal Information that we collect is used for providing and improving the
                    service. We will not use or share your information with anyone except as described in this Privacy
                    Policy.</p>
                <p>The web site/app owner is responsible for data collection. You will find contact details in the
                    imprint on Estating S.A.’s website and further below in the Data Privacy Policy.</p>

                <h3 className="my-3 font-weight-bold">Principles</h3>
                <p>Our principles for processing personal data are:</p>
                <p><b>Fairness and lawfulness.</b> When we process personal data, the individual rights of the clients
                    must be protected. All personal data must be collected and processed in a legal and fair manner</p>
                <p><b>Restricted to a specific purpose.</b> The personal data of clients must be processed only for
                    specific purposes.</p>
                <p><b>Transparency.</b> The client must be informed of how his/her data is being collected, processed
                    and used.</p>
            </div>
            <div>
                <h2 className='my-4 text-primary'>2. DATA</h2>
                <h3 className='font-weight-bold'>What data do we collect?</h3>
                <p>Estating S.A. collects the following data:</p>
                <ul className="ml-3">
                    <li>Personal identification information (Name, email address, phone number, your financial
                        advisor)</li>
                    <li>Server log-in Data</li>
                    <li>Browser type and version </li>
                    <li>Operating system</li>
                    <li>URL</li>
                    <li>Hostname</li>
                    <li>Time of visit</li>
                    <li>IP address</li>
                </ul>

                <h3 className='my-3 font-weight-bold'>What data do we collect?</h3>
                <p>We want to inform you that whenever you use our service, in a case of an error in the app we collect
                    data and information (through third party products) on your phone called log data. This log data may
                    include information such as your device internet protocol (“IP”) address, device name, operating
                    system version, the configuration of the app when utilizing our Service, the time and date of your
                    use of the Service, and other statistics.</p>
                <h3 className='my-3 font-weight-bold'>How do we collect your data?</h3>
                <p>You directly provide Estating S.A. with most of the data we collect. We collect data and process data
                    when you:</p>
                <ol className="ml-3" type='a'>
                    <li>Register online </li>
                    <li>Voluntarily when you complete a customer survey, complete a contact form or provide feedback
                        on any of our message boards or via email</li>
                    <li>Use or view our website via your browser’s cookies. </li>
                    <li>Contact us via social media, e.g. linkedin</li>
                </ol>

                <p>When you use the app, third parties may collect, store and/or process the information detailed in
                    this Data Privacy Policy. These third parties are carefully selected to ensure that they, and their
                    related entitites, who may access your information, provide your information with equal protection
                    to that stated in this Privacy Policy and required by applicable privacy laws.</p>
                <p>Link to privacy policy of third party service providers used by the app:</p>
                <a href="https://policies.google.com/privacy" target='_blank' rel="noreferrer">Google Play Services</a>

                <h3 className='my-3 font-weight-bold'>How will we use your data?</h3>
                <p>Estating S.A. collects your data so that we can:</p>
                <ul className='ml-3'>
                    <li>Maintain website functionality</li>
                    <li>Measure success of marketing campaigns</li>
                    <li>Target better offers to our users</li>
                    <li>Provide a contact form and respond to your request</li>
                    <li>Email you with special offers on other products and services we think you might like
                        (newsletter).
                    </li>
                </ul>
                <span>Analysis of user behaviour with google analytics</span>

                <h3 className='my-3 font-weight-bold'>What is the legal basis for collecting, storing and processing personal data?</h3>
                <p>The egal basis for collecting and using the personal data described in this Data Privacy Policy
                    depends on the personal data we collect and the specific context in which we collect the
                    information:</p>
                <ul className='ml-3'>
                    <li>Estating S.A. needs to perform a contract with you</li>
                    <li>You have given consent to do so</li>
                    <li>Processing your personal data is in Estating S.A. legitimate interests and may be required to
                        comply with the law
                    </li>
                </ul>

                <p>Estating S.A. will retain and use your information to the extent necessary to comply with our legal
                    obligations, resolve disputes, and enforce our policies.</p>
                <p>Estating keeps your data in compliance with GDPR requirements and complies with GDPR removal rules.
                    Legal retention periods are preserved.</p>
                <p>Data based on contact forms and emails are stored to enable processing of your questions/comments and
                    follow-up communication. We process the data due to your consent. You may ask to revoke your consent
                    at any time via email. Your provided data will be stored until you request erasure, the purpose for
                    storing the data ceases or you revoke your consent.</p>

                <h3 className='my-3 font-weight-bold'>Marketing</h3>
                <p>We would like to send you information about products and services of ours that we think you might
                    like, via our Estating Newsletter.</p>
                <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>
                <p>You have the right at any time to stop us from contacting you for marketing purposes or giving your
                    data to other members of the group.</p>
                <p>If you no longer wish to be contacted for marketing purposes, please unsubscribe using the link in
                    our email </p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>3. WHAT ARE YOUR PROTECTION RIGHTS?</h2>
                <p>We would like to make sure you are fully aware of all of your data protection rights.Every user is
                    entitled to the following:</p>
                <p><u>The right to access</u> - You have the right to request copies of your personal data. </p>
                <p><u>The right to rectification</u> - You have the right to request that we correct any information you
                    believe is inaccurate. You also have the right to request to complete information you believe is
                    incomplete.</p>
                <p><u>The right to data portability</u> - You have the right to request that we transfer the data that
                    we have collected to another organization, or directly to you</p>
                <p><u>The right to erasure</u> - You have the right to request that we erase your personal data.</p>
                <p><u>The right to restrict processing</u> - You have the right to request that we restrict the
                    processing of your personal data.</p>
                <p><u>The right to object to processing</u> - You have the right to object to us processing of your
                    personal data.</p>
                <p>The right to erasure, to restrict processing or to object to processing is contingent on data being
                    no longer necessary for the performance of contractual, compliance or statutory obligations.</p>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these rights, please contact us at our email:</p>
                <p>data.protection@estating.com</p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>4. COOKIES</h2>
                <h3 className='font-weight-bold'>What are cookies?</h3>
                <p><b>Cookies</b> are text files with small pieces of data — like a username and password — that are
                    used to identify your computer as you use a computer network, to collect standard Internet log and
                    visitor behaviour information.</p>
                <p>When you visit our websites, we may collect information from you automatically through cookies or
                    similar technology. For further information, you may visit <a
                        href="https://www.allaboutcookies.org" target='_blank' rel="noreferrer">www.allaboutcookies.org</a>.</p>

                <h3 className='my-3 font-weight-bold'>How do we use cookies?</h3>
                <p>Estating S.A. uses cookies in a range of ways to improve your experience on our website,
                    including:</p>
                <ul className="ml-3">
                    <li>Keeping you signed in</li>
                    <li>Understanding how you use our website</li>
                </ul>

                <h3 className='my-3 font-weight-bold'>What type of cookies do we use?</h3>
                <p>There are a number of different types of cookies, however, our website uses:</p>
                <ol className="ml-3" type='1'>
                    <li>Strictly necessary cookies - These cookies are essential for you to browse the website and use
                        its features, such as accessing secure areas of the site
                    </li>
                    <li>Functionality - We use these cookies so that we recognize you on our website and remember your
                        previously selected preferences. These could include what language you prefer and location you
                        are in. A mix of first-party and third-party cookies are used.
                    </li>
                    <li>Advertising - We use these cookies to collect information about your visit to our website, the
                        content you viewed, the links you followed and information about your browser, device, and your
                        IP address. We may also share online data collected through cookies with our advertising
                        partners in the future. This means that when you visit another website, you may be shown
                        advertising based on your browsing patterns on our website/app.
                    </li>
                    <li>Statistics cookies – We use these cookies to collect information about how you use our website,
                        like which pages you visited, and which links you clicked on. None of this information can be
                        used to identify you. It is all aggregated and, therefore, anonymized. Their sole purpose is to
                        improve website functions. This includes cookies from third-party analytics services as long as
                        the cookies are for the exclusive use of the owner of the website visited.
                    </li>
                </ol>

                <h3 className='my-3 font-weight-bold'>How to manage cookies?</h3>
                <p>You can set your browser to inform you about, restrict the use of or not to accept cookies, and the
                    above website tells you how to remove cookies from your browser. However, in a few cases, some of
                    our website features may not function as a result. We will ask for your consent when you open our
                    website. </p>


            </div>

            <div>
                <h2 className='my-4 text-primary'>5. SOCIAL MEDIA</h2>
                <p>We are active on social media platforms and is processing user data for communication purposes. User
                    data may be processed outside of the European Union which may restrict user data rights. Social
                    media platforms maintain their own data protection rules. For further information or any requests
                    please contact the platform providers directly</p>
                <p><b>LinkedIn:</b> (website) <a href="https://www.linkedin.com"
                                                target='_blank' rel="noreferrer">https://www.linkedin.com</a></p>
                <p><b>Data Privacy Policy:</b> <a
                    href="https://www.linkedin.com/legal/privacy-policy" target='_blank' rel="noreferrer">https://www.linkedin.com/legal/privacy-policy</a>
                </p>
                <p><b>Medium:</b> (website) <a href="https://medium.com" rel="noreferrer" target='_blank'>https://medium.com</a></p>
                <p><b>Data Privacy Policy:</b> <a
                    href="https://policy.medium.com/medium-privacy-policy-f03bf92035c9" target='_blank' rel="noreferrer">https://policy.medium.com/medium-privacy-policy-f03bf92035c9</a>
                </p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>6. NEWSLETTER AND OTHER ELECTRONIC COMMUNICATION</h2>
                <p>We are using newsletter, emails or other electronic communication only with your consent or if
                    legally required.</p>
                <p>You can register for any electronic communication by providing your email address. We may ask for
                    your name and further information if necessary, to provide appropriate services to you.</p>
                <p><b>Double-Opt-In:</b> When you register for an electronic communication, we will send you an email
                    and ask for verification of your registration to avoid misuse of email addresses. We are storing
                    registration information, e.g. registration time and IP address.</p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>7. USER ACCOUNT</h2>
                <p>Users, e.g. contractual partners, can register and open accounts. During the registration we will ask
                    for mandatory details. We will collect data at all times a user registers, accesses or uses the
                    personal account to prove who registered and to prevent any misuse.</p>
                <p>We will delete user data after account closure, legal retention periods are preserved. If you want to
                    delete your account, please send an email to <a
                        href="mailto:data.protection@estating.com">data.protection@estating.com</a></p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>8. CLOUD SERVICES</h2>
                <p>We are using cloud service providers (Microsoft 365, Amazon Web Services) which are accessible
                    through the Internet for several business processes, e.g. document management, calendar management,
                    email, spreadsheet applications, presentations, video conferences, chats. In this context, personal
                    data may be processed and stored on the service provider’s servers, as far as we process data as
                    described in this policy. The service providers also process usage data and meta data for security
                    reasons and service optimization. </p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>9. CLIENT RELATIONSHIP MANAGEMENT</h2>
                <p>We are using Hubspot Inc. Massachusetts USA, as client relationship management system. Hubspot
                    confirms that no transfer of European data to any country or recipient not recognized as providing
                    an adequate level of protection for personal data (within the meaning of applicable European Data
                    Protection Laws) will take place, unless it first takes all such measures as are necessary to ensure
                    the transfer is in compliance with applicable European Data Protection Laws. For further information
                    please use the Hubspot data processing agreement:</p>
                <a href="https://legal.hubspot.com/dpa" target='_blank' rel="noreferrer">https://legal.hubspot.com/dpa</a>
            </div>

            <div>
                <h2 className='my-4 text-primary'>10. CHILDREN’S PRIVACY</h2>
                <p>Our services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
            </div>

            <div>
                <h2 className='my-4 text-primary'>11. GENERAL INFORMATION</h2>
                <h2>Privacy policies of other websites</h2>
                <p>Our website contains links to other third party resources. Our  Data Privacy Policy applies only to our website/app, so if you click on a link to another website, you should read their privacy policy.</p>
                <p>Third-party resources are not under our control, and we are not responsible for the contents of any of websites. The third-party hypertext links presented on this site are provided for your convenience only. The inclusion of any link on this website does not imply any recommendation, approval or endorsement of that site by us.</p>
                <h3 className="my-3 font-weight-bold">Security</h3>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h3 className="my-3 font-weight-bold">Chances to our privacy policy</h3>
                <p>We keep our Data Privacy Policy under regular review and place any updates on this web page. This Data Privacy Policy was last updated on 20 April 2021.</p>
                <h3 className="my-3 font-weight-bold">How to contact us</h3>
                <p>If you have any questions about our Data Privacy Policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
                <h3 className="my-3 font-weight-bold">How to contact the appropriate authority</h3>
                <p>Email us at: <a href="mailto:data.protection@estating.com">data.protection@estating.com</a></p>
                <p>Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact the Luxembourg data protection supervisory authority <b>Commission nationale pour la protection des données (CNPD)</b></p>

                <p>To contact the CNPD, you may use the form on the CNPD website via the <a href="https://cnpd.public.lu/en/particuliers/faire-valoir/formulaire-plainte.html" target='_blank' rel="noreferrer">following link</a>  or use the contact form <a
                    href="https://cnpd.public.lu/en/support/contact.html" target='_blank' rel="noreferrer">here.</a></p>

                <p>The form may be completed online or sent to the CNPD by post to the following address:</p>

                <b>
                    Commission nationale pour la protection des données<br />
               Service des réclamations<br />
               15, Boulevard du Jazz<br />
               L-4370 Belvaux</b>
            </div>
        </div>
    )
}

export default PrivacyPolicy
