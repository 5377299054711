export enum PropertySectionAnchor {
  INVESTMENT = 'opportunity_detail_page.anchor_navigation.investment',
  PARTNER = 'opportunity_detail_page.anchor_navigation.partner',
  LOCATION = 'opportunity_detail_page.anchor_navigation.location',
  RISK = 'opportunity_detail_page.anchor_navigation.risk',
  ROLES = 'opportunity_detail_page.anchor_navigation.roles',
  SECURITY_INFORMATION = 'opportunity_detail_page.anchor_navigation.security_information',
  DATA_ROOM = 'opportunity_detail_page.anchor_navigation.data-room',
  INVESTOR_REPORT = 'opportunity_detail_page.anchor_navigation.investor_report',
  EDUCATION_AND_RESEARCH = 'opportunity_detail_page.anchor_navigation.education_and_research',
  DISCLAIMER = 'opportunity_detail_page.anchor_navigation.disclaimer'
};
