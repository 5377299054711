import { memo, FC } from 'react';
import { TagDescription } from '../store/search';

import styles from './tags.module.scss';

interface TagsProps {
  tags: Array<TagDescription>;
  onClick: (tag: TagDescription) => void;
};

export const Tags: FC<TagsProps> = memo(({ tags, onClick }) => {
  return (
    <div className={styles['tags']}>
    {tags.map((tag, index) => {
      if (!tag) {
        return null;
      }

      return (
        <span
          key={tag.value}
          onClick={() => onClick(tag)}
          className={styles['tags__item']}
        >
          {tag.label}
        </span>
      );
    })}
  </div>
  );
});