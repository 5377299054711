import { EducationMaterialsApi } from "../../api/education-materials";
import { WhoAreYou } from "../../types/user/who-are-you";
import { AppThunk } from "../types";
import { setErrorEducationMaterialsGroups, setGroupsEducationMaterialsGroups, setLoadingEducationMaterialsGroups } from "./actions";

export const getEducationMaterialsGroups: (whoAreYou: WhoAreYou | string) => AppThunk = (whoAreYou) => async (dispatch) => {
  try {
    dispatch(setLoadingEducationMaterialsGroups(true));
    dispatch(setGroupsEducationMaterialsGroups(await EducationMaterialsApi.queryEducationMaterialsGroups(whoAreYou)));
  } catch (error: any) {
    const errorMessge = error.message || 'Error on loading education materials';
    dispatch(setErrorEducationMaterialsGroups(errorMessge));
  } finally {
    dispatch(setLoadingEducationMaterialsGroups(false));
  }
};

export const searchEducationMaterialsGroups: (
  queryParams?: Record<string, any>
) => AppThunk = (queryParams) => async (dispatch) => {
  try {
    dispatch(setLoadingEducationMaterialsGroups(true));
    dispatch(
      setGroupsEducationMaterialsGroups(
        await EducationMaterialsApi.querySearchEducationMaterialsGroups(
          queryParams
        )
      )
    );
  } catch (error: any) {
    const errorMessge = error.message || "Error on loading education materials";
    dispatch(setErrorEducationMaterialsGroups(errorMessge));
  } finally {
    dispatch(setLoadingEducationMaterialsGroups(false));
  }
};