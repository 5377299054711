import { memo, useCallback, useMemo, useRef, useState } from 'react';
import useDebounce from '../../../components/Hooks/useDebounce';
import Input from '../../../components/Input';
import PropertiesTopFilter from '../../../components/Properties/TopFilter';
import { PropertyObj, PropertyOpportunityTypeEnum } from '../../../store/properties/types';
import { ReactComponent as AllDealsSvg } from "assets/all-deals.svg";
import { ReactComponent as MyListSvg } from "assets/my-list.svg";
import SearchSVG from "assets/search.svg";
import { useGetPropertyTeasersCountQuery, useGetPropertyTeasersQuery } from 'api/property-teaser';
import { PropertiesTimelineGanttChart } from '../../../components/properties-timeline-gantt-chart';
import { ScreenShoot } from '../../hocs/screen-shoot';
import { createFileName } from 'helpers/create-file-name';
import Button from '../../../components/Button';
import PropertyListEmpty from '../../../components/Properties/ListEmpty';
import { typesCases } from 'helpers/type-filter-cases';
import { useTolgee, useTranslate } from '@tolgee/react';

const INPUT_PLACEHOLDERS = {
  'all-deals': 'opportunity_timeline.content.search_placeholder_all_deals',
  'my-list': 'opportunity_timeline.content.search_placeholder_my_list',
}

export const TeaserTimeline = memo(() => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [searchedValues, setSearchedValues] = useState<string[]>([]);
  const tolgee = useTolgee();
  
  const [propertyType, setPropertyType] =
    useState<PropertyOpportunityTypeEnum>();
  const [bookmarkedFilter, setBookmarkedFilter] =
    useState<boolean>(false);
  const [propertyStatus, setPropertyStatus] =
    useState<string>();

  const [searchPlaceholder, setSearchPlaceholder] = useState(INPUT_PLACEHOLDERS['all-deals']);

  const { data: matchedProperties, /*isError: isErrorMatchedProperties, error: errorMatchedProperties,*/ isLoading: isLoadingMatchedProperties, isFetching: isFetchingMatchedProperties } = useGetPropertyTeasersQuery({
    opportunityType: propertyType,
    bookmarked: bookmarkedFilter,
    status: propertyStatus,
    search: debouncedSearchValue ? debouncedSearchValue : undefined,
    tags: searchedValues,
    timelineEnabled: true,
    containEvents: true,
    lang: tolgee.getPendingLanguage()
  });

  const { data: properyTeaserCountResult, isError: isErrorTeaserCountResult, /*error: errorTeaserCountResult,*/ isLoading: isLoadingTeaserCountResult, isFetching: isFetchingTeaserCountResult } = useGetPropertyTeasersCountQuery({ timelineEnabled: true, containEvents: true });

  const { t: tHomapageSearch } = useTranslate('homepage.search');
  const { t: tOpportunityTimelineContent } = useTranslate('opportunity_timeline.content');

  const propertyFilters = useMemo(() => {
    return isErrorTeaserCountResult || isLoadingTeaserCountResult || isFetchingTeaserCountResult ? [] : 
    [
      {
        name: tHomapageSearch('homepage.search.all_deals'),
        key: 'all-deals',
        counter: properyTeaserCountResult.all,
        icon: <AllDealsSvg />,
      },

      {
        name: tOpportunityTimelineContent('opportunity_timeline.content.my_list'),
        key: 'my-list',
        counter: properyTeaserCountResult.bookmarked,
        icon: <MyListSvg />,
      },
    ]
  }, [isErrorTeaserCountResult, isLoadingTeaserCountResult, isFetchingTeaserCountResult, tHomapageSearch, properyTeaserCountResult, tOpportunityTimelineContent]);

  const setValueIfNotExists = (value: string) => {
    let exists =
      searchedValues.findIndex((elem) => {
        return elem.toLowerCase() === value.toLowerCase();
      }) !== -1;

    if (!exists) {
      setSearchedValues([...searchedValues, value]);
    }
  };
  
  const onChangeFilter = useCallback((key: string) => {
    let bookmark = false;
    let status: string | undefined = undefined;

    switch (key) {
      case 'my-list':
        bookmark = true;
      break;
      
      case 'search-mandates':
        status = 'Search Mandate';
      break;

      default: 
        break;
    }

    setBookmarkedFilter(bookmark);
    setPropertyStatus(status)
    setSearchPlaceholder(INPUT_PLACEHOLDERS[key]);
    setPropertyType(typesCases[key]);
  }, []);
  
  const screenShootHocRef = useRef<any>(null);

  const onDownloadImage = useCallback(async () => {
    const image = await screenShootHocRef.current?.onTakeScreenShot();

    if (image) {
      const a = document.createElement("a");
      a.href = image;
      a.download = createFileName('jpeg', Date.now());
      a.click();
    }
  }, [screenShootHocRef]);  

  const { t: tSearchNotFound } = useTranslate('homepage.search');

  const NoResults = useCallback(() => {
    let text = tSearchNotFound("homepage.search.investment_opportunities");
    
    if (bookmarkedFilter) {
      text = tSearchNotFound('homepage.search.my_list')
    }

    return (
      <div className="col-8 col-md-6 col-xl-4 mx-auto mt-7 d-flex align-items-center flex-column">
        <p className="text-center">
          {tSearchNotFound('homapage.search.not_match.your_search')}
          {' '}
          <b className="text-lowercase">{text}</b>
          {' '}
          {tSearchNotFound('homepage.search.we_have')}
        </p>
        <p className="text-center">
          {tSearchNotFound('homepage.search.suggestions')}
          <ul className="text-left">
            <li>{tSearchNotFound('homepage.search.make_sure')}</li>
            <li>{tSearchNotFound('homepage.search.try_different')}</li>
            <li>{tSearchNotFound('homepage.search.try_fewer')}</li>
          </ul>
        </p>
      </div>
    );
  }, [bookmarkedFilter, tSearchNotFound]);

  return (
    <div className="d-flex flex-column flex-grow-1 col-xl-10 col-sm-11 pt-5 col-11 px-0 mx-auto properties">
      <div className="fade-anim">
        <h1 className="d-lg-none">
          {tOpportunityTimelineContent('opportunity_timeline.content.opportunity_timeline')}
        </h1>
        <PropertiesTopFilter
          items={propertyFilters}
          defaultSelectedIndex={0}
          onChange={onChangeFilter}
        />
        <Input
          inputAttributes={{
            placeholder: tOpportunityTimelineContent(searchPlaceholder),
            value: searchValue,
            onKeyDown: (e) => {
              if (e.keyCode === 13) {
                setValueIfNotExists(searchValue);
                setSearchValue("");
              }
            },
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className="radius-20 mt-5"
          mergeIcon={true}
          leftIcon={
            <img
              alt="search"
              onClick={() => {
                if (searchValue) {
                  setValueIfNotExists(searchValue);
                  setSearchValue("");
                }
              }}
              src={SearchSVG}
              className="cursor-pointer"
            />
          }
        />
        {searchedValues.length > 0 ? (
          <div className="my-3 d-flex flex-wrap">
            {searchedValues.map((searchVal, index) => {
              return (
                <div
                  className="d-flex bg-gray mx-2 radius-5 py-2 justify-content-between"
                  key={searchVal + index}
                >
                  <span className="mx-2" style={{ wordBreak: "break-word" }}>
                    {searchVal}
                  </span>
                  <i
                    className="mr-2 fe fe-x cursor-pointer"
                    onClick={() => {
                      setSearchedValues([
                        ...searchedValues.filter((_, idx) => idx !== index),
                      ]);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {isLoadingMatchedProperties || isFetchingMatchedProperties || isLoadingTeaserCountResult ? (
        <div className="d-flex justify-content-center mt-8 align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (properyTeaserCountResult.all ? (
        matchedProperties?.length ? (
          <>
            <div 
              className='flex-grow-1 d-flex'
            >
              <ScreenShoot ref={screenShootHocRef}>
                <PropertiesTimelineGanttChart propertiesObjects={matchedProperties as Array<PropertyObj>} />
              </ScreenShoot>
            </div>
            <div className='w-100 d-flex align-items-center justify-content-end my-3'>
              <Button
                className="btn-block"
                onClick={onDownloadImage}
                styles={{ width: 'auto' }}
              >
                {tOpportunityTimelineContent('opportunity_timeline.content.button_download_image')}
              </Button>
            </div>
        </>
        ) : <NoResults/>
      ) : <PropertyListEmpty />)
}
    </div>
  )
});
