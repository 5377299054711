import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { PDFViewer } from "../components/pdf-viewer";
import { EducationMaterial } from "../types/education-materials/education-material";
import { ReactBootstrapPopUp } from "./react-bootstrap-pop-up";
import { SuggestedMaterialsList } from "../components/education-materials/suggested-materials-list";
import { Helmet } from 'react-helmet';

import styled from 'styled-components';
import { useTranslate } from "@tolgee/react";
interface MaterialViewProps {
  file: string;
  mimeType: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  suggestedMaterials: Array<EducationMaterial>;

  //  analitics
  material?: EducationMaterial;
  onMaterialOpen?: (material: EducationMaterial, page: number) => void;
  onMaterialClose?: (material: EducationMaterial, page: number) => void;
  onMaterialPageChange?: (material: EducationMaterial, page: number) => void;
}

export const MaterialView: FC<MaterialViewProps> = memo(({
  file,
  mimeType,
  isOpen,
  onClose,
  title,
  suggestedMaterials,

  material,
  onMaterialOpen,
  onMaterialClose,
  onMaterialPageChange
}) => {
  const currentPage = useRef(0);

  const onForceClose = useCallback(() => {
    if (!material) {
      return;
    }

    if (onMaterialClose) {
      onMaterialClose(material, currentPage.current);
    }
  }, [material, onMaterialClose]);

  useEffect(() => {
    window.onbeforeunload = onForceClose;

    return () => {
      window.onbeforeunload = null;
    }
  }, [onForceClose]);

  useEffect(() => {
    if (material && isOpen && onMaterialOpen) {
      onMaterialOpen(material, currentPage.current);
    }

    return () => {
      if (material && onMaterialClose) {
        onMaterialClose(material, currentPage.current);
      }
    }

  }, [isOpen, material, onMaterialClose, onMaterialOpen]);

  const _onMaterialPageChange = useCallback((page: number) => {
    if (!onMaterialPageChange || !material) {
      return;
    }

    currentPage.current = page;
    onMaterialPageChange(material, page);
  }, [material, onMaterialPageChange]);

  const mediaContentDisplayer = useMemo(() => {
    switch (true) {
      case mimeType.startsWith("video"):
        return (
          <video
            width="100%"
            height="auto"
            controls
            className={`slider-image border border-2 `}
            muted
            autoPlay
          >
            <source src={file} type={mimeType} />
          </video>
        );

      case mimeType.startsWith("image"):
        return (
          <img
            alt="File"
            style={{
              height: "auto",
              maxHeight: "75vh",
              width: "100%",
              objectFit: "cover",
            }}
            src={file}
          />
        );

      case mimeType === "application/pdf":
        return (
          <PDFViewer file={file} onPageChange={_onMaterialPageChange} />
        );

      default:
        return;
    }
  }, [mimeType, file, _onMaterialPageChange]);

  const helmet = useMemo(() => {
    if (!isOpen || !material) {
      return null;
    }
    
    return (
      <Helmet>
        <title>{`${material?.name} - Estating`}</title>
        {material?.description && 
          <meta name="description" content={`${material?.description} | ${material?.description}`} />
        }
      </Helmet>
    )
  }, [isOpen, material]);

  const { t: estatingAcademyRecomendedReads } = useTranslate('estating_academy');

  return (
    <>
      {helmet}
      <ReactBootstrapPopUp
        isShow={isOpen}
        handleClose={onClose}
        headerContent={
          <div
            className="overflow-hidden text-break m-0"
            style={{
              maxHeight: '30px',
            }}
          >
            {title}
          </div>
        }
        bodyContent={
          <>
            {mediaContentDisplayer}
            <hr className="mt-0" />
            <FooterContainer className="w-max">
              <h3 style={{ fontSize: '24px' }}>
                {estatingAcademyRecomendedReads('estating_academy.recomended_reads')}
              </h3>
              <div className="pb-4">
                <SuggestedMaterialsList
                  carouselProps={{
                    className: "",
                    additionalTransfrom: 0,
                    infinite: true,
                    arrows: true,
                    draggable: true,
                    itemClass: "",
                    keyBoardControl: true,
                    minimumTouchDrag: 80,
                    pauseOnHover: true,
                    shouldResetAutoplay: true,
                    sliderClass: "",
                    slidesToSlide: 1,
                    swipeable: true,
                    responsive,
                    children: null,
                  }}
                  readonly
                  materials={suggestedMaterials}
                />
              </div>
            </FooterContainer>
          </>}
        modalProps={{
          centered: true,
          contentClassName: 'h-100 overflow-auto',
        }}
      />
    </>
  );
});

const FooterContainer = styled.div`
  height: 0;
  width: 100%;
`;

const responsive = {};
let min = 0;
let max = 370;

for(let i = 0; i < 10; i++) {
  responsive[i] = {
    breakpoint: { min, max },
    items: i + 1,
  }
  min = max;
  max += 370;
}
