import { ChangeEvent, FormEvent, useMemo, FC, useState, useEffect } from 'react';
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import TextArea from "../../components/TextArea";
import Select from "../../components/Select";
import Button from "../../components/Button";
import DatePicker from "react-datepicker";
import activity from "../../assets/activity.svg";
import briefcase from "../../assets/briefcase.svg";
import buildings from "../../assets/buildings.svg";
import paperclip from "../../assets/paperclip.svg";
import {ReactComponent as LocationIcon} from "../../assets/location.svg";
import {UserAPI} from "../../api/user";
import documentPDF from "../../assets/document_pdf.svg";
import documentXLSX from "../../assets/document_xlsx.svg";
import documentDOC from "../../assets/document_doc.svg";
import documentZIP from "../../assets/document_zip.svg";
import documentDefault from "../../assets/document_default.svg";
import trash from "../../assets/trash.svg";
import validator from 'validator'
import { COUNTRIES } from "helpers/constants";
import { useSession } from '../../context/session';
import { useTranslate } from '@tolgee/react';
import { isEqual } from 'lodash';


export type AdditionalInformationIVO = {
  officeAddress: OfficeAddressType,
  contactAddress: ContactAddressType,
  companyData: CompanyDataType,
  internalOrganisation: InternalOrganisationType,
  businessActivities: BusinessActivitiesType,
};

type OfficeAddressType = {
  street: string,
  streetAddition?: string,
  postalCode: string,
  poBox?: string,
  city: string,
  country: string,
  estate?: string,
  phoneNumber: string,
  faxNumber: string,
  serviceProviderName: string,
  contact: {
    name: string,
    email: string,
    phone: string
  }
}

type ContactAddressType = {
  street: string,
  streetAddition?: string,
  postalCode: string,
  poBox?: string,
  city: string,
  country: string,
  estate?: string,
};

type CompanyDataType = {
  dataOfIncorporation: {
    date: string,
    documents: string[]
  },
  ownership: {
    value: string,
    documents: string[]
  },
  licenseOfOperate: {
    value: string,
    documents: string[]
  },
  registrations: {
    value: string,
    documents: string[]
  },
  regulatoryStatus: string,
  supervisorAuthority: string,
  serviceAgreementSignedOn: string
}

type InternalOrganisationType = {
  riskManagement: 'Inhouse' | 'Outsourced' | 'No',
  compliance: 'Inhouse' | 'Outsourced' | 'No',
  operations: 'Inhouse' | 'Outsourced' | 'No',
  manuals: 'Inhouse' | 'Outsourced' | 'No',
  activitiesPerformed: string
}

type BusinessActivitiesType = {
  numberOfSalespeople: string,
  backgroundSalespeople: string,
  salesLocations: string,
  clientSegments: string[],
  totalAssetsUnderManagement: string,
  offerAdvisoryMandates: 'Yes' | 'No',
  offerDiscretionaryMandates: 'Yes' | 'No',
  areClientsProfiled: 'Yes' | 'No',
  clientsDomicilies: string,
  coreProductOfferings: string,
  custodianBanks: string,
  numberOfClients: string,
  clientRelationShip: string[]
}

const AdditionalInformation: FC<{}> = () => {
  const { loadSession } = useSession();

  const [officeAddress, setOfficeAddress] = useState<OfficeAddressType>({
    street: "",
    streetAddition: "",
    postalCode: "",
    poBox: "",
    city: "",
    country: "",
    estate: "",
    phoneNumber: "",
    faxNumber: "",
    serviceProviderName: "",
    contact: {
      name: "",
      email: "",
      phone: "",
    }
  });

  const [contactAddress, setContactAddress] = useState<ContactAddressType>({
    street: "",
    streetAddition: "",
    postalCode: "",
    poBox: "",
    city: "",
    country: "",
    estate: ""
  });

  const [companyData, setCompanyData] = useState<CompanyDataType>({
    dataOfIncorporation: {
      date: "",
      documents: [],
    },
    ownership: {
      value: "",
      documents: [],
    },
    regulatoryStatus: "",
    supervisorAuthority: "",
    licenseOfOperate: {
      value: "",
      documents: []
    },
    registrations: {
      value: "",
      documents: []
    },
    serviceAgreementSignedOn: ""
  });

  const [internalOrganisation, setInternalOrganisation] = useState<InternalOrganisationType>({
    riskManagement: "No",
    compliance: "No",
    operations: "No",
    manuals: "No",
    activitiesPerformed: ""
  });

  const [businessActivities, setBusinessActivities] = useState<BusinessActivitiesType>({
    numberOfSalespeople: "",
    backgroundSalespeople: "",
    salesLocations: "",
    clientSegments: [""],
    totalAssetsUnderManagement: "",
    offerAdvisoryMandates: "No",
    areClientsProfiled: "No",
    offerDiscretionaryMandates: "No",
    clientsDomicilies: "",
    coreProductOfferings: "",
    custodianBanks: "",
    numberOfClients: "",
    clientRelationShip: [""]
  });

  const [documentsToAdd, setDocumentsToAdd] = useState<any>({
    incorporationDocuments: null,
    ownershipDocuments: null,
    licenseOfOperateDocuments: null,
    registrationsDocuments: null,
  })

  const [hasError, setHasError] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [validationResults, setValidationResults] = useState<{ [key: string]: boolean }>({})
  const [date, setDate] = useState("");

  useEffect(() => {
    UserAPI.getAdditionalInfoIVO()
      .then(additionalInfo => {
        setOfficeAddress(additionalInfo.officeAddress)
        setCompanyData(additionalInfo.companyData)
        setInternalOrganisation(additionalInfo.internalOrganisation)
        setBusinessActivities(additionalInfo.businessActivities)
        setContactAddress(additionalInfo.contactAddress)
        setDate(additionalInfo.companyData.dataOfIncorporation.date)
      }).catch((e) => {
      console.log(e)
    })
  }, [])

  const onChangeDocuments = (e: ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target

    if (files) {
      setDocumentsToAdd(documentsToAdd => {
        documentsToAdd[e.target.name] = files[0]
        return {...documentsToAdd}
      })
    }
  }

  const _getDocumentIcon = (uri: string) => {
    let ext = uri.split('.').pop();
    if (ext) {
      ext = ext.toLowerCase()
    }
    switch (ext) {
      case  'pdf':
        return documentPDF
      case 'xlsx':
        return documentXLSX
      case 'doc':
        return documentDOC
      case 'docx':
        return documentDOC
      case 'zip':
        return documentZIP
      default:
        return documentDefault
    }
  }

  const save = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (hasError) {
          return false
      }

      const additionalInformation = {
          companyData,
          officeAddress,
          internalOrganisation,
          businessActivities,
          contactAddress
      }

      additionalInformation.companyData.dataOfIncorporation.date = date;

      const {
          incorporationDocuments: incorporationDocumentToAdd,
          ownershipDocuments: ownershipDocumentToAdd,
          licenseOfOperateDocuments: licenseOfOperateDocumentToAdd,
          registrationsDocuments: registrationsDocumentToAdd
      } = documentsToAdd

      setLoading(true)
      UserAPI.upsertAdditionalInfoIVO(
          additionalInformation, {
              incorporationDocumentToAdd,
              ownershipDocumentToAdd,
              licenseOfOperateDocumentToAdd,
              registrationsDocumentToAdd
          })
          .then(updated => {
              setResponseMessage(() => {
                  setTimeout(() => {
                      setResponseMessage('')
                  }, 3000)

                  return 'Successfully changed!!'
              })
              setHasError(false)
              setOfficeAddress(updated.officeAddress)
              setCompanyData(updated.companyData)
              setInternalOrganisation(updated.internalOrganisation)
              setBusinessActivities(updated.businessActivities)
              setContactAddress(updated.contactAddress)
              setDate(updated.companyData.dataOfIncorporation.date)
              setDocumentsToAdd(() => ({
                  incorporationDocuments: null,
                  ownershipDocuments: null,
                  licenseOfOperateDocuments: null,
                  registrationsDocuments: null,
              }))
              loadSession();
          }).catch(e => {
          setHasError(true)
          setResponseMessage(() => {
              setTimeout(() => {
                  setResponseMessage('')
              }, 3000)

              return e.message
          })
      }).finally(() => {
          setLoading(false)
      })
  }

  const removeDocument = (field: 'dataOfIncorporation' | 'ownership' | 'licenseOfOperate' | 'registrations', index: number) => {
      setCompanyData(companyData => {
          companyData[field].documents = companyData[field].documents.filter((_, idx) => idx !== index)

          return {...companyData}
      })
  }

  const _isValid = () => {
      return Object.values(validationResults).filter(isValid => !isValid).length === 0 ||
          Object.keys(validationResults).length === 0
  }

  const changeValidation = (isValid: boolean, field: string) => {
      if (validationResults[field] !== isValid) {
          setValidationResults(validationResults => (
                  {
                      ...validationResults,
                      [field]: isValid
                  }
              )
          )
      }
  }

  const changeOfficeAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target;
    const splitName = name.split(/([.])/);

    if (splitName[1] === ".") {
      setOfficeAddress({
        ...officeAddress,
        [splitName[0]]: {
          ...officeAddress.contact,
          [splitName[2].toLowerCase()]: value
        }
      });
    } else {
      setOfficeAddress({
        ...officeAddress,
        [name]: value
      });
    }
  };

  const changeContactAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target;
    setContactAddress({
      ...contactAddress,
      [name]: value
    });
  };

  const changeCompanyData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const splitName = name.split(/([.])/);

    if (splitName.includes(".")) {
      const fields = splitName.filter(val => val !== '.')

      setCompanyData(companyData => {
        companyData[fields[0]][[fields[1]]] = value

        return {...companyData}
      });
    } else {
      setCompanyData({
        ...companyData,
        [name]: value
      });
    }
  };

  const changeInternalOrganisation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setInternalOrganisation({
      ...internalOrganisation,
      [name]: value
    });
  };

  const changeBusinessActivities = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target;
    setBusinessActivities({
      ...businessActivities,
      [name]: value
    });
  };

  const changeArrayInBusinessActivities = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target;
    const arr = businessActivities[name].includes(value) ? businessActivities[name].filter(i => i !== value) : [...businessActivities[name], value];
    setBusinessActivities({
      ...businessActivities,
      [name]: arr
    });
  };

  const _isSameAddress = useMemo(() => isEqual(officeAddress, contactAddress), [contactAddress, officeAddress]);

  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');

  return (
    <>
      {
        responseMessage ?
          <div
            className={`alert text-center text-dark col-xl-9 col-lg-10 col-md-12 col-12 ${hasError ? 'alert-danger' : 'alert-success'}`}
            style={{
              position: "sticky",
              top: 130,
              zIndex: 10,
              width: "inherit"
            }}
          >
            {responseMessage}
          </div>
          :
          null
      }

        <form onSubmit={save} className='mb-6'>
            {/*---------------------------------------- OFFICE ADDRESS START----------------------------------------------*/}
            <div className="office-address-row">
                <div className="d-flex align-items-center mb-4">
                  <LocationIcon/>
                  <h3 className="d-inline-block ml-2 mb-0 font-weight-bold text-brown">
                    {tPrivateAreaMyAccount('private_area.my_account.office_address')}
                  </h3>
                </div>
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.street')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: 'Ex.: Alexanderufer 3-7',
                    name: "street",
                    value: officeAddress?.street || '',
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.street_addition')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
                    name: "streetAddition",
                    value: officeAddress?.streetAddition || '',
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.postal_code')}
                  validator={(value => value && validator.isPostalCode(value, 'any'))}
                  callback={changeValidation}
                  invalidFeedback='Invalid Postal Code'
                  inputAttributes={{
                    placeholder: '10117',
                    name: "postalCode",
                    value: officeAddress?.postalCode || '',
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.po_box')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
                    name: "poBox",
                    value: officeAddress?.poBox || '',
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.city')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: 'Ex.: Berlin',
                    name: "city",
                    value: officeAddress?.city || '',
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.state')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
                    name: "estate",
                    value: officeAddress?.estate || '',
                  }}
                />
                <Select
                  label={tPrivateAreaMyAccount('private_area.my_account.country')}
                  onChange={changeOfficeAddress}
                  containerClasses='mb-4 col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  selectProps={{
                    name: "country",
                    value: officeAddress?.country || '',
                  }}
                  placeholder='Select Country'
                  values={COUNTRIES.map(countryObj => countryObj.name)}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.phone_number')}
                  inputAttributes={{
                    type: 'tel',
                    placeholder: '+49 1234 5678',
                    name: "phoneNumber",
                    value: officeAddress.phoneNumber,
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.fax_number')}
                  inputAttributes={{
                    type: 'tel',
                    placeholder: '+49 1234 5678',
                    name: "faxNumber",
                    value: officeAddress.faxNumber,
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.service_provider_name')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: tPrivateAreaMyAccount('private_area.my_account.service_provider_name'),
                    name: "serviceProviderName",
                    value: officeAddress.serviceProviderName,
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.contact_person_name')}
                  inputAttributes={{
                    type: 'text',
                    placeholder: 'Ex.: Sam Smith',
                    name: "contact.name",
                    value: officeAddress.contact.name,
                  }}
                />
                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.contact_person_email')}
                  invalidFeedback='Please use valid email address'
                  validator={(value) => validator.isEmail(value)}
                  callback={changeValidation}
                  inputAttributes={{
                    type: 'email',
                    required: true,
                    placeholder: 'Ex.: sam.smith@estating.com',
                    name: "contact.email",
                    value: officeAddress.contact.email,
                  }}
                />

                <Input
                  onChange={changeOfficeAddress}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.contact_person_phone')}
                  inputAttributes={{
                    type: 'tel',
                    placeholder: '+49 1234 5678',
                    name: "contact.phone",
                    value: officeAddress.contact.phone,
                  }}
                />
            </div>
            {/*---------------------------------------- OFFICE ADDRESS END----------------------------------------------*/}

            {/*---------------------------------------- CONTACT ADDRESS START----------------------------------------------*/}
            <div className={`contact-address-row mt-5`} id="contact-address">
              <div className="d-flex align-items-center">
                <LocationIcon/>
                <h3 className="mb-0 d-inline-block ml-2 font-weight-bold text-brown">
                  {tPrivateAreaMyAccount('private_area.my_account.contact_address')}
                </h3>
              </div>
              <div className='d-flex align-items-center my-4'>
                <label
                  htmlFor='contactAddressIsSame'
                  className="p-0 m-0 label-fix-width font-weight-bold"
                >
                  {tPrivateAreaMyAccount('private_area.my_account.contact_address_is_same')}
                </label>
                <div className="custom-control custom-checkbox-toggle">
                  <input
                    type="checkbox"
                    checked={_isSameAddress}
                    onChange={e => {
                      if (e.target.checked) {
                        const {contact, phoneNumber, faxNumber, ...address} = officeAddress
                        setContactAddress(address)
                      } else {
                        setContactAddress({
                          street: '',
                          streetAddition: '',
                          estate: '',
                          postalCode: '',
                          poBox: '',
                          city: '',
                          country: ''
                        })
                      }
                    }}
                    className="custom-control-input"
                    id='contactAddressIsSame'
                  />
                  <label
                    className="custom-control-label"
                    htmlFor='contactAddressIsSame'
                  />
                </div>
              </div>
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.street')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.street'),
                  name: "street",
                  value: contactAddress?.street || ''
                }}
              />
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.street_addition')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.street'),
                  name: "streetAddition",
                  value: contactAddress?.streetAddition || '',
                }}
              />
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.postal_code')}
                validator={(value => value && validator.isPostalCode(value, 'any'))}
                callback={((isValid, field) => changeValidation(isValid, 'contactAddress_' + field))}
                invalidFeedback='Invalid Postal Code'
                inputAttributes={{
                  placeholder: '10117',
                  name: "postalCode",
                  value: contactAddress?.postalCode || '',
                }}
              />
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.po_box')}
                inputAttributes={{
                  type: 'text',
                  placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
                  name: "poBox",
                  value: contactAddress?.poBox || 'poBox',
                }}
              />
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.city')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.city'),
                  name: "city",
                  value: contactAddress?.city || '',
                }}
              />
              <Input
                onChange={changeContactAddress}
                containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                label={tPrivateAreaMyAccount('private_area.my_account.state')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.state'),
                  name: "(optional)",
                  value: contactAddress?.estate || '',
                }}
              />
              <Select
                label={tPrivateAreaMyAccount('private_area.my_account.country')}
                onChange={changeContactAddress}
                containerClasses='mb-4 col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                selectProps={{
                  name: "country",
                  value: contactAddress?.country || '',
                }}

                placeholder='Select Country'
                values={COUNTRIES.map(countryObj => countryObj.name)}
              />
            </div>


            {/*---------------------------------------- CONTACT ADDRESS END----------------------------------------------*/}

            {/*---------------------------------------- COMPANY DATA START----------------------------------------------*/}

            <div className="company-data-row mt-5">
              <div className="d-flex align-items-center mb-4">
                <img src={briefcase} alt='Briefcase'/>
                <h3 className="mb-0 ml-2 font-weight-bold text-brown">
                  {tPrivateAreaMyAccount('private_area.my_account.copany_data')}
                </h3>
              </div>
                <div className="date-of-incorporation-row">
                  <div className="d-xl-flex">
                    <div className="datepicker-row col-xl-9 col-lg-10 col-md-12 col-12 px-0">
                      <label className="d-xl-flex" style={{ fontWeight: 'bold' }}>
                        {tPrivateAreaMyAccount('private_area.my_account.incorporation_date')}
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => setDate(String(date))}
                        selected={date ? new Date(date) : null}
                        className="form-control input-theme-height react-datepicker-icon mb-4"
                        placeholderText={tPrivateAreaMyAccount('private_area.my_account.form.select_date')}
                        withPortal
                      />
                    </div>
                      <Input
                        onChange={onChangeDocuments}
                        containerClasses='col-xl-5 col-12 px-0 add-document'
                        labelClasses="pl-5 cursor-pointer d-inline"
                        label={documentsToAdd.incorporationDocuments?.name || tPrivateAreaMyAccount('private_area.my_account.add_document')}
                        inputAttributes={{
                          type: 'file',
                          name: "incorporationDocuments",
                        }}
                        labelStyles={{
                          background: `url(${paperclip}) no-repeat`
                        }}
                      />
                  </div>
                    {
                      companyData.dataOfIncorporation.documents.length > 0 ?
                        companyData.dataOfIncorporation.documents.map((incorporationDocument, index) => {
                          return (
                            <div
                              key={incorporationDocument + index}
                              className={`border my-2 px-3 py-3 mr-2 rounded d-flex justify-content-between align-items-center bg-white`}
                            >
                            <div className="d-flex align-items-center mr-2">
                              <img
                                src={_getDocumentIcon(incorporationDocument)}
                                alt=""
                              />
                              <span className='ml-3'>{incorporationDocument.split('/').pop()}</span>
                            </div>
                            <img
                              onClick={() => removeDocument("dataOfIncorporation", index)}
                              className='hover-opacity cursor-pointer' src={trash} alt=""
                            />
                            </div>
                          )
                        })
                        :
                        null
                    }
                </div>
                <div className="ownership-row">
                    <div className="d-xl-flex">
                        <Input
                          onChange={changeCompanyData}
                          containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                          label={`${tPrivateAreaMyAccount('private_area.my_account.ownership')}*`}
                          inputAttributes={{
                            type: 'text',
                            placeholder: tPrivateAreaMyAccount('private_area.my_account.ownership'),
                            name: "ownership.value",
                            value: companyData.ownership.value,
                          }}
                        />
                        <Input
                          onChange={onChangeDocuments}
                          containerClasses='col-xl-5 col-12 px-0 add-document'
                          labelClasses="pl-5 cursor-pointer d-inline"
                          label={documentsToAdd.ownershipDocuments?.name || tPrivateAreaMyAccount('private_area.my_account.add_document')}
                          inputAttributes={{
                            type: 'file',
                            name: "ownershipDocuments",
                          }}
                          labelStyles={{
                            background: `url(${paperclip}) no-repeat`
                          }}
                        />
                    </div>
                    {
                      companyData.ownership.documents.length > 0 ?
                        companyData.ownership.documents.map((ownershipDocument, index) => {
                          return (
                            <div
                              key={ownershipDocument + index}
                              className={`border my-2 px-3 py-3 bg-white rounded d-flex justify-content-between align-items-center`}
                            >
                              <div className="d-flex align-items-center mr-2">
                                <img 
                                  src={_getDocumentIcon(ownershipDocument)}
                                  alt=""
                                />
                                <span className='ml-3'>{ownershipDocument.split('/').pop()}</span>
                              </div>
                              <img
                                onClick={() => removeDocument("ownership", index)}
                                className='hover-opacity cursor-pointer'
                                src={trash} alt=""
                              />
                            </div>
                          )
                        })
                      :
                      null
                    }
                </div>
                <Input
                  onChange={changeCompanyData}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={`${tPrivateAreaMyAccount('private_area.my_account.regulatory_status')}*`}
                  inputAttributes={{
                    type: 'text',
                    placeholder: tPrivateAreaMyAccount('private_area.my_account.regulatory_status_placeholder'),
                    name: "regulatoryStatus",
                    value: companyData.regulatoryStatus,
                  }}
                />
                <Input
                  onChange={changeCompanyData}
                  containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                  label={tPrivateAreaMyAccount('private_area.my_account.supervisor_authority')}
                  invalidFeedback='Please set correct URL'
                  validator={(value) => value && validator.isURL(value, {require_protocol: false})}
                  callback={changeValidation}
                  inputAttributes={{
                    placeholder: 'https://www.bafin.de',
                    name: "supervisorAuthority",
                    required: true,
                    value: companyData.supervisorAuthority,
                  }}
                />
                <div className="license-of-operate-row">
                  <div className='d-xl-flex'>
                    <Input
                      onChange={changeCompanyData}
                      containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                      label={tPrivateAreaMyAccount('private_area.my_account.license_of_operate')}
                      inputAttributes={{
                        type: 'text',
                        placeholder: tPrivateAreaMyAccount('private_area.my_account.license_of_operate_placeholder'),
                        name: "licenseOfOperate.value",
                        value: companyData.licenseOfOperate.value,
                      }}
                    />
                    <Input
                      onChange={onChangeDocuments}
                      containerClasses='col-xl-5 col-12 px-0 add-document'
                      labelClasses="pl-5 cursor-pointer d-inline"
                      label={documentsToAdd.licenseOfOperateDocuments?.name || tPrivateAreaMyAccount('private_area.my_account.add_document')}
                      inputAttributes={{
                        type: 'file',
                        name: "licenseOfOperateDocuments",
                      }}
                      labelStyles={{
                        background: `url(${paperclip}) no-repeat`
                      }}
                    />
                  </div>
                    {
                      companyData.licenseOfOperate.documents.length > 0 ?
                        companyData.licenseOfOperate.documents.map((licenseOfOperateDocument, index) => {
                          return (
                            <div
                              key={licenseOfOperateDocument + index}
                              className={`border my-2 px-3 py-3 bg-white rounded d-flex justify-content-between align-items-center`}
                            >
                              <div className="d-flex align-items-center mr-2">
                                <img
                                  src={_getDocumentIcon(licenseOfOperateDocument)}
                                  alt=""
                                />
                                <span className='ml-3'>{licenseOfOperateDocument.split('/').pop()}</span>
                              </div>
                              <img
                                onClick={() => removeDocument("licenseOfOperate", index)}
                                className='hover-opacity cursor-pointer'
                                src={trash} alt=""
                              />
                            </div>
                          )
                        })
                      :
                      null
                    }
                </div>
                <div className="registrations">
                  <div className="d-xl-flex">
                    <Input
                      onChange={changeCompanyData}
                      containerClasses='col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                      label={tPrivateAreaMyAccount('private_area.my_account.registrations')}
                      inputAttributes={{
                        placeholder: tPrivateAreaMyAccount('private_area.my_account.registrations_placeholder'),
                        name: "registrations.value",
                        value: companyData.registrations.value,
                      }}
                    />
                    <Input
                      onChange={onChangeDocuments}
                      containerClasses='col-xl-5 col-12 px-0 add-document'
                      labelClasses="pl-5 cursor-pointer d-inline"
                      label={documentsToAdd.registrationsDocuments?.name || tPrivateAreaMyAccount('private_area.my_account.add_document')}
                      inputAttributes={{
                        type: 'file',
                        name: "registrationsDocuments",
                      }}
                      labelStyles={{
                        background: `url(${paperclip}) no-repeat`
                      }}
                    />
                  </div>
                    {
                      companyData.registrations.documents.length > 0 ?
                        companyData.registrations.documents.map((registrationsDocument, index) => {
                          return (
                            <div
                              key={registrationsDocument + index}
                              className={`border my-2 px-3 py-3 bg-white rounded d-flex justify-content-between align-items-center`}
                            >
                              <div className="d-flex align-items-center mr-2">
                                <img
                                  src={_getDocumentIcon(registrationsDocument)}
                                  alt=""
                                />
                                <span className='ml-3'>{registrationsDocument.split('/').pop()}</span>
                              </div>
                              <img
                                onClick={() => removeDocument("registrations", index)}
                                className='hover-opacity cursor-pointer'
                                src={trash} alt=""
                              />
                            </div>
                          )
                        })
                      :
                      null
                    }
                </div>
                <p className='col-xl-9 col-lg-10 col-md-12 col-12 px-0'>
                  {tPrivateAreaMyAccount('private_area.my_account.ask_confirm_statements')}
                </p>
            </div>
            {/*---------------------------------------- COMPANY DATA END----------------------------------------------*/}

            {/*---------------------------------------- INTERNAL ORGANIZATION START----------------------------------------------*/}

            <div className="internal-organization mt-5">
              <div className='d-flex align-items-center'>
                <img src={buildings} alt='Buildings'/>
                <h3 className='mb-0 d-inline-block ml-2 text-brown font-weight-bold'>
                  {tPrivateAreaMyAccount('private_area.my_account.internal_organization')}
                </h3>
              </div>
              <h4 className="mt-4 d-flex">
                {tPrivateAreaMyAccount('private_area.my_account.risk_managment')}
              </h4>
              <div
                className="risk-management-row col-xl-9 col-lg-10 col-md-12 col-12 px-0 d-flex justify-content-between flex-wrap mt-4"
              >
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.inhouse')}
                  inputAttributes={{
                    name: "riskManagement",
                    value: "Inhouse",
                    checked: internalOrganisation.riskManagement === 'Inhouse'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.outsourced')}
                  inputAttributes={{
                    name: "riskManagement",
                    value: "Outsourced",
                    checked: internalOrganisation.riskManagement === 'Outsourced'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.no')}
                  inputAttributes={{
                    name: "riskManagement",
                    value: "No",
                    checked: internalOrganisation.riskManagement === 'No'
                  }}
                />
              </div>
              <h4 className="mt-4 d-flex">
                {tPrivateAreaMyAccount('private_area.my_account.compilance_aml')}
              </h4>
              <div
                className="compliance-aml-row col-xl-9 col-lg-10 col-md-12 px-0 d-flex justify-content-between flex-wrap mt-4"
              >
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.inhouse')}
                  inputAttributes={{
                    name: "compliance",
                    value: "Inhouse",
                    checked: internalOrganisation.compliance === 'Inhouse'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.outsourced')}
                  inputAttributes={{
                    name: "compliance",
                    value: 'Outsourced',
                    checked: internalOrganisation.compliance === 'Outsourced'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.no')}
                  inputAttributes={{
                    name: "compliance",
                    value: "No",
                    checked: internalOrganisation.compliance === 'No'
                  }}
                />
              </div>
              <h4 className="mt-4 d-flex">
                {tPrivateAreaMyAccount('private_area.my_account.operations')}
              </h4>
              <div
                className="operations-row d-flex col-xl-9 col-lg-10 col-md-12 col-12 px-0 justify-content-between flex-wrap mt-4"
              >
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.inhouse')}
                  inputAttributes={{
                    name: "operations",
                    value: "Inhouse",
                    checked: internalOrganisation.operations === 'Inhouse'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.outsourced')}
                  inputAttributes={{
                    name: "operations",
                    value: "Outsourced",
                    checked: internalOrganisation.operations === 'Outsourced'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.no')}
                  inputAttributes={{
                    name: "operations",
                    value: "No",
                    checked: internalOrganisation.operations === 'No'
                  }}
                />
              </div>
              <h4 className="mt-4 d-flex">
                {tPrivateAreaMyAccount('private_area.my_account.cross_borders_manuals')}
              </h4>
              <div
                className="cross-borders-anuals-row col-xl-9 col-lg-10 col-md-12 col-12 px-0 d-flex justify-content-between flex-wrap mt-4"
              >
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.inhouse')}
                  inputAttributes={{
                    name: "manuals",
                    value: "Inhouse",
                    checked: internalOrganisation.manuals === 'Inhouse'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.outsourced')}
                  inputAttributes={{
                    name: "manuals",
                    value: "Outsourced",
                    checked: internalOrganisation.manuals === 'Outsourced'
                  }}
                />
                <Checkbox
                  onChange={changeInternalOrganisation}
                  label={tPrivateAreaMyAccount('private_area.my_account.form.no')}
                  inputAttributes={{
                    name: "manuals",
                    value: "No",
                    checked: internalOrganisation.manuals === 'No'
                  }}
                />
              </div>
              <TextArea
                onChange={changeInternalOrganisation}
                label={tPrivateAreaMyAccount('private_area.my_account.how_activities_performed')}
                containerClasses='my-4 col-xl-9 col-lg-10 col-md-12 col-12 px-0'
                placeholder={tPrivateAreaMyAccount('private_area.my_account.your_answer')}
                name="activitiesPerformed"
                value={internalOrganisation.activitiesPerformed}
                maxLength={800}
                rows={5}
              />
            </div>

            {/*---------------------------------------- INTERNAL ORGANIZATION END----------------------------------------------*/}

            {/*---------------------------------------- BUSINESS ACTIVITIES START----------------------------------------------*/}

            <div className="business-activities mt-5 col-xl-9 col-lg-10 col-md-12 col-12 px-0">
              <img src={activity} alt='Activity'/>
              <h3 className="d-inline-block ml-2 text-brown font-weight-bold mb-4">
                {tPrivateAreaMyAccount('private_area.my_account.business_activities')}
              </h3>
              <Input
                onChange={changeBusinessActivities}
                label={tPrivateAreaMyAccount('private_area.my_account.number_of_salespeople')}
                inputAttributes={{
                  placeholder: '0',
                  name: "numberOfSalespeople",
                  value: businessActivities.numberOfSalespeople,
                }}
              />
              <Input
                onChange={changeBusinessActivities}
                containerClasses='my-4'
                label={tPrivateAreaMyAccount('private_area.my_account.professional_background_salespeople')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.your_answer'),
                  name: "backgroundSalespeople",
                  value: businessActivities.backgroundSalespeople,
                }}
              />
              <Input
                onChange={changeBusinessActivities}
                label={tPrivateAreaMyAccount('private_area.my_account.sales_locations')}
                inputAttributes={{
                  type: 'text',
                  placeholder: 'Ex.: Berlin',
                  name: "salesLocations",
                  value: businessActivities.salesLocations,
                }}
              />
              <Input
                onChange={changeBusinessActivities}
                validator={(value => validator.isNumeric(`${value}`))}
                label={tPrivateAreaMyAccount('private_area.my_account.number_of_clients')}
                inputAttributes={{
                  type: 'text',
                  placeholder: '0',
                  name: "numberOfClients",
                  value: businessActivities.numberOfClients,
                }}
              />
              <h4 className="mt-4 d-flex">
                {tPrivateAreaMyAccount('private_area.my_account.clients_segments')}
              </h4>
              <div
                className="risk-management-row col-xl-9 col-lg-10 col-md-12 col-12 px-0 d-flex justify-content-between flex-wrap mt-4"
              >
                <Checkbox
                  onChange={changeArrayInBusinessActivities}
                  label='UHNW'
                  inputType="checkbox"
                  inputAttributes={{
                    name: "clientSegments",
                    value: "UHNW",
                    checked: businessActivities.clientSegments.includes("UHNW")
                  }}
                />
                <Checkbox
                  onChange={changeArrayInBusinessActivities}
                  label='HNW'
                  inputType="checkbox"
                  inputAttributes={{
                    name: "clientSegments",
                    value: "HNW",
                    checked: businessActivities.clientSegments.includes("HNW")
                  }}
                />
                <Checkbox
                  onChange={changeArrayInBusinessActivities}
                  label='Retail'
                  inputType="checkbox"
                  inputAttributes={{
                    name: "clientSegments",
                    value: "Retail",
                    checked: businessActivities.clientSegments.includes("Retail")
                  }}
                />
              </div>
              <div className="advisory-mandates-row mt-5">
                <p className='d-flex'>
                  {tPrivateAreaMyAccount('private_area.my_account.do_you_have_profile_clients')}
                </p>
                <div className="d-flex justify-content-between col-8 px-0">
                  <Checkbox
                    onChange={changeBusinessActivities}
                    label={tPrivateAreaMyAccount('private_area.my_account.form.yes')}
                    inputAttributes={{
                      name: "areClientsProfiled",
                      value: "Yes",
                      checked: businessActivities.areClientsProfiled === 'Yes'
                    }}
                  />
                  <Checkbox
                    onChange={changeBusinessActivities}
                    label={tPrivateAreaMyAccount('private_area.my_account.form.no')}
                    inputAttributes={{
                      name: "areClientsProfiled",
                      value: "No",
                      checked: businessActivities.areClientsProfiled === 'No'
                    }}
                  />
                </div>
              </div>
              <h4 className="mt-4 d-flex"> </h4>
              <Input
                onChange={changeBusinessActivities}
                label={tPrivateAreaMyAccount('private_area.my_account.total_assets_under_management')}
                inputAttributes={{
                  placeholder: '0',
                  name: "totalAssetsUnderManagement",
                  value: businessActivities.totalAssetsUnderManagement,
                }}
              />
              <div className="advisory-mandates-row mt-5">
                <p className='d-flex'>
                  {tPrivateAreaMyAccount('private_area.my_account.whats_you_relationship_with_your_clients')}
                </p>
                <div className="d-flex justify-content-between col-8 px-0">
                  <Checkbox
                    onChange={changeArrayInBusinessActivities}
                    label={tPrivateAreaMyAccount('private_area.my_account.form.advisory')}
                    inputType='checkbox'
                    inputAttributes={{
                      name: "clientRelationShip",
                      value: "Advisory",
                      checked: businessActivities.clientRelationShip.includes("Advisory")
                    }}
                  />
                  <Checkbox
                    onChange={changeArrayInBusinessActivities}
                    label={tPrivateAreaMyAccount('private_area.my_account.form.discretionary')}
                    inputType='checkbox'
                    inputAttributes={{
                      name: "clientRelationShip",
                      value: "Discretionary",
                      checked: businessActivities.clientRelationShip.includes("Discretionary")
                    }}
                  />
                </div>
              </div>
              <Input
                onChange={changeBusinessActivities}
                containerClasses='col-xl-12 px-0 mt-4'
                label={tPrivateAreaMyAccount('private_area.my_account.top_five_clients_domicilies')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.your_answer'),
                  name: "clientsDomicilies",
                  value: businessActivities.clientsDomicilies,
                }}
              />
              <Input
                onChange={changeBusinessActivities}
                containerClasses='col-xl-12 px-0 mt-4'
                label={tPrivateAreaMyAccount('private_area.my_account.core_product_offerings')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.your_answer'),
                  name: "coreProductOfferings",
                  value: businessActivities.coreProductOfferings,
                }}
              />
              <Input
                onChange={changeBusinessActivities}
                containerClasses='col-xl-12 px-0 mt-4'
                label={tPrivateAreaMyAccount('private_area.my_account.custodian_banks_used_for_clients')}
                secondaryLabel={tPrivateAreaMyAccount('private_area.my_account.only_banks_connected_to_six')}
                inputAttributes={{
                  type: 'text',
                  placeholder: tPrivateAreaMyAccount('private_area.my_account.your_answer'),
                  name: "custodianBanks",
                  value: businessActivities.custodianBanks,
                }}
              />
              <div className="col-xl-12 px-0 mt-4 mb-4">
                <label className="font-weight-bold">
                  {tPrivateAreaMyAccount('private_area.my_account.service_agreement_signed_with_estating')}
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  disabled={true}
                  onChange={() => {}}
                  selected={companyData.serviceAgreementSignedOn ? new Date(companyData.serviceAgreementSignedOn) : null}
                  className="form-control input-theme-height react-datepicker-icon mb-4"
                  placeholderText={tPrivateAreaMyAccount('private_area.my_account.form.select_date')}
                  withPortal
                />
                {tPrivateAreaMyAccount('private_area.my_account.we_will_add_this_information_contact_us')}
                (<a href="mailto:info@estating.com">info@estating.com</a>)
              </div>
            </div>

            {/*---------------------------------------- BUSINESS ACTIVITIES END----------------------------------------------*/}

            <div
              className='d-flex col-xl-9 col-lg-10 col-md-12 col-12 px-0 justify-content-start'
              style={{
                position: "sticky",
                bottom: '0',
                background: 'white',
                zIndex: 5
              }}>
              <Button
                label={tPrivateAreaMyAccount('private_area.my_account.common.button.save')}
                className={`${!_isValid() ? 'disabled' : ''} btn-block my-2 ${loading ? 'is-loading disabled' : ''}`}
                buttonAttributes={{
                  type: "submit",
                  disabled: !_isValid() || loading
                }}
                invalid={!_isValid()}
              />
            </div>
        </form>
    </>
  );
};

export default AdditionalInformation;
