import { useTranslate } from "@tolgee/react";
import { FC, memo } from "react";
import { Helmet } from 'react-helmet';

const Loading: FC<{}> = memo(() => {
  const { t: tTitle } = useTranslate('title');

  return (
    <div className="d-flex justify-content-center mt-8 align-items-center">
      <Helmet>
        <title>{tTitle('title.loading', 'Loading')}...</title>
      </Helmet>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only ">{tTitle('title.loading', 'Loading')}...</span>
      </div>
    </div>
  );
});

export default Loading;