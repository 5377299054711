import UserAPI from "../../api/user";
import { useSession } from "../../context/session";
import SendMail, { SendMailModal } from "../../modal/SendMail";
import { memo } from "react";
import { useTranslate } from "@tolgee/react";

export default memo(function PropertyListEmpty() {
  const { user } = useSession();
  const { t: tPrivateAreaProperties } = useTranslate('private_area.properties');

  const reactOnInvitation = (data, type: SendMailModal['type']) => {
    return new Promise((resolve, reject) => {
      UserAPI.reactOnProperty({...data, type})
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <>
      <div className="col-8 col-md-6 col-xl-4 mx-auto mt-7 d-flex align-items-center flex-column">
        <h1 className="font-weight-bold text-center">
          {tPrivateAreaProperties(
            "private_area.properties.empty_list.common.do_you_want_to_see_more_deals",
            "Do you want to see more deals?"
          )}
        </h1>
        <p className="text-center">
        {tPrivateAreaProperties(
            "private_area.properties.empty_list.user.your_financial_advisor_can_share",
            "Your financial advisor can share deals with you. Invite them to sign up to Estating so they can send you the best deals for you."
          )}
        </p>
        <button
          data-target="#invite-popUp"
          data-toggle="modal"
          className="btn btn-theme-height btn-primary col px-md-5"
          style={{ width: "fit-content" }}
        >
          {tPrivateAreaProperties(
            "private_area.properties.empty_list.user.invite_financial_advisor",
            "Invite Financial Advisor"
          )}
        </button>
      </div>
      {user && (
        <SendMail
          type="invite"
          label="Email invitation"
          options={{
            fromEmail: user.email,
            subject: `Invitation for Estating.com`,
            message:
              `Dear advisor,\n` +
              `I am interested in investing in securitized real estate through Estating. I would like to invite you to create a user on their <a href="app.estating.com">webapp</a> so we can work together and choose the best investments for my portfolio.\n` +
              `Best regards,\n${user.firstName} ${user.lastName}`,
            type: 'invite'
          }}
          handleYes={reactOnInvitation}
          id="invite-popUp"
        />
      )}
    </>
  );
});
