import { CSSProperties } from "react";
import { Tooltip } from "react-tooltip";
import "../../node_modules/react-tooltip/dist/react-tooltip.css";
import ExclamationCircle from "./Icons/ExclamationCircle";
import "./IncompleteProfileBadge.scss";

type IncompleteProfileBadgeProps = {
  id: string;
  style?: CSSProperties;
};

export default function IncompleteProfileBadge(
  props: IncompleteProfileBadgeProps
) {
  return (
    <>
      <Tooltip anchorId={props.id} />
      <div
        style={props.style}
        id={props.id}
        data-tooltip-content="Missing information in User Profile"
        className="profile-completeness-icon"
      >
        <ExclamationCircle />
      </div>
    </>
  );
}
