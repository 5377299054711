import { SVGProps } from "react";

export default function ExclamationCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
    >
      <circle
        cx="24"
        cy="6.5"
        r="5"
        fill="#FC3D86"
        stroke="#F6F9FC"
        strokeWidth="2"
      />
    </svg>
  );
}
