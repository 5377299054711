import { memo, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useScreenshot } from '../../hooks/use-screenshot';

export const ScreenShoot = memo(forwardRef<any, any>(({ children }, componentRef) => {
  const ref = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, takeScreenShot] = useScreenshot({ mimeType: 'jpeg', quality: 0.5 });

  const onTakeScreenShot = useCallback(async () => {
    if (!ref.current) {
      throw new Error('ref not exist');
    }

    return takeScreenShot(ref.current)
  }, [takeScreenShot]);

  useImperativeHandle(componentRef, () => ({
    onTakeScreenShot
  }));

  return (
    <ScreenShootContainer ref={ref}>
      { children }
    </ScreenShootContainer>
  )
}));

const ScreenShootContainer = styled.div`
  width: 100%;
  min-height: 100%;
`;