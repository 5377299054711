import classNames from 'classnames';
import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, memo, ReactElement } from 'react';

import style from './style.module.scss';

interface InputProps {
  icon?: ReactElement;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'className' | 'style'>;
  containerClassName?: string;
}

export const Input: FC<InputProps> = memo(({
  icon,
  placeholder,
  value,
  onChange,
  inputProps = {},
  containerClassName
}) => {
  return (
    <div className={classNames(containerClassName, `${style.container} d-flex align-items-center`)}>
      {
        icon && (
          <div className={`mr-2 mr-sm-3 ${style.icon_container} d-flex`}>
            {icon}
          </div>
        )
      }
      <input
        {...inputProps}
        onChange={onChange}
        value={value}
        className={`p-0 border-0 flex-grow-1 ${style.input}`}
        placeholder={placeholder}
      />
    </div>
  )
});
