import { memo, FC, useCallback, useMemo } from 'react'
import CustomSelect from "components/CustomSelect";
import { useTranslate } from '@tolgee/react';

enum HearFrom {
  FINANCIAL_ADVISOR = 'Financial Advisor',
  ADVERTISEMENT = 'Advertisement',
  EVENT = 'Event',
  LINKED_IN = 'LinkedIn',
  ESTATING_TEAM_MEMBER = 'Estating Team Member',
  PERSONAL_OR_PROFESSIONAL_NETWORK = 'Personal or Professional Network'
}

interface HearAboutFromProps {
  callback: (value: number | string) => any,
  selected: string | number
}

const HearAboutFrom: FC<HearAboutFromProps> = memo(({
  callback,
  selected
}) => {
  const { t: tEnums } = useTranslate('enums');
  const { t: tPublicArea } = useTranslate('public_area');

  const { associativeMap, options } = useMemo(() => {
    const labels = Object.keys(HearFrom);

    const associativeMap: {[key: string]: string} = {};

    labels.forEach((key) => {
      associativeMap[tEnums(`enums.hear_from.${key}`)] = HearFrom[key];
    });

    return {associativeMap, options: Object.keys(associativeMap)};
  }, [tEnums]);

  const onSelect = useCallback((value: number | string) => {
    callback(associativeMap[value]);
  }, [associativeMap, callback]);

  return (
    <div>
      <h1 className='text-center font-weight-bold'>
        {tPublicArea('public_area.how_you_hear_about_estating')}
      </h1>
      <CustomSelect
        data={options}
        callback={onSelect}
        addNew={true}
        selected={selected}
        newInputProps={{
          rows: 3,
          style: {minHeight: 60}
        }}
      />
    </div>
  );
});

export default HearAboutFrom
