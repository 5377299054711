import { memo } from "react";
import { ReactComponent as Icon } from "assets/majesticons_document-line.svg";
import { useTranslate } from "@tolgee/react";

import style from "./style.module.scss";

export const PropertyDisclaimerTitle = memo(() => {
  const { t: tOpportunityDetailPageDisclaimer } = useTranslate('opportunity_detail_page.disclaimer');

  return (
    <div className="d-flex align-items-center text-primary">
      <div className={style["disclaimer__icon-container"]}>
        <Icon />
      </div>
      <div className={style["disclaimer__text"]}>
        {tOpportunityDetailPageDisclaimer('opportunity_detail_page.disclaimer')}
      </div>
    </div>
  );
});
