import { memo, FC, useCallback } from 'react'
import { Modal, ModalProps } from 'react-bootstrap';


interface ReactBootstrapPopUpProps {
  isShow: boolean;
  handleClose: () => void;
  headerContent: JSX.Element;
  bodyContent?: JSX.Element;
  footerContent?: JSX.Element;
  modalProps?: ModalProps;
}

export const ReactBootstrapPopUp: FC<ReactBootstrapPopUpProps> = memo(({
  isShow,
  handleClose,
  headerContent,
  bodyContent,
  footerContent,
  modalProps
}) => {

  const closeForm = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Modal
        show={isShow}
        onHide={closeForm}
        keyboard={false}
        scrollable={true}
        {...modalProps}
      >
        <Modal.Header className='modal-title h1 m-0' closeButton>
          {headerContent}
        </Modal.Header>
        <Modal.Body>
          {bodyContent}
        </Modal.Body>
        <Modal.Footer>
          {footerContent && footerContent}
        </Modal.Footer>
      </Modal>
  )
})