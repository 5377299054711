import { FC, memo, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { subheaderPortalId } from '../../constants/subheader-portal';

export const SubheaderPortal: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setContainer(document.getElementById(subheaderPortalId));
    
    return () => {
      setContainer(null);
    }
  }, []);

  if (!container) {
    return null;
  }
  return (
    createPortal(children, container)
  );
});
