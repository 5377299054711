import { FC, memo, useCallback, useMemo } from "react";
import { Popup } from "new-architecture/components/pop-up";
import { ContactEstatingModalHeader } from "./property-share-modal-header";
import { ContactEstatingModalBody } from "./property-share-modal-body";
import { ContactEstatingModalFooter } from "./property-share-modal-footer";
import { useFormik } from "formik";
import { ContactEstatingMessageOptions } from "../types/index";
import { FormikValues } from "../types";
import { useContactEstatingMutation } from "../store";
import CircleFilledIcon from "assets/circle-filled.svg";
import { QueryStatus } from "@reduxjs/toolkit/dist/query/react";
import { emailMessage } from "../utils/email-template";

import styles from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

interface ContactEstatingModalProps {
  isOpen: boolean;
  onClose: () => void;
  options: ContactEstatingMessageOptions;
};

export const ContactEstatingModal: FC<ContactEstatingModalProps> = memo(({ 
  isOpen,
  onClose,
  options
}) => {
  const { 
    toEmail,
    fromEmail,
    propertyId,
    otherEmails,
    subject,
    attachments,
    attachTeaser,
    name,
    phone,
    images
  } = options;

  const [contactEstating, { isLoading, isSuccess, error, status, reset }] = useContactEstatingMutation();
  
  const initialValues = useMemo<FormikValues>(() => {
    return ({
      name,
      fromEmail,
      phone,
      message: '',
    });
  }, [fromEmail, name, phone]);

  const isRequestSended = useMemo(() =>
    status === QueryStatus.fulfilled ||
    status === QueryStatus.rejected
  , [status]);

  const formik = useFormik({
    initialValues,
    onSubmit(values) {
      if (isLoading) {
        return;
      }

      if (isRequestSended) {
        reset();
        return;
      }

      const { message, fromEmail, phone, name } = values;

      const buildedMessage = emailMessage(message, name, phone, fromEmail);

      const requestBody = {
        fromEmail,
        message: buildedMessage,
        propertyId,
        otherEmails,
        subject,
        attachments,
        attachTeaser,
        toEmail,
        images,
        type: 'share' as 'share',
      };
      contactEstating(requestBody);
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const { values, setFieldValue, handleChange, submitForm, resetForm } = formik;

  const closeModal = useCallback(() => {
    onClose();
    reset();
    resetForm();
  }, [onClose, reset, resetForm]);

  const { t: tEmailTemplates } = useTranslate('email_templates');

  return (
    <Popup
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className={styles['modal-container']}>
        <div className={styles['modal-container__header']}>
          <ContactEstatingModalHeader onClose={closeModal} />
        </div>
        <div className={styles['modal-container__body']}>
          { isRequestSended ?
            <div
              className={`alert ${
                isSuccess ? "alert-info" : "alert-danger"
              } d-flex justify-content-center align-items-center py-4 m-4`}
            >
              {isSuccess ? (
                <img src={CircleFilledIcon} className="mr-3" alt="" />
              ) : null}
              <h4 className="mb-0">
                {isSuccess ? tEmailTemplates('email_templates.email_sent') : (error as any)?.data?.message}
              </h4>
            </div>
            :
            <ContactEstatingModalBody
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          }
        </div>
        <div className={styles['modal-container__footer']}>
          <ContactEstatingModalFooter
            onClose={closeModal}
            onConfirm={submitForm}
            isLoading={isLoading}
            isSended={isRequestSended}
          />
        </div>
      </div>
    </Popup>
  );
});
