import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import styles from './style.module.scss';

export interface WysiwygEditorProps {
  value: string;
  onValueChange: (value: string) => void;
}

export function WysiwygEditor(props: WysiwygEditorProps) {
  const blocksFromHtml = htmlToDraft(props.value);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
    props.onValueChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  };

  return (
    <Editor
      editorState={editorState}
      toolbarClassName={styles['draft-toolbar']}
      wrapperClassName={styles['draft-wrapper']}
      editorClassName={styles['draft-editor']}
      toolbar={{
        options: [
          'inline',
          'link'
        ],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
      }}
      onEditorStateChange={onEditorStateChange}
    />
  );
}
