import { memo, FC, useState, useCallback, useRef } from 'react';
import { ReactComponent as DownSvg } from 'assets/arrow-down.svg';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/use-outside-click';

import styles from './style.module.scss';

interface LanguageSelectorProps {
  availableLanguages: Array<{ value: string; label: string }>;
  onLanguageSelect: (selectedLanguage: string) => void;
  selectedLanguage?: string;
}

export const LanguageSelector: FC<LanguageSelectorProps> = memo(({
  availableLanguages,
  onLanguageSelect,
  selectedLanguage
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setIsDropDownOpen(false));

  const onDropDownOpen = useCallback(() => {
    setIsDropDownOpen((prev) => !prev);
  }, []);

  const findLanguageDescription = useCallback((selectedLanguage?: string) => {
    return availableLanguages.find(({ value }) => value === selectedLanguage);
  }, [availableLanguages]);

  const getLanguageFlagImage = useCallback((lang?: string) => {
    return `${window.location.origin}/img/language-flags/${lang || 'en'}.svg`;
  }, []);

  return (
    <div 
      role="button"
      className={
        classNames(
          styles['button'],
          {[styles['button_open']]: isDropDownOpen}
        )
      }
      ref={containerRef}
    >
      <div 
        className={
          classNames(
            styles['button__content'],
            {[styles['button__content_open']]: isDropDownOpen}
          )
        }
        onClick={onDropDownOpen}
      >
        <div 
          className={
            classNames(
              styles['button__language-description'],
              styles['button__hover-animated'],
            )
          }
        >
          <div
            className={
              classNames(
                styles["button__language-description-text"],
                'mr-1',
                'd-none',
                'd-md-flex'
              )
            }
          >
            {findLanguageDescription(selectedLanguage)?.label}
          </div>
          <div
            className={classNames(
              styles['button__language-description-image-flag-container']
            )}
          >
            <img src={getLanguageFlagImage(selectedLanguage)} alt="country flag" />
          </div>
        </div>
        <div
          className={
            classNames(
              styles['button__icon'],
              styles['button__hover-animated'],
              {[styles['button__icon_open']]: isDropDownOpen}
            )
          }
        >
          <DownSvg />
        </div>
      </div>
      <div className={
          classNames(
            {[styles['button__dropdown_open']]: isDropDownOpen},
            styles['button__dropdown']
          )
        }
      > 
        {
          availableLanguages.map(({ label, value }) => {
            const targetLanguageDescription = findLanguageDescription(value);

            if (!targetLanguageDescription || value === selectedLanguage) {
              return null;
            }

            return (
              <div
                className={
                  classNames(
                    styles['button__dropdown-item'],
                  )
                }
                onClick={() => {
                  onLanguageSelect(value);
                  setIsDropDownOpen(false);
                }}
                key={label}
              >
                <div 
                  className={
                    classNames(
                      styles['button__language-description'],
                      styles['button__hover-animated'],
                    )
                  }
                >
                  <div
                    className={
                      classNames(
                        styles["button__language-description-text"],
                        'mr-1',
                        'd-none',
                        'd-md-flex'
                      )
                    }
                  >
                    {targetLanguageDescription.label}
                  </div>
                  <div
                    className={classNames(
                      styles['button__language-description-image-flag-container']
                    )}
                  >
                    <img src={getLanguageFlagImage(value)} alt="country flag" />
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  )
});
