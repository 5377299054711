import { TextareaHTMLAttributes, FC, useState, useEffect } from 'react';
import { useTranslate } from '@tolgee/react';

interface CustomSelectProps {
  label?: string,
  data: string[],
  callback: (value: number | string) => any,
  selected?: any,
  headerSmall?: boolean,
  disabled?: boolean,
  addNew?: boolean,
  newInputProps?: TextareaHTMLAttributes<HTMLTextAreaElement>,
}


const CustomSelect: FC<CustomSelectProps> = ({
  label,
  data,
  callback,
  selected,
  disabled ,
  headerSmall,
  addNew,
  newInputProps
}) => {
  const [addCustomValue, setAddCustomValue] = useState<boolean>(!!selected && !data.includes(selected));
  const [newAddedValue, setNewAddedValue] = useState(selected && data.includes(selected) ? '' : selected);
  const { t: tPublicArea } = useTranslate('public_area');

  const onChange = (value: string) => {
    callback && callback(value)
  }

  useEffect(() => {
    if (!!newAddedValue && addCustomValue && (newAddedValue !== selected)) {
      callback(newAddedValue)
    }
  }, [newAddedValue, selected, addCustomValue, callback])

  let newAddedInputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
    placeholder: tPublicArea('public_area.type_something'),
    autoFocus: true
  }

  if (newInputProps) {
    newAddedInputProps = {...newAddedInputProps, ...newInputProps}
  }


  return (
    <>
      {
        label &&
        <p className={`text-center ${headerSmall ? 'header-text-small' : 'header-text'}`}>{label}</p>
      }
      {
        data.map((value: string) => {
          const isSelected = selected === value

          return (
            <div
              onClick={() => {
                if (!disabled) {
                  setAddCustomValue(false)
                  onChange(value)
                }
              }}
              key={value}
              className={`custom-select my-2 ${isSelected ? 'custom-select-selected' : ''}`}>
              {value}
            </div>
          )
        })
      }
      {
        addNew &&
        <div
          onClick={() => {
            setAddCustomValue(true)
            onChange('')
          }}
          className={`custom-select my-2 ${addCustomValue ? 'custom-select-selected' : ''}`}
        >
          {tPublicArea('public_area.other')}
        </div>
      }
      {
        addCustomValue &&
        <textarea
          {...newAddedInputProps}
          value={newAddedValue}
          className='form-control'
          onChange={(e) => {
            setNewAddedValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      }
    </>
  );
}

export default CustomSelect
