import { privateApi } from "./estatingApi";
import { getBaseUrl, getEstatingCatalystURL } from "../app-config";
import dayjs from 'dayjs';

const DATE_FORMAT = 'DD-MM-YYYY - HH:mm:ss [UTC]Z';

export const downloadPdfTeaser = async (propertyTeaserId: string) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const requestURL = `${getBaseUrl()}/teaser/${propertyTeaserId}?format=pdf&timeString=${dayjs().format(DATE_FORMAT)}`;

        const response = await fetch(requestURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "x-auth": localStorage.getItem("jwtToken") ?? '',
          },
        })

        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));

        const contentDisposition = response.headers.get('content-disposition');
        
        if (!contentDisposition) {
          throw new Error('content-disposition header is empty')
        }
        const fileName = contentDisposition.split('filename=')[1];

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();
        link.remove();
        resolve(fileName);
    } catch (err) {
        reject(err);
    }
    })
}

export const fetchHtmlTeaser = async (propertyTeaserId: string) => {
    try {
        const requestURL = `${getBaseUrl()}/teaser/${propertyTeaserId}?format=html&timeString=${dayjs().format(DATE_FORMAT)}`;
        const response = await privateApi.get(requestURL);

        return { html: response.data, requestURL };
    } catch (err) {
        console.log(err)
    }
}

export const getTeaserPreviewLink = (propertyTeaserId: string) =>
  `${getEstatingCatalystURL()}opportunity/${propertyTeaserId}?teaser-preview=${propertyTeaserId}`;

export const getTeaserDownloadUrl = (propertyTeaserId: string) =>
  `${getBaseUrl()}/teaser/${propertyTeaserId}?format=pdf`;
