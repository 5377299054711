import { SubscriptionStatus } from "../../../store/properties/types";

export const oneDayPixelWidth = 2.5;
export const gapBetweenMonthColumns= 26;


export const propertyNameColumnWidth = 160;
export const propertyNameColumnPaddingRight = 11;

export const tablePaddingX = 30;

export const quarterNameBlockHeight = 25;
export const quarterNameBlockMarginBottom = 12;

export const eventHeight = 45;

export const eventStatsColors: { [key in SubscriptionStatus]: string } = {
  'PRIMARY_MARKET': '#B6DAE0',
  'CLOSED_TRANSACTION': '#1B2633',
  'PRE_SUBSCRIPTION': '#E1DFCD',
  'SECONDARY_MARKET': '#9E8163',
};
