import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';

import styles from './style.module.scss';

interface InvestmentRiskItemsProps {
  value: string;
}

export const InvestmentRiskItems: FC<InvestmentRiskItemsProps> = memo(({ value }) => {
  const { t: tDetailPageInvestment } = useTranslate('opportunity_detail_page.investment');

  
  const titleArray = useMemo(() =>[
    tDetailPageInvestment('opportunity_detail_page.investment.risk_level.low'),
    tDetailPageInvestment('opportunity_detail_page.investment.risk_level.mid'),
    tDetailPageInvestment('opportunity_detail_page.investment.risk_level.high'),
  ], [tDetailPageInvestment]);

  return(
    <div className={styles.risk}>
      {
        titleArray.map(item => (
          <div 
            className={
              classNames(
                styles.risk__button,
                {[styles.risk__button_active]: value.toLowerCase() === item.toLowerCase()},
              )
            }
            key={item}
          >
            {item}
          </div>
        )) 
      }
    </div>
  )
})