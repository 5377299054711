import { FC, Fragment, memo, useCallback, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "assets/catalyst-logo.png";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as BurgerMenuSvg } from "assets/mobileMenu.svg";
import { ReactComponent as CloseBurgerMenuSvg } from "assets/x.svg";
import classNames from "classnames";
import { NavItem } from "../../types/nav-item";
import { useSession } from "context/session";
import { MobileLanguageSelector } from "new-architecture/components/mobile-language-selector";
import UserAPI from "api/user";
import { useTolgee } from "@tolgee/react";

import styles from "../mobile-menu/style.module.scss";

interface MobileMenuProps {
  navItems: Array<NavItem>;
}

const navMenuClass = classNames(
  "d-flex align-items-center mt-5",
  styles.mobilemenu
);
const navItemsGapClass = classNames(
  "d-flex direction-column flex-column h-100",
  styles.menuItemsGaps
);

const navBar = classNames("w-100 bg-bgLight", styles.navBar);
const navBarButton = classNames("p-0", styles.toggleButton);
const navBarContentClass = classNames(styles.mobilemenu__content);

export const MobileMenu: FC<MobileMenuProps> = memo(({ navItems }) => {
  const { pathname } = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { logout, setUser, user } = useSession();
  const tolgee = useTolgee();

  useEffect(() =>{
    if (menuExpanded) {
      document.body.classList.add(styles.no_scroll)
    } else {
      document.body.classList.remove(styles.no_scroll)
    }
  }, [menuExpanded]);


  useEffect(() => {
    if (menuExpanded) {
      document.body.classList.add(styles.no_scroll);
    } else {
      document.body.classList.remove(styles.no_scroll);
    }
  }, [menuExpanded]);

  const onLanguageChange = useCallback(async (newLanguage: string) => {
    if (user) {
      const updatedUser = await UserAPI.changeUser({ lang: newLanguage });
      setUser(updatedUser)
    }

    await tolgee.changeLanguage(newLanguage);
  }, [user, tolgee, setUser]);

  const handleMenuToggle = () => {
    setMenuExpanded((prev) => !prev);
  };

  return (
      <Navbar expand="sm" className={navBar}>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Estating logo" className={styles.logo} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleMenuToggle}
          className={navBarButton}
        >
          {menuExpanded ? <CloseBurgerMenuSvg /> : <BurgerMenuSvg />}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className={navBarContentClass}>
          <Nav className={navMenuClass}>
            <div className={navItemsGapClass}>
              {navItems.map(({ name, route, icon, target }, index) => {
                const textStyle = classNames(
                  "w-100 d-flex text-gray align-items-center",
                  styles.text,
                  { [styles.active]: pathname === route }
                );

                return index === navItems.length - 1 ? (
                  <div
                    key={route}
                    className="d-flex pb-5 flex-grow-1 flex-column-reverse"
                  >
                    <div className="mt-5">
                      <MobileLanguageSelector
                        availableLanguages={[{ value: 'en', label: 'EN'}, { value: 'es', label: 'ES' }]}
                        onLanguageSelect={onLanguageChange}
                        selectedLanguage={tolgee.getPendingLanguage()}
                      />
                    </div>
                    {
                      target ?
                      <a href={route} rel="noreferrer" target="_blank" className={textStyle}>
                        <div className={classNames(styles.icon, "mr-2")}>
                          {icon}
                        </div>
                        <span>{name}</span>
                      </a>
                      :
                      <Link to={route} target={target} onClick={logout} className={textStyle}>
                        <div className={classNames(styles.icon, "mr-2")}>
                          {icon}
                        </div>
                        {name}
                      </Link>
                    }
                  </div>
                ) : (
                  <Fragment key={route}>
                  {
                    target ?
                    <a href={route} rel="noreferrer" target="_blank" className={textStyle}>
                      <div className={classNames(styles.icon, "mr-2")}>
                        {icon}
                      </div>
                      <span>{name}</span>
                    </a>
                    :
                    <Link to={route} target={target} className={textStyle}>
                      <div className={classNames(styles.icon, "mr-2")}>
                        {icon}
                      </div>
                      <span>{name}</span>
                    </Link>
                  }
                  </Fragment>
                );
              })}
            </div>
          </Nav>
          <div className={styles.overlay}  />
        </Navbar.Collapse>
      </Navbar>
  );
});