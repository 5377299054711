import { memo, FC } from 'react';
import { TagDescription } from 'store/search';

import styles from './style.module.scss';

interface PropertyCardDetailsTagsProps {
  tags: Array<TagDescription>;
  onTagClick: (tag: TagDescription) => void;
};

export const PropertyCardDetailsTags: FC<PropertyCardDetailsTagsProps> = memo(({ tags, onTagClick }) => {

  return (
    <div className={styles['tags']}>
      {tags.map((tag) => {
        if (!tag || !tag.value || !tag.label) {
          return null;
        }

        return (
          <span
            key={tag.value + tag.label}
            onClick={() => onTagClick(tag)}
            className={styles['tags__item']}
          >
            {tag.label}
          </span>
        );
      })}
    </div>
  );
});