import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { PDFViewer } from "components/pdf-viewer";
import { ReactBootstrapPopUp } from "modal/react-bootstrap-pop-up";
import { Helmet } from 'react-helmet';
import { PropertyTeaserType } from "store/properties/types";

interface TeaserPreviewProps {
  file: string;
  mimeType: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;

  //  analitics
  property?: PropertyTeaserType;
  onPropertyOpen?: (material: PropertyTeaserType, page: number) => void;
  onPropertyClose?: (material: PropertyTeaserType, page: number) => void;
  onPropertyPageChange?: (material: PropertyTeaserType, page: number) => void;
}

export const TeaserPreview: FC<TeaserPreviewProps> = memo(({
  file,
  mimeType,
  isOpen,
  onClose,
  title,

  property,
  onPropertyOpen,
  onPropertyClose,
  onPropertyPageChange
}) => {
  const currentPage = useRef(0);

  const onForceClose = useCallback(() => {
    if (!property) {
      return;
    }

    if (onPropertyClose) {
      onPropertyClose(property, currentPage.current);
    }
  }, [property, onPropertyClose]);

  useEffect(() => {
    window.onbeforeunload = onForceClose;

    return () => {
      window.onbeforeunload = null;
    }
  }, [onForceClose]);

  useEffect(() => {
    if (property && isOpen && onPropertyOpen) {
      onPropertyOpen(property, currentPage.current);
    }

    return () => {
      if (property && onPropertyClose) {
        onPropertyClose(property, currentPage.current);
      }
    }

  }, [isOpen, property, onPropertyClose, onPropertyOpen]);

  const _onMaterialPageChange = useCallback((page: number) => {
    if (!onPropertyPageChange || !property) {
      return;
    }

    currentPage.current = page;
    onPropertyPageChange(property, page);
  }, [property, onPropertyPageChange]);

  const mediaContentDisplayer = useMemo(() => {
    switch (true) {
      case mimeType.startsWith("video"):
        return (
          <video
            width="100%"
            height="auto"
            controls
            className={`slider-image border border-2 `}
            muted
            autoPlay
          >
            <source src={file} type={mimeType} />
          </video>
        );

      case mimeType.startsWith("image"):
        return (
          <img
            alt="File"
            style={{
              height: "auto",
              maxHeight: "75vh",
              width: "100%",
              objectFit: "cover",
            }}
            src={file}
          />
        );

      case mimeType === "application/pdf":
        return (
          <PDFViewer file={file} onPageChange={_onMaterialPageChange} />
        );

      default:
        return;
    }
  }, [mimeType, file, _onMaterialPageChange]);

  const helmet = useMemo(() => {
    if (!isOpen || !property) {
      return null;
    }
    
    return (
      <Helmet>
        <title>{`${property?.name} - Estating`}</title>
        {property?.description && 
          <meta name="description" content={`${property?.description} | ${property?.description}`} />
        }
      </Helmet>
    )
  }, [isOpen, property]);

  return (
    <>
      {helmet}
      <ReactBootstrapPopUp
        isShow={isOpen}
        handleClose={onClose}
        headerContent={
          <div
            className="overflow-hidden text-break m-0"
            style={{
              maxHeight: '30px',
            }}
          >
            {title}
          </div>
        }
        bodyContent={mediaContentDisplayer}
        modalProps={{
          centered: true,
          contentClassName: 'h-100 overflow-auto',
        }}
      />
    </>
  );
});
