import { SubscriptionStatus } from 'store/properties/types';
import { FC, memo, useMemo } from 'react';
import { propertyStatusColors } from '../../constants/property-status-colors';
import { useTranslate } from '@tolgee/react';

import styles from './style.module.scss';

interface PropertyStatusProps {
  status: SubscriptionStatus
}

export const PropertyStatus: FC<PropertyStatusProps> = memo(({ status }) => {
  const { color, backgroundColor } = useMemo(() => propertyStatusColors[status], [status]);
  const { t: tEnums } = useTranslate('enums');

  return (
    <div 
      className={styles.container}
      style={{
        color,
        backgroundColor
      }}
    >
      {tEnums(`enums.subscription_status.${status}`) || status}
    </div>
  );
});
