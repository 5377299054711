import { FC, memo } from "react";
import classNames from "classnames";
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { OverlayTrigger } from "react-bootstrap";
import { descriptionPopover } from "new-architecture/components/description-popover";

import styles from "./style.module.scss";

type InvestmentOpportunityDetailsItemProps = {
  title: string;
  value?: string | JSX.Element;
  tooltip?: string;
  icon?: string;
};

export const InvestmentOpportunityDetailsItem: FC<InvestmentOpportunityDetailsItemProps> = memo(
  ({ title, value, tooltip, icon }) => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <span className={styles.details__title}>{title}</span>
          {tooltip &&
            <div 
              role="button"
              className={
                classNames(
                  styles['details__title-icon-container'],
                )
              }
            >
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="auto"
                overlay={descriptionPopover({ 
                  descriptions: [{
                    description: tooltip,
                    title
                  }]
                })}
              >
                <InfoSVG />
              </OverlayTrigger>
            </div>
          }
        </div>
        
        <div className="d-flex align-items-center">
          { icon &&
            <div className={`${styles['details__title-prefix-icon-container']} mr-1`}>
              <img alt="icon" src={icon} />
            </div>
          }
          <div
            className={styles.details__value}
          >
            {value}
          </div>
        </div>
      </div>
    );
  }
);

InvestmentOpportunityDetailsItem.displayName =
  "InvestmentOpportunityDetailsItem";