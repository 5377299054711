import { memo, FC, useCallback, useState, useEffect } from 'react';
import { Document } from 'store/properties/types';
import { ReactComponent as ArrowSvg } from "assets/arrowDown.svg";
import { useSelector } from 'react-redux';
import { useDownloadReportFileMutation } from '../../store/api/performance-report';
import { performancePassword } from '../../store/slices/preformance-report';
import { getPrivateThumbnail } from 'helpers/thumbnail-generator';

import styles from './style.module.scss';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

interface DocumentCardProps {
  document: Document;
  propertyId: string;
}

export const DocumentCard: FC<DocumentCardProps> = memo(({ document, propertyId }) => {
  const { _id, name, description, documentName, key, mimeType } = document;
  const password = useSelector(performancePassword);

  const [downloadFile] = useDownloadReportFileMutation();

  const onDownload = useCallback(async () => {
    const splittedName = documentName?.split('_');
    const extension = splittedName?.pop()?.split('.')?.pop();
    const fileName = `${splittedName?.join('_')}.${extension}`;

    downloadFile({ password, propertyId, documentId: _id, name: fileName || 'Performance report' });
  }, [_id, downloadFile, password, propertyId, documentName]);

  const [thumbnail, setThumbnail] = useState<string>();

  useEffect(() => {
    (async() => {
      const image = await getPrivateThumbnail(key as string, mimeType);
      const imageUrl = window.URL.createObjectURL(image);
      setThumbnail(imageUrl);
    })();
  }, [key, mimeType]);

  const { t: tOpportunityDetailPageInvestorReport } = useTranslate('opportunity_detail_page.investor_report');

  return (
    <div className={styles['document-card']}>
      <div className={styles['document-card__thumbnail-area']}>
        <div className={styles['document-card__thumbnail']}>
          <img
            alt='Document thumbnail'
            src={thumbnail}
          />
        </div>
      </div>

      <div
        className={
          classNames(
            'w-100 d-flex flex-column',
            styles['document-card__content-container']
          )
        }
      >
        <div className={styles["document-card__download-button-area"]}>
          <div
            className={styles["document-card__download-button"]}
            role="button"
            onClick={onDownload}
          >
            <ArrowSvg />
            <span>
              {tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.button.download')}
            </span>
          </div>
        </div>

        <div className='d-flex flex-column justify-content-center flex-grow-1'>
          <div className={styles['document-card__title']}>
            {name}
          </div>
          <div className={styles['document-card__description']}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
});
