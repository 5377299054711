import { FC, memo, useCallback, useState } from 'react';
import { PropertyCard } from 'new-architecture/modules/properties-cards-list/components/property-card';
import { PropertyObj } from 'store/properties/types';
import { TagDescription } from 'store/search';
import { AnimatePresence, motion } from "framer-motion";
import { SummaryCardBanner } from './summary-card-banner';
import { useSession } from 'context/session';

import styles from './style.module.scss';


interface PropertiesCardsListProps {
  properties: Array<PropertyObj>;
  onTagClick: (tag: TagDescription) => void;
}

export const PropertiesCardsList: FC<PropertiesCardsListProps> = memo(({
  properties,
  onTagClick,
}) => {
  const { isBannerShow, setIsBannerShow } = useSession();
  const [showItem, setShowItem] = useState(isBannerShow);
  const handleBannerClose = useCallback(() => {
    setShowItem(false);
    setTimeout(() => {
      setIsBannerShow(false)
    }, 300);
  }, [setIsBannerShow]);

  if (!properties.length) {
    return null;
  }
  
  return (
    <AnimatePresence>
      <div className={styles.container}>
        {
          showItem && 
          <motion.div
            layout
            exit={{ scale: 0.8, opacity: 0 }}
            className={styles.container__item}
            key={0}
          >
            <SummaryCardBanner
              onClose={handleBannerClose}
              onDismiss={handleBannerClose}
            />
          </motion.div>
        }
        {
          properties.map((property, indx) => {
            return (
              <motion.div
                layout
                exit={{ scale: 0.8, opacity: 0 }}
                className={styles.container__item}
                key={indx + 1}
              >
                <PropertyCard
                  propertyDescription={property}
                  onTagClick={onTagClick}
                />
              </motion.div>
            );
          })
        }
      </div>
    </AnimatePresence>
  )
});
