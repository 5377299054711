import React from 'react'
import SetPassword from "new-architecture/pages/sign-up/components/set-password";
import Input from "../../components/Input";
import { useTranslate } from '@tolgee/react';


interface ChangePasswordProps {
    currentPassword: {
        value: string,
        callback: (value: string) => any,
        inputProps?: React.InputHTMLAttributes<any>,
    },
    newPassword: {
        value: string,
        callback: (value: string) => any,
        inputProps?: React.InputHTMLAttributes<any>,
    },
    confirmNewPassword: {
        value: string,
        callback: (value: string) => any,
        inputProps?: React.InputHTMLAttributes<any>,
    }
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  currentPassword,
  newPassword,
  confirmNewPassword
}) => {
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');

  return (
    <>
      <Input
        inputAttributes={{
          value: currentPassword.value,
          type: 'password',
          placeholder: 'Current password',
          ...currentPassword.inputProps,
          onChange: (e) => currentPassword.callback(e.target.value)
        }}
      />
      <SetPassword
        password={{
          value: newPassword.value,
          callback: newPassword.callback,
          inputProps: {
            ...newPassword.inputProps,
            placeholder: tPrivateAreaMyAccount('private_area.my_account.new_password')
          }
        }}
        confirmPassword={{
          value: confirmNewPassword.value,
          callback: confirmNewPassword.callback,
          inputProps: {
            ...confirmNewPassword.inputProps,
            placeholder: tPrivateAreaMyAccount('private_area.my_account.re_enter_new_password')
          }
        }}
      />
    </>
  )
}

export default ChangePassword
