import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchEducationMaterialsGroups } from "../../store/estating-academy/thunks";
import {
  educationMaterialsGroupsSelector,
  educationMaterialsGroupsLoadingSelector,
  educationMaterialsGroupsErrorSelector,
  educationMaterialsGroupsUpdatingSelector,
} from "../../store/estating-academy/selectors";
import Input from "../../components/Input";
import SearchSVG from "../../assets/search.svg";
import { useSession } from "../../context/session";
import { EducationMaterialsGroupList } from "../../components/education-materials/education-materials-groups-list";
import { debounce } from "lodash";
import { SelectedTags } from "../../components/selected-tags";
import { selectSearchTags } from "../../store/search/selectors";
import { addSearchTag, removeSearchTag, TagDescription } from "../../store/search";
import { setGroupsEducationMaterialsGroups } from "../../store/estating-academy/actions";
import { useTolgee, useTranslate } from "@tolgee/react";
import { WhoAreYou } from "types/user/who-are-you";

export const EstatingAcademy = memo(() => {
  const dispatch = useDispatch();
  const { user } = useSession();

  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const onSearchIconClick = useCallback(() => {
    searchInputRef.current?.focus();
  }, []);
  const tolgee = useTolgee();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchValue, tags) => {
      dispatch(searchEducationMaterialsGroups(
        {
          'lang': tolgee.getPendingLanguage() || 'en',
          'whoAreYou': user ? user!.whoAreYou : WhoAreYou.OTHER,
          'searchValue': searchValue,
          'tags': tags
        }
      ));
    }, 1000),
    [tolgee.getPendingLanguage()]
  );

  const tags = useSelector(selectSearchTags);
  const tagsValues = useMemo(() => tags.map(({ value }) => value), [tags]);

  const removeTag = useCallback((tag: TagDescription) => {
    dispatch(removeSearchTag(tag));
  }, [dispatch]);
  
  useEffect(() => {
    debouncedSearch(searchValue, tagsValues);
  }, [searchValue, tagsValues, debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setGroupsEducationMaterialsGroups(null));
    }
  }, [dispatch]);

  const groups = useSelector(educationMaterialsGroupsSelector);
  const isLoading = useSelector(educationMaterialsGroupsLoadingSelector);
  const isError = useSelector(educationMaterialsGroupsErrorSelector);
  const isUpdating = useSelector(educationMaterialsGroupsUpdatingSelector);
  const addTag = useCallback((tag: TagDescription) => {
    dispatch(addSearchTag(tag));
  }, [dispatch]);

  const { t: tEstatingAcademy } = useTranslate('estating_academy.content');

  return (
    <div className="col-xl-10 col-sm-11 col-11 pt-5 pt-lg-0 px-0 mx-auto properties">
      <>
        <h1 className="d-lg-none">
          {tEstatingAcademy('estating_academy.content.search_title')}
        </h1>
        <Input
          inputAttributes={{
            placeholder: tEstatingAcademy('estating_academy.content.search_placeholder'),
            value: searchValue,
            ref: searchInputRef,
            onKeyDown: (e) => {
              if (e.keyCode === 13) {
                addTag({ label: searchValue, value: searchValue });
                setSearchValue("");
              }
            },
          }}
          onChange={({ target }) => {
            const { value } = target;
            setSearchValue(value);
          }}
          className="radius-20 mt-5"
          mergeIcon={true}
          leftIcon={
            <img
              alt="search"
              onClick={onSearchIconClick}
              src={SearchSVG}
              className="cursor-pointer"
            />
          }
        />
        {!!tags.length &&
          <SelectedTags tags={tags} onRemoveTag={removeTag} />
        }
      </>
      {!!isError ? (
        <div className="d-flex justify-content-center mt-8 align-items-center">
          <div className="text-primary" role="status">
            <span>{isError}</span>
          </div>
        </div>
      ) : !groups || isLoading || isUpdating ? (
        <div className="d-flex justify-content-center mt-8 align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="justify-content-lg-between justify-content-md-center justify-content-sm-center">
          <EducationMaterialsGroupList groups={groups} />
        </div>
      )}
    </div>
  );
});
