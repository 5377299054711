import { FC, memo } from "react";

import style from "./style.module.scss";

type PropertyDataRoomTextProps = {
  text: string;
};

export const PropertyDataRoomText: FC<PropertyDataRoomTextProps> = memo(
  ({ text }) => (
    <div className={style["data-room__container"]}>
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        className={style["data-room__text-box"]}
      />
    </div>
  )
);
