import React, { CSSProperties } from 'react'

const Select: React.FC<{
    id?: string,
    label?: string,
    labelComponent?: JSX.Element,
    values: any[],
    selected?: string,
    placeholder?: string,
    selectProps?: React.InputHTMLAttributes<HTMLSelectElement>,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any,
    containerClasses?: string,
    style?: CSSProperties,

}> = ({
          id,
          label,
          labelComponent,
          selectProps = {},
          onChange = () => {
          },
          values,
          selected,
          containerClasses,
          placeholder,
          style
      }) => {

    if (!id) {
        id = Math.random().toString()
    }
    return (
        <div className={`${containerClasses || ''}`}>
            {
                label &&
                <label className='font-weight-bold d-flex' htmlFor={id}>{label}{labelComponent}</label>
            }

            <select
                id={id}
                style={{
                    height: 50,
                    ...style
                }}
                value={selected}
                onChange={onChange}
                className="form-control"
                {...selectProps}
            >
                <option value=""  hidden>{placeholder || 'Select'}</option>
                {
                    values.map((value, index) => {
                        return (
                            <option value={value} key={value + index}>{value}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default Select
