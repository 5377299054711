import classNames from 'classnames';
import { memo, FC, useCallback, useMemo } from 'react';
import styles from './style.module.scss';

interface MobileLanguageSelectorProps {
  availableLanguages: Array<{ value: string; label: string }>;
  onLanguageSelect: (selectedLanguage: string) => void;
  selectedLanguage?: string;
}

export const MobileLanguageSelector: FC<MobileLanguageSelectorProps> = memo(({
  availableLanguages,
  onLanguageSelect,
  selectedLanguage
}) => {
  const getLanguageFlagImage = useCallback((lang?: string) => {
    return `${window.location.origin}/img/language-flags/${lang || 'en'}.svg`;
  }, []);

  const mappableLanguages = useMemo(() => {
    const tuples: Array<[{ value: string, label: string}, { value: string, label: string}]> = [];

    for (let i = 0; i < availableLanguages.length; i+= 2) {
      tuples.push([availableLanguages[i], availableLanguages[i + 1]])
    }

    return tuples;
  }, [availableLanguages]);

  const onSelectLanguage = useCallback((value: string) => {
    onLanguageSelect(value);
  }, [onLanguageSelect]);

  return (
    <div
      className={classNames(
        styles['container']
      )}
    >
      {mappableLanguages.map(([first, second]) => {
        return (
          <div className={classNames(styles['container__row'])}>
            <div
              className={
                classNames(
                  styles['container__row-column'],
                  {[styles['container__row-column_selected']]: selectedLanguage === first.value}
                )
              }
              role="button"
              onClick={() => {
                onSelectLanguage(first.value)
              }}
            >
              <div className={classNames(styles['container__row-text'])}>
                {first?.label}
              </div>
              <div
                className={classNames(
                  styles['container__language-flag-container']
                )}
              >
                <img src={getLanguageFlagImage(first?.value)} alt="country flag" />
              </div>
            </div>
            <div className={classNames(styles['container__row-divider'])} />
            <div
              className={
                classNames(
                  styles['container__row-column'],
                  {[styles['container__row-column_selected']]: selectedLanguage === second.value}
                )
              }
              role="button"
              onClick={() => {
                onSelectLanguage(second.value)
              }}
            >
              <div className={classNames(styles['container__row-text'])}>
                {second?.label}
              </div>
              <div
                className={classNames(
                  styles['container__language-flag-container']
                )}
              >
                <img src={getLanguageFlagImage(second?.value)} alt="country flag" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});