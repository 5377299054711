import { ChangeEvent, FC, memo, useCallback } from 'react';
import { Input } from 'new-architecture/components/input';
import { MultipleEmailsInput } from '../multiple-emails-input';
import { FormikValues } from '../../types/formik-values';
import { FormikErrors } from 'formik';
import { WysiwygEditor } from 'new-architecture/components/wysiwyg-editor';
import { PhotosSelect } from '../photos-select';
import { DocumentsSelect } from '../documents-select';
import { Document } from 'store/properties/types';
import { useTranslate } from '@tolgee/react';

import styles from './style.module.scss';

interface PropertyShareModalBodyProps {
  values: FormikValues;
  handleChange: (event: ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  propertyImages?: Array<string>;
  documents?: Array<Document>;
  teaserName: string;
}

export const PropertyShareModalBody: FC<PropertyShareModalBodyProps> = memo(({
  values,
  setFieldValue,
  handleChange,
  propertyImages,
  documents,
  teaserName
}) => {
  const { 
    otherEmails,
    toEmail,
    subject,
    message,
    images,
    attachments,
    attachTeaser
  } = values;

  // const [email, setEmail] = useState('');

  // const handleAddEmail = useCallback(() => {
  //   if (_validateEmail(email)) {
  //     setFieldValue('otherEmails', [...otherEmails, email]);
  //     setEmail('');
  //   }
  // }, [email, otherEmails, setFieldValue]);

  // const handleRemoveEmail = useCallback((index: number) => {
  //   const copy = [...otherEmails]
  //   copy.splice(index, 1);
  //   setFieldValue('otherEmails', copy);
  // }, [otherEmails, setFieldValue]);

  // const handleEnterPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === "Enter"){
  //     handleAddEmail();
  //   }
  // }, [handleAddEmail]);

  const handleChangeSelectedPhotos = useCallback((newValue: Array<string>) => {
    setFieldValue('images', newValue);
  }, [setFieldValue]);

  const handleChangeSelectedAttachments = useCallback((newValue: Array<Document>) => {
    setFieldValue('attachments', newValue);
  }, [setFieldValue]);

  const handleChangeAttachTeaser = useCallback((newValue: boolean) => {
    setFieldValue('attachTeaser', newValue);
  }, [setFieldValue]);

  const { t: tShareModal } = useTranslate('modal');

  return (
    <div className={styles['modal-body']}>
      <div className={styles['modal-body__inputs-group']}>
        <div className={styles['modal-body__inputs-group__send-to-area']}>
          <Input
            label={tShareModal('modal.send_to')}
            inputAttributes={{
              placeholder: tShareModal('modal.receiver_of_email'),
              type: 'email',
              value: toEmail,
              id: 'toEmail',
              onChange: handleChange
            }}
          />
        </div>
        <div className={styles['modal-body__inputs-group__additional-emails']}>
          <MultipleEmailsInput
            label={tShareModal('modal.cc')}
            multiEmailProps={{
              placeholder: tShareModal('modal.receiver_of_email'),
              emails: otherEmails,
              onChange: (_emails: Array<string>) => {
                setFieldValue('otherEmails', _emails);
              },
              getLabel: (
                email: string,
                index: number,
                removeEmail: (index: number) => void
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              },
            }}
          />
        </div>
        <div className={styles['modal-body__inputs-group__subject-area']}>
          <Input
            label={tShareModal('modal.subject')}
            inputAttributes={{
              placeholder: tShareModal('modal.subject'),
              type: 'email',
              id: 'subject',
              value: subject,
              onChange: handleChange,
            }}
          />
        </div>
      </div>
      <div className={styles['modal-body__custom-fields']}>
        <div className={styles['custom-field-container']}>
          <div className={styles['custom-field-container__label']}>
            {tShareModal('modal.message')}
          </div>
          <WysiwygEditor
            value={message}
            onValueChange={(value) => setFieldValue('message', value)}
          />
        </div>
      </div>
      {
        propertyImages && 
        <div className={styles['modal-body__custom-fields']}>
          <div className={styles['custom-field-container']}>
            <div className={styles['custom-field-container__label']}>
              {tShareModal('modal.photos')}
            </div>
            <PhotosSelect
              selectedPhotos={images}
              availableImages={propertyImages}
              onChangeSelectedPhotos={handleChangeSelectedPhotos}
            />
          </div>
        </div>
      }
      {
        documents &&
        <div className={styles['modal-body__custom-fields']}>
          <div className={styles['custom-field-container']}>
            <div className={styles['custom-field-container__label']}>
              {tShareModal('modal.attachments')}
            </div>
            <DocumentsSelect
              teaserName={teaserName}
              availableDocuments={documents}
              attachments={attachments}
              onChangeAttachments={handleChangeSelectedAttachments}
              attachTeaser={attachTeaser}
              setAttachTeaser={handleChangeAttachTeaser}
            />
          </div>
        </div>
      }
    </div>
  );
});