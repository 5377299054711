import { Popup } from "new-architecture/components/pop-up";
import { FC, memo, useState } from "react";
import { PropertyGalleryModalSwiper } from "./property-gallery-modal-swiper";
import { PropertyGalleryModalMain } from "./property-gallery-modal-main";
import { ReactComponent as CloseSvg } from "assets/closeWhite.svg";
import classNames from "classnames";

import style from "./style.module.scss";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  assets: Array<string>;
};

const boxClass = classNames(
  "d-flex flex-column align-items-center justify-content-between w-100",
  style["modal-container__box"]
);

const swiperContainerClass = classNames(
  "d-flex mt-4",
  style["modal-container__swipper-container"]
);
export const PropertyGalleryModal: FC<Props> = memo(
  ({ isOpen, closeModal, assets }) => {
    const [selectedImage, setSelectedimage] = useState<string>(assets[0]);
    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const setImage = (param: string) => {
      setSelectedimage(param);
    };

    const isVisibleButtonPlay = (param: boolean) => {
      setIsButtonVisible(param);
    };
    return (
      <Popup
        isOpen={isOpen}
        onClose={closeModal}
        popUpWrapperStyle={{ width: "100%", maxHeight: "100vh" }}
      >
        <div className={boxClass}>
          <div className="align-self-end cursor-pointer" onClick={closeModal}>
            <CloseSvg />
          </div>
          <div className={style['modal-container__main']}> 
            <PropertyGalleryModalMain
              asset={selectedImage}
              isVisibleButtonPlay={isVisibleButtonPlay}
              isButtonVisible={isButtonVisible}
            />
            </div>
          <div className={swiperContainerClass}>
            <PropertyGalleryModalSwiper
              assets={assets}
              setImage={setImage}
              isVisibleButtonPlay={isVisibleButtonPlay}
              isButtonVisible={isButtonVisible}
            />
          </div>
        </div>
      </Popup>
    );
  }
);
