import { generateThumbnail } from "helpers/thumbnail-generator";
import { Dispatch, FC, memo, SetStateAction, useContext, useMemo } from "react";
import { BsFillFileEarmarkFill } from "react-icons/bs";
import { EducationMaterial } from "types/education-materials/education-material";
import { EducationAndResearchContext } from "../property-education-and-research-card";

interface PropertyEducationAndResearchFilePreviewProps {
  setPreviewedMaterial: Dispatch<SetStateAction<EducationMaterial | null>>;
}

export const PropertyEducationAndResearchFilePreview: FC<PropertyEducationAndResearchFilePreviewProps> = memo(({
  setPreviewedMaterial,
}) => {
  const material = useContext(EducationAndResearchContext);
  const { mimeType, file, customThumbnail } = material!;

  const mediaContentDisplayer = useMemo(() => {
      if(!mimeType){
          return (
            <div style={{ height: 120, width: 300, objectFit: "cover" }}>
              <BsFillFileEarmarkFill style={{ width: "100%", height: "100%" }} />
            </div>
          );
      }

    if (mimeType.startsWith("video")) {
      return (
        <video
          style={{ pointerEvents: "none", objectFit: "cover" }}
          width={300}
          height={120}
          className={`slider-image border border-2 `}
          muted
        >
          <source src={file} type={mimeType} />
        </video>
      );
    }

    const generatedThumbnail =
      customThumbnail || generateThumbnail(mimeType, file);

    if (generatedThumbnail) {
      return (
        <img
          crossOrigin="anonymous"
          style={{
            height: "100%",
            width: "203px",
            objectFit: "cover",
          }}
          src={generatedThumbnail}
          alt="Document thumbnail"
        />
      );
    }

    return (
      <div style={{ height: 120, width: 300, objectFit: "cover" }}>
        <BsFillFileEarmarkFill style={{ width: "100%", height: "100%" }} />
      </div>
    );
  }, [mimeType, customThumbnail, file]);

  if (!mimeType) {
    return null;
  }

  return (
    <div className='h-100' onClick={() => setPreviewedMaterial(material)} style={{width: '203px'}}>
      {mediaContentDisplayer}
    </div>
  );
});
