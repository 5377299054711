export const _validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const _arrayChunk = (arr: any[], chunkSize: number) => {
    return arr.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}

export const _parseHTML = (htmlString: string) => {
    return htmlString.replace('{{', '<sup>').replace('}}', '</sup>')

}


export const _findOnlyNumbersIfExist = (valueToSearch: string, value: string) => {
    const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    let results: string[] = []

    if (isInteger(valueToSearch)) {
        for (let letter of value) {
            if (NUMBERS.includes(letter)) {
                results.push(letter)
            }
        }
    } else {
        return value
    }




    return results.length > 0 ?
        results.join('')
        :
        value

}


//---------------------------------

function isInteger(str: string) {
    const num = Number(str);

    return Number.isInteger(num);
}
