import { baseQueryWithAuth } from "api/base-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { InvestData } from "../../types/invest-data";

export const propertyInvestApi = createApi({
  reducerPath: 'propertyInvestApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    investOnProperty: builder.mutation<void, InvestData>({
      query: (body) => ({
        method: 'POST',
        url: `/user/invest`,
        body,
      }),
    }),
  }),
});

export const { useInvestOnPropertyMutation } = propertyInvestApi;