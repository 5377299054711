import { FC, memo, useCallback, useMemo } from 'react'
import CustomSelect from "components/CustomSelect";
import { WhoAreYou as WhoAreYouEnum } from "types/user/who-are-you";
import { useTranslate } from '@tolgee/react';

// const OPTIONS = ['Financial Advisor', 'Investor', 'Other']

interface WhoAreYouProps {
  callback: (value: number | string) => any,
  selected: string | number,
  disabled?: boolean
}

const WhoAreYou: FC<WhoAreYouProps> = memo(({
  callback,
  selected,
  disabled
}) => {
  const { t: tEnums } = useTranslate('enums');
  const { t: tPublicArea } = useTranslate('public_area');

  const { associativeMap, options } = useMemo(() => {
    const labels = Object.keys(WhoAreYouEnum);

    const associativeMap: {[key: string]: string} = {};

    labels.forEach((key) => {
      associativeMap[tEnums(`enums.who_are_you.${key}`)] = WhoAreYouEnum[key];
    });

    return {associativeMap, options: Object.keys(associativeMap)};
  }, [tEnums]);

  const onSelect = useCallback((value: number | string) => {
    callback(associativeMap[value]);
  }, [associativeMap, callback]);

  return (
    <>
      <h1 className='text-center font-weight-bold'>
        {tPublicArea('public_area.who_are_you')}
      </h1>
      <CustomSelect
        data={options}
        callback={onSelect}
        selected={selected}
        disabled={disabled}
      />
      <p className='text-center'>
        {tPublicArea('public_area.kyc_info')}
      </p>
    </>
  )
})

export default WhoAreYou
