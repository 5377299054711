export const emailMessage = (
  message: string,
  name: string,
  phone: string,
  fromEmail: string
) => 
  `<p>Name: ${name}</p>
    <p>Email: ${fromEmail}</p>
    <p>Phone: ${phone}</p>
    </br>
    ${message}`;

