import { PropertyTeaserType } from "store/properties/types";
import { FC, memo } from "react";
import { PropertyRiskLevelTitle } from "./property-risk-level-title";
import { Container } from "react-bootstrap";
import { RiskMigrationFactors } from "./property-risk-factors";
import { PropertyRiskImages } from "./property-risk-images";
import { PropertyRiskFiles } from "./property-risk-files";
import classNames from "classnames";

import style from "./style.module.scss";
interface PropertyRiskLevelProps {
  property: PropertyTeaserType;
}

const riskLevelBoxClass = classNames(
  "d-flex justify-content-between align-items-center w-100",
  style["risk-level__box-gap"]
);

export const PropertyRiskLevel: FC<PropertyRiskLevelProps> = memo(
  ({ property }) => {
    const { risk } = property;
    const { riskMitigationFactors, riskMitigationImages, documents } = risk;

    return (
      <div>
        <div className="bg-bgLight">
          <Container>
            <div className="d-sm-none">
              <div className={style["risk-level__container"]}>
                <div className={style["risk-level__box-top"]}>
                  <PropertyRiskLevelTitle />
                  <RiskMigrationFactors factors={riskMitigationFactors} />
                </div>
                <PropertyRiskImages images={riskMitigationImages} />
                <PropertyRiskFiles documents={documents} />
              </div>
            </div>
            <div className="d-none d-sm-flex w-100">
              <div className={style["risk-level__container"]}>
                <div className={riskLevelBoxClass}>
                  <div className={style["risk-level__box-top"]}>
                    <PropertyRiskLevelTitle />
                    <RiskMigrationFactors factors={riskMitigationFactors} />
                    <PropertyRiskFiles documents={documents} />
                  </div>
                  <div>
                    <PropertyRiskImages images={riskMitigationImages} />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
);
