import React, { ChangeEvent, MouseEvent } from "react";
import Input from "../../components/Input";
import sidePopup from "../../assets/side-popup.svg";
import Button from "../../components/Button";
import { UserAPI } from "../../api/user";
import Select from "../../components/Select";
import { COUNTRIES } from "helpers/constants";
import { useSession } from "../../context/session";
import { useTranslate } from "@tolgee/react";

type CompleteYourProfileProps = {
  withImage?: boolean;
  skipBtnClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  saveCallback?: (e?: MouseEvent<HTMLButtonElement>) => any;
  showSaveBtn?: boolean;
  showSkipBtn?: boolean;
  userId: string;
};

export type UserAdditionalInformation = {
  companyName: string;
  website: string;
  street: string;
  streetAddition: string;
  postalCode: string;
  poBox: string;
  city: string;
  estate: string;
  country: string;
};

const CompleteYourProfile: React.FC<CompleteYourProfileProps> = ({
  withImage,
  skipBtnClick,
  saveCallback,
  showSaveBtn = true,
  showSkipBtn = true,
  userId,
}) => {
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');
  const [additionalInformation, setAdditionalInformation] =
    React.useState<UserAdditionalInformation>({
      companyName: "",
      website: "",
      street: "",
      streetAddition: "",
      postalCode: "",
      poBox: "",
      city: "",
      estate: "",
      country: "",
    });
  const [responseMessage, setResponseMessage] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { user, setUser } = useSession();

  React.useEffect(() => {
    if (user && user.additionalInformation) {
      setAdditionalInformation(user.additionalInformation);
    }
  }, [user]);

  const onSave = (e) => {
    setLoading(true);
    UserAPI.changeUser({ additionalInformation })
      .then((changedUser) => {
        setUser(changedUser);
        setHasError(false);
        setResponseMessage(() => {
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
          return "Successfully saved";
        });

        saveCallback && saveCallback(e);
      })
      .catch((e) => {
        setHasError(true);
        setResponseMessage(() => {
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
          return e.message;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAdditionalInformation((additionalInformation) => {
      additionalInformation[name] = value;

      return { ...additionalInformation };
    });
  };

  return (
    <div className="d-flex flex-wrap-reverse justify-content-between">
      <div className={`${withImage ? "col-xl-7" : "col-xl-12"} px-0`}>
        <div>
          {/*<Input*/}
          {/*    onChange={onChange}*/}
          {/*    label='Company Name'*/}
          {/*    containerClasses='col-xl-5 px-0'*/}
          {/*    inputAttributes={{*/}
          {/*        placeholder: 'Ex. Estating',*/}
          {/*        name: 'companyName',*/}
          {/*        value: additionalInformation.companyName*/}
          {/*    }}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*    onChange={onChange}*/}
          {/*    label='Website'*/}
          {/*    containerClasses='col-xl-5 px-0'*/}
          {/*    inputAttributes={{*/}
          {/*        placeholder: 'www.estating.com',*/}
          {/*        name: 'website',*/}
          {/*        value: additionalInformation.website*/}
          {/*    }}*/}
          {/*/>*/}
          <Input
            onChange={onChange}
            label={tPrivateAreaMyAccount('private_area.my_account.street')}
            containerClasses="col-xl-9 px-0"
            inputAttributes={{
              placeholder: "Ex. Alexanderufer 3",
              name: "street",
              value: additionalInformation.street,
            }}
          />
          <Input
            onChange={onChange}
            label={tPrivateAreaMyAccount('private_area.my_account.postal_code')}
            containerClasses="col-xl-9 px-0"
            inputAttributes={{
              placeholder: "Ex. 10117",
              name: "postalCode",
              value: additionalInformation.postalCode,
            }}
          />
          <Input
            onChange={onChange}
            label={tPrivateAreaMyAccount('private_area.my_account.city')}
            containerClasses="col-xl-9 px-0"
            inputAttributes={{
              placeholder: "Ex. Berlin",
              name: "city",
              value: additionalInformation.city,
            }}
          />
          <Select
            label={tPrivateAreaMyAccount('private_area.my_account.country')}
            containerClasses="mb-4 col-xl-9 px-0"
            selected={additionalInformation.country || ""}
            onChange={onChange}
            selectProps={{
              name: "country",
            }}
            placeholder="Select Country"
            values={COUNTRIES.map((countryObj) => countryObj.name)}
          />
          <Input
            onChange={onChange}
            label={tPrivateAreaMyAccount('private_area.my_account.po_box')}
            containerClasses="col-xl-9 px-0"
            inputAttributes={{
              placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
              name: "poBox",
              value: additionalInformation.poBox,
            }}
          />
          <Input
            onChange={onChange}
            containerClasses="col-xl-9 px-0"
            label={tPrivateAreaMyAccount('private_area.my_account.street_addition')}
            inputAttributes={{
              placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
              name: "streetAddition",
              value: additionalInformation.streetAddition,
            }}
          />
          <Input
            onChange={onChange}
            label={tPrivateAreaMyAccount('private_area.my_account.state')}
            containerClasses="col-xl-9 px-0"
            inputAttributes={{
              placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
              name: "estate",
              value: additionalInformation.estate,
            }}
          />

          {responseMessage ? (
            <div
              className={`alert col-9 px-0 mb-3 ${
                hasError ? "alert-danger" : "alert-success"
              }  d-flex justify-content-center align-items-center`}
            >
              <h4 className="mb-0">{responseMessage}</h4>
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-wrap pb-4 px-0">
          {showSaveBtn ? (
            <Button
              className={`btn-primary col-xl-9 px-0 ${
                loading ? "is-loading disabled" : ""
              }`}
              label={tPrivateAreaMyAccount('private_area.my_account.common.button.save')}
              buttonAttributes={{
                disabled: loading,
              }}
              onClick={onSave}
            />
          ) : null}
          {showSkipBtn ? (
            <Button
              onClick={skipBtnClick}
              className="btn-white col-9 px-0"
              label="Skip"
            />
          ) : null}
        </div>
      </div>
      {withImage ? (
        <div className="col-xl-4 mb-6">
          <img src={sidePopup} className="w-100" alt="" />
        </div>
      ) : null}
    </div>
  );
};

export default CompleteYourProfile;
