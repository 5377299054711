
import ReactGA from 'react-ga4';
import { getGoogleMeasurementId } from '../app-config';

const MEASUREMENT_ID = getGoogleMeasurementId();

if (MEASUREMENT_ID) {
    ReactGA.initialize(MEASUREMENT_ID);
}

export function setTrackUserId(userId?: string) {
    ReactGA.set({ userId });
}

export function trackPropertyReaction(label: "share" | "invest" | "bookmark" | "invite", action: "open" | "send" | "bookmark") {
    ReactGA.event({category: 'Property Reaction', label, action})
}