import { useTranslate } from '@tolgee/react';
import dayjs, { Dayjs } from 'dayjs';
import { memo, FC, useMemo, createContext } from 'react'
import { styled } from 'styled-components';
import { PropertyObj, PropertyTeaserType } from '../../store/properties/types';
import { Table } from './components/table';
import { calculateGlobalBounds, calculateTableWidth, getBackgroundColumnsDescription } from './utils/helpers/calculations';

interface PropertiesTimelineGanttChartProps {
  propertiesObjects: Array<PropertyObj>;
}

export const PropertiesTimelineGanttChart: FC<PropertiesTimelineGanttChartProps> = memo(({ propertiesObjects }) => {
  const properties = useMemo(() => propertiesObjects.map((propObj) => propObj.property), [propertiesObjects]);

  const { globalStart, globalEnd, teasersWithDateEvents } = useMemo(() => calculateGlobalBounds(properties), [properties]);

  const {totalMonths, backgroundColumnsDescription, totalDays, dateOneMonthBeforeStart } = useMemo(() => {
    return getBackgroundColumnsDescription(globalStart, globalEnd);
  }, [globalStart, globalEnd]);

  const tableWidth = useMemo(() =>
    calculateTableWidth(totalMonths, totalDays)
  , [totalMonths, totalDays]);

  const contextValue = useMemo(() => ({
    backgroundColumnsDescription,
    tableWidth,
    teasersWithDateEvents,
    globalStart,
    dateOneMonthBeforeStart
  }),
  [
    backgroundColumnsDescription,
    tableWidth,
    teasersWithDateEvents,
    globalStart,
    dateOneMonthBeforeStart
  ])

  const { t: tOpportunityTimelineContent } = useTranslate('opportunity_timeline.content');

  return (
    <PropertiesTimelineGanttChartContainer>
      <p className='text-center' style={{ color: '#434A52B2'}}>
        {tOpportunityTimelineContent('opportunity_timeline.content.please_note')}
      </p>
      <TableContainer>
        <TableContext.Provider value={contextValue}>
          <Table />
        </TableContext.Provider>
      </TableContainer>
    </PropertiesTimelineGanttChartContainer>
  )
})

interface TableContextType {
  backgroundColumnsDescription: Array<[string, Array<{ month: Dayjs, countDayInMonth: number}>]>;
  tableWidth: number;
  teasersWithDateEvents: Array<PropertyTeaserType>;
  globalStart: Dayjs;
  dateOneMonthBeforeStart: Dayjs;
}

export const TableContext = createContext<TableContextType>({
  backgroundColumnsDescription: [],
  tableWidth: 0,
  teasersWithDateEvents: [],
  globalStart: dayjs(),
  dateOneMonthBeforeStart: dayjs(),
});

const TableContainer = styled.div`
  position: relative;

  flex: 1 1 auto;

  height: 0px;

  min-height: 300px;

  overflow: auto;
`

const PropertiesTimelineGanttChartContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;