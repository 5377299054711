import { baseQueryWithAuth } from "api/base-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { InvestOrShareMessage } from "modal/SendMail";

export const propertyShareApi = createApi({
  reducerPath: 'propertyShareApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    shareProperty: builder.mutation<void, InvestOrShareMessage>({
      query: (body) => ({
        method: 'POST',
        url: `user/reaction`,
        body:{data:body},
      }),
    }),
  }),
});

export const { useSharePropertyMutation } = propertyShareApi;
