import { memo, FC, useCallback } from 'react';
import { TagDescription } from '../../types/tag-description';
import closeSVG from 'assets/close.svg';

import style from './style.module.scss';

interface TagPillProps {
  tag: TagDescription;
  onRemove: (tag: TagDescription) => void;
}

export const TagPill: FC<TagPillProps> = memo(({
  tag,
  onRemove,
}) => {
  const removeTag = useCallback(() => {
    onRemove(tag)
  }, [onRemove, tag]);

  return (
    <div className={`d-flex align-items-center bg-white ${style.container} px-3 py-2`}>
      <div className={`d-flex align-items-center justify-content-center ${style.title}`}>
        {tag.label}
      </div>
      <img
        alt="cross"
        src={closeSVG}
        className={`cursor-pointer ${style.cross_image}`}
        onClick={removeTag}
      />
    </div>
  )
});
