import { getBaseUrl } from "../app-config";
const baseName = `${window.location.origin}/img/file-thumbnails/`

export const generateThumbnail = (mimeType: string, fileUrl: string) => {
  switch (true) {
    case mimeType === "application/pdf":
      return `${getBaseUrl()}/image/thumbnail?mimeType=${mimeType}&fileUrl=${fileUrl}&random=${performance.now()}`;
    case mimeType.startsWith('image/'):
      return fileUrl;
    case mimeType.includes("zip"):
      return `${baseName}zip.svg`;
    case mimeType.includes("csv"):
      return `${baseName}csv.svg`;
    case mimeType.includes("msword"):
      return `${baseName}doc.svg`;
    case mimeType.includes("gif"):
      return `${baseName}gif.svg`;
    case mimeType.includes("powerpoint") || mimeType === "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return `${baseName}ppt.svg`;
    case mimeType.includes("octet-stream"):
      return `${baseName}rar.svg`;
    case mimeType === "text/plain":
      return `${baseName}txt.svg`;
    case mimeType.includes("excel") || mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return `${baseName}xls.svg`;
  }
}

export const getPrivateThumbnail = async (fileKey: string, mimeType?: string,) => {
  if (!mimeType) {
    return await (await fetch(`${baseName}csv.svg`)).blob();
  }
  
  let thumbnailUrl: string = '';

  switch (true) {
    case mimeType === "application/pdf":
      thumbnailUrl = `${getBaseUrl()}/image/private-thumbnail?fileKey=${fileKey}`;
      break;
    case mimeType.startsWith('image/'):
      thumbnailUrl = `${getBaseUrl()}/image/private-thumbnail?fileKey=${fileKey}`;
      break;
    case mimeType.includes("zip"):
      thumbnailUrl = `${baseName}zip.svg`;
      break;
    case mimeType.includes("csv"):
      thumbnailUrl = `${baseName}csv.svg`;
      break;
    case mimeType.includes("msword"):
      thumbnailUrl = `${baseName}doc.svg`;
      break;
    case mimeType.includes("gif"):
      thumbnailUrl = `${baseName}gif.svg`;
      break;
    case mimeType.includes("powerpoint") || mimeType === "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      thumbnailUrl = `${baseName}ppt.svg`;
      break;
    case mimeType.includes("octet-stream"):
      thumbnailUrl = `${baseName}rar.svg`;
      break;
    case mimeType === "text/plain":
      thumbnailUrl = `${baseName}txt.svg`;
      break;
    case mimeType.includes("excel") || mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      thumbnailUrl = `${baseName}xls.svg`;
      break;
  }

  if (!thumbnailUrl) {
    thumbnailUrl = `${baseName}csv.svg`;
  }
  
  const response = await (await fetch(thumbnailUrl, { method: 'POST' })).blob();

  return response;
}
