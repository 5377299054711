import { FC, useState, useEffect, useCallback } from 'react';
import Input from "components/Input";
import { COUNTRIES } from "helpers/constants";
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';

interface SelectCountryProps {
  label?: string,
  country: {
    value: string,
    callback: (value: string) => any
  },
  search?: {
    value: string,
    callback: (value: string) => any
  }
}

const COUNTRY_FLAG_URI = 'https://purecatamphetamine.github.io/country-flag-icons/3x2'

const SelectCountry: FC<SelectCountryProps> = ({
  search,
  label,
  country
}) => {
  const { t: tPublicArea } = useTranslate('public_area');
  const [countries, setCountries] = useState(COUNTRIES);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (search) {
      setCountries((_) => COUNTRIES
        .filter(country => country.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1))
    }
  }, [search])

  const openDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <div className="position-relative">
      {
        label &&
        <p className='h1 font-weight-bold text-center'>
          {label}
        </p>
      }
      <div
        className='position-relative'
        role="button"
        onClick={openDropdown}
      >
        {
          search &&
          <Input
            inputAttributes={{
              value: search.value,
              placeholder: tPublicArea('public_area.search_a_country'),
            }}
            leftIcon={<i className='fe fe-search input-group-text'/>}
            onChange={(e) => search.callback(e.target.value)}
            style={{
              backgroundColor: '#EEEFF1',
            }}
          />
        }
        <div
          className={classNames(
            // 'position-absolute',
            {
              "d-none": !isOpen
            }
          )}
          style={{
            top: '100%',
            left: '0',
            right: '0',
            maxHeight: 600,
            overflow: 'auto'
          }}
        >
          {
            countries.map(({code, name}) => {
              const isSelected = country.value === name;

              return (
                <div
                  key={code}
                  onClick={() => {
                    country.callback(name)
                  }}
                  className={`d-flex align-items-center py-3 country-select px-3 ${isSelected ? 'country-select-selected' : ''}`}
                >
                  <img
                    src={`${COUNTRY_FLAG_URI}/${code}.svg`}
                    alt={name + ' flag'}
                    width={40}
                  />
                  <p className='mb-0 ml-3'>
                    {name}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default SelectCountry;
