import { baseQueryWithAuth } from "api/base-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { InvestOrShareMessage } from "modal/SendMail";

export const contactEstatingApi = createApi({
  reducerPath: 'contactEstatingApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    contactEstating: builder.mutation<void, InvestOrShareMessage>({
      query: (body) => ({
        method: 'POST',
        url: `user/reaction`,
        body: { data: body },
      }),
    }),
  }),
});

export const { useContactEstatingMutation } = contactEstatingApi;
