import { memo } from "react";
import { ReactComponent as FlashSvg } from "assets/flash.svg";

import style from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

export const PropertyRiskLevelTitle = memo(() => {
  const { t: tOpportunityDetailPageRisk } = useTranslate('opportunity_detail_page.risk');

  return (
    <div className="d-flex align-items-center text-primary">
      <div className={style["risk-level__icon-container"]}>
        <FlashSvg />
      </div>
      <div className={style["risk-level__text"]}>
        {tOpportunityDetailPageRisk('opportunity_detail_page.risk')}
      </div>
    </div>
  )
});
