import { FC, memo, useMemo } from 'react';
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { OverlayTrigger } from 'react-bootstrap';
import { descriptionPopover } from 'new-architecture/components/description-popover';
import classNames from 'classnames';

import styles from './style.module.scss';

interface PropertyCardDetailsStatProps {
  icon?: string;
  value: string;
  title: string;
  description: {
    label: string;
    link?: string;
  }
}

export const PropertyCardDetailsStat: FC<PropertyCardDetailsStatProps> = memo(({
  icon,
  value,
  title,
  description
}) => {
  const { restTitle, lastWordTitle } = useMemo(() => {
    const words = title.split(' ');
    const lastWordTitle = words.pop();
    
    let restTitle = words.join(' ');
    
    if (title) {
      restTitle += ' ';
    }

    return { lastWordTitle, restTitle };
  }, [title]);

  return (
    <div className={styles.stat}>
      <div className={styles['stat__title-container']}>
        <div className={styles['stat__title']}>
          {restTitle}
          <div 
            role="button"
            className={
              classNames(
                styles['stat__title-icon-container'],
              )
            }
          >
            {lastWordTitle}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="auto"
              overlay={descriptionPopover({ 
                descriptions: [{
                  description: description?.label,
                  link: description?.link,
                  title
                }]
              })}
            >
              <InfoSVG />
            </OverlayTrigger>
          </div>
        </div>
      </div>

      <div className={styles['stat__value']}>
        {icon && <img src={icon} alt={title} />}
        {value}
      </div>
    </div>
  );
});
