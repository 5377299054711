import { FC, memo, useCallback } from "react";
import { ReactComponent as ArrowSvg } from "assets/arrowDown.svg";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useTrackDataRoomFileDownloadMutation } from "api/analitics";
import { useTranslate } from "@tolgee/react";
import { downloadFile } from "helpers/download-file";
import { WhoAreYou } from "types/user/who-are-you";
import { useSession } from "context/session";

import style from "./style.module.scss";

interface PropertyDataRoomFilesProps {
  documents: Array<{ name: string; url: string, allowedFor: Array<keyof typeof WhoAreYou> }>;
  propertyName: string;
};

const buttonBoxClass = classNames(
  "d-flex align-items-center cursor-pointer",
  style["data-room__button-box"]
);
const riskWrapperClass = classNames(
  "d-flex flex-column",
  style["data-room__wrapper"]
);

const documentNameClass = classNames(
  "d-flex text-capitalize",
  style["data-room__name"]
);

export const PropertyDataRoomFiles: FC<PropertyDataRoomFilesProps> = memo(
  ({ documents, propertyName }) => {
  const { id: opportunityId } = useParams<{ id: string }>();

  const [bookmark] = useTrackDataRoomFileDownloadMutation();

  const onDownloadClick = useCallback((fileUrl: string) => {
    const dirtyFileName = decodeURI(fileUrl).split('/').pop();

    if (!dirtyFileName) {
      return;
    }

    const splittedName = dirtyFileName?.split('_');
    const extension = splittedName?.pop()?.split('.')?.pop();
    const fileName = `${splittedName?.join('_')}.${extension}`;

    Promise.all([
      downloadFile(fileUrl, fileName),
      bookmark({ opportunityId, fileName, opportunity: propertyName }),
    ]);
  }, [bookmark, opportunityId, propertyName]);

  const { t: tOpportunityDetailPageSecurityInformation } = useTranslate('opportunity_detail_page.data_room');
  const { whoAreYou } = useSession();

  return (
    <div className={riskWrapperClass}>
      {documents.map(({ name, url, allowedFor }, index) => (
        allowedFor?.includes(whoAreYou) &&
        <div className={style["data-room__box"]} key={name + index}>
          <div className={documentNameClass}>{name}</div>
          <div onClick={() => onDownloadClick(url)}>
            <div className={buttonBoxClass}>
              <ArrowSvg />
              <span className={style["data-room__button-text"]}>
                {tOpportunityDetailPageSecurityInformation('opportunity_detail_page.data_room.download_document')}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
});
