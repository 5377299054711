import { descriptionPopover } from "new-architecture/components/description-popover";
import classNames from "classnames";
import { FC, memo } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';

import styles from './style.module.scss';

type PropertySecurityInfoItemProps = {
  name: string;
  value: string;
  className: string;
  tooltip?: string;
}

export const PropertySecurityInfoItem: FC<PropertySecurityInfoItemProps> = memo(
  ({ name, value, className, tooltip }) => {
    return (
      <div>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <span className={styles["security-info-item__title"]}>
              {name}
            </span>
            {tooltip &&
              <div 
                role="button"
                className={
                  classNames(
                    styles['security-info-item__title-icon-container'],
                  )
                }
              >
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="auto"
                  overlay={descriptionPopover({ 
                    descriptions: [{
                      description: tooltip,
                      title: name
                    }]
                  })}
                >
                  <InfoSVG />
                </OverlayTrigger>
              </div>
            }
          </div>
          <span className={styles["security-info-item__value"]}>{value}</span>
        </div>
      </div>
    );
  }
);
