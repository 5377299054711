import { RootState } from '../';
import { createSelector } from 'reselect';
import { EducationMaterialsGroupsState } from './types';
import { EducationMaterial } from '../../types/education-materials/education-material';

export const rootSelector: (state: RootState) => EducationMaterialsGroupsState = (state: RootState) =>
  state.estatingMaterials;

export const educationMaterialsGroupsSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.groups
);

export const educationMaterialsSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.groups ? 
    educationMaterialsState.groups.reduce((acc, current) => 
    acc.concat(current.materials), [] as Array<EducationMaterial>)
  : []
)

export const educationMaterialsGroupsLoadingSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.isLoading
);

export const educationMaterialsGroupsUpdatingSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.isUpdating
);

export const educationMaterialsGroupsErrorSelector = createSelector(
  rootSelector,
  (educationMaterialsState) => educationMaterialsState.error
);
