import React from "react";
import { withRouter } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { UserAPI } from "../../api/user";
import history from "../../history-entity";
import { useSession } from "../../context/session";
import { useTranslate } from "@tolgee/react";

//TODO check types
const ResetPassword: React.FC<any> = ({ match }) => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const { resetPasswordToken } = match.params;

  const isValid = password.length >= 8 && password === confirmPassword;

  const { setUser } = useSession();
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');

  const savePassword = () => {
    if (isValid) {
      UserAPI.resetPassword(password, resetPasswordToken)
        .then((userInfo) => {
          if (userInfo) {
            setUser(userInfo);
          }
          history.push("/opportunities");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div
      className="mx-auto mt-7 "
      style={{
        maxWidth: 450,
      }}
    >
      <h1 className="text-center mb-5 font-weight-bold">Reset my password</h1>
      <Input
        onChange={(e) => setPassword(e.target.value)}
        inputAttributes={{
          value: password,
          placeholder: "New password",
          required: true,
          type: "password",
        }}
      />
      <Input
        onChange={(e) => setConfirmPassword(e.target.value)}
        inputAttributes={{
          value: confirmPassword,
          placeholder: "Re-enter new password",
          required: true,
          type: "password",
        }}
        className="mt-3"
      />
      <Button
        label={tPrivateAreaMyAccount('private_area.my_account.common.button.save_password')}
        className="btn-block mt-5"
        onClick={savePassword}
        buttonAttributes={{
          disabled: !isValid,
        }}
      />
    </div>
  );
};

export default withRouter(ResetPassword);
