import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { getSessionExpirationTime } from "../app-config";
import { useState } from "react";
import { UserAPI } from "../api/user";
import logoPartials from "assets/logo-partials.png";
import Button from "../components/Button";
import { useSession } from "../context/session";
import { useTranslate } from "@tolgee/react";

type Props = {
  isAuthenticated: boolean;
};

const MINUTE_IN_MILLISECONDS = 1000 * 60;

const Idle: React.FC<Props> = ({ isAuthenticated }) => {
  const timeout = getSessionExpirationTime() * MINUTE_IN_MILLISECONDS;
  const [isIdle, setIsIdle] = useState(false);
  const { logout } = useSession();
  const { t: tPublicArea } = useTranslate('public_area');

  const handleOnIdle = () => {
    if (isAuthenticated) {
      UserAPI.logOut().then(() => {
        setIsIdle(true);
        localStorage.setItem("sessionLastPageSeen", window.location.href);
        document.body.style.overflow = "hidden";
        pause();
      });
    }
  };

  const handleToLogout = () => {
    logout();
    window.location.replace("/login");
  };

  const { pause } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    events: [
      "blur",
      "change",
      "click",
      "copy",
      "focus",
      "fullscreenchange",
      "mousemove",
      "resize",
      "scroll",
      "unload",
    ],
  });

  if (!isIdle || !isAuthenticated) return null;

  return (
    <div className="centered-blur">
      <img src={logoPartials} className="centered-blur-box-image" alt="" />
      <div className="centered-blur-box card">
        <h2>
          {tPublicArea('public_area.your_session_has_expired')}
        </h2>
        <Button onClick={handleToLogout} className="btn-primary btn-sm px-5">
          {tPublicArea('public_area.login_again')}
        </Button>
      </div>
    </div>
  );
};

export default Idle;
