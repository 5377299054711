import { InvestmentTitle } from "../investment-title"
import { ReactComponent as LocationSvg } from "assets/location-icon.svg";
import { FC, memo, useMemo } from "react";
import classNames from "classnames";
import { InvestmentBox } from "../investment-box";
import { Map } from "new-architecture/components/map";
import { Container } from "react-bootstrap";
import { PropertyTeaserType } from "store/properties/types";

import styles from './style.module.scss'
import { useTranslate } from "@tolgee/react";

const containerClass = classNames('d-flex flex-column', styles['location__container']);

interface InvestmentOpportunityLocationProps {
  property: PropertyTeaserType
}

export const InvestmentOpportunityLocation: FC<InvestmentOpportunityLocationProps> = memo(({
  property
}) => {
  const { locations, specialLocationDescription } = property;

  const mapPoints = useMemo(() =>  {
    if (!locations.length) {
      return [];
    }

    return locations.map(({ coords }) => coords);
  }, [locations]);

  const locationsTitle = useMemo(() => (
    locations.map(({ address }) => address).join(', ')
  ), [locations]);

  const { t: tOpportunityDetailPageLocation } = useTranslate('opportunity_detail_page.location');

  if (!locations.length) {
    return null;
  }

  return (
    <Container>
      <InvestmentBox>
        <InvestmentTitle
          title={tOpportunityDetailPageLocation('opportunity_detail_page.location.title')}
          icon={<LocationSvg />}
        />
        <div className="mt-3 mt-md-4">
          <div
            className={containerClass}
          >
            <div className={styles.location__text}>
              {locationsTitle || specialLocationDescription || 'N/a'}
            </div>
            <div className={styles['location__map-container']}>
              <Map coords={mapPoints} />
            </div>
          </div>
        </div>
      </InvestmentBox>
    </Container>
  );
});

InvestmentOpportunityLocation.displayName = 'InvestmentOpportunityLocation'
