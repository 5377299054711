import { Highlight } from "store/properties/types";
import { emptyField } from "../constants/empty-field";

export const extractValueFromPropertyHighlights = (
  key: string,
  additionalValues: Highlight[]
): string => {
  const additionalValue = additionalValues.find((av) => av.description === key);
  if (additionalValue) {
    return additionalValue.value || emptyField;
  }
  return emptyField;
};