import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, memo } from 'react';

import styles from './style.module.scss';

interface ButtonProps {
  onClick?: () => void;
  icon?: JSX.Element;
  text: string;
  buttonProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
;}

const buttonClass = classNames('text-white d-flex align-items-center cursor-pointer justify-content-md-center', styles.button);

export const Button: FC<ButtonProps> = memo(({ onClick, icon, text, buttonProps }) => {
  return (
    <button
      className={buttonClass}
      onClick={onClick}
      {...buttonProps}
      type="button"
    >
      <span>{text}</span>
      {icon}
    </button>
  );
});
