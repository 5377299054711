import { ReactChild, ReactFragment, ReactPortal } from 'react'

interface ButtonProps {
  styles?: React.CSSProperties,
  className?: string,
  buttonAttributes?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  label?: string,
  children?: ReactChild | ReactFragment | ReactPortal | null | undefined;
  icon?: JSX.Element,
  iconPosition?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any,
  invalid?: boolean,
  iconStyles?: React.CSSProperties
}

const Button: React.FC<ButtonProps> = ({
  className,
  styles,
  buttonAttributes,
  label,
  icon,
  children,
  onClick,
  iconStyles,
  invalid,
}) => {
  return (
    <button
      style={{
        position: 'relative',
        ...styles,
      }}
      onClick={onClick}
      disabled={!!invalid}
      className={`btn btn-theme-height ${invalid ? 'btn-gray' : 'btn-primary'} ${className || ''}`}
      {...buttonAttributes}
    >
      <span
        style={{
          ...iconStyles,
        }}
      >
        {icon}
      </span>
      {
        children
      }
      {
        (!children && label) &&
        label
      }
    </button>
  )
}

export default Button
