import { FC, memo } from "react";

import style from "./style.module.scss";

interface PropertySubsectionTitleProps {
  title: string;
  icon: JSX.Element;
}

export const PropertySubsectionTitle: FC<PropertySubsectionTitleProps> = memo(({
  title,
  icon
}) => (
  <div className="d-flex align-items-center text-primary">
    <div className={style["subtitle__icon-container"]}>
      {icon}
    </div>
    <div className={style["subtitle__text"]}>
      {title}
    </div>
  </div>
));
