import { UserLocal } from "context/types";
import { getEstatingCatalysSygnUpPageURL, getEstatingCatalystURL } from "app-config";

export function emailMessage(user: UserLocal, emailTranslations: Array<string>): string {
const message =
`${emailTranslations[0]} <a href="${getEstatingCatalystURL()}">Estating Catalyst</a>. ${emailTranslations[2]} <a href="${getEstatingCatalysSygnUpPageURL()}">${emailTranslations[3]}</a>.

${emailTranslations[4]}

${user.firstName} ${user.lastName}`;

  return message;
}
