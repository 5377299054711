import { memo, FC } from 'react'

import styles from './style.module.scss';

interface PropertyDisclaimersProps {
  disclaimers: Array<string>;
}

export const PropertyDisclaimers: FC<PropertyDisclaimersProps> = memo(({
  disclaimers
}) => {
  return (
    <ul className={styles.disclaimers}>
      {disclaimers.map((item, index) => (
        <li className={styles.disclaimers__item} key={index + item}>
          {item}
        </li>
      ))}
    </ul>
  );
});
