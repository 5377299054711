
import classNames from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom'
import "./Modal.scss"

export function Modal(props: { children: ReactNode; className?: string }) {  
    return createPortal(
      <div
        className={classNames(
          'modal-container',
          props.className
        )}>
        {props.children}
      </div>,
      document.body
    )
  }