import { FC, memo, useMemo, useCallback } from "react";
import { Popup } from "new-architecture/components/pop-up";
import { PropertyInvestModalHeader } from "./property-invest-modal-header";
import { PropertyInvestModalBody } from "./property-invest-modal-body";
import { PropertyInvestModalFooter } from "./property-invets-modal-footer";
import { PropertyTeaserType } from "store/properties/types";
import { useFormik } from "formik";
import { investFormikValidationSchema } from "../utils/invest-formik-validation-schema";
import { InvestData } from "../types/invest-data";
import { extractValueFromPropertyHighlights } from "../utils/extract-value-from-property-highlights";
import { extractValueFromPropertyAdditionalValues } from "../utils/extract-value-from-property-additional-values";
import { trackPropertyReaction } from "helpers/tracking";
import { useSession } from "context/session";
import { WhoAreYou } from 'types/user/who-are-you';
import { useInvestOnPropertyMutation } from "../store";
import { QueryStatus } from "@reduxjs/toolkit/dist/query/react";
import CircleFilledIcon from "assets/circle-filled.svg";

import styles from "./style.module.scss";
import { emptyField } from "../constants/empty-field";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  property: PropertyTeaserType;
};

export const PropertyInvestModal: FC<Props> = memo(({ isOpen, onClose, property }) => {
  const { user } = useSession();

  const [investOnProperty, { isLoading, isSuccess, error, status, reset }] = useInvestOnPropertyMutation();

  const initialValues = useMemo<InvestData>(() => {
    return ({
      ISIN: extractValueFromPropertyAdditionalValues("ISIN", property.details.values),
      internalName: property.internalName || emptyField,
      investmentStrategy: property.options.investmentStrategy || emptyField,
      classification: property.options.category || emptyField,
      address: property.locations[0]?.address || emptyField,
      serviceProviderName: "",
      contactPersonName: "",
      telephoneNumber: "",
      email: "",
      investmentAmount: "",
      custodyBank: "",
      bankContactNameEmail: "",
      currency: extractValueFromPropertyAdditionalValues(
        "Currency",
        property.securityAndRoles.security
      ),
      issuanceVolume: extractValueFromPropertyHighlights(
        "Issuance Volume",
        property.details.highlights
      ),
      minimumInvestment: extractValueFromPropertyAdditionalValues(
        "Minimum Investment",
        property.details.values
      ),
      propertyId: property._id
    });
  }, [property._id, property.details.highlights, property.details.values, property.internalName, property.locations, property.options.category, property.options.investmentStrategy, property.securityAndRoles.security]);

  const isRequestSended = useMemo(() =>
    status === QueryStatus.fulfilled ||
    status === QueryStatus.rejected
  , [status]);

  const formik = useFormik<InvestData>({
    validationSchema: investFormikValidationSchema,
    initialValues,
    onSubmit(values) {
      if (isLoading || !user || user.whoAreYou !== WhoAreYou.FINANCIAL_ADVISOR) {
        return;
      }

      if (isRequestSended) {
        reset();
        return;
      }

      trackPropertyReaction("invest", "send");
      investOnProperty(values);
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true
  });

  const {
    values,
    setFieldValue,
    handleChange,
    submitForm,
    errors,
    handleBlur,
    resetForm,
    touched,
  } = formik;

  const closeModal = useCallback(() => {
    onClose();
    reset();
    resetForm();
  }, [onClose, reset, resetForm]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className={styles['modal-container']}>
        <div className={styles['modal-container__header']}>
          <PropertyInvestModalHeader onClose={closeModal} />
        </div>
        <div className={styles['modal-container__body']}>
          { isRequestSended ?
            <div
              className={`alert ${
                isSuccess ? "alert-info" : "alert-danger"
              } d-flex justify-content-center align-items-center py-4 m-4`}
            >
              {isSuccess ? (
                <img src={CircleFilledIcon} className="mr-3" alt="" />
              ) : null}
              <h4 className="mb-0">
                {isSuccess ? "Thank you for your interest! Our team will be in contact with you shortly to help you close the transaction" : (error as any)?.data?.message}
              </h4>
            </div>
            :
            <PropertyInvestModalBody
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              errors={errors}
              handleBlur={handleBlur}
              touched={touched}
            />
          }
        </div>
        <div className={styles['modal-container__footer']}>
          <PropertyInvestModalFooter
            onClose={closeModal}
            onConfirm={submitForm}
            isLoading={isLoading}
            isSended={isRequestSended}
          />
        </div>
      </div>
    </Popup>
  );
});
