import { FC, memo } from "react";

import style from "./style.module.scss";

type Props = {
  tag: string;
};

export const PropertyEducationAndResearchCardTags: FC<Props> = memo(
  ({ tag }) => {
    return (
      <div className={style["education-and-research-tags__box"]}>
        <span className={style["education-and-research-tags__text"]}>
          {tag}
        </span>
      </div>
    );
  }
);
