export enum IconType {
  CALENDAR = 'CALENDAR',
  CALCULATOR = 'CALCULATOR',
  CURRENCY = 'CURRENCY',
  PIE_CHART = 'PIE_CHART',
  COINS = 'COINS',
  LINE_CHART = 'LINE_CHART',
  LINE = 'LINE',
  RISK_LEVELS = 'RISK_LEVELS',
  PROGRESS_BAR = 'PROGRESS_BAR',
  TEXT = 'TEXT'
}

const baseUrl = window.location.origin;

const RiskLevelsSVG = `${baseUrl}/img/highlighted-details/icon-risk.svg`;
const ProgressBarSVG = `${baseUrl}/img/highlighted-details/icon-progress1.svg`;
const CalendarSVG = `${baseUrl}/img/highlighted-details/icon-calendar.svg`;
const PieChartSVG = `${baseUrl}/img/highlighted-details/pie-chart.svg`;
const CalculatorSVG = `${baseUrl}/img/highlighted-details/icon-calc.svg`;
const CoinsSVG = `${baseUrl}/img/highlighted-details/coins.svg`;
const CurrencySVG = `${baseUrl}/img/highlighted-details/currency.svg`;
const LineChartSVG = `${baseUrl}/img/highlighted-details/line-chart.svg`;


export const iconTypes: { [key in IconType]: string } = {
  [IconType.RISK_LEVELS]: RiskLevelsSVG,
  [IconType.PROGRESS_BAR]: ProgressBarSVG,
  [IconType.CALENDAR]: CalendarSVG,
  [IconType.PIE_CHART]: PieChartSVG,
  [IconType.CALCULATOR]: CalculatorSVG,
  [IconType.COINS]: CoinsSVG,
  [IconType.CURRENCY]: CurrencySVG,
  [IconType.LINE_CHART]: LineChartSVG,
  [IconType.TEXT]: '',
  [IconType.LINE]: ''
}