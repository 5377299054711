import { CriteriaIcon, CriteriaIconProps } from 'new-architecture/ui/criteria-icon';
import { FC, memo } from 'react';

import style from './style.module.scss';

interface CriteriasProps {
  criterias: Array<CriteriaIconProps & { criteriaKey: string }>;
  selectedCriteriaKey: string;
  onSelect: (key: string) => void;
}

export const Criterias: FC<CriteriasProps> = memo(({
  criterias,
  onSelect,
  selectedCriteriaKey
}) => {
  return (
    <div className={`d-flex justify-content-sm-between ${style.criterias_container}`}>
      {
        criterias.map(({ count, name, icon, criteriaKey }) => (
          <CriteriaIcon
            key={criteriaKey}
            count={count}
            name={name}
            icon={icon}
            selected={criteriaKey === selectedCriteriaKey}
            onClick={() => onSelect(criteriaKey)}
          />
        ))
      }
    </div>
  )
});
