import { EducationMaterial } from 'types/education-materials/education-material';
import { Dayjs } from 'dayjs';
import {Action} from 'redux';
import { WhoAreYou } from 'types/user/who-are-you';

export const ADD_PROPERTY = 'ADD_PROPERTY';
export const INTERESTED = 'INTERESTED';

export const ADD_PROPERTIES = 'ADD_PROPERTIES';
export const INVEST = 'INVEST';
export const DELETE_PROPERTY = 'DELETE_PROPERTY'

type viewFromCio = {
  title: string,
  body: string[],
  disclaimer: string
}

export enum SubscriptionStatus {
  PRIMARY_MARKET = 'PRIMARY_MARKET',
  CLOSED_TRANSACTION = 'CLOSED_TRANSACTION',
  PRE_SUBSCRIPTION = 'PRE_SUBSCRIPTION',
  SECONDARY_MARKET = 'SECONDARY_MARKET',
}

export enum SubscriptionStatusReadable {
  PRIMARY_MARKET = 'Subscription Open',
  CLOSED_TRANSACTION = 'Closed',
  PRE_SUBSCRIPTION = 'Pre-subscription',
  SECONDARY_MARKET = 'Secondary Market',
}


export enum PropertyOpportunityTypeEnum {
  PROPERTY_INVESTMENTS = 'PROPERTY_INVESTMENTS',
  LP_INVESTMENTS = 'LP_INVESTMENTS',
  PRIVATE_EQUITY_INVESTMENT = 'PRIVATE_EQUITY_INVESTMENT'
}

export enum Orientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL'
}

type PropertyStatus =
    'Open'
    | 'Closed'
    | 'Pre-subscription'
    | 'Secondary'
    | 'Example'
    | string



export interface Property {
  _id: string;
  name: string;
  details: PropertyDetails;
  landingPageName?: string[],
  assets: string[];
  floorPlan: FloorPlan;
  location: Location;
  expectedReturn: string;
  cio: viewFromCio;
  costs: string;
  investment: AdditionalValue[];
  documents: Document[];
  opportunity: OpportunityType[],
  disclaimer: string[],
  status?: PropertyStatus,
  swotAnalysis: SwotAnalysis[],
  financials: Financials,
  estateManagement: EstateManagement,
  propertyDetails: PropertyFloorPlanDetails,
  locationDetails: LocationDetails,
  classification: 'Opportunities' | 'Prime',
  tags?: string[]
}

export enum AboutType {
  OPPORTUNITY = 'OPPORTUNITY',
  PROPERTY = 'PROPERTY'
}

interface PropertyTeaserAboutType {
  values: AdditionalValue[];
  footnotes: string[];
  descriptions: string[];
  wysiwygDescription?: string;
  aboutType: AboutType;
}

export interface Event {
  subscriptionStatus: keyof typeof SubscriptionStatus,
  startDate: string | Dayjs,
  endDate: string | Dayjs,
}

export enum AllowedCountries {
  AFGHANISTAN = "Afghanistan",
  ALBANIA = "Albania",
  ALGERIA = "Algeria",
  AMERICAN_SAMOA = "American Samoa",
  ANDORRA = "Andorra",
  ANGOLA = "Angola",
  ANGUILLA = "Anguilla",
  ANTARCTICA = "Antarctica",
  ANTIGUA_AND_BARBUDA = "Antigua & Barbuda",
  ARGENTINA = "Argentina",
  ARMENIA = "Armenia",
  ARUBA = "Aruba",
  ASCENSION_ISLAND = "Ascension Island",
  AUSTRALIA = "Australia",
  AUSTRIA = "Austria",
  AZERBAIJAN = "Azerbaijan",
  BAHAMAS = "Bahamas",
  BAHRAIN = "Bahrain",
  BANGLADESH = "Bangladesh",
  BARBADOS = "Barbados",
  BELARUS = "Belarus",
  BELGIUM = "Belgium",
  BELIZE = "Belize",
  BENIN = "Benin",
  BERMUDA = "Bermuda",
  BHUTAN = "Bhutan",
  BOLIVIA = "Bolivia",
  BOSNIA_AND_HERZEGOVINA = "Bosnia & Herzegovina",
  BOTSWANA = "Botswana",
  BOUVET_ISLAND = "Bouvet Island",
  BRAZIL = "Brazil",
  BRITISH_INDIAN_OCEAN_TERRITORY = "British Indian Ocean Territory",
  BRITISH_VIRGIN_ISLANDS = "British Virgin Islands",
  BRUNEI = "Brunei",
  BULGARIA = "Bulgaria",
  BURKINA_FASO = "Burkina Faso",
  BURUNDI = "Burundi",
  CAMBODIA = "Cambodia",
  CAMEROON = "Cameroon",
  CANADA = "Canada",
  CANARY_ISLANDS = "Canary Islands",
  CAPE_VERDE = "Cape Verde",
  CARIBBEAN_NETHERLANDS = "Caribbean Netherlands",
  CAYMAN_ISLANDS = "Cayman Islands",
  CENTRAL_AFRICAN_REPUBLIC = "Central African Republic",
  CEUTA_AND_MELILLA = "Ceuta & Melilla",
  CHAD = "Chad",
  CHILE = "Chile",
  CHINA = "China",
  CHRISTMAS_ISLAND = "Christmas Island",
  CLIPPERTON_ISLAND = "Clipperton Island",
  COCOS_KEELING_ISLANDS = "Cocos (Keeling) Islands",
  COLOMBIA = "Colombia",
  COMOROS = "Comoros",
  CONGO_BRAZZAVILLE = "Congo - Brazzaville",
  CONGO_KINSHASA = "Congo - Kinshasa",
  COOK_ISLANDS = "Cook Islands",
  COSTA_RICA = "Costa Rica",
  CROATIA = "Croatia",
  CUBA = "Cuba",
  CURAÇAO = "Curaçao",
  CYPRUS = "Cyprus",
  CZECHIA = "Czechia",
  COTE_DIVOIRE = "Côte d’Ivoire",
  DENMARK = "Denmark",
  DIEGO_GARCIA = "Diego Garcia",
  DJIBOUTI = "Djibouti",
  DOMINICA = "Dominica",
  DOMINICAN_REPUBLIC = "Dominican Republic",
  ECUADOR = "Ecuador",
  EGYPT = "Egypt",
  EL_SALVADOR = "El Salvador",
  EQUATORIAL_GUINEA = "Equatorial Guinea",
  ERITREA = "Eritrea",
  ESTONIA = "Estonia",
  ESWATINI = "Eswatini",
  ETHIOPIA = "Ethiopia",
  FALKLAND_ISLANDS = "Falkland Islands (Islas Malvinas)",
  FAROE_ISLANDS = "Faroe Islands",
  FIJI = "Fiji",
  FINLAND = "Finland",
  FRANCE = "France",
  FRENCH_GUIANA = "French Guiana",
  FRENCH_POLYNESIA = "French Polynesia",
  FRENCH_SOUTHERN_TERRITORIES = "French Southern Territories",
  GABON = "Gabon",
  GAMBIA = "Gambia",
  GEORGIA = "Georgia",
  GERMANY = "Germany",
  GHANA = "Ghana",
  GIBRALTAR = "Gibraltar",
  GREECE = "Greece",
  GREENLAND = "Greenland",
  GRENADA = "Grenada",
  GUADELOUPE = "Guadeloupe",
  GUAM = "Guam",
  GUATEMALA = "Guatemala",
  GUERNSEY = "Guernsey",
  GUINEA = "Guinea",
  GUINEA_BISSAU = "Guinea-Bissau",
  GUYANA = "Guyana",
  HAITI = "Haiti",
  HEARD_AND_MCDONALD_ISLANDS = "Heard & McDonald Islands",
  HONDURAS = "Honduras",
  HONG_KONG = "Hong Kong",
  HUNGARY = "Hungary",
  ICELAND = "Iceland",
  INDIA = "India",
  INDONESIA = "Indonesia",
  IRAN = "Iran",
  IRAQ = "Iraq",
  IRELAND = "Ireland",
  ISLE_OF_MAN = "Isle of Man",
  ISRAEL = "Israel",
  ITALY = "Italy",
  JAMAICA = "Jamaica",
  JAPAN = "Japan",
  JERSEY = "Jersey",
  JORDAN = "Jordan",
  KAZAKHSTAN = "Kazakhstan",
  KENYA = "Kenya",
  KIRIBATI = "Kiribati",
  KOSOVO = "Kosovo",
  KUWAIT = "Kuwait",
  KYRGYZSTAN = "Kyrgyzstan",
  LAOS = "Laos",
  LATVIA = "Latvia",
  LEBANON = "Lebanon",
  LESOTHO = "Lesotho",
  LIBERIA = "Liberia",
  LIBYA = "Libya",
  LIECHTENSTEIN = "Liechtenstein",
  LITHUANIA = "Lithuania",
  LUXEMBOURG = "Luxembourg",
  MACAO = "Macao",
  MADAGASCAR = "Madagascar",
  MALAWI = "Malawi",
  MALAYSIA = "Malaysia",
  MALDIVES = "Maldives",
  MALI = "Mali",
  MALTA = "Malta",
  MARSHALL_ISLANDS = "Marshall Islands",
  MARTINIQUE = "Martinique",
  MAURITANIA = "Mauritania",
  MAURITIUS = "Mauritius",
  MAYOTTE = "Mayotte",
  MEXICO = "Mexico",
  MICRONESIA = "Micronesia",
  MOLDOVA = "Moldova",
  MONACO = "Monaco",
  MONGOLIA = "Mongolia",
  MONTENEGRO = "Montenegro",
  MONTSERRAT = "Montserrat",
  MOROCCO = "Morocco",
  MOZAMBIQUE = "Mozambique",
  MYANMAR_BURMA = "Myanmar (Burma)",
  NAMIBIA = "Namibia",
  NAURU = "Nauru",
  NEPAL = "Nepal",
  NETHERLANDS = "Netherlands",
  NEW_CALEDONIA = "New Caledonia",
  NEW_ZEALAND = "New Zealand",
  NICARAGUA = "Nicaragua",
  NIGER = "Niger",
  NIGERIA = "Nigeria",
  NIUE = "Niue",
  NORFOLK_ISLAND = "Norfolk Island",
  NORTH_KOREA = "North Korea",
  NORTH_MACEDONIA = "North Macedonia",
  NORTHERN_MARIANA_ISLANDS = "Northern Mariana Islands",
  NORWAY = "Norway",
  OMAN = "Oman",
  PAKISTAN = "Pakistan",
  PALAU = "Palau",
  PALESTINE = "Palestine",
  PANAMA = "Panama",
  PAPUA_NEW_GUINEA = "Papua New Guinea",
  PARAGUAY = "Paraguay",
  PERU = "Peru",
  PHILIPPINES = "Philippines",
  PITCAIRN_ISLANDS = "Pitcairn Islands",
  POLAND = "Poland",
  PORTUGAL = "Portugal",
  PUERTO_RICO = "Puerto Rico",
  QATAR = "Qatar",
  ROMANIA = "Romania",
  RUSSIA = "Russia",
  RWANDA = "Rwanda",
  REUNION = "Réunion",
  SAMOA = "Samoa",
  SAN_MARINO = "San Marino",
  SAUDI_ARABIA = "Saudi Arabia",
  SENEGAL = "Senegal",
  SERBIA = "Serbia",
  SEYCHELLES = "Seychelles",
  SIERRA_LEONE = "Sierra Leone",
  SINGAPORE = "Singapore",
  SINT_MAARTEN = "Sint Maarten",
  SLOVAKIA = "Slovakia",
  SLOVENIA = "Slovenia",
  SOLOMON_ISLANDS = "Solomon Islands",
  SOMALIA = "Somalia",
  SOUTH_AFRICA = "South Africa",
  SOUTH_GEORGIA_SOUTH_SANDWICH_ISLANDS = "South Georgia & South Sandwich Islands",
  SOUTH_KOREA = "South Korea",
  SOUTH_SUDAN = "South Sudan",
  SPAIN = "Spain",
  SRI_LANKA = "Sri Lanka",
  ST_BARTHELEMY = "St. Barthélemy",
  ST_HELENA = "St. Helena",
  ST_KITTS_NEVIS = "St. Kitts & Nevis",
  ST_LUCIA = "St. Lucia",
  ST_MARTIN = "St. Martin",
  ST_PIERRE_MIQUELON = "St. Pierre & Miquelon",
  ST_VINCENT_GRENADINES = "St. Vincent & Grenadines",
  SUDAN = "Sudan",
  SURINAME = "Suriname",
  SVALBARD_JAN_MAYEN = "Svalbard & Jan Mayen",
  SWEDEN = "Sweden",
  SWITZERLAND = "Switzerland",
  SYRIA = "Syria",
  SAO_TOME_PRINCIPE = "São Tomé & Príncipe",
  TAIWAN = "Taiwan",
  TAJIKISTAN = "Tajikistan",
  TANZANIA = "Tanzania",
  THAILAND = "Thailand",
  TIMOR_LESTE = "Timor-Leste",
  TOGO = "Togo",
  TOKELAU = "Tokelau",
  TONGA = "Tonga",
  TRINIDAD_TOBAGO = "Trinidad & Tobago",
  TRISTAN_DA_CUNHA = "Tristan da Cunha",
  TUNISIA = "Tunisia",
  TURKEY = "Turkey",
  TURKMENISTAN = "Turkmenistan",
  TURKS_CAICOS_ISLANDS = "Turks & Caicos Islands",
  TUVALU = "Tuvalu",
  US_OUTLYING_ISLANDS = "U.S. Outlying Islands",
  US_VIRGIN_ISLANDS = "U.S. Virgin Islands",
  UGANDA = "Uganda",
  UKRAINE = "Ukraine",
  UNITED_ARAB_EMIRATES = "United Arab Emirates",
  UNITED_KINGDOM = "United Kingdom",
  UNITED_STATES = "United States",
  URUGUAY = "Uruguay",
  UZBEKISTAN = "Uzbekistan",
  VANUATU = "Vanuatu",
  VATICAN_CITY = "Vatican City",
  VENEZUELA = "Venezuela",
  VIETNAM = "Vietnam",
  WALLIS_FUTUNA = "Wallis & Futuna",
  WESTERN_SAHARA = "Western Sahara",
  YEMEN = "Yemen",
  ZAMBIA = "Zambia",
  ZIMBABWE = "Zimbabwe",
  ALAND_ISLANDS = "Åland Islands",
  NULL = ""
}

export enum FinancialStructure {
  LEVERED = 'LEVERED',
  UNLEVERED = 'UNLEVERED',
  NULL = 'NULL'
}

export enum FinancialStructureValues {
  LEVERED = 'Levered',
  UNLEVERED = 'Unlevered',
  NULL = ''
}

export enum TeaserStatus{
  TRANSACTION_EXAMPLE = 'TRANSACTION_EXAMPLE',
  STRICTLY_PRELIMINARY = 'STRICTLY_PRELIMINARY',
  PRICING_SUPPLEMENT_AVALILABLE = 'PRICING_SUPPLEMENT_AVALILABLE',
  CLOSED_TRANSACTION = 'CLOSED_TRANSACTION',
  SEARCH_MANDATE = 'SEARCH_MANDATE',
}


export enum OnlineStatus{
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT_PUBLISHED'
}

export enum InvestmentStrategy {
	WEALTH_PRESERVATION = 'WEALTH_PRESERVATION',
	WEALTH_GROWTH = 'WEALTH_GROWTH'
};

export enum InvestmentStrategyReadable {
  WEALTH_PRESERVATION = 'Wealth Preservation',
  WEALTH_GROWTH = 'Wealth Growth',
};

export enum Category {
	CORE = 'CORE',
	CORE_PLUS = 'CORE_PLUS',
	VALUE_ADD = 'VALUE_ADD',
	OPPORTUNITY = 'OPPORTUNITY'
};

export enum CategoryReadable {
  CORE = 'Core',
	CORE_PLUS = 'Core +',
	VALUE_ADD = 'Value-Add',
	OPPORTUNITY = 'Opportunity',
};

export enum CapitalStructure {
  EQUITY = 'EQUITY',
  PREFERRED_EQUITY = 'PREFERRED_EQUITY',
  DEBT = 'DEBT'
};

export enum CapitalStructureValues {
  EQUITY = 'Equity',
  PREFERRED_EQUITY = 'Preferred Equity',
  DEBT = 'Debt'
};

export enum AssetType {
  RESIDENTIAL = 'RESIDENTIAL',
  MULTIFAMILY = 'MULTIFAMILY',
  COLIVING = 'COLIVING',
  INDUSTRIAL = 'INDUSTRIAL',
  HOTEL = 'HOTEL',
  MIXED_USE = 'MIXED_USE',
  LAND = 'LAND',
  MULTIPLE = 'MULTIPLE'
};

export enum AssetTypeValue {
  RESIDENTIAL = 'Residential',
  MULTIFAMILY = 'Multifamily',
  COLIVING = 'Coliving',
  INDUSTRIAL = 'Industrial',
  HOTEL = 'Hotel',
  MIXED_USE = 'Mixed-use',
  LAND = 'Land',
  MULTIPLE = 'Multiple'
}

export interface LocationDescription {
  address: string;
  coords: {
      latitude: number;
      longitude: number;
      city: string;
      state: string;
      country: keyof typeof AllowedCountries;
  };
}

export enum IconType {
  CALENDAR = 'CALENDAR',
  CALCULATOR = 'CALCULATOR',
  CURRENCY = 'CURRENCY',
  PIE_CHART = 'PIE_CHART',
  COINS = 'COINS',
  LINE_CHART = 'LINE_CHART',
  LINE = 'LINE',
  RISK_LEVELS = 'RISK_LEVELS',
  PROGRESS_BAR = 'PROGRESS_BAR',
  TEXT = 'TEXT'
}

export interface CustomField {
  _id: string;
  id: string;
  name: string;
  value: string;
  tooltip?: string;
  type?: IconType;
}

export interface PropertyOptions {
  financialStructure: FinancialStructure;
  status: SubscriptionStatus;
  teaserStatus: TeaserStatus;
  state: OnlineStatus;
  investmentStrategy: InvestmentStrategy;
  category: Category;
  capitalStructure: CapitalStructure;
  propertyType: Array<AssetType>;
  relincPrice?: string;
  isin?: string;
}

export interface PropertyTeaserType {
  _id: string;
  summary: Array<CustomField>;
  propertyId: string;
  name: string;
  internalName: string;
  description: {
    organisation: string;
    name: string;
  };
  opportunityType: PropertyOpportunityTypeEnum;
  assets: string[];
  about: PropertyTeaserAboutType;
  details: {
    values: AdditionalValue[];
    highlights: Highlight[];
    footnotes: AdditionalValue[];
    secondaryKpi: Array<AdditionalValue>;
    highlighted: Array<CustomField>;
    secondary: {
      financialStructure: keyof typeof FinancialStructure;
      capitalStructure:  keyof typeof CapitalStructure;
      assetType: Array<keyof typeof AssetType>;
      others: Array<CustomField>;
  }
  };
  floorPlan: {
    assets: string[],
    descriptions: string[],
    orientation: Orientation
  },
  securityAndRoles: {
    roles: AdditionalValue[],
    security: AdditionalValue[],
    aboutThePartner: {
      text?: string,
      image?: string,
    }
  },
  partners: string[],
  documents: Document[],
  disclaimers: AdditionalValue[],
  investmentStrategy: AdditionalValue[],
  tags: string[],
  options: PropertyOptions,
  location: {
    address: string,
    coords: {
      latitude: number,
      longitude: number,
    },
    descriptions: string[],
  },
  specialLocationDescription?: string;
  locations: Array<LocationDescription>,
  user?: {
    isBookmarked: boolean
  },
  timeline: {
    enabled: boolean,
    events: Array<Event>,
  }
  risk: {
    documents: Array<{name: string, url: string}>,
    riskMitigationFactors: string,
    riskMitigationImages: Array<string>
  },
  performanceReport: {
    isPasswordSetted: boolean;
    documents: Array<DocumentRoleProtected>;
  },
  dataRoom: {
    documents: Array<DocumentRoleProtected>,
    marketResearchImage: string,
    marketResearchText: string;
  },
  academyMaterials: EducationMaterial[];
}


export interface PropertyFloorPlanDetails {
  values: AdditionalValue[],
  disclaimers: string[]
}

export interface EstateManagement {
  values: AdditionalValue[],
  descriptions: string[]
}
export interface Financials {
  infoBlockLeft: {
    _id: string,
    header: string,
    htmlString: string
  }[],
  infoBlockRight: {
    _id: string,
    header: string,
    htmlString: string
  }[],
  priceInfos: AdditionalValue[],
  percentInfos: AdditionalValue[],
  statistics: StatisticsType,
  disclaimers: string[]

}

export interface LocationDetails {
  city: {
    name: string,
    descriptions: string[],
    images: string[],
    disclaimers: string[]
  },
  country: {
    name: string,
    graphTitle: string,
    descriptions: string[],
    images: string[],
    disclaimers: string[]
  },
  neighborhood: {
    name: string,
    descriptions: string[],
    images: string[],
    disclaimers: string[],
    footerValues: AdditionalValue[]
  }
}

export interface SwotAnalysis {
  _id?: string,
  header: string;
  descriptions: string[];
  [key: string]: any
}

export interface OpportunityType {
  _id?: string,
  subHeader: {
    text: string,
    icon: string
  };
  header: string;
  descriptions: string[];
  [key: string]: any
}

export interface StatisticsType {
  header: string,
  columns: string[],
  rows: Array<{
    label: string,
    value: string,
    data: {
      [key: string]: any
    }
  }>
  disclaimers: string[]
  [key: string]: any,
}

export interface PropertyCountObj {
  all: number, 
  lpInvestments: number,
	propInvestments: number,
  bookmarked: number,
  searchMandates: number
}

export interface PropertyObj {
  property: PropertyTeaserType,
  isScanned?: boolean,
  isInterested?: boolean,
  isBookmarked?: boolean,
  _id?: string
}

export interface Document {
  name: string;
  description?: string;
  documentName?: string;
  key?: string;
  url: string;
  _id: string;
  mimeType?: string;
}

export interface DocumentRoleProtected extends Document {
  allowedFor: Array<keyof typeof WhoAreYou>;
}

export interface PropertyDetails {
  values: [{
    _id: string;
    name: string;
    value: string;
  }],
  highlights: Highlight[],
  disclaimers: string[]

}

export interface AdditionalValue {
  _id?: string;
  name: string;
  value: string;
  subValues?: AdditionalValue[]
  image?: string;
  tooltip?: string;
}

export interface Highlight {
  _id: string;
  description: string;
  value: string;
  icon: string;
}

export interface FloorPlan {
  assets: string[];
  highlights?: Highlight[];
  descriptions: string[];
}

export interface Location {
  address: string;
  coords: {
    latitude: number;
    longitude: number;
  };
  images?: string[],
  descriptions: string[];
}

export interface DealsState {
  properties: PropertyObj[];
}

export interface AddProperty extends Action {
  type: typeof ADD_PROPERTY;
  payload: {
    property: PropertyTeaserType,
    isScanned?: boolean
  };
}

export interface PropertyInterested extends Action {
  type: typeof INTERESTED;
  payload: string;
}

export interface DeleteProperty extends Action {
  type: typeof DELETE_PROPERTY;
  payload: string;
}

export interface AddProperties extends Action {
  type: typeof ADD_PROPERTIES;
  payload: PropertyObj[];
}

export interface AuditLog {
  _id: string,
  action: 'OPPORTUNITY_SHARED' | 'INVESTMENT_ASK',
  date: Date,
  changes: {
    values: {
      opportunity: {
        _id: string,
        name: string
      }
      sharedWith: string
    }
  }[]
}


export const INITIAL_STATE: PropertyObj[] = []

