import { MaterialView } from "modal/material-view";
import { EducationMaterial } from "types/education-materials/education-material";
import { FC } from "react";

type Props = {
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
  file: string;
  mimeType: string;
  name: string;
  suggestedMaterials: Array<EducationMaterial>;
  onClose?: () => void;

  //  analitics
  material?: EducationMaterial;
  onMaterialOpen?: (material: EducationMaterial, page: number) => void;
  onMaterialClose?: (material: EducationMaterial, page: number) => void;
  onMaterialPageChange?: (material: EducationMaterial, page: number) => void;
};

export const PropertyEducationAndResesrchMaterialView: FC<Props> = ({
  isOpen,
  setIsOpen,
  file,
  mimeType,
  name,
  suggestedMaterials,
  onClose,

  material,
  onMaterialOpen,
  onMaterialClose,
  onMaterialPageChange
}) => (
  <MaterialView
    isOpen={isOpen}
    onClose={() => {
      setIsOpen(false);
      if (onClose) {
        onClose();
      }
    }}
    file={file}
    mimeType={mimeType}
    title={name}
    suggestedMaterials={suggestedMaterials}

    material={material}
    onMaterialOpen={onMaterialOpen}
    onMaterialClose={onMaterialClose}
    onMaterialPageChange={onMaterialPageChange}
  />
);
