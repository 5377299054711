import {FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import { ActionButtons } from "./action-buttons";
import { Collapse, Container } from 'react-bootstrap';
import { PropertyCard } from "./property-card";
import { PropertyTeaserType } from "store/properties/types";
import classNames from "classnames";
import { collapseAnimationDuration } from "../constans/collapse-animation";
import { Breakpoint } from "../constans/breakpoints";
import { PropertyShareModal } from "new-architecture/modules/property-share-modal";
import { useSession } from 'context/session';
import { emailMessage } from "../constans/email-message";
import { UnathorizeDisabled } from "new-architecture/hocs/unathorize-disabled";

import styles from './style.module.scss';
import { useTranslate } from "@tolgee/react";

interface PropertySubHeaderProps {
  property: PropertyTeaserType
}

export const PropertySubHeader: FC<PropertySubHeaderProps> = ({ property }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const openShareModal = useCallback(() => {
    setIsShareModalVisible(true);
  }, []);

  const closeShareModal = useCallback(() => {
    setIsShareModalVisible(false);
  }, []);

  const [initialCardTopPosition, setInitialCardTopPosition] = useState<number>(0);
  const cardContainerRef = useRef<HTMLDivElement>(null);

  const calculateCardTopPosition = useCallback(() => {
    const rects = cardContainerRef.current?.getClientRects();

    if (!rects) {
      return;
    }

    const rect = rects.item(0);

    if (!rect) {
      return;
    }
    setInitialCardTopPosition(rect.top);
  }, []);

    // card top initial position observer
    useLayoutEffect(() => {
      calculateCardTopPosition();
  
      window.addEventListener('resize', calculateCardTopPosition);
  
      return () => {
        window.removeEventListener('resize', calculateCardTopPosition);
      }
    }, [calculateCardTopPosition]);
  
    const [isAnimationApplying, setIsAnimationApplying] = useState(false);

    const [currentBreakpoint, setCurrentBreakpoint] = useState<number>(Breakpoint.XS);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // current breakpoint observer
  useLayoutEffect(() => {
    const keys = Object.keys(Breakpoint) as Array<keyof typeof Breakpoint>;
    const unsubscribeJobs: Array<() => void> = [];

    keys.forEach((breakpoint, index) => {
      const mediaQuery = window.matchMedia(`(min-width: ${Breakpoint[breakpoint]}px)`);

      if (mediaQuery.matches) {
        setCurrentBreakpoint(Breakpoint[breakpoint]);
      }

      const handleMediaChangeEvent = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setCurrentBreakpoint(Breakpoint[breakpoint]);
          return;
        }
        setCurrentBreakpoint(Breakpoint[keys[index - 1]]);
      };

      mediaQuery.addEventListener('change', handleMediaChangeEvent);

      unsubscribeJobs.push(() => mediaQuery.removeEventListener('change', handleMediaChangeEvent));
    });

    return () => {
      unsubscribeJobs.forEach((job) => job());
    };
  }, []);


  const onScrinkStart = useCallback(() => {
    setIsAnimationApplying(true);
  }, []);

  const onShrinkFinish = useCallback(() => {
    // need to solve for proper

    // window.scrollTo({ top: window.scrollY + 1});
    setTimeout(() => {
      setIsAnimationApplying(false);
    }, collapseAnimationDuration);
  }, []);

  const [scrolled, setScrolled] = useState(false);

  const calculateIsVisible = useCallback(() => {
    if (!cardContainerRef.current || isAnimationApplying || !scrolled) {
      return;
    }

    const bodyRect = document.body.getBoundingClientRect();
    const cardRect = cardContainerRef.current.getBoundingClientRect();
    const offset = cardRect.top - bodyRect.top;

    setIsVisible((prev) => {
      const out = initialCardTopPosition === offset;
      if(out !== prev){
        setScrolled(false);
      }
      return out;
    });
  }, [initialCardTopPosition, isAnimationApplying, setIsVisible, scrolled]);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        if (!cardContainerRef.current) {
          return;
        }

        const bodyRect = document.body.getBoundingClientRect();
        const cardRect = cardContainerRef.current.getBoundingClientRect();
        const offset = cardRect.top - bodyRect.top;

        // really hacky :(
        if(offset < 120) {
          window.scrollTo({top: window.scrollY + 1});
        }
      }, collapseAnimationDuration);
    }
  }, [isVisible]);

  const scrolling = useCallback(()=>{
    if(!isAnimationApplying){
      setScrolled(true);
    }
  },[isAnimationApplying]);

  // is card shrinked observer
  useLayoutEffect(() => {
    window.addEventListener('scroll', calculateIsVisible);
    window.addEventListener('resize', calculateIsVisible);
    window.addEventListener('scroll', scrolling);

    return () => {
      window.removeEventListener('scroll', calculateIsVisible);
      window.removeEventListener('resize', calculateIsVisible);
      window.removeEventListener('scroll', scrolling);
    }
  }, [calculateIsVisible,scrolling]);

  useLayoutEffect(() => {
    calculateIsVisible();
  }, [calculateIsVisible, initialCardTopPosition]);

  const { user } = useSession();

  const { t: tEmailTemplates } = useTranslate('email_templates');

  const emailTranslations = useMemo(() => {
    const out: Array<string> = [];

    for (let i = 0; i < 5; i++) {
      out.push(tEmailTemplates(`email_templates.share_opportunity_${i}`));
    }

    return out;
  }, [tEmailTemplates]);

  return(
    <div
      className={styles['sub-header']}
      ref={cardContainerRef}
    >
      <Container>
        <Collapse
          in={isVisible || currentBreakpoint < Breakpoint.SM}
          onExiting={onScrinkStart}
          onExited={onShrinkFinish}
          timeout={collapseAnimationDuration}
        >
          <div>
            <div
              className={
                classNames(
                  styles['sub-header__action-buttons'],
                )
              }
            >
              <UnathorizeDisabled>
                <ActionButtons openShareModal={openShareModal} property={property}/>
              </UnathorizeDisabled>
            </div>
          </div>
        </Collapse>

        <PropertyCard
          property={property}
          isVisible={isVisible}
          currentBreakpoint={currentBreakpoint}
          onScrinkStart={onScrinkStart}
          onShrinkFinish={onShrinkFinish}
          openShareModal={openShareModal}
        />
      </Container>
      {
        user && property &&
        <PropertyShareModal
          onClose={closeShareModal}
          isOpen={isShareModalVisible}
          options={{
            fromEmail: user.email,
            subject: `${tEmailTemplates('email_templates.share_opportunity_have_a_look', 'Have a look at')} ${property.name}`,
            message: emailMessage(user, emailTranslations),
            documents: [...property.documents, ...property.dataRoom.documents],
            propertyImages: property.assets,
            attachTeaser: true,
            teaserName: `EN TEASER - ${property.name}.pdf`,
            propertyId: property._id,
          }}
        />
      }
    </div>
  )
}
