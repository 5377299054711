import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getBaseUrl } from "../app-config";
import history from '../history-entity';
import { UserAPI } from 'api/user';

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders: (headers) => {
    headers.set('x-auth', localStorage.getItem('jwtToken') ?? '');
  },
  credentials: 'include',
});

export const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    UserAPI.logOut();
    localStorage.removeItem('jwtToken');
    history.push("/");
  }
  return result;
};

export const baseQueryWithAuthWithoutAutoLogout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  return baseQuery(args, api, extraOptions);
};

export const baseQueryWithoutAuth = fetchBaseQuery({ baseUrl: getBaseUrl() });
