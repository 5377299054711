import { memo, FC } from "react";
import { EducationMaterial } from "../../types/education-materials/education-material";
import Carousel, { CarouselProps } from "react-multi-carousel";
import { SuggestedMaterialListItem } from "./suggested-materials-list-item";

interface SuggestedMaterialsListProps {
  materials: Array<EducationMaterial>;
  readonly?: boolean;
  carouselProps: CarouselProps;
}

export const SuggestedMaterialsList: FC<SuggestedMaterialsListProps> = memo(
  ({ materials, readonly, carouselProps }) => {
    return (
      <Carousel
        {...carouselProps}
      >
        {materials.map((material) => (
          <SuggestedMaterialListItem  readonly={readonly} key={material._id} material={material} />
        ))}
      </Carousel>
    );
  }
);
