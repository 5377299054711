import { AdditionalValue } from "store/properties/types";
import { emptyField } from "../constants/empty-field";

export const extractValueFromPropertyAdditionalValues = (
  key: string,
  additionalValues: AdditionalValue[]
): string => {
  const additionalValue = additionalValues.find((av) => av.name === key);
  if (additionalValue) {
    return additionalValue.value || emptyField;
  }
  return emptyField;
};