import React, { FC, memo, useCallback, useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { ReactComponent as PrevButtonSvg } from "assets/sliderPrev.svg";
import { ReactComponent as NextButtonSvg } from "assets/sliderNext.svg";
import { ReactComponent as PlayButtonSvg } from "assets/playButtonSmall.svg";

import { isVideo } from "helpers/isVideoChecker";

import style from "./style.module.scss";
import classNames from "classnames";

type Props = {
  assets: Array<string>;
  setImage: (param: string) => void;
  isVisibleButtonPlay: (param: boolean) => void;
  isButtonVisible: boolean;
};

const swiperSlideClass = classNames(
  "cursor-pointer",
  style["swiper-container__slide"]
);
const videoBoxClass = classNames(
  "position-relative",
  style["swiper-container__video-container"]
);
const playButtonBox = classNames(
  "cursor-pointer",
  style["swiper-container__play-button-box"]
);

const swiperMainContainer = classNames(
  style["swiper-container__main-container"],
  "d-flex mt-auto"
);

export const PropertyGalleryModalSwiper: FC<Props> = memo(
  ({ assets, setImage, isVisibleButtonPlay, isButtonVisible }) => {
    const sliderRef = useRef<null | SwiperRef>(null);

    const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }, []);

    return (
      <div className={swiperMainContainer}>
        <div
          className="cursor-pointer d-flex align-items-center"
          onClick={handlePrev}
        >
          <PrevButtonSvg />
        </div>
        <Swiper ref={sliderRef} spaceBetween={33} slidesPerView="auto">
          {assets.map((el) => {
            return (
              <SwiperSlide
                className={swiperSlideClass}
                onClick={() => setImage(el)}
              >
                {isVideo(el) ? (
                  <div className={videoBoxClass}>
                    <video
                      height="140px"
                      className={style["swiper-container__video-box"]}
                    >
                      <source src={el} type="video/mp4" />
                    </video>
                    {isButtonVisible && (
                      <div className={playButtonBox}>
                        <PlayButtonSvg
                          className={style["swiper-container__button"]}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <img
                    src={el}
                    alt="img"
                    className={style["swiper-container__button-image"]}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className="cursor-pointer d-flex align-items-center"
          onClick={handleNext}
        >
          <NextButtonSvg />
        </div>
      </div>
    );
  }
);
