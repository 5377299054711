import { memo, FC, useContext, useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TableContext } from '..'
import { eventHeight, tablePaddingX } from '../utils/constants'
import { Background } from './background'
import { Rows } from './content/rows'

interface TableProps {
}

export const Table: FC<TableProps> = memo(() => {
  const { tableWidth } = useContext(TableContext);
  const [rowsHeigh, setRowsHeigh] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const rowsContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setTableHeight(entries[0].target.clientHeight);
    });

    if (tableContainerRef.current) {
      resizeObserver.observe(tableContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect()
    };
  }, []);

  useEffect(() => {
    setRowsHeigh(rowsContainerRef.current?.clientHeight! + eventHeight / 2 ?? 0);
  }, [rowsContainerRef.current?.clientHeight]);

  return (
    <TableContainer ref={tableContainerRef} width={tableWidth} backgroundHeight={rowsHeigh}>
      <Background height={tableHeight} />
      <div className="h-100 d-flex align-items-center w-100">
        <Rows ref={rowsContainerRef} />
      </div>
    </TableContainer>
  )
})


interface TableContainerProps {
  width: number;
  backgroundHeight: number;
}

const TableContainer = styled.div<TableContainerProps>`
  margin-bottom: 20px;

  padding: 0 ${tablePaddingX}px;

  width: ${({width}) => width}px;

  min-height: ${({backgroundHeight}) => backgroundHeight}px;

  height: 100%;
`