import * as yup from 'yup';

export const investFormikValidationSchema = yup.object({
  ISIN: yup.string().required(),
  internalName: yup.string().required(),
  investmentStrategy: yup.string().required(),
  classification: yup.string().required(),
  address: yup.string().required(),
  serviceProviderName: yup.string().required(),
  contactPersonName: yup.string().required(),
  telephoneNumber: yup.string().required(),
  email: yup.string().email().required(),
  investmentAmount: yup.string().required(),
  custodyBank: yup.string().required(),
  bankContactNameEmail: yup.string().optional(),
  currency: yup.string().required(),
  issuanceVolume: yup.string().required(),
  minimumInvestment: yup.string().required(),
  propertyId: yup.string().required()
});
