import { createApi } from "@reduxjs/toolkit/query/react";
import { EducationMaterial } from "../types/education-materials/education-material";
import { baseQueryWithAuth } from "./base-query";
import { WhoAreYou } from "types/user/who-are-you";

export const educationMaterialGroupsApi = createApi({
  reducerPath: "educationMaterialGroupsApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ['material', 'materials'],
  endpoints: (builder) => ({
    getEducationMaterial: builder.query<EducationMaterial, { id?: string, params?: Record<string, string> }>({
      query: ({ id, params }) => {
        if (!id) {
          throw new Error('id not provided');
        }

        const url = `education-materials-groups/education-material/${id}`;

        return { url, params };
      },
    }),
    getEducationMaterialBySearch: builder.query<Array<EducationMaterial>,{ whoAreYou: WhoAreYou | string, searchValue?: string, tags?: Array<string> }>({
      query: (params) => {
        let url = '/education-materials-groups';
      
        if (params) {
          url += '?';
          Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
              return params[key].forEach((value) =>
                url += `${key}=${value}&`
              );
            }
      
            if (params[key]) {
              url += `${key}=${params[key]}&`
            }
          })
        }
        return { url };
      },
      providesTags: (result) => {
        return (
          result ?
            [
              ...result.map(({ tags }) => ({ type: 'material' } as const)),
              { type: 'materials', id: 'LIST' }
            ]
          :
            [{ type: 'materials', id: 'LIST' }]
        );
      }
    })
  }),
  refetchOnMountOrArgChange: true,
});

export const { useGetEducationMaterialQuery, useGetEducationMaterialBySearchQuery } = educationMaterialGroupsApi;