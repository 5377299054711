import { FC, memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PropertyTeaserType } from 'store/properties/types';
import { useSession } from 'context/session';
import { trackPropertyReaction } from 'helpers/tracking';
import { getTeaserPreviewLink, downloadPdfTeaser } from 'api/teaser';
import { PropertyActionsDropdown } from '../property-actions-dropdown';
import { PropertyContactEstatingDropdown } from '../property-contact-estating-dropdown';
import { PropertyInvestModal } from 'new-architecture/modules/property-invest-modal';
import { ContactEstatingModal } from 'new-architecture/modules/contact-estating-modal';
import { fetchHtmlTeaser } from 'api/teaser';
import { useTrackOpportunityDetailsTeaserCopyLinkMutation, useTrackOpportunityDetailsTeaserDownloadMutation } from 'api/analitics';
import { useHistory } from "react-router";
import { useQuery } from 'hooks/use-query';
import { UnathorizeDisabled } from 'new-architecture/hocs/unathorize-disabled';
import { useTranslate } from '@tolgee/react';

import styles from './style.module.scss';

enum Action {
  OPEN_INVEST_MODAL,
  CLOSE_INVEST_MODAL,
  OPEN_CONTACT_ESTATING_MODAL,
  CLOSE_CONTACT_ESTATING_MODAL,
  DOWNLOAD_TEASER,
  DISPLAY_TEASER,
  COPY_TEASER_LINK,
  TEASER_PREVIEW_OPEN,
}

interface PropertyCardButtonsProps {
  property: PropertyTeaserType;
  collapsed: boolean;
}

export const PropertyCardButtons: FC<PropertyCardButtonsProps> = memo(({ property, collapsed }) => {
  const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');
  const { user } = useSession();
  const history = useHistory();
  const query = useQuery();

  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);
  const [isContactEstatingModalOpen, setIsContactEstatingModalOpen] = useState(false);
  const [isDoingAction, setIsDoingAction] = useState(false);

  const [trackCopyTeaserLink] = useTrackOpportunityDetailsTeaserCopyLinkMutation();
  const [trackTeaserDownload] = useTrackOpportunityDetailsTeaserDownloadMutation();

  const executor = useCallback(async (action: Action) => {
    switch(action) {
      case Action.OPEN_CONTACT_ESTATING_MODAL:
        setIsContactEstatingModalOpen(true);
      break;

      case Action.CLOSE_CONTACT_ESTATING_MODAL:
        setIsContactEstatingModalOpen(false);
      break;

      case Action.OPEN_INVEST_MODAL:
        if (user) {
          trackPropertyReaction("invest", "open")
        }
    
        setIsInvestModalOpen(true);
      break;
      
      case Action.CLOSE_INVEST_MODAL:
        setIsInvestModalOpen(false);
      break;

      case Action.DOWNLOAD_TEASER:
        try {
          setIsDoingAction(true);
          const filename = await downloadPdfTeaser(property._id);
          trackTeaserDownload({
            opportunityId: property._id,
            opportunity: property.name,
            fileName: filename
          })
        } catch (error) {
          console.error(error);
        } finally {
          setIsDoingAction(false);
        }
      break;

      case Action.DISPLAY_TEASER:
        try {
          setIsDoingAction(true);
          const response = await fetchHtmlTeaser(property._id);
    
          if(!response) {
            return;
          }
    
          const { html, requestURL } = response;
          const newTab = window.open(requestURL,"newTab");
          
          if (newTab) {
            newTab.document.open();
            newTab.document.write(html);
            newTab.document.close();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsDoingAction(false);
        }
      break;

      case Action.COPY_TEASER_LINK:
        try {
          setIsDoingAction(true);
          await navigator.clipboard.writeText(getTeaserPreviewLink(property._id));
          trackCopyTeaserLink({ opportunityId: property._id, opportunity: property.name });
        } catch(error) {
          console.error(error)
        } finally {
          setIsDoingAction(false);
        }
        
      break;

      case Action.TEASER_PREVIEW_OPEN:
        try {
          setIsDoingAction(true);
          query.append('teaser-preview', property._id);
          history.push({ pathname: history.location.pathname, search: query.toString(), hash: history.location.hash });
        } catch(error) {
          console.error(error)
        } finally {
          setIsDoingAction(false);
        }
      break;
    }
  }, [history, property, query, trackCopyTeaserLink, trackTeaserDownload, user]);


  const actions = useMemo(() => [
    // {label: 'Display', action: () => executor(Action.DISPLAY_TEASER)},
    {label: tOpportunityDetailPageCard('opportunity_detail_page.card.download'), action: () => executor(Action.DOWNLOAD_TEASER)},
    {label: tOpportunityDetailPageCard('opportunity_detail_page.card.copy_link'), action: () => executor(Action.COPY_TEASER_LINK)},
    {label: tOpportunityDetailPageCard('opportunity_detail_page.card.preview'), action: () => executor(Action.TEASER_PREVIEW_OPEN)}
  ], [executor, tOpportunityDetailPageCard]);

  const contactEstatingButtonActions = useMemo(() => [
    { label: tOpportunityDetailPageCard('opportunity_detail_page.card.contact_us'), action: () => executor(Action.OPEN_CONTACT_ESTATING_MODAL) },
    { label: tOpportunityDetailPageCard('opportunity_detail_page.card.invest'), action: () => executor(Action.OPEN_INVEST_MODAL) },
  ], [executor, tOpportunityDetailPageCard]);

  const contactEstatingButtonActionsShortDescription = useMemo(() => [
    { label: tOpportunityDetailPageCard('opportunity_detail_page.card.contact_us'), action: () => executor(Action.OPEN_CONTACT_ESTATING_MODAL) },
    { label: tOpportunityDetailPageCard('opportunity_detail_page.card.invest'), action: () => executor(Action.OPEN_INVEST_MODAL) },
  ], [executor, tOpportunityDetailPageCard]);

  const contactEstatingDropdown = useMemo(() => 
    <PropertyContactEstatingDropdown
      actions={contactEstatingButtonActions}
      isDoingAction={false}
      label={tOpportunityDetailPageCard('opportunity_detail_page.card.contact_estating')}
    />
  ,[contactEstatingButtonActions, tOpportunityDetailPageCard]);

  const contactEstatingDropdownShortDescription = useMemo(() => 
    <PropertyContactEstatingDropdown
      actions={contactEstatingButtonActionsShortDescription}
      isDoingAction={false}
      label="Contact"
    />
  ,[contactEstatingButtonActionsShortDescription]);

  const propertyActionsDropdown = useMemo(() => 
    <PropertyActionsDropdown
      actions={actions}
      isDoingAction={isDoingAction}
      label={tOpportunityDetailPageCard('opportunity_detail_page.card.teaser')}
    />
  ,[actions, isDoingAction, tOpportunityDetailPageCard]);

  return (
    <UnathorizeDisabled>
      <div
        className={
          classNames(
            styles.buttons
          )
        }
      >
        <div
          className={styles['buttons__contact']}
        >
          <div
            className="d-none d-sm-block d-md-none"
          >
            <div
              className={
                classNames(
                  { 'd-none': collapsed }
                )
              }
            >
              {contactEstatingDropdown}
            </div>
            <div
              className={
                classNames(
                  { 'd-none': !collapsed }
                )
              }
            >
              {contactEstatingDropdownShortDescription}
            </div>
          </div>
          <div
            className="d-sm-none d-md-block"
          >
            {contactEstatingDropdown}
          </div>
        </div>
        <div
          className="d-sm-none d-md-block"
        >
          {propertyActionsDropdown}
        </div>
        <div
          className="d-none d-sm-block d-md-none"
        >
          <div
            className={
              classNames(
                { 'd-none': collapsed }
              )
            }
          >
            {propertyActionsDropdown}
          </div>
          <div
            className={
              classNames(
                { 'd-none': !collapsed }
              )
            }
          >
            {propertyActionsDropdown}
          </div>
        </div>
        <PropertyInvestModal
          isOpen={isInvestModalOpen}
          onClose={() => executor(Action.CLOSE_INVEST_MODAL)}
          property={property}
        />
        {
          user && property &&
          <ContactEstatingModal
            isOpen={isContactEstatingModalOpen}
            onClose={() => executor(Action.CLOSE_CONTACT_ESTATING_MODAL)}
            options={{
              fromEmail: user.email,
              subject: "Message from 'Contact Estating'",
              attachTeaser: false,
              attachments: [],
              images: [],
              otherEmails: [],
              propertyId: property._id,
              name: `${user.firstName} ${user.lastName}`,
              phone: '',
              toEmail: 'info@estating.com',
            }}
          />
        }
      </div>
    </UnathorizeDisabled>
  )
});
