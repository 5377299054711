import { PropertyTeaserType } from 'store/properties/types';
import { FC, memo, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { PropertySubsectionTitle } from './property-subsection-title';
import { ReactComponent as ChartIcon } from "assets/fa6-solid_chart-line.svg";
import { useDispatch, useSelector } from 'react-redux';
import { PasswordCardForm } from './password-card-form';
import { setProperty } from '../store/slices/preformance-report';
import { performanceAccessAllowed } from '../store/slices/preformance-report';
import { DocumentCard } from './document-card';
import { useTranslate } from '@tolgee/react';
import { useSession } from 'context/session';

import styles from './style.module.scss';

interface PropertyPerformanceReportProps {
  property: PropertyTeaserType
}

export const PropertyPerformanceReport:FC<PropertyPerformanceReportProps> = memo(({ property }) => {
  const { _id, performanceReport } = property;
  const { documents } = performanceReport;
  const dispatch = useDispatch();
  const accessAllowed = useSelector(performanceAccessAllowed); 

  // auth require on every unmount
  useEffect(() => {
    dispatch(setProperty(null));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setProperty(_id));
  }, [dispatch, _id]);

  const { t: tOpportunityDetailPageInvestorReport } = useTranslate('opportunity_detail_page.investor_report');
  const { whoAreYou } = useSession();

  return (
    <Container>
      <div className={styles['performance-report']}>
        <PropertySubsectionTitle
          title={tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.title')}
          icon={<ChartIcon />}
        />

        {
          accessAllowed ? 
          (
            <div className={styles['performance-report__documents-container']}>
              {documents.map((document) => (
                document?.allowedFor?.includes(whoAreYou) && 
                <DocumentCard
                  document={document}
                  key={document._id}
                  propertyId={_id}
                />
              ))}
            </div>
          )
          :
          (
            <div className={styles['performance-report__form-container']}>
              <PasswordCardForm />
            </div>
          )
        }
      </div>
    </Container>
  )
});
