import { memo, useContext } from "react";
import classNames from "classnames";
import { EducationAndResearchContext } from "../property-education-and-research-card";

import style from "./style.module.scss";

const boxClass = classNames(
  "d-flex flex-column justify-items-center",
  style["education-card-main__box"]
);

export const PropertyEducationAndResearchCardMain = memo(() => {
  const material = useContext(EducationAndResearchContext);
  const { name, description } = material!;
  return (
    <div className={boxClass}>
      <div className={style["education-card-main__title"]}>{name}</div>
      <div className={style["education-card-main__text"]}>{description}</div>
    </div>
  );
});
