import { memo, FC, useState, useCallback, createContext, useMemo, useRef } from "react";
import { ReactBootstrapPopUp } from "../../modal/react-bootstrap-pop-up";
import { EducationMaterialsGroup } from "../../types/education-materials/education-materials-group";
import { EducationMaterialsGroupsListItem } from "./education-materials-groups-list-item";
import { EducationMaterial } from "../../types/education-materials/education-material";
import { MaterialView } from "../../modal/material-view";
import { useGetEducationMaterialQuery } from "api/estating-academy";
import { useHistory, useParams } from "react-router-dom";
import { useTrackEducationMaterialCloseMutation, useTrackEducationMaterialOpenMutation, useTrackEducationMaterialPageChangeMutation, useTrackEducationMaterialShareLinkCopyMutation } from "api/analitics";
import { v4 } from "uuid";
import { useTolgee, useTranslate } from "@tolgee/react";

interface EducationMaterialsListMaterialsProps {
  groups: Array<EducationMaterialsGroup>;
}

export const EducationMaterialsGroupList: FC<EducationMaterialsListMaterialsProps> =
  memo(({ groups }) => {
    const history = useHistory();
    const { id } = useParams<{ id: string | undefined }>();
    const [sharedMaterial, setSharedMaterial] = useState<null | EducationMaterial>(null);
    const [isCopyClicked, setIsCopyClicked] = useState<boolean>(false);
    const { t: tEstatingAcademy } = useTranslate('estating_academy');

    const onHide = useCallback(() => {
      setSharedMaterial(null);
      setIsCopyClicked(false);
    }, []);
    const tolgee = useTolgee();

    const { 
      data: fetchedPrevievedMaterial,
      // isLoading: isPrevievedMaterialLoading,
      // error: previevedMaterialLoadingError
    } = useGetEducationMaterialQuery({ id, params: { lang: tolgee.getPendingLanguage() || 'en' } }, { skip: !id });

    const handleMaterialSetMaterialPreview = useCallback((material: EducationMaterial | null) => {
      if (!material) {
        history.push('/estating-academy');
        return;
      }

      history.push(`/estating-academy/${material._id}`);
    }, [history]);

    const { isOpen, file, mimeType, title, suggestedMaterials } = useMemo(() => {
      if (!fetchedPrevievedMaterial) {
        return ({
          isOpen: false,
          file: '',
          mimeType: '',
          title: '',
          suggestedMaterials: [],
        });
      }

      const isOpen = !!id && !!fetchedPrevievedMaterial && fetchedPrevievedMaterial._id === id;

      return ({
        isOpen,
        file: fetchedPrevievedMaterial!.file,
        mimeType: fetchedPrevievedMaterial!.mimeType,
        title: fetchedPrevievedMaterial!.name,
        suggestedMaterials: fetchedPrevievedMaterial!.suggestedMaterials,
      });
    }, [fetchedPrevievedMaterial, id]);

    const materialsGroupsContextValue = useMemo<MaterialsGroupsContextType>(() => ({
      sharedMaterial,
      setSharedMaterial,
      previewedMaterial: fetchedPrevievedMaterial,
      setPreviewedMaterial: handleMaterialSetMaterialPreview,
    }), [sharedMaterial, setSharedMaterial, fetchedPrevievedMaterial, handleMaterialSetMaterialPreview]);

    const shareFileEncodedLink = useMemo(() => {
      if (!sharedMaterial) {
        return '';
      }

      return `${window.location.origin}/estating-academy/${sharedMaterial._id}`;
    }, [sharedMaterial]);

    const [trackCopyLink] = useTrackEducationMaterialShareLinkCopyMutation();

    const onCopyDownloadMaterialLink = useCallback(async () => {
      try {
        trackCopyLink({
          fileName: sharedMaterial!.originalName,
          material: sharedMaterial!.name,
          materialId: sharedMaterial!._id
        });
        await navigator.clipboard.writeText(shareFileEncodedLink);
        setIsCopyClicked(true);
      } catch (err) {
        console.error(err);
      }
    }, [shareFileEncodedLink, sharedMaterial, trackCopyLink]);

    const sessionId = useRef(v4());

    const generateNewSessionId = useCallback(() => {
      sessionId.current = v4();
    }, []);

    const [trackMaterialOpen] = useTrackEducationMaterialOpenMutation();
    const [trackMaterialClose] = useTrackEducationMaterialCloseMutation();
    const [trackMaterialPageChange] = useTrackEducationMaterialPageChangeMutation();

    const onMaterialOpen = useCallback((material: EducationMaterial, page: number) => {
      if (sessionId) {
        trackMaterialOpen({
          material: material.name,
          materialId: material._id,
          page: String(page),
          sessionId: sessionId.current
        })
      }
    }, [trackMaterialOpen]);

    const onMaterialClose = useCallback((material: EducationMaterial, page: number) => {
      if (sessionId) {
        trackMaterialClose({
          material: material.name,
          materialId: material._id,
          page: String(page),
          sessionId: sessionId.current
        })
      }
      generateNewSessionId()
    }, [generateNewSessionId, trackMaterialClose]);

    const onMaterialPageChange = useCallback((material: EducationMaterial, page: number) => {
      if (sessionId) {
        trackMaterialPageChange({
          material: material.name,
          materialId: material._id,
          page: String(page),
          sessionId: sessionId.current
        })
      }
    }, [trackMaterialPageChange]);

    const { t: tSearchNotFound } = useTranslate('homepage.search');

    if(groups.length === 0) {
      return (
        <div className="col-8 col-md-6 col-xl-4 mx-auto mt-7 d-flex align-items-center flex-column fade-anim">
          <p className="text-center">
            {tSearchNotFound('homapage.search.not_match.your_search')}
            {' '}
            <b className="text-lowercase">
              {tSearchNotFound('homepage.search.values')}
            </b>
            {' '}
            {tSearchNotFound('homepage.search.we_have')}
          </p>
          <p className="text-center">
            {tSearchNotFound('homepage.search.suggestions')}
          <ul className="text-left">
            <li>{tSearchNotFound('homepage.search.make_sure')}</li>
            <li>{tSearchNotFound('homepage.search.try_different')}</li>
            <li>{tSearchNotFound('homepage.search.try_fewer')}</li>
          </ul>
          </p>
      </div>
      );
    }

    return (
      <div className="d-flex flex-column fade-anim" style={{ gridGap: "50px" }}>
        <MaterialsGroupsContext.Provider value={materialsGroupsContextValue}>
          {groups.map((group) => (
            <EducationMaterialsGroupsListItem key={group._id} group={group} />
          ))}
        
          {isOpen && <MaterialView
            isOpen={isOpen}
            onClose={() => handleMaterialSetMaterialPreview(null)}
            file={file}
            mimeType={mimeType}
            title={title}
            suggestedMaterials={suggestedMaterials}

            material={fetchedPrevievedMaterial}
            onMaterialOpen={onMaterialOpen}
            onMaterialClose={onMaterialClose}
            onMaterialPageChange={onMaterialPageChange}
          />}
          <ReactBootstrapPopUp
            isShow={!!sharedMaterial}
            handleClose={onHide}
            headerContent={
              <>{tEstatingAcademy('estating_academy.share_document')}</>
            }
            bodyContent={
              <div 
                className='d-flex flex-column flex-sm-row align-items-center'
              >
                <input 
                  value={shareFileEncodedLink}
                  className="mb-2 mb-sm-0 mr-sm-2 form-control radius-5"
                  readOnly
                />
                <button
                  className='btn btn-primary col px-md-5 h-100'
                  onClick={onCopyDownloadMaterialLink}
                >
                  {isCopyClicked ? tEstatingAcademy('estating_academy.copied') : tEstatingAcademy('estating_academy.copy')}
                </button>
              </div>
            }
            modalProps={{
              centered: true,
            }}
          />
        </MaterialsGroupsContext.Provider>
      </div>
    );
  }
);

interface MaterialsGroupsContextType {
  sharedMaterial: EducationMaterial | null;
  setSharedMaterial: (value : EducationMaterial | null) => void;
  previewedMaterial?: EducationMaterial | null;
  setPreviewedMaterial: (value : EducationMaterial | null) => void;
}

export const MaterialsGroupsContext = createContext<MaterialsGroupsContextType>({
  sharedMaterial: null,
  setSharedMaterial: () => undefined,
  previewedMaterial: null,
  setPreviewedMaterial: () => undefined,
});
