import { FC, memo } from 'react';
import { IReactMultiEmailProps, ReactMultiEmail } from 'react-multi-email';
import classNames from 'classnames';

import styles from './style.module.scss';
import "react-multi-email/dist/style.css";

interface InputProps {
  label?: string;
  multiEmailProps: IReactMultiEmailProps;
}

export const MultipleEmailsInput: FC<InputProps> = memo(({
  label,
  multiEmailProps
}) => {
  const { className } = multiEmailProps;
  return (
    <label className={styles['input-container']}>
      { label && 
        <div className={styles['input-container__label']} role="button">
          {label}
        </div>
      }
      <div
        className={classNames(
          styles['input-container__input-wrapper'],
        )}
      >
        <ReactMultiEmail 
          {
            ...multiEmailProps
          }
          className={classNames(
            className,
            styles['input-container__input']
          )}
        />
      </div>
    </label>
  );
});
