import { FC, memo } from "react";

import styles from './style.module.scss';

type Props = {
  list: Array<string>;
}

export const InvestmentList:FC<Props> = memo(({ list }) => {
  return(
      <ul className={styles.list}>
        {
          list.map((value, index) => (
            <li
              className={styles.list__item}
              key={value + index}
            >
              {value}
            </li>
          ))
        }
      </ul>
  )
})

InvestmentList.displayName = 'InvestmentList'