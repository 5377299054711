import { FC, memo } from 'react';
import { Button } from 'new-architecture/components/button';
import { ReactComponent as ArrowSvg } from 'assets/arrow-forward.svg';
import { Spin } from 'antd';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface PropertyInvestModalFooterProps {
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  isSended: boolean;
}

export const PropertyShareModalFooter: FC<PropertyInvestModalFooterProps> = memo(({
  onClose,
  onConfirm,
  isLoading,
  isSended
}) => {
  const { t: tShareModal } = useTranslate('modal');

  return (
    <div className={styles['modal-footer']}>
      <div
        role="button"
        className={styles['modal-footer__cancel-button']}
        onClick={onClose}
      >
        {tShareModal('modal.cancel')}
      </div>
      <div
        className={styles['modal-footer__confirm-button']}
        onClick={onConfirm}
      >
        {
          isSended ?
          <Button
            text="Ok"
          />
          :
          <Button
            icon={
              isLoading ? 
              <div className={styles['modal-footer__loading-icon__spinner']}>
                <Spin /> 
              </div>
              :
              <ArrowSvg />
          }
            text={isLoading ? tShareModal('modal.sending') : tShareModal('modal.send_email')}
          />
        }
      </div>
    </div>
  );
});