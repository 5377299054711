import classNames from 'classnames';
import { Fragment } from 'react';
import { Popover } from 'react-bootstrap';

import styles from './style.module.scss';

interface DescriptionPopoverProps {
  descriptions: Array<{
    description: string;
    title?: string;
    link?: string;
  }>;
}

export const descriptionPopover = ({ 
  descriptions
}: DescriptionPopoverProps) => {
  return (
    <Popover
      id="description-popover"
      className={classNames(styles.container, {[styles.container_list]: descriptions.length > 1})}
    >
      {descriptions.map(({ description, title }) => (
        <Fragment key={title}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.content}>
            {description}
          </div>
        </Fragment>
      ))}
    </Popover>
  );
};