export const spanishLanguageConuntries = [
  'Argentina',
  'Bolivia (Plurinational State of)',
  'Chile',
  'Spain',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Ecuador',
  'Espanha',
  'Guatemala',
  'Equatorial Guinea',
  'Honduras',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Paraguay',
  'Peru',
  'Dominican Republic (the)',
  'El Salvador',
  'Uruguay',
  'Venezuela (Bolivarian Republic of)',
]