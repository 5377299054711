import classNames from 'classnames';
import { useSession } from 'context/session';
import { FC, memo, PropsWithChildren } from 'react'

export const UnathorizeDisabled: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const { user } = useSession();

  return (
    <div
      className={classNames(
        { 'disabled': !user },
      )}
    >
      {children}
    </div>
  );
});
