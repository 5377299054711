import { useTranslate } from '@tolgee/react';
import { memo } from 'react'
import Input from "components/Input";
import {InputProps} from "components/Input";

interface PersonalDetailsProps {
    firstName?: {
        value: string,
        callback: (value: string) => any,
        inputAttributes?: React.InputHTMLAttributes<any>,
        label?: string,
        inputProps?: InputProps
        className?: string,
    },
    lastName?: {
        value: string,
        callback: (value: string) => any,
        inputAttributes?: React.InputHTMLAttributes<any>,
        label?: string,
        inputProps?: InputProps
        className?: string
    },
    email?: {
        value: string,
        callback: (value: string) => any,
        inputAttributes?: React.InputHTMLAttributes<any>,
        label?: string,
        inputProps?: InputProps
        className?: string
    },
    phoneNumber?: {
        value: string,
        callback: (value: string) => any,
        inputAttributes?: React.InputHTMLAttributes<any>,
        label?: string,
        inputProps?: InputProps
        className?: string
    }
}


const PersonalDetails: React.FC<PersonalDetailsProps> = memo(({
  firstName,
  lastName,
  email,
  phoneNumber
}) => {
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');
  return (
    <>
      {
        firstName &&
        <Input
          label={firstName.label || tPrivateAreaMyAccount('private_area.my_account.form.first_name')}
          inputAttributes={{
            placeholder: 'Ex.Sam',
            ...firstName.inputAttributes,
            value: firstName.value,
            onChange: (event => firstName.callback(event.target.value))
          }}
          {...firstName.inputProps}
        />
      }
      {
        lastName &&
        <Input
          label={lastName.label || tPrivateAreaMyAccount('private_area.my_account.form.last_name')}
          inputAttributes={{
            placeholder: 'Ex.Smith',
            ...lastName.inputAttributes,
            value: lastName.value,
            onChange: (event => lastName.callback(event.target.value))
          }}
          {...lastName.inputProps}
        />
      }
      {
        email &&
        <Input
          label={email.label || tPrivateAreaMyAccount('private_area.my_account.email')}
          className={email.className || ''}
          inputAttributes={{
            placeholder: 'Ex.email@gmail.com',
            ...email.inputAttributes,
            value: email.value,
            type: 'email',
            onChange: (event => email.callback(event.target.value))
          }}
          {...email.inputProps}
        />
      }
      {
        phoneNumber &&
        <Input
          label={phoneNumber.label || tPrivateAreaMyAccount('private_area.my_account.phone_number')}
          inputAttributes={{
            placeholder: `(${tPrivateAreaMyAccount('private_area.my_account.optional')})`,
            ...phoneNumber.inputAttributes,
            value: phoneNumber.value,
            onChange: (event => phoneNumber.callback(event.target.value))
          }}
          {...phoneNumber.inputProps}
        />
      }
    </>
  );
});

export default PersonalDetails;
