import React from 'react';
import cryptoRandomString from 'crypto-random-string';

export type CheckboxProps = {
    id?: string,
    label?: string,
    inputAttributes?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'style'>,
    style?: React.CSSProperties,
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any,
    containerClasses?: string,
    containerStyles?: React.CSSProperties,
    labelClasses?: string,
    labelStyles?: React.CSSProperties,
    inputType?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
                                               id,
                                               label,
                                               inputAttributes = {},
                                               style,
                                               className,
                                               onChange = () => {
                                               },
                                               containerClasses = '',
                                               containerStyles = {},
                                               labelClasses,
                                               labelStyles = {},
                                               inputType
                                           }) => {

    if (!id && !inputAttributes?.id) {
        id = cryptoRandomString({length: 10});
    }

    return (
        <div className={containerClasses || 'my-3'}
             style={{
                 ...containerStyles
             }}
        >
            {
                label &&
                <label htmlFor={id} className={`label-container d-inline ${labelClasses}` || 'font-weight-bold'} style={{
                    ...labelStyles
                }}>{label}
                    <input
                        id={id}
                        style={{
                            ...style,
                        }}
                        type={inputType || 'radio'}
                        onChange={onChange}
                        autoComplete="off"
                        className={`${className || ''}`}
                        {...inputAttributes}
                    />
                    <span className="checkmark"> </span>
                </label>
            }
        </div>
    );
};

export default Checkbox;
