import { FC, memo, useEffect } from 'react';
import { downloadFile } from "helpers/download-file";
import logoPartials from "assets/logo-partials.png";
import { useTranslate } from '@tolgee/react';

interface LoadingFileProps {
  url: string;
  fileName: string;
}

export const LoadingFile: FC<LoadingFileProps> = memo(({ url, fileName }) => {
  const { t: tPublicArea } = useTranslate('public_area');

  useEffect(() => {
    (async () => {
      await downloadFile(url, fileName);
      window.close();
    })();
  }, [fileName, url]);

  return (
    <div className="centered-blur">
      <img src={logoPartials} className="centered-blur-box-image" alt="" />
      <div className="centered-blur-box card justify-content-center">
        <h2>
          {tPublicArea('public_area.loading_file')}
        </h2>
      </div>
    </div>
  );
});