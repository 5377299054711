import noPropertiesScanned from "../../assets/no_properties.svg";
import { memo } from "react";
import { useTranslate } from "@tolgee/react";

export default memo(function FinancialAdvisorListEmpty() {
  const { t: tPrivateAreaProperties } = useTranslate('private_area.properties');

  return (
    <>
      <div className="col-8 col-md-6 col-xl-4 mx-auto mt-7 d-flex align-items-center flex-column">
        <h1 className="font-weight-bold text-center">
        {tPrivateAreaProperties(
            "private_area.properties.empty_list.common.do_you_want_to_see_more_deals",
            "Do you want to see more deals?"
          )}
        </h1>
        <p className="text-center">
          {tPrivateAreaProperties(
            "private_area.properties.empty_list.financial_advisor.talk_to_us_0",
            "Talk to us at"
          )}
          {' '}
          <a href="mailto:test@example.com?subject=Testing out mailto!">
            info@estating.com
          </a>
          {' '}
          {tPrivateAreaProperties(
            "private_area.properties.empty_list.financial_advisor.talk_to_us_1",
            "to verify your account and give you access to more deals."
          )}
        </p>
        <img src={noPropertiesScanned} alt="no properties scanned" />
      </div>
    </>
  );
});
