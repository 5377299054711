import { FC, memo } from "react";

import styles from './style.module.scss';

type Props = {
  title: string;
  icon: JSX.Element
}

export const InvestmentTitle:FC<Props> = memo(({ title, icon }) => {
  return(
    <div className="d-flex align-items-center text-primary">
      <div className={styles.title__icon}>
        {icon}
      </div>
      <span className={styles.title__text}>{title}</span>
    </div>
    )
})

InvestmentTitle.displayName = 'InvestmentTitle'