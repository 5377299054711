import { useBanner } from "hooks/use-banner";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserAPI } from "api/user";
import { UserLocal } from "./types";
import { useTolgee } from "@tolgee/react";
import { WhoAreYou } from "types/user/who-are-you";

interface ISession {
  loading: boolean;
  user?: UserLocal;
  token: string,
  logout: () => void;
  setUser: (user?: UserLocal) => void;
  loadSession: () => void;
  getToken: () => string | null;
  isBannerShow: boolean;
  setIsBannerShow: (value: boolean) =>  void;
  whoAreYou: keyof typeof WhoAreYou
}

const SessionContext = createContext<ISession>({
  loading: true,
  token: '',
  logout: () => {},
  setUser: () => {},
  loadSession: () => {},
  getToken: () => '',
  isBannerShow: false,
  setIsBannerShow: (value: boolean) => {},
  whoAreYou: 'OTHER'
});

const whoAreYouKeyValueSwapped = Object.fromEntries(Object.entries(WhoAreYou).map(([key, value]) => [value, key]));

export function SessionProvider(props: { children: ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserLocal>();
  const [token, setToken] = useState('');
  const { setIsBannerShow, isBannerShow } = useBanner();
  const tolgee = useTolgee();

  const logout = useCallback(async () => {
    UserAPI.logOut();
    setUser(undefined);
    setIsBannerShow(true);
  }, [setIsBannerShow]);

  const getToken = () => {
    return localStorage.getItem("jwtToken");
  }

  const loadSession = useCallback(async () => {
    const token = localStorage.getItem("jwtToken");
    if (!!token) {
      setToken(token);
      try {
        const userInfo = await UserAPI.getUserInfo(token);

        if (userInfo) {
          setUser(userInfo);
          tolgee.changeLanguage(userInfo.lang);
        }

      } catch (e) {
        // TODO: Replace this by proper error tracing
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [tolgee]);

  const updateUser = (user?: UserLocal) => {
    setUser((currentUser) => {
      if (user && !user?.profileCompletness) {
        return {
          ...user,
          profileCompletness: currentUser?.profileCompletness
        }
      } else {
        return user;
      }
    })
  }

  useEffect(() => {
    loadSession();
  }, [loadSession]);

  const whoAreYou = useMemo(() => {
    return user?.whoAreYou ? whoAreYouKeyValueSwapped[user?.whoAreYou] : 'OTHER'
  }, [user]);

  return (
    <SessionContext.Provider
      value={{
        loading,
        user,
        token,
        logout,
        setUser: updateUser,
        loadSession,
        getToken,
        isBannerShow,
        setIsBannerShow,
        whoAreYou: whoAreYou as keyof typeof WhoAreYou
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
