import { FC, memo } from "react";
import { ProgressBar } from "react-bootstrap";

import styles from './style.module.scss';

type Props = {
  progress?: number;
}

export const InvestmentRiskProgressBarWithPercentages:FC<Props> = memo(({ progress = 60}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='mr-2'>
        <ProgressBar now={progress} className={styles['custom-progress-bar']} />
      </div>
      <span className={styles['custom-progress-bar__text']}>{progress}%</span>
    </div>
  )
})

InvestmentRiskProgressBarWithPercentages.displayName = 'InvestmentRiskProgressBarWithPercentages'

