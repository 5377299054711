import { PropertyObj, PropertyTeaserType } from "store/properties/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./base-query";

export const propertyTeaserApi = createApi({
  reducerPath: "propertyTeaserApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Properties', 'Properties_Count', 'Property'],
  endpoints: (builder) => ({
    getPropertyTeasers: builder.query<Array<PropertyObj>, { [key: string]: any }>({
      query: (params) => {
        let url = 'propertyTeaser/user';

        if (params) {
          url += '?';
          Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
              return params[key].forEach((value) =>
                url += `${key}[]=${value}&`
              );
            }

            if (params[key]) {
              url += `${key}=${params[key]}&`
            }
          })
        }

        return { url };
      },
      providesTags: (result) => {
        return (
          result ?
            [
              ...result.map(({ property }) => ({ type: 'Properties', id: property._id } as const)),
              { type: 'Properties', id: 'LIST' }
            ]
          :
            [{ type: 'Properties', id: 'LIST' }]
        );
      }
    }),
    getPropertyById: builder.query<PropertyTeaserType, { id: string, params: string }>({
      query: ({ id, params }) => ({
        method: 'GET',
        url: `propertyTeaser/${id}?${params}`,
      }),
      providesTags: (result) => {
        return (
          result ?
            [
              { 
                type: 'Property',
                id: result._id
              }
            ]
          :
            [{ type: 'Property' }]
        );
      }
    }),
    bookmarkProperty: builder.mutation<PropertyObj, string>({
      query: (id) => {
        return {
          url: `/user/bookmark/${id}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error) => {
        if (!error && result) {
          return [
            { type: 'Properties', id: 'LIST' }, 
            { type: 'Properties_Count', id: 'LIST' },
            { type: 'Property'},
          ];
        }
        return [];
      },
    }),
    getPropertyTeasersCount: builder.query<any, { [key: string]: any }>({
      query: (params) => {
        let url = 'propertyTeaser/count';

        if (params) {
          url += '?';
          Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
              return params[key].forEach((value) =>
                url += `${key}=${value}&`
              );
            }

            if (params[key]) {
              url += `${key}=${params[key]}&`
            }
          })
        }
        return { url };
      },
      providesTags: [{ type: 'Properties_Count', id: 'LIST' }]
    }),
  }),
  refetchOnMountOrArgChange: 5,
});

export const { useGetPropertyTeasersQuery, useGetPropertyTeasersCountQuery, useBookmarkPropertyMutation, useGetPropertyByIdQuery } = propertyTeaserApi;
