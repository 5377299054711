import { memo } from "react";
import { ReactComponent as BookSvg } from "assets/book.svg";
import { useTranslate } from "@tolgee/react";

import style from "./style.module.scss";

export const PropertyEducationAndResearchTitle = memo(() => {
  const { t: tOpportunityDetailPageEducationAndResearch } = useTranslate('opportunity_detail_page.education_and_research');

  return (
    <div className="d-flex align-items-center text-primary">
      <div className={style["education-and-research__icon-container"]}>
        <BookSvg />
      </div>
      <div className={style["education-and-research__text"]}>
        {tOpportunityDetailPageEducationAndResearch('opportunity_detail_page.education_and_research.education_and_research')}
      </div>
    </div>
  );
});
