import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react'
import { Input } from 'new-architecture/ui/input';
import { ReactComponent as SearchSVG } from "assets/search.svg";
import { useTranslate } from '@tolgee/react';

interface SearchInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'className' | 'style'>;
  containerClassName?: string;
}

export const SearchInput: FC<SearchInputProps> = memo(({
  value,
  onChange,
  inputProps,
  containerClassName
}) => {
  const { t } = useTranslate('homepage.search');
  return (
    <Input
      icon={<SearchSVG />}
      placeholder={t('homepage.search.search_input_placeholder')}
      value={value}
      onChange={onChange}
      inputProps={inputProps}
      containerClassName={containerClassName}
    />
  )
});