import { memo, FC, useMemo } from 'react';
import styled from 'styled-components';
import { PropertyTeaserType } from '../../../../store/properties/types';
import { propertyNameColumnWidth } from '../../utils/constants';
import { TimelineEvent } from './timeline-event';

interface RowProps {
  teaser: PropertyTeaserType;
}

export const Row: FC<RowProps> = memo(({ teaser }) => {
  const events = useMemo(() => teaser.timeline.events, [teaser]);

  return (
    <ColumnsContainer>
      <NameColumn>
        {teaser.name}
      </NameColumn>
      {events.map((event, index) => (
        <TimelineEvent
          event={event}
          key={String(event.startDate) + String(event.endDate) + index}
        />
      ))}
    </ColumnsContainer>
  )
})

const NameColumn = styled.div`
  position: sticky;
  left: 0;

  display: inline-block;

  width: ${propertyNameColumnWidth}px;

  padding-left: 20px;

  z-index: 3;
`;

const ColumnsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;