import { memo, FC, useMemo, useCallback, useContext } from "react";
import { EducationMaterial } from "../../types/education-materials/education-material";
import { Card } from "react-bootstrap";
import { EducationMaterialsApi } from "../../api/education-materials";
import { BsDownload, BsShareFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { Tags } from "../tags";
import { useDispatch } from "react-redux";
import { addSearchTag, TagDescription } from "../../store/search";
import { useHistory } from 'react-router-dom';
import backSvg from '../../assets/back.svg';
import { generateThumbnail } from "helpers/thumbnail-generator";
import { MaterialsGroupsContext } from "./education-materials-groups-list";
import { useTranslate } from "@tolgee/react";

interface SuggestedMaterialListItemProps {
  material: EducationMaterial;
  readonly?: boolean;
}

export const SuggestedMaterialListItem: FC<SuggestedMaterialListItemProps> =
  memo(({ material, readonly }) => {
    const { name, description, mimeType, file, customThumbnail, originalName, tags } = material;
    const dispatch = useDispatch();
    const history = useHistory();
    const addTag = useCallback((tag: TagDescription) => {
      dispatch(addSearchTag(tag));
    }, [dispatch]);

    const mediaContentDisplayer = useMemo(() => {
      if (mimeType.startsWith("video")) {
        return (
          <video
            style={{ pointerEvents: "none", objectFit: 'cover' }}
            width={300}
            height={120}
            className={`slider-image border border-2 `}
            muted
          >
            <source src={file} type={mimeType} />
          </video>
        );
      }

      const generatedThumbnail = customThumbnail || generateThumbnail(mimeType, file);

      if (generatedThumbnail) {
        return (
          <img
            crossOrigin="anonymous"
            style={{ height: 120, width: 'auto', }}
            src={generatedThumbnail}
            alt="Document thumbnail"
          />
        );
      }

      return (
        <div style={{ height: 120, width: 'auto', objectFit: "cover" }}>
          <BsFillFileEarmarkFill style={{ width: "100%", height: "100%" }} />
        </div>
      );
    }, [mimeType, customThumbnail, file]);

    const onDownloadMaterial = useCallback(async () => {
      try {
        await EducationMaterialsApi.downloadMaterial(file, originalName);
      } catch (err) {
        console.error(err);
      }
    }, [file, originalName]);

    const { setSharedMaterial, setPreviewedMaterial } = useContext(MaterialsGroupsContext);

    const onPreviewClick = useCallback(() => {
      if (mimeType === "application/pdf" || mimeType.startsWith("video") || mimeType.startsWith('image') ) {
        setPreviewedMaterial(material);
      }
    }, [material, mimeType, setPreviewedMaterial]);

    const tagsDescriptions = useMemo(() => tags.map((tag) => ({ label: tag, value: tag })),[tags]);

    const goToOpportunitiesWithTags = useCallback(() => {
      history.push("/opportunities");
      dispatch(addSearchTag(tagsDescriptions));
    },[tagsDescriptions, dispatch, history]);

    const { t: tEstatingAcademyContent } = useTranslate('estating_academy.content');

    return (
      <Card
        className="d-flex flex-column position-relative h-100 mx-1 overflow-hidden"
      >
        <div 
          role="button" 
          onClick={onPreviewClick}
          className="d-flex align-items-center justify-content-center overflow-hidden"
        >
          {mediaContentDisplayer}
        </div>
        <Card.Body className="d-flex flex-column flex-grow-1 overflow-auto">
          <div className="flex-grow-1 d-flex flex-column justify-content-between">
            <div>
              <h3>{name}</h3>
              <Card.Text className="education-material-description">
                {description}
              </Card.Text>

              {!readonly &&
                <div>
                  {tags &&
                    <Tags tags={tagsDescriptions} onClick={addTag} />
                  }
                </div>
              }
            </div>
            {!readonly && 
              <div
              className="cursor-pointer hover-opacity d-flex align-items-center justify-content-start w-100"
              onClick={goToOpportunitiesWithTags}
              >
                <span className="mr-2">
                  {tEstatingAcademyContent('estating_academy.content.related_opportunities')}
                </span>
                <img style={{transform: 'rotateY(180deg)'}}src={backSvg} alt="back icon" />
              </div>
            }
          </div>
        </Card.Body>
        {!readonly &&
          <div className="position-absolute w-100 d-flex justify-content-between" style={{ pointerEvents: 'none'}}>
            <button
              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
              style={{ width: "30px", height: "30px", zIndex: 1, pointerEvents: 'all' }}
              onClick={onDownloadMaterial}
            >
              <BsDownload />
            </button>
            <button
              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
              style={{ width: "30px", height: "30px", zIndex: 1, pointerEvents: 'all' }}
              onClick={() => setSharedMaterial(material)}
            >
              <BsShareFill />
            </button>
          </div>
        }
      </Card>
    );
  });
