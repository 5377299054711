const webVideoFormats = [
  "mp4",
  "webm",
  "ogg",
  "avi",
  "wmv",
  "flv",
  "mkv",
  "mov",
  "3gp",
  "mpeg",
  "m4v",
  "h.264",
  "h.265 (hevc)",
  "vp9",
];

export const isVideo = (param: string) => {
  const index = param.lastIndexOf(".");
  const format = param.slice(index + 1);
  
  return webVideoFormats.includes(format);
};