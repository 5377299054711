import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface TagDescription {
  label: string;
  value: string;
}
export interface SearchState {
  searchTags: Array<TagDescription>;
}

const initialState: SearchState = {
  searchTags: [],
};

export const searchSlice = createSlice({
  name: 'search-slice',
  initialState,
  reducers: {
    addSearchTag(state, action: PayloadAction<TagDescription | Array<TagDescription>>) {
      const { searchTags } = state;
      const { payload } = action;

      if (Array.isArray(payload)) {
        const newValue = JSON.parse(JSON.stringify([...searchTags])) as Array<TagDescription>;

        payload.forEach((tagObject) => {
          if (newValue.some(({ label, value }) => label === tagObject.label || value === tagObject.value)) {
            return;
          }

          newValue.push(tagObject);
        })

        state.searchTags = newValue;

        return;
      }

      if (searchTags.some(({ label, value }) => label === payload.label || value === payload.value)) {
        return;
      }
  
      state.searchTags = [...searchTags, payload];
    },
    removeSearchTag(state, action: PayloadAction<TagDescription>) {
      const { searchTags } = state;
      const { payload } = action;
  
      state.searchTags = searchTags.filter(tagObject => payload.label !== tagObject.label && payload.value !== tagObject.value);
    },
    removeAllSearchTags(state) {
      state.searchTags= [];
    }
  }
})

export const { addSearchTag, removeAllSearchTags, removeSearchTag } = searchSlice.actions;