import { FC, memo, useMemo } from "react";
import { ReactComponent as CloseButtonSvg } from "assets/ic-round-close-white.svg";
import background from "assets/subtract.svg";
import { SummaryCardBannerList } from "../summary-card-banner-list";
import { SummaryCardBannerText } from "../summary-card-banner-text";
import { useTranslate } from "@tolgee/react";

import styles from "./style.module.scss";

interface  SummaryCardBannerProps {
  onClose: () => void;
  onDismiss: () => void;
};

export const SummaryCardBanner: FC<SummaryCardBannerProps> = memo(({ 
  onClose, 
  onDismiss 
  }) => {
    const { t: tHomepageWelcomeMessage } = useTranslate('homepage.welcome_message');

    const cardText = useMemo(() => [
      {
        content: tHomepageWelcomeMessage('homepage.welcome_message.intro_text1'),
      },
      {
        content: tHomepageWelcomeMessage('homepage.welcome_message.intro_text2'),
      },
    ], [tHomepageWelcomeMessage]);

    const cardList = useMemo(() => [
      {
        content: tHomepageWelcomeMessage('homepage.welcome_message.learn_more1'),
        link: tHomepageWelcomeMessage('homepage.welcome_message.estating_academy'),
        to: "/estating-academy",
      },
      {
        content: tHomepageWelcomeMessage('homepage.welcome_message.learn_more2'),
        link: tHomepageWelcomeMessage('homepage.welcome_message.sign_up_for_newsletter'),
        to: "https://share.hsforms.com/1B-dJO9-HRuqnffZ-O02iqA4utcm",
        target: "_blank"
      },
      {
        content: tHomepageWelcomeMessage('homepage.welcome_message.learn_more3'),
        link: tHomepageWelcomeMessage('homepage.welcome_message.contact_us'),
        to: "https://www.estating.com/contact-us",
        target: "_blank"
      },
    ], [tHomepageWelcomeMessage]);

    return (
      <div
        className={styles.card}
      >
        <div className={styles['card__background-container']}>
          <img 
            src={background}
            alt="background"
          />
        </div>
        
        <div className={styles['card__close-button']} onClick={onClose}>
          <CloseButtonSvg />
        </div>

        <div className={styles['card__content']}>
          <div className={styles['card__title']}>
            {tHomepageWelcomeMessage('homepage.welcome_message.welcome_to_estating_catalyst')}
          </div>

          <SummaryCardBannerText cardText={cardText}/>

          <div>
            <div className={styles['card__sub-title']}>
              {tHomepageWelcomeMessage('homepage.welcome_message.learn_more')}
            </div>
            <div className="mt-2">
              <SummaryCardBannerList cardList={cardList}/>
            </div>
          </div>
        </div>

        <div
          className={styles['card__dismiss_button']}
          onClick={onDismiss}
        >
          {tHomepageWelcomeMessage('homepage.welcome_message.button.dismiss')}
        </div>
      </div>
    );
  }
);
