import html2canvas from 'html2canvas';
import { useState } from 'react';

type useScreenshotHookType = (options: { mimeType: string, quality: number }) => [string | null, (node: any) => Promise<string>];

export const useScreenshot: useScreenshotHookType = (options) => {
  const { mimeType, quality } = options;

  const [screenShoot, setScreenShoot] = useState<string | null>(null);

  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = async (node) => {
    if (!node) {
      throw new Error('You should provide correct html node.');
    }

    const canvas = await html2canvas(node, { scale: 1.5 });

    const croppedCanvas = document.createElement('canvas');
    const croppedCanvasContext = croppedCanvas.getContext('2d');

    if (!croppedCanvasContext) {
      throw new Error('Context not created');
    }

    // init data
    const cropPositionTop = 0;
    const cropPositionLeft = 0;
    const cropWidth = canvas.width;
    const cropHeight = canvas.height;

    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;

    croppedCanvasContext.drawImage(
      canvas,
      cropPositionLeft,
      cropPositionTop,
    );

    const base64Image = croppedCanvas.toDataURL(mimeType, quality);

    setScreenShoot(base64Image);

    return base64Image;
  };

  return [
    screenShoot,
    takeScreenShot
  ];
}