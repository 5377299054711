import { FC, memo, useCallback } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { NavItem } from "../../types/nav-item";

import styles from './style.module.scss';

interface NavItemsProps {
  navInfo: Array<NavItem>;
}

const navStyle = classNames("d-none d-sm-flex justify-content-sm-between justify-content-md-start col-sm-8 col-xl-6 flex-nowrap p-0", styles.grid_gap)
export const NavItems: FC<NavItemsProps> = memo(({ navInfo }) => {
  const { pathname } = useLocation();

  const scrollTop = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 1);
  }, []);

  return (
    <Nav className={navStyle}>
      {navInfo.map(({ name, icon, route }) => {
        const textStyle = classNames("text-gray", styles.text, {[styles.active]: pathname === route});

        return (
          <Nav.Item role="button" key={route} className={textStyle}>
            <Link
              to={route}
              className="d-flex align-items-center"
              onClick={scrollTop}
            >
              {icon}
              <span
                className="text-gray text-nowrap"
                style={{ marginLeft: "6px", fontSize: "16px" }}
              >
                {name}
              </span>
            </Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
});

NavItems.displayName = 'NavItems'
