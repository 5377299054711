import { useTranslate } from '@tolgee/react';
import { ReactComponent as SecuritySvg } from 'assets/security.svg';
import { memo } from 'react';

import style from './style.module.scss';

export const PropertySecutityInfoTitle = memo(() => {
  const { t: tOpportunityDetailPageSecurityInformation } = useTranslate('opportunity_detail_page.security_information');

  return (
    <div className="d-flex align-items-center text-primary">
      <div className={style["security-info__icon-container"]}>
        <SecuritySvg />
      </div>
      <div className={style["security-info__text"]}>
        {tOpportunityDetailPageSecurityInformation('opportunity_detail_page.security_information.title')}
      </div>
    </div>
  );
})
