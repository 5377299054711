import React, { useMemo } from 'react'
import PersonalDetails from "new-architecture/pages/sign-up/components/personal-details";
import Select from "../../components/Select";
import Input from "../../components/Input";
import { COUNTRIES } from "helpers/constants";
import { useTranslate } from '@tolgee/react';
import { WhoAreYou } from 'types/user/who-are-you';

interface ChangeAccountInfoProps {
  firstName: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  lastName: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  email: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  phoneNumber: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  whoAreYou: {
    value: string,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  country: {
    selected: string,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any
    inputProps?: React.InputHTMLAttributes<any>,
  },
  financialAdvisor?: {
    email: string
  }
}


const ChangeAccountInfo: React.FC<ChangeAccountInfoProps> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  whoAreYou,
  country,
  financialAdvisor,
}) => {
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');
  const { t: tEnums } = useTranslate('enums');

  const whoAreYouEnumKey = useMemo(() => {
    const entries = Object.entries(WhoAreYou);
    const targetValue = entries.find(([, value]) => value === whoAreYou.value)
    return targetValue && targetValue[0];
  }, [whoAreYou.value]);

  return (
    <>
      <PersonalDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
      />
      <Select
        label={tPrivateAreaMyAccount('private_area.my_account.form.country_of_residence')}
        selected={country.selected}
        onChange={country.onChange}
        values={COUNTRIES.map(countryObj => countryObj.name)}
        containerClasses="mb-4"
      />
      {
        financialAdvisor &&
        <Input
          label='Contact details financial advisor*'
          inputAttributes={{
            placeholder: financialAdvisor.email,
            readOnly: true,
            disabled: true
          }}
        />
      }
      <Input
        label={tPrivateAreaMyAccount('private_area.my_account.form.who_are_you')}
        inputAttributes={{
          disabled: true,
          readOnly: true,
          placeholder: whoAreYouEnumKey ? tEnums(`enums.who_are_you.${whoAreYouEnumKey}`) : whoAreYou.value
        }}
      />
      <p>
        {tPrivateAreaMyAccount('private_area.my_account.please_contatc_us1')}
        {' '}
        <a href='mailto:info@estating.com'>info@estating.com</a>
        {' '}
        {tPrivateAreaMyAccount('private_area.my_account.please_contatc_us2')}
      </p>
    </>
  );
}

export default ChangeAccountInfo
