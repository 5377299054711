import ReactDOM from "react-dom";
import { App } from "./app";
import reportWebVitals from "./report-web-vitals";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history-entity";
import { SessionProvider } from "./context/session";
import { persistor, store } from "./store";
import { PersistGate } from 'redux-persist/integration/react';
import { Tolgee, TolgeeProvider, DevTools, FormatSimple, LanguageDetector, LanguageStorage, BackendFetch, TolgeeInstance } from '@tolgee/react';
import { getTolgeeApiKey, getTolgeeApiURL, getTolgeeTranslationsCdnUrl } from "./app-config";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-carousel/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./app.scss";
import "./scss/custom.css";
import "bootstrap";

let tolgee = Tolgee();
let tolgeeInstance: TolgeeInstance;

if (process.env.NODE_ENV === 'development') {
  tolgee = tolgee.use(DevTools())
}
  
  tolgee = tolgee
  .use(LanguageDetector())
  .use(LanguageStorage())
  .use(FormatSimple());

  if (process.env.NODE_ENV === 'production') {
    tolgeeInstance = tolgee
    .use(BackendFetch({ prefix: getTolgeeTranslationsCdnUrl() }))
    .init({
      fallbackLanguage: 'en',
      defaultLanguage: 'en',
      availableLanguages: ['en', 'es'],
    });
  } else {
    tolgeeInstance = tolgee
    .init({
      fallbackLanguage: 'en',
      defaultLanguage: 'en',
      availableLanguages: ['en', 'es'],

      // for development
      apiUrl: getTolgeeApiURL(),
      apiKey: getTolgeeApiKey(),
    });
  }

ReactDOM.render(
  <TolgeeProvider
    tolgee={tolgeeInstance}
    options={{
      useSuspense: false,
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <SessionProvider>
            <App />
          </SessionProvider>
        </Router>
      </PersistGate>
    </Provider>
  </TolgeeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
