import { Dayjs } from 'dayjs';
import { Popover } from 'react-bootstrap'

interface EventDescriptionPopoverProps {
  eventName: string;
  startDate: Dayjs;
  endDate: Dayjs;
}

export const eventDescriptionPopover = ({ 
  eventName,
  startDate,
  endDate
}: EventDescriptionPopoverProps) => {
  return (
    <Popover id="event-description-popover">
      <Popover.Title as="h3">{eventName}</Popover.Title>
      <Popover.Content>
        {startDate.format('DD/MM/YYYY')} - {endDate.format('DD/MM/YYYY')}
      </Popover.Content>
    </Popover>
  )
};