import { useTranslate } from '@tolgee/react';
import React from 'react';

interface PasswordValidProps {
  password: string;
  confirmPassword?: string,
  minLength: number;
  upperCase?: boolean;
  symbols?: boolean;
  numbers?: boolean;
  lowerCase?: boolean;
  callback: (isValid: boolean) => void;
}

type CasesToBeValidate = {
  minLength?: boolean,
  lowerCase?: boolean,
  upperCase?: boolean,
  symbols?: boolean,
  numbers?: boolean
}

const CustomPasswordValidator: React.FC<PasswordValidProps> = ({
  callback,
  password,
  minLength = 8,
  upperCase = false,
  symbols = false,
  numbers = false,
  lowerCase = true,
  confirmPassword
}) => {
  const [validCases, setValidCases] = React.useState<CasesToBeValidate | null>(null);
  const { t: tPublicArea } = useTranslate('public_area');

  const _validatePassword = React.useCallback(
    (password: string) => {
      const cases: CasesToBeValidate = {};

      // Validate lower letters
      if (lowerCase) {
        const lowerCaseLetters = /[a-z]/g;
        cases.lowerCase = !!password.match(lowerCaseLetters);
      }

      if (upperCase) {
        // Validate capital letters
        const upperCaseLetters = /[A-Z]/g;
        cases.upperCase = !!password.match(upperCaseLetters);
      }

      if (numbers) {
        // Validate numbers
        const numbers = /[0-9]/g;
        cases.numbers = !!password.match(numbers);
      }

      if (symbols) {
        // Validate symbols
        // old ->  /[-!$%^&*()_+|#@~=`{}\[\]:";'<>?,.\/]/
        const symbols = /[-!$%^&*()_+|#@~=`{}\\[\]:";'<>?,.\\/]/;
        cases.symbols = !!password.match(symbols);
      }

      if (minLength) {
        // Validate length
        cases.minLength = password.length >= minLength;
      }

      setValidCases(cases);

      // Has not valid case
      return !Object.values(cases).some((caseBool) => !caseBool);
    },
    [minLength, numbers, symbols, upperCase, lowerCase]
  )

  React.useEffect(() => {
    let isValid = false
    if (!!password) {
      isValid = _validatePassword(password);
    }
    callback(isValid);
  }, [password, _validatePassword, callback]);


  if (!validCases) {
    return null;
  }

  return (
    <div className='my-4'>
      <div className='d-flex align-items-center'>
        {validCases.minLength ? (
          <i className='fe fe-check text-success  font-weight-bold mr-2'></i>
        ) : (
          <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
        )}
        <p className={`mb-0 ${validCases.minLength ? 'text-success' : 'text-danger'}`}>
          {`${minLength} ${tPublicArea('public_area.or_more_characters')}`}
        </p>
      </div>

      {lowerCase && (
        <div className='d-flex align-items-center'>
          {validCases.lowerCase ? (
            <i className='fe fe-check text-success font-weight-bold mr-2'></i>
          ) : (
            <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
          )}
          <p className={`mb-0 ${validCases.lowerCase ? 'text-success' : 'text-danger'}`}>
            {tPublicArea('public_area.mix_of_items')}
          </p>
        </div>
      )}

      {upperCase && (
        <div className='d-flex align-items-center'>
          {validCases.upperCase ? (
            <i className='fe fe-check text-success font-weight-bold mr-2'></i>
          ) : (
            <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
          )}
          <p className={`mb-0 ${validCases.upperCase ? 'text-success' : 'text-danger'}`}>
            {tPublicArea('public_area.capital_letters')}
          </p>
        </div>
      )}

      {numbers && (
        <div className='d-flex align-items-center'>
          {validCases.numbers ? (
            <i className='fe fe-check text-success font-weight-bold mr-2'></i>
          ) : (
            <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
          )}
          <p className={`mb-0 ${validCases.numbers ? 'text-success' : 'text-danger'}`}>
            {tPublicArea('public_area.numbers')}
          </p>
        </div>
      )}

      {symbols && (
        <div className='d-flex align-items-center'>
          {validCases.symbols ? (
            <i className='fe fe-check text-success font-weight-bold mr-2'></i>
          ) : (
            <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
          )}
          <p className={`mb-0 ${validCases.symbols ? 'text-success' : 'text-danger'}`}>
            {tPublicArea('public_area.symbols')}
          </p>
        </div>
      )}

      {confirmPassword && (
        <div className='d-flex align-items-center'>
          {password === confirmPassword ? (
            <i className='fe fe-check text-success font-weight-bold mr-2'></i>
          ) : (
            <i className='fe fe-x text-danger  font-weight-bold mr-2'></i>
          )}
          <p className={`mb-0 ${password === confirmPassword ? 'text-success' : 'text-danger'}`}>
            {password === confirmPassword ?
              tPublicArea('public_area.password_confirmed')
              :
              tPublicArea('public_area.password_unconfirmed')
            }
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomPasswordValidator;
