import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import logo from "assets/catalyst-logo.png";
import CircleFilledIcon from "assets/circle-filled.svg";
import CircleDisabledIcon from "assets/circle_disable.svg";
import CircleActiveIcon from "assets/circle_active.svg";
import ConfirmationEmailPng from "assets/confirm_email.png";

import Button from "components/Button";

import {
  HaveFinancialAdvisorEnum,
  HearAboutFromEnum,
  User,
} from "context/types";
import PersonalDetails from "./personal-details";
import SetPassword from "./set-password";
import SelectCountry from "./select-country";
import CustomSelect from "components/CustomSelect";
import WhoAreYouComponent from "./who-are-you";
import HearAboutFrom from "./hear-about-from";
import HaveFinancialAdvisor from "./have-financial-advisor";
import { _validateEmail } from "helpers/helpers";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { UserAPI } from "api/user";
import queryString from "querystring-es3";
import CustomPasswordValidator from "components/CustomPasswordValidor";
import SocialLogin from "components/SocialLogin";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { getGoogleRecaptchaSiteKey } from "app-config";
import { useSession } from "context/session";
import { WhoAreYou } from "types/user/who-are-you";
import { LanguageSelector } from "new-architecture/components/language-selector";
import { useTolgee, useTranslate } from "@tolgee/react";
import { spanishLanguageConuntries } from "../constants/spanish-language-countries";

interface UserLocalState extends User {
  confirmPassword: string;
  fromSocial?: boolean;
}

type SignUpSteps =
  | "account_details"
  | "set_password"
  | "select_country"
  | "who_are_you"
  | "hear_about_from"
  | "complete_profile";
const SIGN_UP_STEPS = [
  "account_details",
  "set_password",
  "select_country",
  "who_are_you",
  "hear_about_from",
  "complete_profile",
];

const intervalFunctions: { [id: string]: any } = {};

export const SignUp: FC<{}> = memo(() => {
  const params = useParams<{ token?: string }>();
  const { token } = params;
  const location = useLocation();
  const tolgee = useTolgee();

  const [step, setStep] = useState<SignUpSteps>("account_details");
  const [skippedSteps, setSkippedSteps] = useState<Partial<SignUpSteps>[]>([]);
  const [availableSteps, setAvailableSteps] = useState<{
    [key: string]: boolean;
  }>({});
  const history = useHistory();
  const [userOptions, setUserOptions] = useState<UserLocalState>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    whoAreYou: "",
    country: "",
    hearAboutFrom: "",
    confirmPassword: "",
    fromSocial: false,
  });
  const [financialAdvisor, setFinancialAdvisor] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTermCountry, setSearchTermCountry] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isInvested, setIsInvested] = useState(false);
  const [isUserExists, setIsUserExists] = useState<boolean | null>(null);
  const [userToSignUpId, setUserToSignUpId] = useState("");
  const [resendEmailMessage, setResendEmailMessage] = useState({
    message: "",
    status: "",
  });
  const [resendEmailMessageLoading, setResendEmailMessageLoading] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const { user, setUser } = useSession();

  const predefinedUserToken =
    queryString.parse(location.search || "")["?authToken"] || null;
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (predefinedUserToken && typeof predefinedUserToken === "string") {
      UserAPI.getPredefinedAccount(predefinedUserToken)
        .then((userPredefinedOptions) => {
          setFinancialAdvisor(userPredefinedOptions);
          setUserOptions((userOptions) => {
            return {
              ...userOptions,
              whoAreYou: WhoAreYou.INVESTOR,
              haveFinancialAdvisor: HaveFinancialAdvisorEnum.YES,
              hearAboutFrom: HearAboutFromEnum.FinancialAdvisor,
            };
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [location, predefinedUserToken]);

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.user) {
      // @ts-ignore
      const user = location?.state?.user || {};
      setUserOptions((userOptions) => {
        return { ...userOptions, ...user };
      });
      if (user.fromSocial) {
        setSkippedSteps(["account_details", "set_password"]);
        setStep("who_are_you");
      }
    }
  }, [location]);

  useEffect(() => {
    if (token) {
      UserAPI.getUserInfo(token).then((user) => {
        if (user) {
          setIsInvested(true);
          // @ts-ignore
          setUserOptions((userOptions) => {
            return { ...userOptions, ...user };
          });
        }
      });
    }
  }, [token]);

  const _toggleSteps = (
    e: React.MouseEvent<HTMLAnchorElement>,
    stepNumber: SignUpSteps
  ) => {
    e.preventDefault();
    if (availableSteps[stepNumber]) {
      setStep(stepNumber);
    }
  };

  const _isCorrectPerStep = useCallback(
    (forStep?: SignUpSteps | string) => {
      const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        hearAboutFrom,
        country,
        whoAreYou,
        fromSocial,
      } = userOptions;

      let isValid = false;
      const checkStep = forStep || step;

      switch (checkStep) {
        case "account_details":
          isValid =
            !!firstName &&
            !!lastName &&
            _validateEmail(email) &&
            !isUserExists &&
            !fromSocial;
          break;
        case "set_password":
          isValid =
            isPasswordValid && password === confirmPassword && !fromSocial;
          break;
        case "select_country":
          isValid = !!country;
          break;
        case "who_are_you":
          if (
            userOptions.haveFinancialAdvisor === HaveFinancialAdvisorEnum.YES
          ) {
            const { lastName, firstName, email } = financialAdvisor;
            isValid = !!lastName && !!firstName && _validateEmail(email);
          } else {
            isValid = !!whoAreYou;
          }
          break;
        case "hear_about_from":
          isValid = !!hearAboutFrom;
          break;
      }

      return isValid;
    },
    [step, userOptions, financialAdvisor, isPasswordValid, isUserExists]
  );

  const onChange = (value: any, args: { key: keyof UserLocalState }) => {
    if(value === WhoAreYou.FINANCIAL_ADVISOR){
      setStep("hear_about_from")
    }

    const { key } = args;
    setUserOptions((userOptions) => {
      if (key === "email" && _validateEmail(value)) {
        userOptions[key] = value;
      } else {
        // @ts-ignore
        userOptions[key] = value;
      }

      return { ...userOptions };
    });
  };

  useEffect(() => {
    UserAPI.isUserExists(userOptions.email).then((isUserExists) => {
      setIsUserExists(() => isUserExists);
    });
  }, [userOptions.email]);

  useEffect(() => {
    if (isVerified) {
      setResendEmailMessage({
        status: "",
        message: "",
      });
      clearInterval(intervalFunctions["check-user-active"]);
    }

    return () => {
      clearInterval(intervalFunctions["check-user-active"]);
    };
  }, [isVerified]);

  const createAccount = () => {
    const recaptchaToken = recaptchaRef?.current?.getValue();
    if (!recaptchaToken) {
      alert("Please verify you're not a robot");
      return;
    }

    setLoading(true);

    if (isInvested) {
      const { confirmPassword, financialAdvisor, ...rawUser } = userOptions;
      UserAPI.changeUser({ ...user, ...rawUser })
        .then((changedUser) => {
          setUser(changedUser);
          setLoading(false);
          setStep("complete_profile");
          setErrorMessage("");
        })
        .catch((e) => {
          setStep("account_details");
          setLoading(false);
          setErrorMessage(e.message);
        });
    } else {
      UserAPI.signUp(
        userOptions,
        financialAdvisor,
        recaptchaToken,
        predefinedUserToken && typeof predefinedUserToken === "string"
          ? predefinedUserToken
          : null
      )
        .then(({ user }) => {
          setUserToSignUpId(user);
          setStep("complete_profile");
          setErrorMessage("");
          setLoading(false);
          intervalFunctions["check-user-active"] = setInterval(() => {
            UserAPI.isUserEmailVerified(user).then(({ isVerified }) => {
              setIsVerified(isVerified);
            });
          }, 3000);
        })
        .catch((e) => {
          setStep("account_details");
          setLoading(false);
          setErrorMessage(e.message);
        })
        .finally(() => {
          localStorage.removeItem("jwtToken");
        });
    }
  };

  const isCorrectPerStep = _isCorrectPerStep();

  useEffect(() => {
    setAvailableSteps((availableSteps) => {
      const availableStepsCopy = { ...availableSteps };
      SIGN_UP_STEPS.forEach((step) => {
        availableStepsCopy[step] = _isCorrectPerStep(step);
      });
      return availableStepsCopy;
    });
  }, [step, userOptions, _isCorrectPerStep]);

  const _getIcon = (stepLabel: SignUpSteps) => {
    if (stepLabel === step) {
      return <img src={CircleActiveIcon} className="mr-2" alt="" />;
    }
    if (availableSteps[stepLabel] && step !== stepLabel) {
      return <img src={CircleFilledIcon} className="mr-2" alt="" />;
    }
    if (!availableSteps[stepLabel]) {
      return <img src={CircleDisabledIcon} className="mr-2" alt="" />;
    }
  };

  const _checkEmailInputClass = () => {
    const { email } = userOptions;

    if (!_validateEmail(email)) {
      return "";
    }
    if (isUserExists === true) {
      return "is-invalid";
    }
    if (isUserExists === false) {
      return "is-valid";
    }
  };

  const resendVerificationEmail = () => {
    setResendEmailMessage({
      status: "",
      message: "",
    });
    if (userToSignUpId) {
      setResendEmailMessageLoading(true);
      UserAPI.resendVerificationEmail(userToSignUpId)
        .then(({ message, status }) => {
          setResendEmailMessage({
            message,
            status,
          });
        })
        .catch((e) => {
          setResendEmailMessage({
            message: "Fail to send verification email",
            status: "error",
          });
        })
        .finally(() => {
          setResendEmailMessageLoading(false);
        });
    }
  };
  const [, setReRenderTrigger] = useState(false);
  const onLanguageChange = useCallback(async (newLanguage: string) => {
    tolgee.changeLanguage(newLanguage);
    // small trick to trigger re-render
    setReRenderTrigger((prev) => !prev);
  }, [tolgee]);

  const { t: tPublicArea } = useTranslate('public_area');
  const { t: tEnum } = useTranslate('enums');

  const { associativeMap, optionsHaveFinancialAdvisor } = useMemo(() => {
    const labels = Object.keys(HaveFinancialAdvisorEnum);

    const associativeMap: {[key: string]: string} = {};

    labels.forEach((key) => {
      associativeMap[tEnum(`enums.have_financial_advisor.${key}`)] = HaveFinancialAdvisorEnum[key];
    });

    return {associativeMap, optionsHaveFinancialAdvisor: Object.keys(associativeMap)};
  }, [tEnum]);

  const onSelectHaveFinancialAdvisor = useCallback((_value: number | string) => {
    const value = associativeMap[_value]

    onChange(value, { key: "haveFinancialAdvisor" });
    if (value === HaveFinancialAdvisorEnum.NO) {
      if (userOptions.fromSocial) {
        setStep("select_country");
      } else {
        setStep("hear_about_from");
      }
    }
  }, [associativeMap, userOptions.fromSocial]);

  const [, setTriggerLangReRender] = useState(false);

  return (
    <div className="sign-up">
      <div className="navbar navbar-expand-lg navbar-light header-shadow px-6 flex-wrap">
        <Link to={"/"} className="logo">
          <img src={logo} height={40} alt="Estating logo" />
        </Link>
        <ul className="navbar-nav align-items-start mx-auto">
          {!skippedSteps.includes("account_details") && (
            <li className={`${step === "account_details" ? "active" : ""}`}>
              <a
                className={`nav-link text-uppercase ${
                  !availableSteps["account_details"] ? "disabled" : ""
                }`}
                onClick={(e) => _toggleSteps(e, "account_details")}
                href="!#"
              >
                {_getIcon("account_details")}
                {tPublicArea('public_area.personal_details')}
              </a>
            </li>
          )}
          {!skippedSteps.includes("set_password") && (
            <li className={`${step === "set_password" ? "active" : ""}`}>
              <a
                className={`nav-link text-uppercase ${
                  !availableSteps["set_password"] ? "disabled" : ""
                }`}
                onClick={(e) => _toggleSteps(e, "set_password")}
                href="!#"
              >
                {_getIcon("set_password")}
                {tPublicArea('public_area.password')}
              </a>
            </li>
          )}
          {userOptions.fromSocial ? (
            <>
              <li className={`${step === "who_are_you" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["who_are_you"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "who_are_you")}
                  href="!#"
                >
                  {_getIcon("who_are_you")}
                  {tPublicArea('public_area.who_are_you')}
                </a>
              </li>
              <li className={`${step === "select_country" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["select_country"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "select_country")}
                  href="!#"
                >
                  {_getIcon("select_country")}
                  {tPublicArea('public_area.country')}
                </a>
              </li>
              <li className={`${step === "complete_profile" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["complete_profile"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "complete_profile")}
                  href="!#"
                >
                  {_getIcon("complete_profile")}
                  {tPublicArea('public_area.complete_sign_ap')}
                </a>
              </li>
            </>
          ) : (
            <>
              <li className={`${step === "select_country" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["select_country"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "select_country")}
                  href="!#"
                >
                  {_getIcon("select_country")}
                  {tPublicArea('public_area.country')}
                </a>
              </li>
              <li className={`${step === "who_are_you" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["who_are_you"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "who_are_you")}
                  href="!#"
                >
                  {_getIcon("who_are_you")}
                  {tPublicArea('public_area.who_are_you')}
                </a>
              </li>
              <li className={`${step === "complete_profile" ? "active" : ""}`}>
                <a
                  className={`nav-link text-uppercase ${
                    !availableSteps["complete_profile"] ? "disabled" : ""
                  }`}
                  onClick={(e) => _toggleSteps(e, "complete_profile")}
                  href="!#"
                >
                  {_getIcon("complete_profile")}
                  {tPublicArea('public_area.complete_sign_up')}
                </a>
              </li>
            </>
          )}
        </ul>
        <LanguageSelector
          availableLanguages={[{ value: 'en', label: 'EN'}, { value: 'es', label: 'ES' }]}
          onLanguageSelect={onLanguageChange}
          selectedLanguage={tolgee.getPendingLanguage()}
        />
      </div>

      {loading ? (
        <div className="d-flex justify-content-center mt-8 align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="w-75 mx-auto mt-7">
          {step === "account_details" &&
            !skippedSteps.includes("account_details") && (
              <>
                <h1 className="font-weight-bold col">
                  {tPublicArea('public_area.sign_up')}
                </h1>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-4 offset-lg-0 mb-4">
                    {!!errorMessage && (
                      <div>
                        <div className="alert alert-danger">
                          <p>{errorMessage}</p>
                        </div>
                      </div>
                    )}
                    <p>
                      {tPublicArea('public_area.create_an_account')}
                    </p>
                    <PersonalDetails
                      firstName={{
                        value: userOptions.firstName,
                        callback: (value) =>
                          onChange(value, { key: "firstName" }),
                      }}
                      lastName={{
                        value: userOptions.lastName,
                        callback: (value) =>
                          onChange(value, { key: "lastName" }),
                      }}
                      email={{
                        value: userOptions.email,
                        inputProps: {
                          validator: (value) => validator.isEmail(value),
                          invalidFeedback: isUserExists
                            ? "This email address is already in use."
                            : "",
                        },
                        className: _checkEmailInputClass(),
                        callback: (value) => onChange(value, { key: "email" }),
                      }}
                      phoneNumber={{
                        value: userOptions.phoneNumber || "",
                        callback: (value) =>
                          onChange(value, { key: "phoneNumber" }),
                      }}
                    />
                    <Button
                      label={tPublicArea('public_area.next')}
                      buttonAttributes={{
                        disabled: !isCorrectPerStep,
                      }}
                      onClick={() => setStep("set_password")}
                      className="btn-block mt-4"
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-4 offset-lg-0 mb-4">
                    <p className="text-center">
                      {tPublicArea('public_area.or_sign_up_with_social')}
                    </p>
                    <SocialLogin
                      success={({ userOptions }) => {
                        setUser(userOptions);

                        const sessionLastPageSeen = localStorage.getItem(
                          "sessionLastPageSeen"
                        );

                        if (!!sessionLastPageSeen) {
                          const pageToRedirect = sessionLastPageSeen.replace(
                            window.location.origin,
                            ""
                          );
                          localStorage.removeItem("sessionLastPageSeen");
                          history.push(pageToRedirect);
                        } else {
                          history.push("/opportunities");
                        }
                        const lastSeenPage = localStorage.getItem("lastSeen");
                        if (!!lastSeenPage) {
                          window.location.href = lastSeenPage;
                        }
                      }}
                      error={(e) => setErrorMessage(e.message)}
                      showTerms={true}
                    />
                  </div>
                </div>
                <p className="text-center mt-5 pb-4">
                  {tPublicArea('public_area.already_have_account')}
                  <Link to="/" className="ml-3 alert-link">
                    {tPublicArea('public_area.login')}
                  </Link>
                </p>
              </>
            )}
          {step === "set_password" &&
            !skippedSteps.includes("set_password") && (
              <div
                className="col-12 col-sm-8 col-md-6 col-xl-4 offset-lg-0 m-auto"
                style={{}}
              >
                <SetPassword
                  label={tPublicArea('public_area.set_a_password')}
                  description={tPublicArea('public_area.password_requirements')}
                  password={{
                    value: userOptions.password,
                    callback: (value) => onChange(value, { key: "password" }),
                  }}
                  confirmPassword={{
                    value: userOptions.confirmPassword,
                    callback: (value) =>
                      onChange(value, { key: "confirmPassword" }),
                  }}
                />
                <Button
                  label={tPublicArea('public_area.set_a_password')}
                  className="btn-block mt-4"
                  buttonAttributes={{
                    disabled: !isCorrectPerStep,
                  }}
                  onClick={() => setStep("select_country")}
                />

                <CustomPasswordValidator
                  password={userOptions.password}
                  minLength={8}
                  lowerCase={true}
                  symbols={true}
                  upperCase={true}
                  numbers={true}
                  confirmPassword={userOptions.confirmPassword}
                  callback={(isValid) => setIsPasswordValid(isValid)}
                />
              </div>
            )}
          {step === "select_country" && (
            <div className="col-12 col-sm-8 col-md-6 col-xl-4 offset-lg-0 m-auto">
              <SelectCountry
                country={{
                  value: userOptions.country,
                  callback: (value) => {
                    if (spanishLanguageConuntries.some((listLang) => listLang === value)) {
                      tolgee.changeLanguage('es')
                    } else {
                      tolgee.changeLanguage('en')
                    }
                    setTriggerLangReRender((prev) => !prev)

                    onChange(value, { key: "country" });
                    if (userOptions.fromSocial) {
                      setStep("hear_about_from");
                    } else {
                      setStep("who_are_you");
                    }
                  },
                }}
                search={{
                  value: searchTermCountry,
                  callback: (searchTerm) => setSearchTermCountry(searchTerm),
                }}
                label={tPublicArea('public_area.country_of_residence')}
              />
              <p>
                {tPublicArea('public_area.system_language_explanation')}
              </p>
              {/* <Button
                label={tPublicArea('public_area.set_language')}
                className="btn-block mt-4"
                buttonAttributes={{
                  disabled: !userOptions.country,
                }}
                onClick={() => {
                  if (userOptions.fromSocial) {
                    setStep("hear_about_from");
                  } else {
                    setStep("who_are_you");
                  }
                }}
              /> */}
            </div>
          )}
          {step === "who_are_you" && (
            <div className="col-12 col-sm-8 col-md-6 col-xl-4 offset-lg-0 m-auto">
              <WhoAreYouComponent
                callback={(value) => {
                  onChange(value, { key: "whoAreYou" });
                }}
                disabled={!!predefinedUserToken}
                selected={userOptions.whoAreYou}
              />

              {userOptions.whoAreYou &&
                  !userOptions.whoAreYou.includes(WhoAreYou.FINANCIAL_ADVISOR) && (
                <div className="mt-3">
                  <CustomSelect
                    data={optionsHaveFinancialAdvisor}
                    label={tPublicArea('public_area.do_you_have_fa')}
                    headerSmall={true}
                    disabled={!!predefinedUserToken}
                    callback={onSelectHaveFinancialAdvisor}
                    selected={userOptions.haveFinancialAdvisor}
                  />
                </div>
              )}

              {userOptions.haveFinancialAdvisor ===
                HaveFinancialAdvisorEnum.YES && (
                <div className="my-4">
                  <HaveFinancialAdvisor
                    firstName={{
                      value: financialAdvisor.firstName,
                      callback: (value) =>
                        setFinancialAdvisor((financialAdvisor) => ({
                          ...financialAdvisor,
                          firstName: value,
                        })),
                      disabled: !!predefinedUserToken,
                    }}
                    lastName={{
                      value: financialAdvisor.lastName,
                      callback: (value) =>
                        setFinancialAdvisor((financialAdvisor) => ({
                          ...financialAdvisor,
                          lastName: value,
                        })),
                      disabled: !!predefinedUserToken,
                    }}
                    email={{
                      value: financialAdvisor.email,
                      callback: (value) =>
                        setFinancialAdvisor((financialAdvisor) => ({
                          ...financialAdvisor,
                          email: value,
                        })),
                      disabled: !!predefinedUserToken,
                    }}
                  />
                  <Button
                    label={tPublicArea('public_area.next')}
                    className="btn-block my-4"
                    buttonAttributes={{
                      disabled: !isCorrectPerStep,
                    }}
                    onClick={() => setStep("hear_about_from")}
                  />
                </div>
              )}
            </div>
          )}
          {step === "hear_about_from" && (
            <div className="col-12 col-sm-8 col-md-6 col-xl-4 offset-lg-0 m-auto">
              <HearAboutFrom
                callback={(value) => onChange(value, { key: "hearAboutFrom" })}
                selected={userOptions.hearAboutFrom}
              />

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={getGoogleRecaptchaSiteKey()}
              />
              <p className="text-center">
                {tPublicArea('public_area.to_participate_in_catalyst')}
              </p>
              <Button
                label={tPublicArea('public_area.create_account')}
                className="btn-block mt-2"
                buttonAttributes={{
                  disabled: !isCorrectPerStep,
                }}
                onClick={createAccount}
              />
            </div>
          )}

          {step === "complete_profile" && (
            <div className="d-flex align-items-center flex-column">
              <img src={ConfirmationEmailPng} width={150} alt="" />
              <p className="text-center h1 mt-3">
                {tPublicArea('public_area.confirm_your_email_address')}
              </p>

              <div className="text-center mt-4">
                <p className="h5 font-weight-bold">
                  {tPublicArea('public_area.confirm_email_has_been_sent')}
                </p>
                <p className="h5">{userOptions.email}</p>
                <p className="h5 mt-3 text-dark-gray">
                  {tPublicArea('public_area.click_to_email_link_activate')}
                </p>
              </div>

              <div className="mt-4 text-center">
                <p className="h5 font-weight-bold mb-1">
                  {tPublicArea('public_area.not_receive_email')}
                </p>
                <a
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    resendVerificationEmail();
                  }}
                  className="mb-1"
                >
                  {tPublicArea('public_area.resend_confirmation_email')}
                </a>
                <p className="h5 text-dark-gray">
                  {tPublicArea('public_area.check_junk_folder')}
                </p>
              </div>
              {isVerified ? (
                <Button
                  onClick={() => {
                    const sessionLastPageSeen = localStorage.getItem("sessionLastPageSeen");

                    if (!!sessionLastPageSeen) {
                      const pageToRedirect = sessionLastPageSeen.replace(
                        window.location.origin,
                        ""
                      );
                      localStorage.removeItem("sessionLastPageSeen");
                      history.push(pageToRedirect);
                    } else {
                      window.location.replace("/");
                    }
                  }}
                  label={tPublicArea('public_area.already_verified')}
                  className="mt-2"
                />
              ) : null}

              {resendEmailMessage.message ? (
                <div
                  className={`alert mt-2 mb-0 alert-${
                    resendEmailMessage.status === "success"
                      ? "success"
                      : "danger"
                  }`}
                >
                  {resendEmailMessage.message}
                </div>
              ) : null}

              {resendEmailMessageLoading ? (
                <div className="d-flex justify-content-center mt-3 align-items-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only ">
                      {tPublicArea('public_area.loading')}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
