import { FC, memo } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

const flexContainerClass = classNames('d-flex flex-column', styles['footnotes__gap-container']);

interface InvestmentFootnotesProps {
  values: Array<string>;
  title: string;
}

export const InvestmentFootnotes: FC<InvestmentFootnotesProps> = memo(({ title, values }) => {
  return (
    <>
    {
      !!values.length && (
      <div className={styles.footnotes__box}>
        <div className={styles.footnotes__title}>
          {title}
        </div>
        <div className={flexContainerClass}>
          {values.map((value) => (
            <div className={styles.footnotes__text} key={value}>
              {value}
            </div>
          ))}
        </div>
      </div>)
    }
    </>
  );
});

InvestmentFootnotes.displayName = "InvestmentFootnotes";
