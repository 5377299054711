import { SubscriptionStatus } from "store/properties/types";

export const propertyStatusColors: { [key in SubscriptionStatus]: { color: string; backgroundColor: string; } } = {
  [SubscriptionStatus.PRE_SUBSCRIPTION]: {
    color: '#16839A',
    backgroundColor: 'rgba(255, 255, 255, 0.86)',
  },
  [SubscriptionStatus.PRIMARY_MARKET]: {
    color: '#00CAB2',
    backgroundColor: 'rgba(255, 255, 255, 0.86)',
  },
  [SubscriptionStatus.SECONDARY_MARKET]: {
    color: '#FFFFFF',
    backgroundColor: '#009DC3'
  },
  [SubscriptionStatus.CLOSED_TRANSACTION]: {
    color: '#07344B',
    backgroundColor: 'rgba(254, 203, 76, 0.86)',
  },
};