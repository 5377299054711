import { FC } from "react";
import classNames from "classnames";
import { ReactComponent as MyListSvg } from "assets/my-list.svg";

import styles from './styles.module.scss';
import { useTranslate } from "@tolgee/react";

interface SaveButtonProps {
  onClick: () => void;
  isBookmarked: boolean;
  text?: [string, string];
}

export const SaveButton:FC<SaveButtonProps>  = ({ onClick, isBookmarked, text }) => {
  const [savedText, unsavedText] = text || [];
  const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');

  return (
    <div
      className={
        classNames(
          styles.button,
          {[styles.saved]: isBookmarked}
        )
      }
      onClick={onClick}
      role="button"
    >
      {
        <MyListSvg />
      }
      { 
        isBookmarked ? 
          text ? savedText : tOpportunityDetailPageCard('opportunity_detail_page.card.saved')
        : 
          text ? unsavedText : tOpportunityDetailPageCard('opportunity_detail_page.card.save')
      }
    </div>
  );
}