import { useTolgee } from '@tolgee/react';
import { Dayjs } from 'dayjs';
import { memo, FC, useMemo } from 'react';
import styled from 'styled-components';
import { oneDayPixelWidth, quarterNameBlockHeight, quarterNameBlockMarginBottom } from '../../utils/constants';
import 'dayjs/locale/es';
interface MonthColumnProps {
  days: number;
  month: Dayjs;
}

export const MonthColumn: FC<MonthColumnProps> = memo(({ days, month }) => {
  const tolgee = useTolgee();
  const monthDescription = useMemo(() => month.locale(tolgee.getPendingLanguage() || 'en').format('MMM/YY'), [month, tolgee]);

  return (
    <MonthColumnContainer>
      <NameContainer>
        {monthDescription}
      </NameContainer>
      <Column days={days} />
    </MonthColumnContainer>
  );
});

interface ColumnProps {
  days: number;
}

const Column = styled.div<ColumnProps>`
  width: ${({ days }) => `${days * oneDayPixelWidth}px`};
  height: 100%;

  background: #FFF;

  border: 1px solid rgba(9, 34, 39, 0.10);
  border-radius: 20px;
  box-shadow: 2px 2px 20px 0px rgba(9, 34, 39, 0.10);
`;

const NameContainer = styled.div`
  position: sticky;
  
  top: 0px;

  height: ${quarterNameBlockHeight}px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: capitalize;

  z-index: 2;
`

const MonthColumnContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  grid-gap: ${quarterNameBlockMarginBottom}px;
`;