import { FC, createContext, memo, Dispatch, SetStateAction } from "react";
import { PropertyEducationAndResearchCardMain } from "../property-education-and-research-card-main";
import { PropertyEducationAndResearchCardTags } from "../property-education-and-research-card-tags";
import classNames from "classnames";
import { PropertyEducationAndResearchFilePreview } from "../property-education-and-research-preview";
import { PropertyEducationAndResearchButtons } from "../property-education-and-research-buttons";
import { EducationMaterial } from "types/education-materials/education-material";

import style from "./style.module.scss";

type Props = {
  material: EducationMaterial;
  setPreviewedMaterial: Dispatch<SetStateAction<EducationMaterial | null>>;
};

const tagsBoxClass = classNames(
  "d-flex h-100",
  style["education-and-research__tags-box"]
);

const mainTopContentClass = classNames(
  style["education-and-research__main-box"],
  style["education-and-research__main-top"]
);

const leftBoxClass = classNames("d-none d-md-block cursor-pointer", style['education-and-research__left-box'])

export const EducationAndResearchContext =
  createContext<null | EducationMaterial>(null);

export const PropertyEducationAndResearchCard: FC<Props> = memo(
  ({ material, setPreviewedMaterial }) => {
    if (!material) {
      return null;
    }

    const { tags } = material;
    return (
      <EducationAndResearchContext.Provider value={material}>
        <div className={style["education-and-research__card"]}>
          <div className={leftBoxClass}>
            <PropertyEducationAndResearchFilePreview setPreviewedMaterial={setPreviewedMaterial} />
          </div>
          <div className={style["education-and-research__box"]}>
            <div className="align-self-end">
              <PropertyEducationAndResearchButtons />
            </div>
            <div className={mainTopContentClass}>
              <PropertyEducationAndResearchCardMain />
            </div>
            <div className={style["education-and-research__main-box"]}>
              <div className={style["education-and-research__tags-container"]}>
                <div className={tagsBoxClass}>
                  {tags &&
                    tags.map((tag: string, i: number) => (
                      <PropertyEducationAndResearchCardTags
                        tag={tag}
                        key={`${tag}_${i}`}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </EducationAndResearchContext.Provider>
    );
  }
);
