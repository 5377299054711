import axios from "axios";
import history from "../history-entity";
import { getBaseUrl } from "../app-config";

export const publicApi = axios.create({
  proxy: true,
  baseURL: getBaseUrl(),
  timeout: 6000000,
});

export const privateApi = axios.create({
  proxy: true,
  baseURL: getBaseUrl(),
  withCredentials: true,
  timeout: 6000000,
});

privateApi.interceptors.request.use(function (config) {
  config.headers["x-auth"] = localStorage.getItem("jwtToken");
  return config;
});

privateApi.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      history.push("/");
    }

    return Promise.reject(error);
  }
);
