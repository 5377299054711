import { memo, isValidElement } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Login } from "new-architecture/pages/login";
import { SignUp } from "new-architecture/pages/sign-up/components/index";
import MainLayout from "layouts/MainLayout";
import { VerifyUser } from "new-architecture/pages/verify-user";
import PrivateRoute from "new-architecture/hocs/private-route";
import { EstatingAcademy } from "screens/estating-academy";
import { TeaserTimeline } from "new-architecture/pages/teaser-timeline";
import { Property } from "new-architecture/pages/property";
import MyAccount from "screens/Account/MyAccount";
import { Properties } from "new-architecture/pages/properties";
import ForgotPassword from "screens/Account/ForgotPassword";
import ResetPassword from "screens/Account/ResetPassword";
import PrivacyPolicy from "screens/PrivacyPolicy";
import TermsOfUse from "screens/TermsOfUse";
import PageNotFound from "screens/PageNotFound";
import { Buffer } from "buffer";
import { publicApi } from "api/estatingApi";
import { useQuery } from "hooks/use-query";
import { getBaseUrl } from "app-config";
import { LoadingFile } from "new-architecture/pages/loading-file";
import { useTranslate } from '@tolgee/react';

const baseUrl = `${getBaseUrl()}/teaser`;

export const AppRouter = memo(() => {
  const { t: tTitle } = useTranslate('title');

  const appRoutes: Array<RouteProps | JSX.Element>= [
    {
      path: '/download-attachment/:mailId',
      component: ((route) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const query = useQuery();
        const filePath = query.get('path');
  
        if (filePath) {
          const decodedResourceUrl = Buffer.from(filePath, "base64").toString("utf-8");
          publicApi.get(`/user/email-opened/${route.match.params.mailId}?attachmentPath=${filePath}`);
  
          const filename = decodedResourceUrl?.split('/')?.pop() || 'attachment';
  
          if (decodedResourceUrl.startsWith(baseUrl)) {
            const targetTeaserUrl = decodedResourceUrl.split('/').pop()?.split('?').shift()
  
            if (!targetTeaserUrl) {
              return <LoadingFile fileName={filename} url={decodedResourceUrl} />;
            }
  
            return <Redirect to={`/opportunity/${targetTeaserUrl}?teaser-preview=${targetTeaserUrl}`} />
          } else {
            return <LoadingFile fileName={filename} url={decodedResourceUrl} />;
          }
        }
  
        window.close();
        return null;
      })
    },
    {
      path: '/login',
      exact: true,
      component: (() =>
        <MainLayout title={tTitle('title.login', 'Login')} withNavbar={false}>
          <Login />
        </MainLayout>
      )
    },
    {
      path: '/signup',
      exact: true,
      component: (() =>
        <MainLayout title={tTitle('title.signup', 'Signup')} withNavbar={false}>
          <SignUp />
        </MainLayout>
      )
    },
    {
      path: '/verify/:id/:token',
      exact: true,
      component: (() =>
        <VerifyUser />
      )
    },
    {
      path: '/signup/:token',
      exact: true,
      component: (() =>
        <MainLayout withNavbar={false}>
          <SignUp />
        </MainLayout>
      )
    },
    {
      path: ["/estating-academy", "/estating-academy/:id" ],
      exact: true,
      component: (() =>
        // <PrivateRoute>
          <MainLayout title={tTitle('title.estating_academy_materials', 'Estating Academy materials')}>
            <EstatingAcademy />
          </MainLayout>
        // </PrivateRoute>
      )
    },
    {
      path: "/teaser-timeline",
      exact: true,
      component: (() =>
        <PrivateRoute>
          <MainLayout title={tTitle('title.opportunity_timeline', 'Opportunity Timeline')}>
            <TeaserTimeline />
          </MainLayout>
        </PrivateRoute>
      )
    },
    {
      path: "/opportunity/:id",
      exact: true,
      component: (() =>
        // <PrivateRoute>
          <MainLayout title={tTitle('title.opportunity', 'Opportunity')}>
            <Property />
          </MainLayout>
        // </PrivateRoute>
      )
    },
    {
      path: "/property/:id",
      component: ((route) => (
        <Redirect to={`/opportunity/${route.match.params.id}`}/>
      ))
    },
    {
      path: "/account",
      exact: true,
      component: (() =>
        <PrivateRoute>
          <MainLayout title={tTitle('title.account', 'Account')}>
            <MyAccount />
          </MainLayout>
        </PrivateRoute>
      )
    },
    {
      path: "/opportunities",
      exact: true,
      component: (() =>
        <PrivateRoute>
          <MainLayout title={tTitle('title.opportunities', 'Opportunities')}>
            <Properties />
          </MainLayout>
        </PrivateRoute>
      )
    },
    {
      path: "/properties",
      component: (() => (
        <Redirect to={`/opportunities`}/>
      ))
    },
    {
      path: "/",
      exact: true,
      component: (() => (
        <Redirect to={`/opportunities`}/>
      ))
    },
    {
      path: "/forgot-password",
      exact: true,
      component: (() => (
        <MainLayout title={tTitle('title.forgot_password', 'Forgot Password')}>
          <ForgotPassword />
        </MainLayout>
      ))
    },
    {
      path: "/reset-password/:resetPasswordToken",
      exact: true,
      component: (() => (
        <MainLayout>
          <ResetPassword />
        </MainLayout>
      ))
    },
    {
      path: "/privacy-policy",
      exact: true,
      component: (() => (
        <MainLayout title={tTitle('title.privacy_policy', 'Privacy Policy')}>
          <PrivacyPolicy />
        </MainLayout>
      ))
    },
    {
      path: "/terms-of-use",
      exact: true,
      component: (() => (
        <MainLayout title={tTitle('title.terms_of_use', 'Terms of Use')}>
          <TermsOfUse />
        </MainLayout>
      ))
    },
    {
      path: "/404",
      exact: true,
      component: (() => (
        <MainLayout title={tTitle('title.page_not_found', 'Page Not Found')}>
          <PageNotFound />
        </MainLayout>
      ))
    },
    <Redirect to="/404" key={"/404"} />
  ];

  return (
    <Switch>
      {appRoutes.map((routeProps, index) => {
        switch(true) {
          // is JSX.Element
          case isValidElement(routeProps):
            return routeProps;
          // is Route props
          case typeof routeProps === 'object':
            return (<Route {...routeProps} key={String((routeProps as RouteProps).path)} />);
          default: throw new Error('Unexpected route type');
        }
      })}
    </Switch>
  )
})