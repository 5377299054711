import { EducationMaterialsGroup } from '../../types/education-materials/education-materials-group';

import {
  EducationMaterialsGroupsActionsTypes,
  SetEducationMaterialsGroups,
  SetLoadingEducationMaterialsGroups,
  SetErrorEducationMaterialsGroups,
  SetUpdatingEducationMaterialsGroups,
} from './types';

export const setLoadingEducationMaterialsGroups = (payload: boolean): SetLoadingEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_LOADING_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setUpdatingEducationMaterialsGroups = (payload: boolean): SetUpdatingEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_UPDATING_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setErrorEducationMaterialsGroups = (payload: string): SetErrorEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_ERROR_EDUCATION_MATERIALS_GROUPS,
  payload,
});

export const setGroupsEducationMaterialsGroups = (
  payload: Array<EducationMaterialsGroup> | null
): SetEducationMaterialsGroups => ({
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIALS_GROUPS,
  payload,
});

