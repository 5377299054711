import { FC, memo, useRef } from "react";
import { ReactComponent as PlayButtonSvg } from "assets/playVideo.svg";
import style from "./style.module.scss";
import classNames from "classnames";
import { isVideo } from "helpers/isVideoChecker";

type Props = {
  asset: string;
  isVisibleButtonPlay: (param: boolean) => void;
  isButtonVisible: boolean;
};

const videoClass = classNames(style["video__main"], "cursor-pointer");
const buttonClass = classNames(
  style["video__button"],
  "cursor-pointer d-flex justify-content-center align-items-center"
);

export const PropertyGalleryModalMain: FC<Props> = memo(
  ({ asset, isVisibleButtonPlay, isButtonVisible }) => {
    const vidRef = useRef<null | HTMLVideoElement>(null);

    const handlePlayVideo = () => {
      if (vidRef.current) {
        vidRef.current.play();
        isVisibleButtonPlay(false);
      }
    };

    const handleStopVideoVideo = () => {
      if (vidRef.current) {
        vidRef.current.pause();
        isVisibleButtonPlay(true);
      }
    };

    return (
      <div className="w-100 d-flex justify-content-center h-100">
        {isVideo(asset) ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <video
              width="100%"
              height="100%"
              className={videoClass}
              ref={vidRef}
              onClick={handleStopVideoVideo}
            >
              <source src={asset} type="video/mp4" />
            </video>
            {isButtonVisible && (
              <div className={buttonClass} onClick={handlePlayVideo}>
                <PlayButtonSvg className={style["video__play-button"]} />
              </div>
            )}
          </div>
        ) : (
          <img src={asset} alt="img" className={style["video__image"]} />
        )}
      </div>
    );
  }
);
