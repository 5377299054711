import { FC, memo, MouseEvent } from 'react';
import { ReactComponent as MyListSvg } from "assets/my-list.svg";
import classNames from 'classnames';

import style from './style.module.scss';
import { useTranslate } from '@tolgee/react';
//import { Spinner } from 'react-bootstrap';

interface PropertyCardSaveButtonProps {
  isBookmarked?: boolean;
  isLoading?: boolean;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
}

export const PropertyCardSaveButton: FC<PropertyCardSaveButtonProps> = memo(({
  isBookmarked,
  isLoading,
  onClick
}) => {
  const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');

  return (
    <div className={classNames(style.button, {[style.saved]: isBookmarked})} onClick={onClick}>
      {
/*        isLoading ?
          <Spinner animation='grow' />
        :*/
          <MyListSvg />
      }
      
      { 
        isBookmarked ? 
          tOpportunityDetailPageCard('opportunity_detail_page.card.saved')
        : 
          tOpportunityDetailPageCard('opportunity_detail_page.card.save')
      }
    </div>
  );
});
