import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import cryptoRandomString from 'crypto-random-string';
import eyeClose from '../assets/eye_close.svg'
import eyeOpen from '../assets/eye_open.svg'
import classNames from 'classnames';

export type InputProps = {
    id?: string,
    label?: string,
    labelComponent?: JSX.Element
    inputAttributes?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'className' | 'style'>,
    style?: React.CSSProperties,
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any,
    leftIcon?: JSX.Element,
    passwordMode?: boolean,
    containerClasses?: string,
    containerStyles?: React.CSSProperties,
    labelClasses?: string,
    labelStyles?: React.CSSProperties,
    rightIcon?: JSX.Element,
    validator?: (value: string | number | readonly string[] | undefined) => boolean,
    validFeedback?: string,
    invalidFeedback?: string,
    callback?: (isValid: boolean, field: string) => void,
    mergeIcon?: boolean,
    secondaryLabel?: string
}

const Input: React.FC<InputProps> = ({
                                         id,
                                         label,
                                         labelComponent,
                                         inputAttributes = {},
                                         style,
                                         className,
                                         leftIcon,
                                         onChange = () => {
                                         },
                                         passwordMode,
                                         containerClasses = '',
                                         containerStyles = {},
                                         rightIcon,
                                         labelClasses,
                                         labelStyles = {},
                                         validFeedback,
                                         invalidFeedback,
                                         validator,
                                         callback,
                                         mergeIcon = false,
                                         secondaryLabel
                                     }) => {

    const [showText, setShowText] = React.useState(!passwordMode)
    const [isValid, setIsValid] = React.useState(true)
    
    const [inputId] = React.useState(id || inputAttributes?.id || cryptoRandomString({length: 10}))

    React.useEffect(() => {
        if (callback && !!inputAttributes.name) {
            callback(isValid, inputAttributes?.name)
        }
    }, [callback, isValid, inputAttributes?.name, inputId])

    React.useEffect(() => {
        if (validator) {
            setIsValid(validator(inputAttributes?.value))
        }
    }, [validator, inputAttributes?.value])


    const toggleShow = () => {
        setShowText(!showText)
    }

    if (passwordMode) {
        inputAttributes.type = showText ? 'text' : 'password'
    }


    const change = (e) => {
        if (validator) {
            setIsValid(validator(e.target.value))
        }
        onChange(e)
    }

    const _validatorClass = () => {
        if (!!inputAttributes?.value) {
            if (isValid && validator) {
                return 'is-valid'
            } else if (!isValid && validator) {
                return 'is-invalid'
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    const _getContainerClass = () => {
        if (leftIcon || rightIcon || passwordMode) {
            if (!mergeIcon) {
                return 'input-group'
            } else {
                return  'w-100'
            }
        }
        return ''
    }
    const _getInputStyles = () => {
        if (leftIcon && mergeIcon) {
            return {
                paddingLeft: '3rem'
            }
        }

        return null
    }

    const _renderLeftIcon = () => {
        if(leftIcon) {
            if (mergeIcon) {
                return  (
                    <div className="" style={{
                        position: 'absolute',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        left: '0.75rem',
                        justifyContent: 'center'
                    }}>
                        {
                            leftIcon
                        }
                    </div>
                )
            } else {
                return (
                    <div className="input-group-prepend">
                        {
                            leftIcon
                        }
                    </div>
                )
            }
        }

        return null
    }
    return (
        <div className={`${containerClasses || ''} mb-2`}
             style={{
                 ...containerStyles
             }}
        >
            {
                label &&
                <label htmlFor={inputId} className={classNames('d-flex', labelClasses || 'font-weight-bold')} style={{
                    ...labelStyles
                }}>{label}{labelComponent}</label>
            }
            {
                secondaryLabel && <label>{secondaryLabel}</label>
            }
            <div className={`${_getContainerClass()} position-relative w-100`}>
                {
                    leftIcon &&
                    _renderLeftIcon()
                }
                <input
                    id={inputId}
                    style={{
                        ...style,
                        ..._getInputStyles()
                    }}
                    onChange={(e) => change(e)}
                    autoComplete="off"
                    className={`form-control input-theme-height ${className || ''} ${_validatorClass()}`}
                    {...inputAttributes}
                />
                {
                    isValid && validFeedback ?
                        <div className="valid-feedback">
                            {validFeedback}
                        </div>
                        :
                        null
                }
                {
                    !isValid || invalidFeedback ?
                        <div className="invalid-feedback">
                            {invalidFeedback}
                        </div>
                        :
                        null
                }

                {
                    passwordMode &&
                    <div className="input-group-append cursor-pointer" onClick={toggleShow}>
                        {
                            showText ?
                                <img src={eyeOpen}
                                     className='input-group-text' alt='eye open icon'/>
                                :
                                <img src={eyeClose} className='input-group-text' alt='eye close icon'/>
                        }
                    </div>
                }

                {
                    rightIcon &&
                    <div className="input-group-append cursor-pointer">
                        {
                            rightIcon
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default Input
