import {
    INITIAL_STATE,
    DELETE_PROPERTY,
    ADD_PROPERTY, ADD_PROPERTIES,
    INTERESTED,
    PropertyObj
} from './types';

// TODO check type for action object
export const dealsReducer = (properties = INITIAL_STATE, {type, payload}: any) => {
    switch (type) {
        case ADD_PROPERTY:
            const addedPropertyId = payload.property._id
            const propertyIds = properties.map((pObj: PropertyObj) => pObj.property._id)
            if (!propertyIds.includes(addedPropertyId)) {
                properties.push(payload)
            }
            return [...properties];
        case INTERESTED:
            return [
                ...properties.map(pObj => {
                    if (pObj.property._id === payload) {
                        pObj.isInterested = true
                    }
                    return {...pObj}
                })
            ]
        case DELETE_PROPERTY:
            return [...properties.filter((pObj: PropertyObj) => pObj.property._id !== payload)]
        case ADD_PROPERTIES:
            return [...payload]
        default:
            return properties;
    }
};
