import { AdditionalValue } from "store/properties/types";
import { FC, memo } from "react";
import { PropertySecurityInfoItem } from "../property-security-info-item";

import style from './style.module.scss';

type PropertySecurityInfoItemsProps = {
  info: Array<AdditionalValue>;
}
export const PropertySecurityInfoItems: FC<PropertySecurityInfoItemsProps>= memo(({ info }) => {

  if(!info || info.length === 0){
    return null;
  }

  return(
    <div className={style['security-info-items']}>
      {
        info.filter(av => av.name !== "" && av.value !== "").map(({ name, value, tooltip }) => (
          <PropertySecurityInfoItem
            key={name}
            name={name}
            value={value}
            className={name.toLowerCase().replaceAll(" ","")}
            tooltip={tooltip}
          />
        ))
      }
    </div>
  );
});
