import { FC } from 'react'
import PersonalDetails from "./personal-details";
import validator from 'validator'
import { useTranslate } from '@tolgee/react';

interface HaveFinancialAdvisorProps {
  firstName: {
    value: string,
    callback: (value: string) => any,
    disabled?: boolean
  },
  lastName: {
    value: string,
    callback: (value: string) => any,
    disabled?: boolean
  },
  email: {
    value: string,
    callback: (value: string) => any,
    disabled?: boolean
  },
}

const HaveFinancialAdvisor: FC<HaveFinancialAdvisorProps> = ({
  firstName,
  lastName,
  email,
}) => {
  const { t: tPublicArea } = useTranslate('public_area');

  return (
    <PersonalDetails
      firstName={{
        value: firstName.value,
        callback: firstName.callback,
        label: tPublicArea('public_area.first_name_fa'),
        inputAttributes: {disabled: firstName.disabled}
      }}
      lastName={{
        value: lastName.value,
        callback: lastName.callback,
        label: tPublicArea('public_area.last_name_fa'),
        inputAttributes: {disabled: lastName.disabled}
      }}
      email={{
        value: email.value,
        callback: email.callback,
        label: tPublicArea('public_area.email_fa'),
        inputAttributes: {disabled: email.disabled},
        inputProps: {
            validator: (value => validator.isEmail(value)),
        }
      }}
    />
  );
}


export default HaveFinancialAdvisor
