import { FC, useCallback, useEffect, memo, useRef, useMemo } from "react";
import logo from "assets/catalyst-logo.png";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSession } from "context/session";
import { MobileMenu } from "./mobile-menu";
import { NavItems } from "./nav-items";
import { DropDownHeader } from "./drop-down";
import { subheaderPortalId } from "../constants/subheader-portal";
import { LanguageSelector } from "new-architecture/components/language-selector";
import { useTolgee, useTranslate } from '@tolgee/react';
import UserAPI from "api/user";
import question from "assets/question.svg";
import logoutIcon from "assets/logout.svg";

import { ReactComponent as PropertiesSvg } from "assets/properties.svg";
import { ReactComponent as EstatingSvg } from "assets/estating-academy.svg";
import { ReactComponent as TeaserTimelineSvg } from "assets/teaser-timeline.svg";
import { ReactComponent as SettingsSvg } from "assets/settings-icon.svg";
import { ReactComponent as SupportSvg } from "assets/support-icon.svg";
import { ReactComponent as LogoutSvg } from "assets/logout-icon.svg";

import styles from './style.module.scss'
import { allowedEmails } from "../constants/allowed-emails";

const navClass = classNames(
  "bg-bgLight justify-content-between justify-content-md-start flex-sm-nowrap align-items-center d-sm-flex d-none m-0",
  styles.header
);

export interface HeaderProps {
  onResize?: (value: number) => void;
}

export const Header: FC<HeaderProps> = memo(({ onResize }) => {
  const { user, logout } = useSession();
  const headerContainer = useRef<HTMLDivElement>(null);
  const tolgee = useTolgee();
  const { setUser } = useSession();

  const handleResize = useCallback((entries: Array<ResizeObserverEntry>) => {
    if (!onResize) {
      return;
    }

    onResize(entries[0].contentRect.height);
  }, [onResize]);

  useEffect(() => {
    if (!headerContainer.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(headerContainer.current);

    const copyRef = headerContainer.current;

    return () => {
      resizeObserver.unobserve(copyRef);
    };
  }, [handleResize]);

  const onLanguageChange = useCallback(async (newLanguage: string) => {
    try {
      if (user) {
        const updatedUser = await UserAPI.changeUser({ lang: newLanguage });
        setUser(updatedUser)
      }
  
      await tolgee.changeLanguage(newLanguage);
    } catch (error) {
      console.error(error);
    }
  }, [user, tolgee, setUser]);

  const { t: tHeader } =  useTranslate('homepage.header');
  const { t: tPrivateArea } =  useTranslate('private_area');

  const headerNavInfo = useMemo(() => [
    {
      name: tHeader('homepage.header.opportunities'),
      icon: <PropertiesSvg />,
      route: "/opportunities",
    },
    {
      name: tHeader('homepage.header.estating_academy'),
      icon: <EstatingSvg />,
      route: "/estating-academy",
    },
    {
      name: tHeader('homepage.header.opportunity_timeline'),
      icon: <TeaserTimelineSvg />,
      route: "/teaser-timeline",
    },
  ], [tHeader]);

  const burgerItems = useMemo(() => [
    ...headerNavInfo,
    {
      name: tPrivateArea('private_area.common.settings'),
      icon: <SettingsSvg />,
      route: "/account",
    },
    {
      name: tPrivateArea('private_area.support'),
      icon: <SupportSvg />,
      route: "https://www.estating.com/contact-us",
      target: '_blank',
    },
    {
      name: tPrivateArea('private_area.common.button.logout'),
      icon: <LogoutSvg />,
      route: "/login",
    },
  ], [headerNavInfo, tPrivateArea]);

  const dropDownItems = useMemo(() => {
    const outItems: Array<{
      className: string;
      name: string;
      link: string;
      icon?: string;
      target?: string;
      onClick?: () => void;
    }> = [
      {
        className: "dropdown-item text-center py-1 my-3",
        name: tPrivateArea('private_area.support'),
        link: "https://www.estating.com/contact-us",
        target: "_blank",
        icon: question,
      },
      {
        className: "dropdown-item text-center py-1",
        name: tPrivateArea('private_area.common.button.logout'),
        link: "/login",
        icon: logoutIcon,
        onClick: logout
      },
    ];

    if (allowedEmails.some((alowedEmail) => user?.email?.split('@')?.pop() === alowedEmail)) {
      outItems.splice(1, 0, 
        {
          className: "dropdown-item text-center py-1 my-3",
          name: tPrivateArea('private_area.analytics'),
          link: "https://grafana.estating.com/dashboards",
          target: "_blank"
        }
      )
    }

    return outItems;
  }, [logout, tPrivateArea, user?.email]);

  const { t: tPublicArea } = useTranslate('public_area');

  return (
    <header
      className="sticky-top"
      ref={headerContainer}
    >
      <div
        className={
          classNames(
            styles['container-header'],
            "shadow bg-bgLight"
          )
        }
      >
        <Container>
          <Nav className={navClass}>
            <Link to="/">
              <img
                src={logo}
                alt="Estating logo"
                className={styles.header_logo}
              />
            </Link>

              {
                user ?
                (
                  <div className={styles.header__gap}>
                    <NavItems navInfo={headerNavInfo} />
                  </div>
                )
                  :
                (
                  <>
                    <p className="mb-0">
                      {tPublicArea('public_area.dont_have_account')}
                      <Link to="/signup" className="ml-3 alert-link">
                        {tPublicArea('public_area.sign_up')}
                      </Link>
                    </p>
                  </>
                )
              }
            <div className="d-flex align-items-center ml-auto flex-md-row-reverse">
              <LanguageSelector
                availableLanguages={[{ value: 'en', label: 'EN'}, { value: 'es', label: 'ES' }]}
                onLanguageSelect={onLanguageChange}
                selectedLanguage={tolgee.getPendingLanguage()}
              />
              { user && 
                <div
                  className="flex-shrink-1 d-none d-md-flex"
                  style={{
                    width: '80px',
                  }}
                />
              }
              { user && <DropDownHeader dropDowntItems={dropDownItems} />}
            </div>

          </Nav>
          <div className="d-sm-none">
            <MobileMenu navItems={burgerItems} />
          </div>
        </Container>
      </div>
      <div id={subheaderPortalId} />
    </header>
  );
});
