import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./base-query";

export const analiticsApi = createApi({
  reducerPath: "analitics",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    trackDataRoomFileDownload: builder.mutation<void, { fileName: string, opportunityId: string, opportunity: string }>({
      query: (body) => { 
        const url = `analitics/data-room/download`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationMaterialShare: builder.mutation<void, { materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/estating-academy/share-opened`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationMaterialShareLinkCopy: builder.mutation<void, { fileName: string, materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/estating-academy/link-copied`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationMaterialDownload: builder.mutation<void, { fileName: string, materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/estating-academy/download`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationMaterialOpen: builder.mutation<void, 
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/estating-academy/material-open`;

          return { url, method: 'post', body };
        },
      }
    ),
    trackEducationMaterialClose: builder.mutation<void,
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/estating-academy/material-close`;

          return { url, method: 'post', body };
        }
      }
    ),
    trackEducationMaterialPageChange: builder.mutation<void,
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/estating-academy/material-page-change`;

          return { url, method: 'post', body };
        }
      }
    ),
    trackEducationAndResearchMaterialOpen: builder.mutation<void, 
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/education-and-research/material-open`;

          return { url, method: 'post', body };
        },
      }
    ),
    trackEducationAndResearchMaterialClose: builder.mutation<void,
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/education-and-research/material-close`;

          return { url, method: 'post', body };
        }
      }
    ),
    trackEducationAndResearchMaterialPageChange: builder.mutation<void,
      {
        material: string,
        materialId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/education-and-research/material-page-change`;

          return { url, method: 'post', body };
        }
      }
    ),
    trackEducationAndResearchMaterialShare: builder.mutation<void, { materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/education-and-research/share-opened`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationAndResearchShareLinkCopy: builder.mutation<void, { fileName: string, materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/education-and-research/link-copied`;

        return { url, method: 'post', body };
      },
    }),
    trackEducationAndResearchDownload: builder.mutation<void, { fileName: string, materialId: string, material: string }>({
      query: (body) => { 
        const url = `analitics/education-and-research/download`;

        return { url, method: 'post', body };
      },
    }),
    trackOpportunityDetailsTeaserDownload: builder.mutation<void, { opportunityId: string, opportunity: string, fileName: string }>({
      query: (body) => { 
        const url = `analitics/opportynity-details/teaser-downloaded`;

        return { url, method: 'post', body };
      },
    }),
    trackOpportunityDetailsTeaserCopyLink: builder.mutation<void, { opportunityId: string, opportunity: string }>({
      query: (body) => { 
        const url = `analitics/opportynity-details/teaser-link-copied`;

        return { url, method: 'post', body };
      },
    }),
    trackOpportunityDetailsTeaserPreviewOpen: builder.mutation<void, 
      {
        opportunity: string,
        opportunityId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/opportynity-details/teaser-preview-open`;

          return { url, method: 'post', body };
        },
      }
    ),
    trackOpportunityDetailsTeaserPreviewClose: builder.mutation<void,
      {
        opportunity: string,
        opportunityId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/opportynity-details/teaser-preview-close`;

          return { url, method: 'post', body };
        }
      }
    ),
    trackOpportunityDetailsTeaserPreviewPageChange: builder.mutation<void,
      {
        opportunity: string,
        opportunityId: string,
        page: string,
        sessionId: string,
      }>(
      {
        query: (body) => {
          const url = `analitics/opportynity-details/teaser-preview-page-change`;

          return { url, method: 'post', body };
        }
      }
    ),
  }),
});

export const {
  useTrackDataRoomFileDownloadMutation,
  useTrackEducationMaterialShareMutation,
  useTrackEducationMaterialShareLinkCopyMutation,
  useTrackEducationMaterialDownloadMutation,
  useTrackEducationMaterialOpenMutation,
  useTrackEducationMaterialCloseMutation,
  useTrackEducationMaterialPageChangeMutation,
  useTrackEducationAndResearchMaterialOpenMutation,
  useTrackEducationAndResearchMaterialCloseMutation,
  useTrackEducationAndResearchMaterialPageChangeMutation,
  useTrackEducationAndResearchMaterialShareMutation,
  useTrackEducationAndResearchShareLinkCopyMutation,
  useTrackEducationAndResearchDownloadMutation,
  useTrackOpportunityDetailsTeaserCopyLinkMutation,
  useTrackOpportunityDetailsTeaserDownloadMutation,
  useTrackOpportunityDetailsTeaserPreviewCloseMutation,
  useTrackOpportunityDetailsTeaserPreviewOpenMutation,
  useTrackOpportunityDetailsTeaserPreviewPageChangeMutation
} = analiticsApi;