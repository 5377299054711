import { createContext } from "react";

interface HeaderContextType {
  containerHeight: number;
}

const initialValues: HeaderContextType = {
  containerHeight: 0,
}

export const HeaderContext = createContext<HeaderContextType>(initialValues);