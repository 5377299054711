import { memo, PropsWithChildren, FC, useEffect, CSSProperties } from "react";
import { Portal } from "new-architecture/components/portal";

import styles from './style.module.scss';

interface PopupProps {
  onClose: () => void;
  isOpen: boolean;
  popUpWrapperStyle?: CSSProperties;
}

const ScrollOffOnMount = memo(() => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  
  return null;
});

export const Popup: FC<PropsWithChildren<PopupProps>> = memo(({ children, isOpen, onClose, popUpWrapperStyle }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <ScrollOffOnMount />

      <div
        className={styles['pop-up']}
        role="dialog"
      >
        <div
          role="button"
          className={styles['pop-up__backdrop']}
          onClick={onClose}
          tabIndex={0}
        />
        <div
          className={styles['pop-up__content']}
          style={popUpWrapperStyle || {}}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
});
