import { ChangeEvent, memo, useCallback } from 'react';
import lockImage from 'assets/mingcute_lock-line.svg';
import { ReactComponent as ArrowSvg } from 'assets/arrow-forward.svg';
import classNames from 'classnames';
import { Button } from 'new-architecture/components/button';
import { useDispatch, useSelector } from 'react-redux';
import { performancePassword, performancePropertyId, setPassword } from '../../store/slices/preformance-report';
import { useAuthorizeMutation } from '../../store/api/performance-report';
import { useTranslate } from '@tolgee/react';

import styles from './style.module.scss';

export const PasswordCardForm = memo(() => {
  const performanceReportPassword = useSelector(performancePassword);
  const performanceReportPropertyId = useSelector(performancePropertyId);
  const dispatch = useDispatch();

  const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(event.target.value));
  }, [dispatch]);

  const [authorize, { isLoading: isAuthorizePending, isError }] = useAuthorizeMutation();

  const onSubmit = useCallback(() => {
    authorize({
      password: performanceReportPassword,
      propertyId: performanceReportPropertyId || ''
    });
  }, [performanceReportPassword, performanceReportPropertyId, authorize]);

  const { t: tOpportunityDetailPageInvestorReport } = useTranslate('opportunity_detail_page.investor_report');

  return (
    <div
      className={styles['password-form']}
    >
      <div>
        <div className="d-flex">
          <div
            className={styles['password-form__title-image-container']}
          >
            <img
              alt="Password"
              src={lockImage}
            />
          </div>
          <div>
            <div className={styles['password-form__title']}>
              {tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.investor_area')}
            </div>
            <div className={styles['password-form__sub-title']}>
              {tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.subtitle1')}{' '}
              <a href="mailto:info@estating.com?subject=Investor Report - Request">
                {tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.subtitle2')}
              </a>{' '}
              {tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.subtitle3')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['password-form__controls-container']}>
        <input
          className={
            classNames(
              'form-control',
              styles['password-form__input'],
              { [styles['password-form__input_error']]: isError }
            )
          }
          placeholder={tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.password_label')}
          type="password"
          value={performanceReportPassword}
          onChange={onPasswordChange}
        />
        <div
          className={styles['password-form__button-container']}
        >
          <Button
            icon={<ArrowSvg />}
            text={tOpportunityDetailPageInvestorReport('opportunity_detail_page.investor_report.button.enter')}
            onClick={onSubmit}
            buttonProps={{
              disabled: isAuthorizePending,
            }}
          />
        </div>
      </div>
    </div>
  );
});
