import { FC, memo } from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { PropertyTeaserType } from "store/properties/types";
import { PropertyDataRoomTitle } from "./property-data-room-title";
import { PropertyDataRoomText } from "./property-data-room-text";
import { PropertyDataRoomFiles } from "./property-data-room-files";
import { PropertyDatsRoomImages } from "./property-data-room-images";

import style from "./style.module.scss";

const dataRoomBoxClass = classNames(
  "d-flex justify-content-between w-100",
  style["data-room__box-gap"]
);

type DataRoomsProps = {
  property: PropertyTeaserType;
};

export const PropertyDataRoom: FC<DataRoomsProps> = memo(({ property }) => {
  const { dataRoom } = property;
  const { marketResearchText, documents, marketResearchImage } = dataRoom;
  const propertyName = `${property.description.organisation || ''} | ${property.description.name || ''}`

  return (
    <div>
      <Container>
        <div className="d-sm-none">
          <div className={style["data-room__container"]}>
            <div className={style["data-room__box-top"]}>
              <PropertyDataRoomTitle />
              <PropertyDataRoomText text={marketResearchText} />
              <PropertyDatsRoomImages images={marketResearchImage} />
            </div>
            <PropertyDataRoomFiles documents={documents} propertyName={propertyName} />
          </div>
        </div>
        <div className="d-none d-sm-flex w-100">
          <div className={style["data-room__container"]}>
            <div className={dataRoomBoxClass}>
              <div className={style["data-room__box-top"]}>
                <PropertyDataRoomTitle />
                <PropertyDataRoomText text={marketResearchText} />
                <PropertyDataRoomFiles documents={documents} propertyName={propertyName} />
              </div>
              <div className={style["data-room__img-right"]}>
                <PropertyDatsRoomImages images={marketResearchImage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
});
