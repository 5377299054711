import { memo, FC } from 'react';
import { Container } from 'react-bootstrap';
import { PropertyDisclaimerTitle } from './property-disclaimer-title';
import { PropertyDisclaimers } from './property-disclaimers';

import style from './style.module.scss';

interface PropertyDisclaimerProps {
  disclaimers: Array<string>;
}

export const PropertyDisclaimer: FC<PropertyDisclaimerProps> = memo(({
  disclaimers,
}) => {
  return (
    <Container>
      <PropertyDisclaimerTitle />
      <div className={style['disclaimer-container']}>
        <PropertyDisclaimers disclaimers={disclaimers} />
      </div>
    </Container>
  )
});
