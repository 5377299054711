import { memo } from "react";
import { ReactComponent as DataRoomSvg } from "assets/data-room.svg";

import style from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

export const PropertyDataRoomTitle = memo(() => {
  const { t: tOpportunityDetailPageSecurityInformation } = useTranslate('opportunity_detail_page.data_room');

  return (
    <div className="d-flex align-items-center text-primary">
      <div className={style["data-room__icon-container"]}>
        <DataRoomSvg />
      </div>
      <div className={style["data-room__text"]}>
        {tOpportunityDetailPageSecurityInformation('opportunity_detail_page.data_room.data_room')}
      </div>
    </div>
  );
});
