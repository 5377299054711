import React from 'react'
import Input from "components/Input";
import { useTranslate } from '@tolgee/react';

interface SetPasswordProps {
  password: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  confirmPassword: {
    value: string,
    callback: (value: string) => any,
    inputProps?: React.InputHTMLAttributes<any>,
  },
  label?: string,
  description?: string
}


const SetPassword: React.FC<SetPasswordProps> = ({
  password,
  confirmPassword,
  label,
  description
}) => {
  const { t: tPublicArea } = useTranslate('public_area');

  return (
    <>
      {
        label &&
        <p className='h1 font-weight-bold text-center'>{label}</p>
      }
      {
        description &&
        <p>{description}</p>
      }
      <Input
        passwordMode={true}
        inputAttributes={{
          placeholder: tPublicArea('public_area.set_a_password'),
          ...password.inputProps,
          value: password.value,
          onChange: (event => password.callback(event.target.value))
        }}
      />
      <Input
        passwordMode={true}
        inputAttributes={{
          placeholder: tPublicArea('public_area.confirm_password'),
          ...confirmPassword.inputProps,
          value: confirmPassword.value,
          onChange: (event => confirmPassword.callback(event.target.value))
        }}
      />
    </>
  );
}

export default SetPassword
