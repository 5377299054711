import { RootState } from 'store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { performanceReportApi } from '../api/performance-report';

interface PerformanceReportAuthState {
  propertyId: string | null;
  password: string | null;
  accessAllowed: boolean;
}

const initialState: PerformanceReportAuthState = {
  propertyId: null,
  password: null,
  accessAllowed: false
}

export const performanceReportSlice = createSlice({
  name: 'performanceReportSlice',
  initialState,
  reducers: {
    setProperty(state, action: PayloadAction<string | null>) {
      const { payload } = action;

      if (state.propertyId === payload) {
        return;
      }

      state.propertyId = payload;
      state.password = null;
      state.accessAllowed = false;
    },

    setPassword(state, action: PayloadAction<string | null>) {
      const { payload } = action;

      if (state.password === payload) {
        return;
      }

      state.password = payload;
      state.accessAllowed = false;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(performanceReportApi.endpoints.authorize.matchFulfilled, (state, action) => {
      const { payload } = action;
      state.accessAllowed = payload.unlocked;
    });
  },
});

export const performancePropertyId = (state: RootState) => state.performanceReportSlice.propertyId;
export const performancePassword = (state: RootState) => state.performanceReportSlice.password || '';
export const performanceAccessAllowed = (state: RootState) => state.performanceReportSlice.accessAllowed;

export const { setProperty, setPassword } = performanceReportSlice.actions;