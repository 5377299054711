import { ChangeEvent, FC, memo } from 'react';
import { Input } from 'new-architecture/components/input';
import { FormikErrors, FormikTouched } from 'formik';
import { InvestData } from '../../types/invest-data';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

// const mainInputs = [
//   {
//     label: 'ISIN',
//     placeholder: 'ISIN',
//     id: 'ISIN',
//     wrapperClass: 'modal-body__main-inputs-group__isin',
//   },
//   {
//     label: 'Internal Name',
//     placeholder: 'Internal Name',
//     id: 'internalName',
//     wrapperClass: 'modal-body__main-inputs-group__internal-name',
//   },
//   {
//     label: 'Investment Strategy',
//     placeholder: 'Investment Strategy',
//     id: 'investmentStrategy',
//     wrapperClass: 'modal-body__main-inputs-group__investment-strategy',
//   },
//   {
//     label: 'Classification',
//     placeholder: 'Classification',
//     id: 'classification',
//     wrapperClass: 'modal-body__main-inputs-group__classification',
//   },
//   {
//     label: 'Address',
//     placeholder: 'Address',
//     id: 'address',
//     wrapperClass: 'modal-body__main-inputs-group__address',
//   },
// ];

const additionaInputs = [
  {
    label: 'modal.name_of_the_advisory',
    placeholder: 'modal.name',
    id: 'serviceProviderName',
    wrapperClass: 'modal-body__additional-inputs-group__service-provider-name',
  },
  {
    label: 'modal.contact_person_name',
    placeholder: 'modal.name',
    id: 'contactPersonName',
    wrapperClass: 'modal-body__additional-inputs-group__contact-person-name',
  },
  {
    label: 'modal.telephone_number',
    placeholder: 'modal.contact_phone_number',
    id: 'telephoneNumber',
    wrapperClass: 'modal-body__additional-inputs-group__telephone-number',
  },
  {
    label: 'modal.e_mail_address',
    placeholder: 'modal.contact_email',
    id: 'email',
    wrapperClass: 'modal-body__additional-inputs-group__e-mail-address',
  },
  {
    label: 'modal.investment_amount',
    placeholder: '',
    id: 'investmentAmount',
    wrapperClass: 'modal-body__additional-inputs-group__investment-amount',
  },
  {
    label: 'modal.custody_bank',
    placeholder: '',
    id: 'custodyBank',
    wrapperClass: 'modal-body__additional-inputs-group__custody-bank',
  },
  {
    label: 'modal.bank_contact_name',
    placeholder: '',
    id: 'bankContactNameEmail',
    wrapperClass: 'modal-body__additional-inputs-group__bank-contact-name-and-email',
  },
  // {
  //   label: 'Currency',
  //   placeholder: '',
  //   id: 'currency',
  //   wrapperClass: 'modal-body__additional-inputs-group__currency',
  // },
  // {
  //   label: 'Issuance Volume',
  //   placeholder: '',
  //   id: 'issuanceVolume',
  //   wrapperClass: 'modal-body__additional-inputs-group__issuance-volume',
  // },
  // {
  //   label: 'Minimum Investment',
  //   placeholder: '',
  //   id: 'minimumInvestment',
  //   wrapperClass: 'modal-body__additional-inputs-group__minimum-investment',
  // },
];

interface PropertyInvestModalBodyProps {
  values: InvestData;
  handleChange: (event: ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<InvestData>>;
  errors: FormikErrors<InvestData>;
  handleBlur: (e) => void;
  touched: FormikTouched<InvestData>;
}

export const PropertyInvestModalBody: FC<PropertyInvestModalBodyProps> = memo(({
  values,
  handleChange,
  errors,
  handleBlur,
  touched
}) => {
  const { t: tInvestModal } = useTranslate('modal');

  return (
    <div className={styles['modal-body']}>
      {/* <div className={styles['modal-body__main-inputs-group']}>
        {mainInputs.map(({ wrapperClass, label, placeholder, id }) => (
          <div className={styles[wrapperClass]} key={id}>
            <Input
              label={label}
              inputAttributes={{
                placeholder,
                value: values[id],
                id,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              error={errors[id]}
              touched={touched[id]}
            />
          </div>
        ))}
      </div> */}
      <div className={styles['modal-body__additional-inputs-group']}>
        {additionaInputs.map(({ wrapperClass, label, placeholder, id }) => (
          <div className={styles[wrapperClass]} key={id}>
            <Input
              label={tInvestModal(label)}
              inputAttributes={{
                placeholder: tInvestModal(placeholder),
                value: values[id],
                id,
                onChange: handleChange,
                onBlur: handleBlur,
                onFocus: handleBlur,
              }}
              error={errors[id]}
              touched={touched[id]}
            />
          </div>
        ))}
      </div>
    </div>
  );
});