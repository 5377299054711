import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PropertyEducationAndResearchTitle } from "./property-education-and-research-title";
import classNames from "classnames";
import { PropertyEducationAndResearchCard } from "./property-education-and-research-card";
import { PropertyEducationAndResesrchMaterialView } from "./property-education-and-research-material-view";
import { useTrackEducationAndResearchMaterialCloseMutation, useTrackEducationAndResearchMaterialOpenMutation, useTrackEducationAndResearchMaterialPageChangeMutation } from "api/analitics";
import { v4 } from "uuid";
import { useSession } from "context/session";
import { WhoAreYou } from "types/user/who-are-you";
import { EducationMaterial } from "types/education-materials/education-material";

import style from "./style.module.scss";

type PropertyEducationAndResearchProps = {
  materials: Array<EducationMaterial>;
};

const educationBoxClass = classNames(
  "d-flex flex-column h-100",
  style["education-and-research__box"]
);
export const PropertyEducationAndResearch: FC<PropertyEducationAndResearchProps> =
  memo(({ materials }) => {
    const [previewedMaterial, setPreviewedMaterial] = useState<null | EducationMaterial>(null);

    const { isOpen, file, mimeType, title, suggestedMaterials } = useMemo(() => {
      if (!previewedMaterial) {
        return ({
          isOpen: false,
          file: '',
          mimeType: '',
          title: '',
          suggestedMaterials: [],
        });
      }

      return ({
        isOpen: !!previewedMaterial,
        file: previewedMaterial.file,
        mimeType: previewedMaterial.mimeType,
        title: previewedMaterial.name,
        suggestedMaterials: previewedMaterial.suggestedMaterials,
      });
    }, [previewedMaterial]);

    const sessionId = useRef(v4());

    const generateNewSessionId = useCallback(() => {
      sessionId.current = v4();
    }, []);

    const [trackMaterialOpen] = useTrackEducationAndResearchMaterialOpenMutation();
    const [trackMaterialClose] = useTrackEducationAndResearchMaterialCloseMutation();
    const [trackMaterialPageChange] = useTrackEducationAndResearchMaterialPageChangeMutation();

    const onMaterialOpen = useCallback((material: EducationMaterial, page: number) => {
      trackMaterialOpen({
        material: material.name,
        materialId: material._id,
        page: String(page),
        sessionId: sessionId.current
      })
    }, [trackMaterialOpen]);

    const onMaterialClose = useCallback((material: EducationMaterial, page: number) => {
      trackMaterialClose({
        material: material.name,
        materialId: material._id,
        page: String(page),
        sessionId: sessionId.current
      });

      generateNewSessionId()
    }, [generateNewSessionId, trackMaterialClose]);

    const onMaterialPageChange = useCallback((material: EducationMaterial, page: number) => {
      trackMaterialPageChange({
        material: material.name,
        materialId: material._id,
        page: String(page),
        sessionId: sessionId.current
      })
    }, [trackMaterialPageChange]);

    const { whoAreYou } = useSession();

    if (!materials.length) {
      return null;
    }

    return (
      <Container>
        <PropertyEducationAndResearchTitle />
        <div className={style["education-and-research__container"]}>
          {materials.map((material: EducationMaterial) => (
            material?.accessFor?.includes(WhoAreYou[whoAreYou]) &&
            <div className={educationBoxClass} key={material._id}>
              <div className="h-100">
                <PropertyEducationAndResearchCard
                  material={material}
                  setPreviewedMaterial={setPreviewedMaterial}
                />
              </div>
            </div>
          ))}
        </div>
        {previewedMaterial && 
          <PropertyEducationAndResesrchMaterialView
            isOpen={isOpen}
            setIsOpen={() => {}}
            file={file}
            mimeType={mimeType}
            name={title}
            suggestedMaterials={suggestedMaterials || []}
            onClose={() => setPreviewedMaterial(null)}

            material={previewedMaterial}
            onMaterialOpen={onMaterialOpen}
            onMaterialClose={onMaterialClose}
            onMaterialPageChange={onMaterialPageChange}
          />
        }
      </Container>
    );
  });
