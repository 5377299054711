import { FC, memo, useCallback, useMemo } from 'react';
import { PropertyTeaserType, SubscriptionStatus } from 'store/properties/types';
import { PropertyStatus } from '../property-card-status';
import { PropertyCardAllPhotosButton } from '../property-card-all-photos-button';
import { PropertyCardButtons } from '../property-card-buttons';
import classNames from 'classnames';
import { PropertyCardStats } from '../property-card-stats';
import { emptyField } from '../../constans/empty-field';
import { ReactComponent as LocationIcon } from "assets/location-property.svg";
import investorMultiple from "assets/icon_investormultiple.svg";
import investorIrr from "assets/Icon_investor_irr.svg";
import iconTarget from "assets/iconTarget.svg";
import investorCashFlow from "assets/Icon_investorcashflow.svg";
import { Collapse, Fade } from 'react-bootstrap';
import { Breakpoint } from '../../constans/breakpoints';
import { collapseAnimationDuration } from '../../constans/collapse-animation';
import { ReactComponent as BackSvg } from "assets/back-arrow.svg";
import { ReactComponent as  ShareSvg } from "assets/share-property.svg";
import { useBookmarkPropertyMutation } from "api/property-teaser";
import { SaveButton } from '../save-button';
import { trackPropertyReaction } from 'helpers/tracking';
import { Link } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';

import styles from './styles.module.scss';

interface PropertyCardProps {
  property: PropertyTeaserType;
  isVisible: boolean;
  currentBreakpoint: number;
  onScrinkStart: () => void;
  onShrinkFinish: () => void;
  openShareModal: () => void;
}

const locationAdressClass = classNames('text-primary', styles.location__adress);

export const PropertyCard: FC<PropertyCardProps> = memo(({
  property,
  isVisible,
  currentBreakpoint,
  onScrinkStart,
  onShrinkFinish,
  openShareModal
}) => {
  const { options, assets, locations, summary, specialLocationDescription, name: fullName } = property;
  const { name, organisation } = useMemo(() => {
    const dividerIndex = fullName.indexOf('|');
    let name, organisation;
    if (~dividerIndex) {
      organisation = fullName.substring(0, dividerIndex).trim();
    }

    name = fullName.substring(dividerIndex + 1, fullName.length).trim();

    return { organisation, name };
  }, [fullName]);

  const { status } = options;

  const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');
  const { t: tOpportunityCard } = useTranslate('opportunity_card');

  const subHeaderItemsArray = useMemo(() =>[
    {
      name: tOpportunityDetailPageCard('opportunity_detail_page.card.back_button'),
      shortName: 'Back',
      icon: <BackSvg />,
    },
    {
      name: tOpportunityDetailPageCard('opportunity_detail_page.card.share'),
      icon: <ShareSvg />,
    },
  ], [tOpportunityDetailPageCard]);

  const _searchValues = useMemo(() => {
    const searchValues = {
      'soldPercentage': '',
      'totalIssuanceVolume': '',
    };

    Object.keys(searchValues).forEach((key) => {
      searchValues[key] = summary.find(({ id }) => id === key)?.value || emptyField;
    });

    return searchValues;
  }, [summary]);

  const stats = useMemo(() => {
    const searchValues = {
      'investorMultiple': '',
      'investorIRR': '',
      'targetHoldingPeriod': '',
      'investorCashflow': '',
      'relincPrice' : ''
    };

    Object.keys(searchValues).forEach((key) => {
      searchValues[key] = summary.find(({ id }) => id === key)?.value || emptyField;
    });

    const out = [
      {
        className: styles['charts-container__investor-multiple'],
        title: tOpportunityCard('opportunity_card.investor_multiple'),
        icon: investorMultiple,
        value: searchValues['investorMultiple'],
        description: { label: tOpportunityCard('opportunity_card.investor_multiple.description') }
      },
      {
        className: styles['charts-container__investor-irr'],
        title: tOpportunityCard('opportunity_card.investor_irr'),
        icon: investorIrr,
        value: searchValues['investorIRR'],
        description: { label: tOpportunityCard('opportunity_card.investor_irr.description') }
      },
      {
        className: styles['charts-container__target-holding-period'],
        title: tOpportunityCard('opportunity_card.target_holding_period'),
        icon: iconTarget,
        value: searchValues['targetHoldingPeriod'],
        description: { label: tOpportunityCard('opportunity_card.target_holding_period.description') }
      },
      {
        className: styles['charts-container__investor-cashflow'],
        title: tOpportunityCard('opportunity_card.investor_cashflow'),
        icon: investorCashFlow,
        value: searchValues['investorCashflow'],
        description: { label: tOpportunityCard('opportunity_card.investor_cashflow.description') }
      },
      {
        className: styles['charts-container__total-issuance-volume'],
        title: tOpportunityCard('opportunity_card.total_issuance_volume'),
        value: _searchValues["totalIssuanceVolume"],
        description: { label: tOpportunityCard('opportunity_card.total_issuance_volume.description') }
      },
    ];


    if (options.relincPrice) {
      out.push(      {
        className: styles['charts-container__relinc-price'],
        title: tOpportunityCard('opportunity_card.relinc_price'),
        value: options.relincPrice,
        description: { label: tOpportunityCard('opportunity_card.relinc_price.description') }
      });
    }

    return out;
  }, [tOpportunityCard, _searchValues, options.relincPrice, summary]);

  const imageSrc = useMemo(() => assets[0], [assets]);

  const cities: string | undefined = useMemo(() => {
    // const cities: Array<string> = [];

    // if (!locations) {
    //   return emptyField;
    // }

    // locations.forEach(({ coords }) => {
    //   const { city } = coords;
    //   if (city) {
    //     cities.push(city);
    //   }
    // });

    // return cities.length ? cities.join(', ') : emptyField;
    return locations[0]?.coords?.city;
  }, [locations]);

  const [bookmark] = useBookmarkPropertyMutation();

  const handleBookmark = useCallback(async () => {
    if (property?._id) {
      await bookmark(property?._id);
    }

    if (!property.user?.isBookmarked) {
      trackPropertyReaction("bookmark", "bookmark");
    }

  }, [property._id, bookmark, property.user?.isBookmarked]);

  return (
    <div>
      <div
        className={
          classNames(
            styles.card,
            { [styles['card_closed-theme']]: status === SubscriptionStatus.CLOSED_TRANSACTION,
              [styles['card_collapsed']]: !isVisible,
            }
          )
        }
      >
        <Collapse
          in={currentBreakpoint >= Breakpoint.SM || isVisible}
          dimension="height"
          onExiting={onScrinkStart}
          onExited={onShrinkFinish}
          timeout={collapseAnimationDuration}
        >
          <div>
            <div
              className={
                classNames(
                  styles["card__image-container"],
                  { [styles['card__image-container_closed-theme']]: status === SubscriptionStatus.CLOSED_TRANSACTION }
                )
              }
            >
              {imageSrc && <img alt="Property" src={imageSrc} />}
              <Fade in={ currentBreakpoint < Breakpoint.SM || isVisible} timeout={collapseAnimationDuration}>
                <div>
                  <div
                    className={
                      classNames(
                        styles["card__status-container"],
                        { [styles['disabled']]: !(isVisible && currentBreakpoint >= Breakpoint.SM) }
                      )
                    }
                  >
                    <PropertyStatus status={status} />
                  </div>
                </div>
              </Fade>
              
              <Fade in={currentBreakpoint < Breakpoint.SM || isVisible} timeout={collapseAnimationDuration}>
                <div>
                  <div
                    className={
                      classNames(
                        styles["card__all-photos-button-container"],
                        { [styles['disabled']]: !(isVisible && currentBreakpoint >= Breakpoint.SM) }
                      )
                    }
                  >
                    <PropertyCardAllPhotosButton name={name} assets={assets} />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Collapse>
        <div
          className={
            classNames(
              styles["card__details-container"],
              { [styles['card__details-container_shrinked']]: !isVisible }
            )
          }
        >
          <div className="d-flex justify-content-between">
            <div className={styles['name']}>
              {organisation && (
                  <>
                    <div className={styles['name__organization']}>
                      {organisation}
                    </div>
                    {' | '}
                  </>
              )}
              <div
                className={styles['name__name']}
              >
                {name}
              </div>
            </div>
            <div
              className={
                classNames(
                  { 'd-none': isVisible }
                )
              }
            >
              <div
                className={
                  classNames(
                    'd-none d-sm-flex align-items-center',
                    styles.action_buttons__wrapper
                  )
                }
              >
                {subHeaderItemsArray.slice(1).map(({ icon }, i) => (
                  <div
                    className={
                      classNames(
                        'd-flex align-items-center',
                        styles['action_buttons__wrapper-item']
                      )
                    }
                    key={`${name}${i}`}
                    onClick={openShareModal}
                  >
                    <div
                      className="d-flex align-items-center"
                      role="button"
                    >
                      <div className={styles['action_buttons__icon-wrapper']}>
                        {icon}
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className={
                    classNames(
                      'd-flex align-items-center',
                      styles['action_buttons__wrapper-item']
                    )
                  }
                >
                  <SaveButton
                    onClick={handleBookmark}
                    isBookmarked={property.user?.isBookmarked ? property.user?.isBookmarked : false}
                    text={['', '']}
                  />
                </div>
              </div>
            </div>
          </div>
          <Collapse
            in={isVisible}
            onExiting={onScrinkStart}
            onExited={onShrinkFinish}
            timeout={collapseAnimationDuration}
          >
            <div className="collapsable">
              <div className={styles['location']} role="button">
                <div className={styles['location__icon-container']}>
                  <LocationIcon />
                </div>
                <div className={locationAdressClass}>
                  {specialLocationDescription || cities || emptyField}
                </div>
              </div>
              <div className={styles["charts-container"]}>
                {stats.map(({ className, title, icon, value, description }) => (
                  <div className={className} key={title}>
                    <PropertyCardStats
                      description={description}
                      title={title}
                      icon={icon}
                      value={value}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
          <div
            className={
              classNames(
                'd-flex justify-content-between w-100',
                styles['card__action-buttons-container'],
                {
                  [styles['card__action-buttons-container_shrinked']]: !isVisible,
                }
              )
            }
          >
            <Collapse
              in={!isVisible}
              onExiting={onScrinkStart}
              onExited={onShrinkFinish}
              timeout={collapseAnimationDuration}
              dimension="width"
            >
              <div>
                <Link
                  to='/opportunities'
                  className={
                    classNames(
                      'd-none d-sm-flex align-items-center h-100',
                      styles['width-max']
                    )
                  }
                >
                  <div className={styles['action_buttons__icon-wrapper']}>
                    {subHeaderItemsArray[0].icon}
                  </div>
                  <div className={styles['action_buttons__name']}>
                    {subHeaderItemsArray[0].name}
                  </div>
                  <div className={styles['action_buttons__name_short']}>
                    {subHeaderItemsArray[0]?.shortName}
                  </div>
                </Link>
              </div>
            </Collapse>

            <div
              className={
                classNames(
                  {
                    'w-100': isVisible || currentBreakpoint < Breakpoint.SM
                  }
                )
              }
            >
              <PropertyCardButtons
                property={property}
                collapsed={!isVisible}
              />
            </div>
          </div>
        </div>
      </div>
      <Collapse 
        in={isVisible}
        onExiting={onScrinkStart}
        onExited={onShrinkFinish}
        timeout={collapseAnimationDuration}
        dimension="width"
      >
        <div>
          <div
            className={
              classNames(
                'd-none d-sm-block',
                styles['card__bottom-spacer']
              )
            }
          />
        </div>
      </Collapse>
    </div>
  );
})

PropertyCard.displayName = 'PropertyCard';
