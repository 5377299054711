import { validatePercentageString } from 'helpers/validate-precentage-string';
import { FC, memo, useMemo } from 'react';
import { CustomField } from 'store/properties/types';
import { InvestmentOpportunityDetailsItem } from '../investment-opportunity-details-item';
import { InvestmentRiskItems } from '../investment-risk-item';
import { InvestmentRiskProgressBarWithPercentages } from '../investment-risk-progress-bar-with-percentages';
import { IconType, iconTypes } from '../../constants/icon-type';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface InvestmentOpportunityDetailsProps {
  values: Array<CustomField>;
}

export const InvestmentOpportunityDetails: FC<InvestmentOpportunityDetailsProps> = memo(({ values }) => {
  const populatedDetailStats = useMemo(() => {
    const out: Array<{
      title: string,
      value: string | JSX.Element,
      tooltip?: string;
      icon?: string;
    }> = [];

    values.forEach((_value) => {
      if (!_value) {
        return;
      }

      const { name, value, tooltip, id, type } = _value;
      
      if (!value) {
        return;
      }

      switch (true) {
        case id === 'riskLevel' && type === IconType.RISK_LEVELS:
          out.push({ 
            title: name,
            value: <InvestmentRiskItems value={value} />,
            tooltip,
            // icon: (type ? iconTypes[type] : undefined)
          });
        return;

        case id === 'loanToValue' && type === IconType.PROGRESS_BAR:
          const { isValid, value: parsedValue } = validatePercentageString(value);
          
          if(!isValid) {
            break;
          }

          out.push({ 
            title: name,
            value: <InvestmentRiskProgressBarWithPercentages progress={parsedValue} />,
            tooltip,
            // icon: (type ? iconTypes[type] : undefined)
          });
        return;
      }

      out.push({ title: name, value, tooltip, icon: (type ? iconTypes[type] : undefined) });
    })

    return out;
  }, [values]);

  const { t: tOpportunityDetailPageInvestment } = useTranslate('opportunity_detail_page.investment');

  return (
    <div className={styles.details__container}>
      <div className={styles.details__title}>
        {tOpportunityDetailPageInvestment('opportunity_detail_page.investment.subheader_information')}
      </div>
      <div className={styles.details__list}>
        {populatedDetailStats.map(({ title, value, tooltip, icon }, index) => (
          <InvestmentOpportunityDetailsItem
            title={title}
            value={value}
            key={title + index}
            tooltip={tooltip}
            icon={icon}
          />
        ))}
      </div>
    </div>
  );
})

InvestmentOpportunityDetails.displayName = 'InvestmentOpportunityDetails'
