import { FC, memo, useCallback } from 'react';
import { Document } from 'store/properties/types';
import classNames from 'classnames';

import documentRemoveBtn from "assets/document-remove-btn.svg";
import documentAddBtn from "assets/document-add-btn.svg";

import documentPDF from "assets/document_pdf.svg";
import documentXLSX from "assets/document_xlsx.svg";
import documentDOC from "assets/document_doc.svg";
import documentZIP from "assets/document_zip.svg";
import documentDefault from "assets/document_default.svg";

import styles from './style.module.scss';

interface DocumentsSelectProps {
  availableDocuments: Array<Document>;
  teaserName: string;
  attachTeaser: boolean;
  setAttachTeaser: (value: boolean) => void;
  attachments: Array<Document>;
  onChangeAttachments: (newValue: Array<Document>) => void;
}

export const DocumentsSelect: FC<DocumentsSelectProps> = memo(({
  availableDocuments,
  teaserName,
  attachTeaser,
  setAttachTeaser,
  attachments,
  onChangeAttachments
}) => {
  const _getDocumentIcon = useCallback((uri: string) => {
    let ext = uri.split(".").pop();
    if (ext) {
      ext = ext.toLowerCase();
    }
    switch (ext) {
      case "pdf":
        return documentPDF;
      case "xlsx":
        return documentXLSX;
      case "doc":
        return documentDOC;
      case "docx":
        return documentDOC;
      case "zip":
        return documentZIP;
      default:
        return documentDefault;
    }
  }, []);

  return (
    <div
      className={
        classNames(
          styles['documents-list']
        )
      }
    >
      <div
        className={
          classNames(
            styles['documents-list__item'],
            {[styles['documents-list__item_selected']]: attachTeaser},
            `d-flex justify-content-between align-items-center`
          )
        }
        key={"attachment_teaser"}
      >
        <div className="d-flex align-items-center mr-2">
          <img
            src={_getDocumentIcon("pdf")}
            alt=""
            className="mr-3"
          />
          <span className={styles['documents-list__item-name']}>
            {teaserName}
          </span>
        </div>
        {attachTeaser ? (
          <img
            src={documentRemoveBtn}
            onClick={() => {
              setAttachTeaser(false);
            }}
            className="cursor-pointer"
            alt="unattach"
          />
        ) : (
          <img
            src={documentAddBtn}
            onClick={() => {
              setAttachTeaser(true);
            }}
            className="cursor-pointer"
            alt="attach"
          />
        )}
      </div>

      {availableDocuments.map((attachment) => {
        // we not show attachmentswithout download url
        if (!attachment.url) {
          return null;
        }
        const isAttached = attachments.find((a) => a._id === attachment._id);

        return (
          <div
            className={
              classNames(
                styles['documents-list__item'],
                {[styles['documents-list__item_selected']]: isAttached},
                `d-flex justify-content-between align-items-center`
              )
            }
            key={"attachment_" + attachment.url}
          >
            <div className="d-flex align-items-center mr-2">
              <img
                src={_getDocumentIcon(attachment.url)}
                alt="document icon"
                className="mr-3"
              />
              <span className={styles['documents-list__item-name']}>
                {attachment.name}
              </span>
            </div>
            {isAttached ? (
              <img
                src={documentRemoveBtn}
                onClick={() => {
                  onChangeAttachments(attachments.filter(
                    (a) => a._id !== attachment._id
                  ));
                }}
                className="cursor-pointer"
                alt=""
              />
            ) : (
              <img
                src={documentAddBtn}
                onClick={() => {
                  onChangeAttachments([...attachments, attachment]);
                }}
                className="cursor-pointer"
                alt=""
              />
            )}
          </div>
        );
      })}
    </div>
  );
});
