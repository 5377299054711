import React from "react";
import { Redirect } from "react-router-dom";
import { useSession } from "../../context/session";

interface PrivateRouteProps {
  children: JSX.Element | React.ReactChildren;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user } = useSession();

  if (!user) {
    localStorage.removeItem("jwtToken");
    localStorage.setItem("sessionLastPageSeen", window.location.href);

    return <Redirect to={{ pathname: "/login" }} />;
  }

  return (<>{children}</>);
};

export default PrivateRoute;
