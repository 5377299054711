import React, { useMemo } from 'react'
import Input from "../../components/Input";
import Button from "../../components/Button";
import {UserAPI} from "../../api/user";
import CircleFilledIcon from "../../assets/circle-filled.svg";
import history from "../../history-entity";
import { _validateEmail } from "helpers/helpers";
import { useTranslate } from '@tolgee/react';


const ForgotPassword: React.FC<{}> = () => {
  const [email, setEmail] = React.useState('')
  const [mailSend, setMailSend] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { t: tPublicAreaForgotPassword } = useTranslate('public_area.forgot_password');
  const { t: tEmailTemplates } = useTranslate('email_templates');

  const sendVerificationCodeInEmail = () => {
    setLoading(true)
    UserAPI.forgotPassword(email)
      .then(message => {
        setMailSend(true)
        setLoading(false)
      })
      .catch(e => {
        setErrorMessage(e.message)
        setMailSend(false)
        setLoading(false)
      })
  }

  const translatedMessage = useMemo(() => {
    switch (errorMessage) {
      case 'Invalid email address':
        return tPublicAreaForgotPassword('public_area.forgot_password.invalid_email_address');
      default:
        return null;
    }
  }, [errorMessage, tPublicAreaForgotPassword]);

  if (loading) {
    return  (
      <div className="d-flex justify-content-center mt-8 align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only ">Loading...</span>
        </div>
      </div>
    )
  }

  const isValid = _validateEmail(email)
  if (mailSend) {
    return (
      <div className='col-10 col-md-8 col-lg-6 col-xl-4 mx-auto mt-7'>
        <h1 className='text-center'>Confirmation</h1>
        <div
          className="alert alert-info d-flex justify-content-center align-items-center py-4">
          <img
            src={CircleFilledIcon}
            className='mr-3'
            alt=""
          />
          <p className='h4 mb-0'>
            {tEmailTemplates('email_templates.password_reset_email_sent_details')}.
          </p>
        </div>
        <p className='text-center'>
          {tEmailTemplates('email_templates.password_reset_email_sent_details')}
        </p>
      </div>
    )
  }

  return (
    <div className='w-75 mx-auto mt-7 d-flex flex-wrap forgot-pwd'>
      <div className="back-button cursor-pointer align-self-start"
        onClick={() => history.goBack()}
      >
        <i className='fe fe-chevron-left font-weight-bold text-black-50 h3'></i>
        <span className='ml-3 text-black-50 h3'>
          {tPublicAreaForgotPassword('public_area.forgot_password.back')}
        </span>
      </div>
      <div
        className='col-12 col-md-8 col-lg-6 col-xl-4 m-auto'
      >
        <h1 className='text-center font-weight-bold'>
          {tPublicAreaForgotPassword('public_area.forgot_password.forgot_password_title')}
        </h1>
        <p>
          {tPublicAreaForgotPassword('public_area.forgot_password.please_enter_email')}
        </p>
        {
          translatedMessage &&
          <div className="alert alert-danger my-3 text-center">
            <span>
              {translatedMessage}
            </span>
          </div>
        }
        <form onSubmit={sendVerificationCodeInEmail}>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            inputAttributes={{
              value: email,
              placeholder: 'ex. email@gmail.com'
            }}
          />
          <Button
            label={tPublicAreaForgotPassword('public_area.forgot_password.reset_password')}
            className='btn-block'
            buttonAttributes={{
              type: "submit",
              disabled: !isValid
            }}
          />
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
