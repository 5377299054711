import { FC } from "react";
import { ReactBootstrapPopUp } from "modal/react-bootstrap-pop-up";
import { EducationMaterial } from "types/education-materials/education-material";
import { useTranslate } from "@tolgee/react";

type PropertyEducationAndResearchShareModalProps = {
  sharedMaterial: EducationMaterial | null;
  onHide: () => void;
  shareFileEncodedLink: string;
  onCopyDownloadMaterialLink: () => void;
  isCopyClicked: boolean;
};

export const PropertyEducationAndResearchShareModal: FC<
  PropertyEducationAndResearchShareModalProps
> = ({
  sharedMaterial,
  onHide,
  shareFileEncodedLink,
  onCopyDownloadMaterialLink,
  isCopyClicked,
}) => {
  const { t: tEstatingAcademy } = useTranslate('estating_academy');

  return (
    <ReactBootstrapPopUp
      isShow={!!sharedMaterial}
      handleClose={onHide}
      headerContent={<>{tEstatingAcademy('estating_academy.share_document')}</>}
      bodyContent={
        <div className="d-flex flex-column flex-sm-row align-items-center">
          <input
            value={shareFileEncodedLink}
            className="mb-2 mb-sm-0 mr-sm-2 form-control radius-5"
            readOnly
          />
          <button
            className="btn btn-primary col px-md-5 h-100"
            onClick={onCopyDownloadMaterialLink}
          >
            {isCopyClicked ? tEstatingAcademy('estating_academy.copied') : tEstatingAcademy('estating_academy.copy')}
          </button>
        </div>
      }
      modalProps={{
        centered: true,
      }}
    />
  );
};