import { memo, FC, useCallback } from 'react';
import { TagDescription } from '../../types/tag-description';
import { TagPill } from '../tag-pill';
import removeAllTagsSVG from 'assets/solar_backspace-linear.svg';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

import style from './style.module.scss';

interface SearchTagsProps {
  tags: Array<TagDescription>;
  onRemoveTag: (tag: TagDescription) => void;
  onRemoveAllTags: () => void;
}

const tagClassWrapper = classNames("d-flex flex-wrap align-items-center ml-1",style.gap)
export const SearchTags: FC<SearchTagsProps> = memo(({
  tags,
  onRemoveTag,
  onRemoveAllTags,
}) => {
  const onRemove = useCallback((tag: TagDescription) => onRemoveTag(tag), [onRemoveTag]);

  const { t: tSearch } = useTranslate('homepage.search');

  if (!tags.length) {
    return null;
  }

  return (
    <div className="d-flex">
      <div className={`${style.title} text-uppercase flex-shrink-0`}>
        {tSearch('homepage.search.current_search')}

      </div>
      <div className={tagClassWrapper}>
        {tags.map((tag) => (
          <div>
            <TagPill tag={tag} onRemove={onRemove} />
          </div>
        ))}
        <div 
          className={`px-3 py-1 d-flex align-items-center ${style.clear_all_container} cursor-pointer`}
          onClick={onRemoveAllTags}
        >
          <img
            alt="remove all tags"
            src={removeAllTagsSVG}
            className="mr-1"
          />
          <div className={`text-center flex-shrink-0 ${style.clear_all_container_title}`}>
            {tSearch('homepage.search.clear_all')}
          </div>
        </div>
      </div>
    </div>
  );
});