export const getBaseUrl = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_ESTATING_API_URI ||
    process.env.REACT_APP_ESTATING_API_URI ||
    ("" as string)
  );
};

export const getFacebookAppId = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_FACEBOOK_APP_ID ||
    process.env.REACT_APP_FACEBOOK_APP_ID ||
    ("" as string)
  );
};

export const getGoogleMapApiKey = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_GOOGLE_MAP_API_KEY ||
    process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
    ("" as string)
  );
};

export const getGoogleClientId = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_GOOGLE_CLIENT_ID ||
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    ("" as string)
  );
};

export const getSessionExpirationTime = () => {
  return ((window as any)["_env_"]?.REACT_APP_SESSION_EXPIRATION_TIME ||
    process.env.REACT_APP_SESSION_EXPIRATION_TIME ||
    15) as number;
};

export const getGoogleRecaptchaSiteKey = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_G_RECAPTCHA_SITE_KEY ||
    process.env.REACT_APP_G_RECAPTCHA_SITE_KEY
  );
};

export const getGoogleMeasurementId = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_GOOGLE_MEASUREMENT_ID ||
    process.env.REACT_APP_GOOGLE_MEASUREMENT_ID
  );
};

export const getEstatingCatalystURL = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_ESTATING_CATALYST_URL ||
    process.env.REACT_APP_ESTATING_CATALYST_URL
  );
};

export const getEstatingCatalysSygnUpPageURL = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_ESTATING_SIGN_UP_URL ||
    process.env.REACT_APP_ESTATING_SIGN_UP_URL
  );
};

export const getTolgeeApiURL = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_TOLGEE_API_URL ||
    process.env.REACT_APP_TOLGEE_API_URL
  );
};

export const getTolgeeApiKey = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_TOLGEE_API_KEY ||
    process.env.REACT_APP_TOLGEE_API_KEY
  );
};

export const getTolgeeTranslationsCdnUrl = () => {
  return (
    (window as any)["_env_"]?.REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL ||
    process.env.REACT_APP_TOLGEE_TRANSLATIONS_CDN_URL
  );
};

