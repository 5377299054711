import { FC, memo, useCallback, useMemo } from "react";
import { ReactComponent as BackSvg } from "assets/back-arrow.svg";
import { ReactComponent as  ShareSvg } from "assets/share-property.svg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { trackPropertyReaction } from "helpers/tracking";
import { PropertyTeaserType } from "store/properties/types";
import { SaveButton } from "../save-button";
import { useBookmarkPropertyMutation } from "api/property-teaser";
import { useTranslate } from "@tolgee/react";

import styles from './styles.module.scss'

interface Props {
  property: PropertyTeaserType;
  openShareModal: () => void;
}

export const ActionButtons:FC<Props> = memo(({ property, openShareModal }) => {
  const [bookmark] = useBookmarkPropertyMutation();

  const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');

  const subHeaderItemsArray = useMemo(() =>[
    {
      name: tOpportunityDetailPageCard('opportunity_detail_page.card.back_button'),
      shortName: 'Back',
      icon: <BackSvg />,
    },
    {
      name: tOpportunityDetailPageCard('opportunity_detail_page.card.share'),
      icon: <ShareSvg />,
    },
  ], [tOpportunityDetailPageCard]);

  const handleBookmark = useCallback(async () => {
    if (property?._id) {
      await bookmark(property?._id);
    }

    if (!property.user?.isBookmarked) {
      trackPropertyReaction("bookmark", "bookmark");
    }
  }, [property._id, bookmark, property.user?.isBookmarked]);

  return(
    <div className={classNames('d-flex justify-content-between text-primary', styles.action_buttons__item)}>
      <Link to='/opportunities' className='d-none d-sm-flex align-items-center'>
        <div className={styles['action_buttons__icon-wrapper']}>
          {subHeaderItemsArray[0].icon}
        </div>
        <div className={styles.action_buttons__text}>
          {subHeaderItemsArray[0].name}
        </div>
      </Link>
      <div className={classNames('d-flex align-items-center', styles.action_buttons__wrapper)}>
        {subHeaderItemsArray.slice(1).map(({ name, icon }, i) => (
          <div 
            className={
              classNames(
                'd-flex align-items-center',
                styles['action_buttons__wrapper-item']
              )
            }
            key={`${name}${i}`}
            onClick={openShareModal}
          >
            <div
              className="d-flex align-items-center"
              role="button"
            >
              <div className={styles['action_buttons__icon-wrapper']}>
                {icon}
              </div>
              <span>
                {name}
              </span>
            </div>
          </div>
        ))}
        <div 
          className={
            classNames(
              'd-flex align-items-center',
              styles['action_buttons__wrapper-item']
            )
          }
        >
          <SaveButton
            onClick={handleBookmark}
            isBookmarked={property.user?.isBookmarked ? property.user?.isBookmarked : false}
          />
        </div>
      </div>
    </div>
  );
});