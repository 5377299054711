import { useState, FC, memo, useCallback, useRef, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from 'styled-components';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


import type { PDFDocumentProxy } from 'pdfjs-dist';
import { Pagination } from './pagination';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

const paginationHeight = 30;

interface PDFViewerProps {
  file: string | File;

  // analytics
  onPageChange?: (page: number) => void;
}

export const PDFViewer: FC<PDFViewerProps> = memo(({ file, onPageChange }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const [pdfContainerWidth, setPdfContainerWidth] = useState<number>(0);
  const [scale, setScale] = useState(1);

  const isPageRendered = useRef(false);

  useEffect(() => {
    setPdfContainerWidth(pdfContainerRef.current!.clientWidth);
  }, []);

  useEffect(() => {
    // first render prevent
    if (!isPageRendered.current) {
      isPageRendered.current = true;
      return;
    }

    if (!isPageRendered.current || !onPageChange) {
      return;
    }

    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  }

  const prevPage = useCallback(() =>  {
    setCurrentPage((prev) => {
      if (prev === 0) {
        return prev;
      }

      return --prev;
    });
  }, []);

  const nextPage = useCallback(() =>  {
    setCurrentPage((prev) => {
      if (prev === numPages - 1) {
        return prev;
      }

      return ++prev;
    });
  }, [numPages]);

  const goToFirstPage = useCallback(() => {
    setCurrentPage(0);
  }, []);

  const goToLastPage = useCallback(() => {
    setCurrentPage(numPages - 1);
  }, [numPages]);

  const goToPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const decreaseScale = useCallback(() => {
    setScale((prevValue) => {
      const newValue = Math.round((prevValue - 0.1) * 10) / 10;
      if (newValue < 0.5) {
        return prevValue
      }

      return newValue;
    })
  }, []);

  const increaseScale = useCallback(() => {
    setScale((prevValue) => {
      const newValue = Math.round((prevValue + 0.1) * 10) / 10;
      if (newValue > 2) {
        return prevValue
      }

      return newValue;
    })
  }, []);

  const [downloadedPdf, setDownloadedPdf] = useState<Blob>();
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);

  const downloadPdf = useCallback(async (downloadUrl) => {
    try {
      setIsPdfLoading(true);
      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'x-auth': localStorage.getItem('jwtToken') ?? ''
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
      }
  
      setDownloadedPdf(await response.blob());
      setIsPdfLoading(false);
      // Now you have the PDF data, and you can proceed to display it or download it.
    } catch (error) {
      setIsPdfLoading(false);
      console.error('Error fetching PDF:', error);
    }
  }, []);

  useEffect(() => {
    downloadPdf(file);
  }, [downloadPdf, file]);

  return (
    <PDFViewerContainer>
      <Pagination
        height={30}
        goToFirstPage={goToFirstPage}
        goToPrevPage={prevPage}
        goToNextPage={nextPage}
        goToLastPage={goToLastPage}
        goToPage={goToPage}
        totalPages={numPages}
        currentPage={currentPage + 1}
        decreaseScale={decreaseScale}
        increaseScale={increaseScale}
        scale={scale}
        setScale={setScale}
      />

      <PdfContainer className="mt-2" ref={pdfContainerRef}>
        {isPdfLoading && !downloadedPdf && 'loading'}
        {!isPdfLoading && !downloadedPdf && 'failed to load'}
        {!isPdfLoading && downloadedPdf &&
          <Document file={downloadedPdf} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            <Page scale={scale} width={pdfContainerWidth - 12} pageNumber={currentPage + 1} />
          </Document>
        }
      </PdfContainer>
    </PDFViewerContainer>
  );
});

const PDFViewerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const PdfContainer = styled.div`
  height: calc(100% - ${paginationHeight}px);
  width: 100%;

  overflow: auto;;
`;

