import { AdditionalValue } from 'store/properties/types';
import { memo, FC } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import { OverlayTrigger } from "react-bootstrap";
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { descriptionPopover } from 'new-architecture/components/description-popover';

import styles from './style.module.scss';

interface PropertyPartnersTableProps {
  roles: Array<AdditionalValue>;
}

export const PropertyPartnersTable: FC<PropertyPartnersTableProps> = memo(({ roles }) => {
  return (
    <div
      className={
        classNames(
          styles['custom-container_wrapper'],
          'container'
        )
      }
    >
      {roles.map(({ name, value, image, tooltip }, index) => {
        return (
          value && (
            <div
              key={name + value + index}
              className={
                classNames(
                  styles['partners'],
                  styles['rounding-container']
                )
              }
            >
              <Container className={styles['custom-container']}>
                <div
                  className={styles['partners__row']}
                >
                  <div className={styles['partners__row-name']}>
                    <span className={styles["security-info-item__title"]}>
                      {name}
                    </span>
                    {tooltip &&
                      <div 
                        role="button"
                        className={
                          classNames(
                            styles['security-info-item__title-icon-container'],
                          )
                        }
                      >
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="auto"
                          overlay={descriptionPopover({ 
                            descriptions: [{
                              description: tooltip,
                              title: name
                            }]
                          })}
                        >
                          <InfoSVG />
                        </OverlayTrigger>
                      </div>
                    }
                  </div>
                  <div
                    className={styles['partners__row-divider']}
                  />
                  <div className={styles['partners__row-value']}>
                    {value}
                  </div>
                  {image &&
                    <div
                      className={styles['partners__row-image']}
                    >
                      <div className={styles['partners__row-image-container']}>
                        <img alt="Partner" src={image} />
                      </div>
                    </div>
                  }
                </div>
              </Container>
            </div>
          )
      )})}
    </div>
  )}
);
