import { publicApi, privateApi } from "./estatingApi";
import { User, UserLocal } from "../context/types";
import { InvestOrShareMessage } from "../modal/SendMail";
import { AdditionalInformationIVO } from "../screens/Account/AdditionalInformation";
import { AuditLog } from "../store/properties/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./base-query";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserLocal, string>({
      query: (token) => {
        return {
          url: '/user/info',
          headers: { 'x-auth': token },
        }
      },
      providesTags: (result) => ['User'],
    }),
    updateUser: builder.mutation<UserLocal, Partial<UserLocal>>({
      query: (body) => { 
        const url = `/user/update`;

        return { url, method: 'put', body };
      },
      invalidatesTags: (result, error) => {
        if (!error && result) {
          return [
            { type: 'User'},
          ];
        }
        return [];
      },
    }),
  })
});

export const { useGetUserInfoQuery, useUpdateUserMutation } = userApi;

export const UserAPI = {
  logIn: (email: string, password: string) => {
    return new Promise<UserLocal>((resolve, reject) => {
      publicApi
        .post("/user/signin", {
          email,
          password,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("jwtToken", response.headers["x-auth"]);
            resolve(response.data);
          } else {
            reject(new Error(response.data || "Something went wrong"));
          }
        })
        .catch((error) => {
          reject(
            new Error(
              error.response.data?.message || "Invalid username or password"
            )
          );
        });
    });
  },
  logOut: () => {
    return new Promise<boolean>((resolve, reject) => {
      const token = localStorage.getItem("jwtToken");
      if (!token || !!token) {
        resolve(true);
      }

      privateApi
        .post("/user/signout")
        .then(() => {
          localStorage.removeItem("jwtToken");
          resolve(true);
        })
        .catch((err) => {
          reject(new Error(err.response.data?.message || "Fail to log out"));
        });
    });
  },
  signUp: (
    user: User,
    customFinancialAdvisor: object,
    recaptchaToken: string,
    predefinedUserToken: string | null
  ) => {
    const isFilled = Object.values(customFinancialAdvisor).every((v) => !!v);

    return new Promise<{ user: string }>((resolve, reject) => {
      publicApi
        .post("/user/signup", {
          user,
          predefinedUserToken,
          recaptchaToken,
          customFinancialAdvisor: isFilled ? customFinancialAdvisor : null,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(response.data || "Something went wrong"));
          }
        })
        .catch((error) => {
          reject(
            new Error(error.response.data?.message || "Fail to sign up user")
          );
        });
    });
  },
  verifyUser: (verifyToken: string, id: string) => {
    return new Promise<{
      fromSocial: boolean;
      token: string;
      message: string;
      user: UserLocal
    }>((resolve, reject) => {
      publicApi
        .get(`/user/verify/${id}/${verifyToken}`)
        .then((response) => {
          resolve({
            token: response.headers["x-auth"],
            ...response.data,
          });
        })
        .catch((error) => {
          reject(
            new Error(
              error.response.data?.message || "User verification failed"
            )
          );
        });
    });
  },
  changeUser: (updated: Partial<UserLocal>) => {
    return new Promise<UserLocal>(async (resolve, reject) => {
      try {
        const response = await privateApi.put("/user/update", { updated });
        localStorage.setItem("jwtToken", response.headers["x-auth"]);
        
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  },
  resetPassword: async (password: string, resetPasswordToken: string) => {
    return new Promise<UserLocal>(async (resolve, reject) => {
      publicApi
        .post("/user/resetPassword", {
          password,
          resetPasswordToken,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("jwtToken", response.headers["x-auth"]);
            resolve(response.data);
          } else {
            reject(new Error(response.data || "Something went wrong"));
          }
        })
        .catch((err) => {
          reject(
            new Error(err.response.data?.message || "Fail to reset password")
          );
        });
    });
  },
  changePassword: (currentPassword: string, newPassword: string) => {
    return new Promise<UserLocal>((resolve, reject) => {
      privateApi
        .post("/user/changePassword", { currentPassword, newPassword })
        .then((response) => {
          localStorage.setItem("jwtToken", response.headers["x-auth"]);
          resolve(response.data);
        })
        .catch((err) => {
          reject(
            new Error(
              err.response.data?.message || "Fail to change user password"
            )
          );
        });
    });
  },
  forgotPassword: (email: string) => {
    return new Promise<string>((resolve, reject) => {
      publicApi
        .post("/user/forgotPassword", {
          email,
          fromWeb: true,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(
            new Error(err.response.data?.message || "Fail to reset password")
          );
        });
    });
  },
  authSocial: (options: {
    platform: string;
    email: string;
    userId: string;
    token?: string;
  }) => {
    return new Promise<UserLocal & { notExists: boolean }>(
      async (resolve, reject) => {
        try {
          const response = await publicApi.post("/user/auth/social", {
            ...options,
          });
          if (response.status === 200) {
            const user = response.data;
            const token = response.headers["x-auth"];
            if (token) {
              localStorage.setItem("jwtToken", token);
            }

            resolve(user);
          }
        } catch (e: any) {
          reject(e.response?.data);
        }
      }
    );
  },

  isUserExists: (email: string) => {
    return new Promise<boolean>((resolve) => {
      publicApi
        .post("/user/exists", {
          email,
        })
        .then((response) => {
          resolve(response.status === 409);
        })
        .catch((error) => {
          resolve(true);
        });
    });
  },
  getPredefinedAccount: (token: string) => {
    return new Promise<Pick<User, "firstName" | "lastName" | "email">>(
      (resolve, reject) => {
        publicApi
          .get(`/user/signInToken/${token}`)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(new Error(response.data || "Something went wrong"));
            }
          })
          .catch((error) => {
            reject(new Error(error.response.data?.message || "Invalid Token"));
          });
      }
    );
  },

  reactOnProperty: (data: InvestOrShareMessage) => {
    return new Promise<string>((resolve, reject) => {
      privateApi
        .post("/user/reaction", {
          data,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(response.data.message || "Something went wrong"));
          }
        })
        .catch((error) => {
          reject(
            new Error(
              error.response.data?.message || "Fail to invest the property"
            )
          );
        });
    });
  },

  investProperty: (propertyId: string, propertyName: string) => {
    return new Promise<string>((resolve, reject) => {
      privateApi
        .post("/property/user/interested", {
          propertyId,
          propertyName,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(response.data.message || "Something went wrong"));
          }
        })
        .catch((error) => {
          reject(
            new Error(
              error.response.data?.message || "Fail to invest the property"
            )
          );
        });
    });
  },

  upsertAdditionalInfoIVO: (
    additionalInformation: AdditionalInformationIVO,
    {
      incorporationDocumentToAdd,
      ownershipDocumentToAdd,
      licenseOfOperateDocumentToAdd,
      registrationsDocumentToAdd,
    }
  ) => {
    return new Promise<AdditionalInformationIVO>((resolve, reject) => {
      const formData = new FormData();
      formData.append(
        "additionalInformation",
        JSON.stringify(additionalInformation)
      );

      formData.append("incorporationDocument", incorporationDocumentToAdd);
      formData.append("ownershipDocument", ownershipDocumentToAdd);
      formData.append(
        "licenseOfOperateDocument",
        licenseOfOperateDocumentToAdd
      );
      formData.append("registrationsDocument", registrationsDocumentToAdd);

      privateApi
        .post("/ivo", formData)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(response?.data?.message || "Something went wrong")
            );
          }
        })
        .catch((error) => {
          reject(
            new Error(error?.response?.data?.message || "Something went wrong")
          );
        });
    });
  },
  getAdditionalInfoIVO: () => {
    return new Promise<AdditionalInformationIVO>((resolve, reject) => {
      privateApi
        .get("/ivo")
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(response?.data?.message || "Something went wrong")
            );
          }
        })
        .catch((error) => {
          reject(
            new Error(error?.response.data?.message || "Something went wrong")
          );
        });
    });
  },
  getUserInfo: async (token?: string) => {
    return new Promise<UserLocal | null>(async (resolve, reject) => {
      try {
        const response = await publicApi.get("/user/info", {
          headers: {
            "x-auth": token || localStorage.getItem("jwtToken"),
          },
        });
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve(null);
      } catch (e) {
        // @ts-ignore
        reject(new Error(e.response?.data?.message || "Invalid token"));
      }
    });
  },
  deleteAccount: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await privateApi.delete(`/user`);
        if (response.status === 204) {
          localStorage.removeItem("jwtToken");
          resolve("Account successfully deleted");
        }
      } catch (e: any) {
        reject(
          new Error(e.message || "Fail to delete account, please contact us")
        );
      }
    });
  },
  resendVerificationEmail: (userId: string) => {
    return new Promise<{ message: string; status: string }>(
      async (resolve, reject) => {
        try {
          const response = await publicApi.post(`/user/verify/resend`, {
            userId,
          });
          if (response.status === 200) {
            resolve(response.data);
          }
        } catch (e) {
          // @ts-ignore
          reject(new Error(e.message || "Fail to send verification email"));
        }
      }
    );
  },
  isUserEmailVerified: (userId: string) => {
    return new Promise<{ isVerified: boolean }>(async (resolve, reject) => {
      try {
        const response = await publicApi.get(`/user/isVerified/${userId}`);
        if (response.status === 200) {
          resolve(response.data);
        }
      } catch (e) {
        // @ts-ignore
        reject(new Error(e.message || "Something went wrong"));
      }
    });
  },
  bookmarkProperty: async (propertyId: string) => {
    const response = await privateApi.put(`/user/bookmark/${propertyId}`);
    if (response.status === 200) {
      return response.data;
    }
  },
  getAuditLogs: async (type: AuditLog['action']) => {
    const response = await privateApi.get(`/user/auditLog/${type}`);
    if (response.status === 200) {
      return response.data;
    }
  }
};

export default UserAPI;
