import { baseQueryWithAuthWithoutAutoLogout } from "api/base-query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export const performanceReportApi = createApi({
  reducerPath: 'performanceReportApi',
  baseQuery: baseQueryWithAuthWithoutAutoLogout,
  endpoints: (builder) => ({
    authorize: builder.mutation<{ unlocked: boolean }, { password: string, propertyId: string }>({
      query: ({ password, propertyId }) => ({
        method: 'POST',
        url: `propertyTeaser/${propertyId}/performanceReport/unlock`,
        body: {
          password,
        },
      }),
    }),
    downloadReportFile: builder.mutation<any, { password: string; propertyId: string; documentId: string; name: string }>({
      query: ({ password, propertyId, documentId, name }) => ({
        method: 'POST',
        url: `propertyTeaser/${propertyId}/performanceReport/document/${documentId}`,
        body: {
          password,
        },
        cache: "no-cache",
        responseHandler: async (response) => {

          const fileBlob = await response.blob();
          const a = document.createElement('a');
          a.download = name;
          a.href = window.URL.createObjectURL(fileBlob);
          a.click();
          a.remove();

          return { data: null }
        }
      }),
    }),
  }),
});

export const { useAuthorizeMutation, useDownloadReportFileMutation } = performanceReportApi;