import React from 'react'
import cryptoRandomString from "crypto-random-string";
import classNames from 'classnames';


type TextAreaProps = {
    id?: string,
    label?: string,
    labelComponent?: JSX.Element;
    textAreaProps?: Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'value' & 'style'>,
    value?: string,
    style?: React.CSSProperties,
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any,
    containerClasses?: string,
    containerStyles?: React.CSSProperties,
    labelClasses?: string,
    labelStyles?: React.CSSProperties,
    placeholder?: string,
    name?: string,
    maxLength?: number
    rows?: number,
    cols?: number,

}

const TextArea: React.FC<TextAreaProps> = ({
                                               textAreaProps,
                                               className = '',
                                               onChange = () => {
                                               },
                                               style,
                                               label,
                                               labelComponent,
                                               value = '',
                                               id,
                                               containerClasses,
                                               containerStyles = {},
                                               labelStyles = {},
                                               labelClasses,
                                               placeholder,
                                               name,
                                               maxLength,
                                               rows,
                                               cols
                                           }) => {

    if (!id && !textAreaProps?.id) {
        id = cryptoRandomString({length: 10});
    }
    return (
        <div className={containerClasses || 'my-2'} style={{
            ...containerStyles
        }}>
            {
                label &&
                <label htmlFor={id} className={classNames("d-flex", labelClasses || 'font-weight-bold')}
                       style={{
                           ...labelStyles
                       }}
                >{label}{labelComponent}</label>
            }
            <textarea className={`form-control textarea-min-height ${className}`}
                      onChange={onChange}
                      style={{
                          ...style,
                      }}
                      id={id}
                      value={value}
                      {...textAreaProps}
                      placeholder={placeholder}
                      name={name}
                      maxLength={maxLength}
                      rows={rows}
                      cols={cols}
            />
        </div>
    )
}

export default TextArea
