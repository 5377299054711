import React, { Fragment, useRef, useState } from "react";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import { _validateEmail } from "helpers/helpers";
import "suneditor/dist/css/suneditor.min.css";
import $ from "jquery";
import { Document } from "../store/properties/types";
import CircleFilledIcon from "../assets/circle-filled.svg";

import documentPDF from "../assets/document_pdf.svg";
import documentXLSX from "../assets/document_xlsx.svg";
import documentDOC from "../assets/document_doc.svg";
import documentZIP from "../assets/document_zip.svg";
import documentDefault from "../assets/document_default.svg";

import documentRemoveBtn from "../assets/document-remove-btn.svg";
import documentAddBtn from "../assets/document-add-btn.svg";

import imageRemoveBtn from "../assets/remove-img-btn.svg";
import imageAddBtn from "../assets/add-image-btn.svg";
import WysiwygEditor from "../components/WysiwygEditor";
import { createPortal } from "react-dom";
import { useTranslate } from "@tolgee/react";

export type InvestOrShareMessage = {
  fromEmail: string;
  toEmail?: string;
  subject: string;
  message: string;
  attachments?: Document[];
  images?: string[];
  propertyImages?: string[];
  documents?: Document[];
  propertyId?: string;
  attachTeaser?: boolean;
  teaserName?: string;
  type: "invest" | "share" | "invite";
};

export interface SendMailModal {
  confirmBtnLabel?: string;
  label: string;
  handleYes: (
    data: InvestOrShareMessage & { otherEmails: string[] },
    type: "invest" | "share" | "invite"
  ) => any;
  id: string;
  options: InvestOrShareMessage;
  type: "invest" | "share" | "invite";
  propertyId?: string;
}

const SendMail: React.FC<SendMailModal> = ({
  confirmBtnLabel,
  label,
  handleYes,
  id,
  options,
  type,
  propertyId,
}) => {
  const [localOptions, setLocalOptions] = React.useState<InvestOrShareMessage>({
    ...options,
  });


  const rootNode = useRef(document.getElementById('root'))

  const [otherEmails, setOtherEmails] = React.useState<string[]>([]);
  const [emailToAdd, setEmailToAdd] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");
  const [hasError, setHasError] = React.useState(false);

  const [message, setMessage] = useState(options.message);

  const _isValid = () => {
    const { fromEmail, subject, toEmail } = localOptions;

    return (
      !!fromEmail &&
      _validateEmail(fromEmail) &&
      !!subject &&
      !!toEmail &&
      _validateEmail(toEmail)
    );
  };

  const modalRef = React.useRef(null);

  const [attachTeaser, setAttachTeaser] = useState(!!options.attachTeaser);

  const handleYesLocal = (e) => {
    if (_isValid()) {
      const { fromEmail, subject, toEmail, attachments, images } = localOptions;
      setLoading(true);
      const data: InvestOrShareMessage = {
        attachTeaser,
        message,
        fromEmail,
        toEmail,
        subject,
        propertyId,
        attachments,
        type
      };

      if (attachments && attachments.length > 0) {
        if (!data.attachments) {
          data.attachments = [];
        }

        data.attachments = [...attachments, ...data.attachments];
      }
      if (images && images.length > 0) {
        if (!data.attachments) {
          data.attachments = [];
        }

        // @ts-ignore
        data.attachments = [
          ...data.attachments,
          ...images.map((imgUrl) => {
            return {
              name: imgUrl.split("/").pop(),
              url: imgUrl,
            };
          }),
        ];
      }

      handleYes(
        {
          ...data,
          otherEmails: otherEmails,
        },
        type
      )
        .then((message) => {
          setLoading(false);
          setResponseMessage(tEmailTemplates('email_templates.email_sent'));
          setHasError(false);
        })
        .catch((err) => {
          setResponseMessage(err.message);
          setHasError(true);
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (modalRef.current) {
      $(modalRef.current).on("hidden.bs.modal", function () {
        setLoading(() => false);
        setHasError(() => false);
        setEmailToAdd(() => "");
        setOtherEmails(() => []);
        setResponseMessage(() => "");
        setLocalOptions(() => ({ ...options }));
      });
    }
  }, [modalRef, options]);

  const _getDocumentIcon = (uri: string) => {
    let ext = uri.split(".").pop();
    if (ext) {
      ext = ext.toLowerCase();
    }
    switch (ext) {
      case "pdf":
        return documentPDF;
      case "xlsx":
        return documentXLSX;
      case "doc":
        return documentDOC;
      case "docx":
        return documentDOC;
      case "zip":
        return documentZIP;
      default:
        return documentDefault;
    }
  };

  const { t: tEmailTemplates } = useTranslate('email_templates');

  return (
    <>
      {createPortal(
        <div
          className="modal fade"
          ref={modalRef}
          id={id}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
            style={{
              minWidth: "60%",
            }}
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">{label}</h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {responseMessage ? (
                  <div
                    className={`alert ${
                      hasError ? "alert-danger" : "alert-info"
                    } d-flex justify-content-center align-items-center py-4`}
                  >
                    {!hasError ? (
                      <img src={CircleFilledIcon} className="mr-3" alt="" />
                    ) : null}
                    <h4 className="mb-0">{responseMessage}</h4>
                  </div>
                ) : (
                  <Fragment>
                    {/*<Input*/}
                    {/*    label='From:'*/}
                    {/*    labelClasses='col-3'*/}
                    {/*    containerClasses='d-flex'*/}
                    {/*    inputAttributes={{*/}
                    {/*        value: localOptions.fromEmail*/}
                    {/*    }}*/}
                    {/*    onChange={(e) => setLocalOptions(localOptions => {*/}
                    {/*        localOptions.fromEmail = e.target.value*/}

                    {/*        return {...localOptions}*/}
                    {/*    })}*/}
                    {/*/>*/}
                    <Input
                      label="To:"
                      labelClasses="col-3"
                      containerClasses="d-flex flex-column flex-sm-row my-2"
                      className={`${!!options.toEmail ? "text-black-50" : ""}`}
                      inputAttributes={{
                        value: localOptions.toEmail || "",
                        disabled: !!options.toEmail,
                        placeholder: localOptions.toEmail || "Email to send",
                      }}
                      onChange={(e) =>
                        setLocalOptions((localOptions) => {
                          localOptions.toEmail = e.target.value;

                          return { ...localOptions };
                        })
                      }
                    />
                    <Input
                      label="CC:"
                      labelClasses="col-3"
                      containerClasses="d-flex flex-column flex-sm-row"
                      inputAttributes={{
                        value: emailToAdd,
                        onChange: (e) => setEmailToAdd(e.target.value),
                      }}
                      rightIcon={
                        <>
                          <span
                            onClick={() => {
                              setEmailToAdd("");
                            }}
                            className="fe fe-delete bg-light  font-weight-bold input-group-text"
                          />
                          <span
                            onClick={() => {
                              if (!!emailToAdd && _validateEmail(emailToAdd)) {
                                setOtherEmails((otherEmails) => [
                                  ...otherEmails,
                                  emailToAdd,
                                ]);
                                setEmailToAdd("");
                              }
                            }}
                            className="fe fe-plus bg-light font-weight-bold input-group-text"
                          ></span>
                        </>
                      }
                    />
                    {otherEmails.length > 0 ? (
                      <div className="d-flex my-2">
                        <span className="col-3"></span>
                        <div className="flex-grow-1">
                          {otherEmails.map((email, index) => {
                            return (
                              <div
                                key={email + "_" + index}
                                className="my-1 px-3 py-3 rounded bg-info d-flex justify-content-between align-items-center"
                              >
                                <span>{email}</span>
                                <span
                                  className="fe fe-x cursor-pointer"
                                  onClick={() => {
                                    setOtherEmails((otherEmails) => {
                                      otherEmails.splice(index, 1);

                                      return [...otherEmails];
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}

                    <TextArea
                      label="Subject:"
                      labelClasses="col-3"
                      containerClasses="d-flex flex-column flex-sm-row my-2"
                      textAreaProps={{
                        value: localOptions.subject,
                      }}
                      onChange={(e) =>
                        setLocalOptions((localOptions) => {
                          localOptions.subject = e.target.value;

                          return { ...localOptions };
                        })
                      }
                    />

                    {localOptions.documents ? (
                      <div className="d-flex my-2 flex-wrap">
                        <label className="col-xl-3">
                          Attachments: <br />
                          {localOptions.attachments &&
                          localOptions.attachments.length > 0 ? (
                            <span className="font-weight-bold">
                              {localOptions.attachments.length} selected
                            </span>
                          ) : null}
                        </label>
                        <div
                          className="col px-2 py-2 d-flex flex-wrap justify-content-between"
                          style={{
                            maxHeight: 190,
                            overflowY: "auto",
                          }}
                        >
                          {options.attachTeaser && (
                            <div
                              className={`border my-1 px-3 py-3 col-xl-5 col-12 mr-2 rounded d-flex justify-content-between align-items-center ${
                                attachTeaser ? "bg-white" : "bg-light"
                              }`}
                              key={"attachment_teaser"}
                            >
                              <div className="d-flex align-items-center mr-2">
                                <img src={_getDocumentIcon("pdf")} alt="" />
                                <span className="ml-3">
                                  {options.teaserName}
                                </span>
                              </div>
                              {attachTeaser ? (
                                <img
                                  src={documentRemoveBtn}
                                  onClick={() => {
                                    setAttachTeaser(false);
                                  }}
                                  className="cursor-pointer"
                                  alt="unattach"
                                />
                              ) : (
                                <img
                                  src={documentAddBtn}
                                  onClick={() => {
                                    setAttachTeaser(true);
                                  }}
                                  className="cursor-pointer"
                                  alt="attach"
                                />
                              )}
                            </div>
                          )}
                          {localOptions.documents.map((attachment) => {
                            const isAttached =
                              localOptions.attachments &&
                              localOptions.attachments.find(
                                (a) => a._id === attachment._id
                              );
                            return (
                              <div
                                className={`border my-1 px-3 py-3 col-xl-5 col-12 mr-2 rounded d-flex justify-content-between align-items-center ${
                                  isAttached ? "bg-white" : "bg-light"
                                }`}
                                key={"attachment_" + attachment.url}
                              >
                                <div className="d-flex align-items-center mr-2">
                                  <img
                                    src={_getDocumentIcon(attachment.url)}
                                    alt=""
                                  />
                                  <span className="ml-3">
                                    {attachment.name}
                                  </span>
                                </div>
                                {isAttached ? (
                                  <img
                                    src={documentRemoveBtn}
                                    onClick={() => {
                                      setLocalOptions((localOptions) => {
                                        if (!localOptions.attachments) {
                                          localOptions.attachments = [];
                                        }

                                        localOptions.attachments = [
                                          ...localOptions.attachments.filter(
                                            (a) => a._id !== attachment._id
                                          ),
                                        ];

                                        return { ...localOptions };
                                      });
                                    }}
                                    className="cursor-pointer"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={documentAddBtn}
                                    onClick={() => {
                                      setLocalOptions((localOptions) => {
                                        if (!localOptions.attachments) {
                                          localOptions.attachments = [];
                                        }

                                        localOptions.attachments = [
                                          ...localOptions.attachments,
                                          attachment,
                                        ];

                                        return { ...localOptions };
                                      });
                                    }}
                                    className="cursor-pointer"
                                    alt=""
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    <hr />
                    {localOptions.propertyImages ? (
                      <div className="d-flex my-2 flex-wrap">
                        <label className="col-xl-3">
                          Photos: <br />
                          {localOptions.images &&
                          localOptions.images.length > 0 ? (
                            <span className="font-weight-bold">
                              {localOptions.images.length} selected
                            </span>
                          ) : null}
                        </label>
                        <div
                          className="col px-2 py-2 d-flex flex-wrap"
                          style={{
                            maxHeight: 280,
                            overflowY: "auto",
                          }}
                        >
                          {localOptions.propertyImages.map((imageUrl) => {
                            const isAttached =
                              localOptions.images?.includes(imageUrl);
                            const fileExtension = imageUrl.split(".").pop();
                            return (
                              <div
                                className={`py-2 mr-3  d-flex align-items-center`}
                                key={"img_" + imageUrl}
                              >
                                <div className="position-relative">
                                  {fileExtension === "mp4" ? (
                                    <video
                                      height={100}
                                      width={100}
                                      controls={false}
                                      className={`object-fit-cover radius-20 ${
                                        isAttached
                                          ? "border border-primary border-3"
                                          : ""
                                      }`}
                                    >
                                      <source src={imageUrl} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={imageUrl}
                                      height={100}
                                      width={100}
                                      className={`object-fit-cover radius-20 ${
                                        isAttached
                                          ? "border border-primary border-3"
                                          : ""
                                      }`}
                                      alt=""
                                    />
                                  )}

                                  {isAttached ? (
                                    <img
                                      src={imageRemoveBtn}
                                      onClick={() => {
                                        setLocalOptions((localOptions) => {
                                          if (!localOptions.images) {
                                            localOptions.images = [];
                                          }

                                          localOptions.images = [
                                            ...localOptions.images.filter(
                                              (attachedImgUrl) =>
                                                attachedImgUrl !== imageUrl
                                            ),
                                          ];

                                          return { ...localOptions };
                                        });
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        transform: "translate(20%, -20%)",
                                      }}
                                      className="cursor-pointer"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={imageAddBtn}
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        transform: "translate(20%, -20%)",
                                      }}
                                      onClick={() => {
                                        setLocalOptions((localOptions) => {
                                          if (!localOptions.images) {
                                            localOptions.images = [];
                                          }

                                          localOptions.images = [
                                            ...localOptions.images,
                                            imageUrl,
                                          ];

                                          return { ...localOptions };
                                        });
                                      }}
                                      className="cursor-pointer"
                                      alt=""
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    <WysiwygEditor
                      value={localOptions.message}
                      onValueChange={(value) => setMessage(value)}
                    />

                    {/* <div 
                                    ref={messageRef}
                                    style={{overflowY: 'scroll', height: '243px'}} 
                                    className='form-control textarea-min-height'
                                    contentEditable={true} 
                                    dangerouslySetInnerHTML={{__html: localOptions.message.replace('\n', '<br/>')}}>

                                </div> */}
                    {/* <TextArea
                                    placeholder='Fill the message to sent'
                                    value={localOptions.message}
                                    rows={10}
                                    onChange={(e) => {
                                        const htmlContent = e.target.value
                                        setLocalOptions(localOptions => {
                                            localOptions.message = htmlContent

                                            return {...localOptions}
                                        })
                                    }}
                                /> */}
                    {/*<SunEditor name='htmlContent' setOptions={{*/}
                    {/*    buttonList: [*/}
                    {/*        ["undo", "redo"],*/}
                    {/*        ["font", "fontSize", "formatBlock"],*/}
                    {/*        ["paragraphStyle"],*/}
                    {/*        [*/}
                    {/*            "bold",*/}
                    {/*            "underline",*/}
                    {/*            "italic",*/}
                    {/*            "strike",*/}
                    {/*        ],*/}
                    {/*        ["fontColor", "hiliteColor"],*/}
                    {/*        ["removeFormat"],*/}
                    {/*        ["outdent", "indent"],*/}
                    {/*        ["align", "horizontalRule", "list", "lineHeight"],*/}
                    {/*        ["table", "link"]*/}
                    {/*    ],*/}
                    {/*}}*/}
                    {/*     */}
                    {/*           setDefaultStyle="font-family: Poppins-Regular; font-size: 1rem; margin-bottom: 0;"*/}
                    {/*           setContents={localOptions.message}*/}
                    {/*/>*/}
                  </Fragment>
                )}
              </div>
              <div className="modal-footer">
                <div className="btn-group" role="group">
                  {responseMessage ? (
                    hasError ? (
                      <div>
                        <button
                          type="button"
                          className="btn btn-white"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`btn btn-success ${
                            loading ? "is-loading" : ""
                          }`}
                          onClick={handleYesLocal}
                        >
                          Resend
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    )
                  ) : (
                    <div>
                      <button
                        type="button"
                        className="btn btn-white"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={!_isValid()}
                        className={`btn btn-success ${
                          loading ? "is-loading" : ""
                        }`}
                        onClick={handleYesLocal}
                      >
                        {confirmBtnLabel || "Send"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>,
        rootNode.current!
      )}
    </>
  );
};


export default SendMail;