import { INITIAL_STATE, EducationMaterialsGroupsActionsTypes, EducationMaterialsGroupsState } from './types';
import { AnyAction } from 'redux';

export const educationMaterialsReducer = (state = INITIAL_STATE, action: AnyAction): EducationMaterialsGroupsState => {
  switch (action.type) {
    case EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIALS_GROUPS:
      return { ...state, groups: action.payload ? [...action.payload] : action.payload };
    case EducationMaterialsGroupsActionsTypes.SET_ERROR_EDUCATION_MATERIALS_GROUPS:
      return { ...state, error: action.payload };
    case EducationMaterialsGroupsActionsTypes.SET_LOADING_EDUCATION_MATERIALS_GROUPS:
      return { ...state, isLoading: action.payload };
    case EducationMaterialsGroupsActionsTypes.SET_UPDATING_EDUCATION_MATERIALS_GROUPS:
      return { ...state, isUpdating: action.payload };

    default:
      return state;
  }
};
