import { FC, memo, useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { UserAPI } from "api/user";
import logo from "assets/logo-horizontal.svg";
import CircleFilledIcon from "assets/circle-filled.svg";
import CircleActiveIcon from "assets/circle_active.svg";
import Button from "components/Button";
import { useSession } from "context/session";
import { useHistory } from "react-router-dom";
import { useTolgee, useTranslate } from "@tolgee/react";
import { LanguageSelector } from "new-architecture/components/language-selector";

export const VerifyUser: FC<{}> = memo(() => {
  const dispatch = useDispatch();
  const { token, id } = useParams<{ token: string, id: string }>();

  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromSocial, setFromSocial] = useState(false);
  const { setUser } = useSession();
  const history = useHistory();

  useEffect(() => {
    if (token && id) {
      (async () => {
        try {
          setLoading(true);
          const { fromSocial, token: jwtToken, user } = await UserAPI.verifyUser(token, id);
          setUser(user);
          localStorage.setItem('jwtToken', jwtToken);
          setIsVerified(true);
          setFromSocial(fromSocial);
          history.push('/account');
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [dispatch, token, id, setUser, history]);

  const { t: tVerifyUser } = useTranslate('public_area.verify_user');
  const tolgee = useTolgee();

  const [, setReRenderTrigger] = useState(false);
  const onLanguageChange = useCallback(async (newLanguage: string) => {
    tolgee.changeLanguage(newLanguage);

    // small trick to trigger re-render
    setReRenderTrigger((prev) => !prev);
  }, [tolgee]);

  return (
    <div>
      {
        true ?
          <div className="verify-user">
            <div className="navbar navbar-expand-lg navbar-light header-shadow px-6 flex-wrap">
              <img
                src={logo}
                height={40}
                alt="Estating logo"
              />
              <ul className="navbar-nav align-items-start mx-auto">
                {
                  !fromSocial ?
                    <>
                      <li className='nav-link disabled'>
                        <img
                          src={CircleFilledIcon}
                          className='mr-2'
                          alt=""
                        />
                        {tVerifyUser('public_area.verify_user.password')}
                      </li>
                      <li className='nav-link disabled'>
                        <img
                          src={CircleFilledIcon}
                          className='mr-2'
                          alt=""
                        />
                        {tVerifyUser('public_area.verify_user.personal_details')}
                      </li>
                    </>
                  :
                    null
                }
                <li className='nav-link disabled'>
                  <img
                    src={CircleFilledIcon}
                    className='mr-2'
                    alt=""
                  />
                  {tVerifyUser('public_area.verify_user.who_are_you')}
                </li>
                <li className='nav-link disabled'>
                  <img
                    src={CircleFilledIcon}
                    className='mr-2'
                    alt=""
                  />
                  {tVerifyUser('public_area.verify_user.complete_sign_up')}
                </li>
                <li className='nav-link'>
                  <img
                    src={CircleActiveIcon}
                    className='mr-2'
                    alt=""
                  />
                  {tVerifyUser('public_area.verify_user.verification')}
                </li>
              </ul>
              <LanguageSelector
                availableLanguages={[{ value: 'en', label: 'EN'}, { value: 'es', label: 'ES' }]}
                onLanguageSelect={onLanguageChange}
                selectedLanguage={tolgee.getPendingLanguage()}
              />
            </div>
            <div className='d-flex justify-content-center text-center mt-7'>
              <div className='col-12 col-sm-12 col-md-6 col-xl-5'>
                <p className='header-text'>
                  {tVerifyUser('public_area.verify_user.welcome_to_estating')}
                  <br/>
                  {tVerifyUser('public_area.verify_user.successful_register')}
                </p>
                <div className="alert border-primary alert-info d-flex justify-content-center align-items-center py-4">
                  <h4 className='mb-0'>
                    {tVerifyUser('public_area.verify_user.contact_us')}{" "}
                    <a
                      href="mailto:info@estating.com"
                      className='text-muted'
                    >
                      info@estating.com
                    </a>
                    , {tVerifyUser('public_area.verify_user.happy_to_help')}.
                  </h4>
                </div>
                <p>
                  {tVerifyUser('public_area.verify_user.best_regards')}
                </p>
                <Button
                  onClick={() => {
                    window.close();
                  }}
                >
                  {tVerifyUser('public_area.verify_user.close')}
                </Button>
              </div>
            </div>
          </div>
        :
          null
      }
      <div className='d-flex align-items-center justify-content-center vh-100'>
        {
          loading ? 
            <div className='text-center'>
              <div className={`spinner-border text-primary`} role='status'/>
              <div>{tVerifyUser('public_area.verify_user.verifying')}...</div>
            </div>
            :
            null
        }
        {
          (!loading && !isVerified) ?
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-10 col-md-8 col-xl-6 my-5">
                  <div className="text-center">
                    <h6 className="text-uppercase mb-2 text-muted">{tVerifyUser('public_area.verify_user.401error')}</h6>
                    <h1 className="display-4 mb-3">{tVerifyUser('public_area.verify_user.something_went')}</h1>
                    <p className="mb-4">{tVerifyUser('public_area.verify_user.invalid_credentials')}</p>
                  </div>
                </div>
              </div>
            </div>
            :
            null
        }
      </div>
    </div>
  );
});