import React from 'react'
import pageNotFoundImg from '../assets/404.svg'
import Button from "../components/Button";
import history from "../history-entity";

const PageNotFound: React.FC<{}> = () => {
    return (
        <div className='col-10 mx-auto mt-7 d-flex justify-content-center align-items-center flex-wrap'>
            <div className='col-12 col-sm-12 col-md-8 col-xl-6'>
                <img src={pageNotFoundImg}
                     className='w-100 mb-5'
                     alt="page not found"/>
            </div>
            <div
                className='col-12 col-sm-12 col-md-8 col-xl-6'
            >
                <h1 className='font-weight-bold'>Oh No! Error 404</h1>
                <p>
                    Maybe something was going wrong on the construction... Come back to the homepage
                </p>
                <Button
                    label='Back to Homepage'
                    onClick={() => history.push('/')}
                    className='mr-3'
                />
                <a href="https://estating.com/" target='_blank' rel="noreferrer" >Visit Help Center</a>
            </div>
        </div>
    )
}

export default PageNotFound
