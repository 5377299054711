import { memo, FC } from "react";
import { EducationMaterialsGroup } from "../../types/education-materials/education-materials-group";
import { EducationMaterialsList } from "./education-materials-list";

interface EducationMaterialsGroupsListItemProps {
  group: EducationMaterialsGroup;
}

const responsive = {};
let min = 0;
let max = 370;

for(let i = 0; i < 30; i++) {
  responsive[i] = {
    breakpoint: { min, max },
    items: i + 1,
  }
  min = max;
  max += 370;
}

export const EducationMaterialsGroupsListItem: FC<EducationMaterialsGroupsListItemProps> =
  memo(({ group }) => {
    const { title, materials, description } = group;

    if(!materials || !materials.length) {
      return null;
    }

    return (
      <div className="d-flex flex-column">
        <h1>{title}</h1>
        {description && <p>{description}</p>}
        <div>
          <EducationMaterialsList
            materials={materials}
            carouselProps={{
              additionalTransfrom: 0,
              arrows: true,
              draggable: true,
              keyBoardControl: true,
              minimumTouchDrag: 80,
              pauseOnHover: true,
              shouldResetAutoplay: true,
              sliderClass: "",
              slidesToSlide: 1,
              swipeable: true,
              responsive,
              children: null,
            }}
          />
          <hr />
        </div>
      </div>
    );
  });
