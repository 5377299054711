import { FC, memo } from "react";

import style from "./style.module.scss";

type RiskMigrationFactorsProps = {
  factors: string;
};

export const RiskMigrationFactors: FC<RiskMigrationFactorsProps> = memo(
  ({ factors }) => (
    <div className={style["risk-factors__container"]}>
      <div dangerouslySetInnerHTML={{ __html: factors }} className={style['risk-factors__text-box']}></div>
    </div>
  )
);
