import React, { useCallback, useMemo, useState } from "react";
import Idle from "./Idle";
import { useSession } from "../context/session";
import { Header, HeaderContext } from "new-architecture/modules/header";
import { Helmet } from 'react-helmet';

import "./MainLayout.scss"

type MainLayoutProps = {
  children: JSX.Element;
  withNavbar?: boolean;
  title?: string
  description?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  withNavbar = true,
  title,
  description
}) => {
  const { user } = useSession();
  const [headerContainerHeight, setHeaderContainerHeight] = useState(0);

  const handleResize = useCallback((value: number) => {
    setHeaderContainerHeight(value)
  }, []);

  const helmet = useMemo(() => (
    <Helmet>
      <title>{title ? `${title} - Estating` : 'Estating'}</title>
      {description && 
        <meta name="description" content={description} />
      }
    </Helmet>
  ), [title, description]);

  return (
    <HeaderContext.Provider value={{ containerHeight: headerContainerHeight }}>
      {helmet}
      <div className="main-layout">
        <Idle isAuthenticated={!!user} />
        {withNavbar && <Header onResize={handleResize}  />}
        {children}
      </div>
    </HeaderContext.Provider>
  );
};

export default MainLayout;
