import { useContext, forwardRef } from 'react'
import styled from 'styled-components'
import { TableContext } from '../..';
import { quarterNameBlockHeight, quarterNameBlockMarginBottom } from '../../utils/constants';
import { Row } from './row';

export const Rows = forwardRef<HTMLDivElement>((_, ref) => {
  const { teasersWithDateEvents } = useContext(TableContext);

  return (
    <RowsContainer ref={ref}>
      {teasersWithDateEvents.map((teaser) => (
        <Row teaser={teaser} key={teaser._id} />
      ))}
    </RowsContainer>
  )
})

const RowsContainer = styled.div`
  padding-top: calc(${quarterNameBlockHeight}px + ${quarterNameBlockMarginBottom}px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 23px;
`