import { Action } from "redux";
import { EducationMaterialsGroup } from "../../types/education-materials/education-materials-group";

export enum EducationMaterialsGroupsActionsTypes {
  SET_LOADING_EDUCATION_MATERIALS_GROUPS = 'set-loading-educations-materials-groups',
  SET_ERROR_EDUCATION_MATERIALS_GROUPS = 'set-error-educations-materials-groups',
  SET_EDUCATION_MATERIALS_GROUPS = 'set-educations-materials-groups',
  SET_UPDATING_EDUCATION_MATERIALS_GROUPS = 'set-updating-educations-materials-groups',
  UPDATE_EDUCATION_MATERIALS_GROUPS_ORDER = 'update-educations-materials-groups-ordering',
}

export interface EducationMaterialsGroupsState {
  groups: Array<EducationMaterialsGroup> | null;
  isLoading: boolean;
  error: null | string;
  isUpdating: boolean;
}

export const INITIAL_STATE: EducationMaterialsGroupsState = {
  groups: null,
  isLoading: false,
  error: null,
  isUpdating: false,
};

export interface SetLoadingEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_LOADING_EDUCATION_MATERIALS_GROUPS;
  payload: boolean;
}

export interface SetUpdatingEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_UPDATING_EDUCATION_MATERIALS_GROUPS;
  payload: boolean;
}

export interface SetErrorEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_ERROR_EDUCATION_MATERIALS_GROUPS;
  payload: string | null;
}

export interface SetEducationMaterialsGroups extends Action {
  type: EducationMaterialsGroupsActionsTypes.SET_EDUCATION_MATERIALS_GROUPS;
  payload: Array<EducationMaterialsGroup> | null;
}