import { FC, memo } from 'react';
import imageRemoveBtn from "assets/remove-img-btn.svg";
import imageAddBtn from "assets/add-image-btn.svg";

interface PhotosSelectProps {
  availableImages: Array<string>;
  selectedPhotos: Array<string>;
  onChangeSelectedPhotos: (newValue: Array<string>) => void;
}

export const PhotosSelect: FC<PhotosSelectProps> = memo(({
  availableImages,
  selectedPhotos,
  onChangeSelectedPhotos
}) => {
  return (
    <div>
      <div
        className="col px-2 py-2 d-flex flex-wrap"
        style={{
          maxHeight: 280,
          overflowY: "auto",
        }}
      >
        {availableImages.map((imageUrl) => {
          const isAttached = selectedPhotos.includes(imageUrl);
          const fileExtension = imageUrl.split(".").pop();

          return (
            <div
              className={`py-2 mr-3  d-flex align-items-center`}
              key={"img_" + imageUrl}
            >
              <div className="position-relative">
                {fileExtension === "mp4" ? (
                  <video
                    height={100}
                    width={100}
                    controls={false}
                    className={`object-fit-cover radius-20 ${
                      isAttached
                        ? "border border-primary border-3"
                        : ""
                    }`}
                  >
                    <source src={imageUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={imageUrl}
                    height={100}
                    width={100}
                    className={`object-fit-cover radius-20 ${
                      isAttached
                        ? "border border-primary border-3"
                        : ""
                    }`}
                    alt=""
                  />
                )}

                {isAttached ? (
                  <img
                    src={imageRemoveBtn}
                    onClick={() => {
                      onChangeSelectedPhotos(
                        selectedPhotos.filter((attachedImgUrl) => attachedImgUrl !== imageUrl)
                      );
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(20%, -20%)",
                    }}
                    className="cursor-pointer"
                    alt=""
                  />
                ) : (
                  <img
                    src={imageAddBtn}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(20%, -20%)",
                    }}
                    onClick={() => {
                      onChangeSelectedPhotos([...selectedPhotos, imageUrl]);
                    }}
                    className="cursor-pointer"
                    alt=""
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
