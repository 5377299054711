import { FC, memo, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { AdditionalValue } from "store/properties/types";

import styles from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

const investGapClass = classNames(
  "d-flex flex-column",
  styles["invest-about-property__wrapper"]
);
const aboutThePropertyClass = classNames(
  "d-flex flex-column flex-sm-row justify-content-sm-between",
  styles["invest-about-property__box"]
);
const topWrapperClass = classNames(
  "d-flex flex-column",
  styles["invest-about-property__top-wrapper"]
);

interface InvestmentAboutThePropertyProps {
  wysiwygDescription?: string;
  values: Array<AdditionalValue>;
  floorPlanImage?: string;
}

export const InvestmentAboutTheProperty: FC<InvestmentAboutThePropertyProps> =
  memo(({ wysiwygDescription, values, floorPlanImage }) => {
    const [mediaContent, setMediaContent] = useState<"video" | "image" | null>(
      null
    );

    const checkMediaContent = useCallback(async () => {
      if (!floorPlanImage) {
        return null;
      }

      try {
        const image = document.createElement("img");
        image.src = floorPlanImage;
        await image.decode();
        setMediaContent("image");
      } catch (error) {
        try {
          const video = document.createElement("video");
          video.src = floorPlanImage;
          video.load();
          setMediaContent("video");
        } catch (error) {
          console.error(error);
          setMediaContent(null);
        }
      }
    }, [floorPlanImage]);

    useEffect(() => {
      checkMediaContent();
    }, [checkMediaContent]);

    const { t: tOpportunityDetailPageInvestment } = useTranslate('opportunity_detail_page.investment');

    if (
      !floorPlanImage &&
      wysiwygDescription === "<p></p>\n" &&
      values &&
      values.every(({ value }) => !value)
    ) {
      return null;
    }

    return (
      <div className={aboutThePropertyClass}>
        <div className={styles["invest-about-property__item"]}>
          <div className={topWrapperClass}>
            <div className={styles["invest-about-property__item-title"]}>
              {tOpportunityDetailPageInvestment('opportunity_detail_page.investment.description')}
            </div>
            <div className={investGapClass}>
              {wysiwygDescription && (
                <div className={styles['wysiwyg-container']} dangerouslySetInnerHTML={{ __html: wysiwygDescription }} />
              )}
            </div>
          </div>
        </div>
        {floorPlanImage ? (
          <div
            className={`${styles["invest-about-property__img-container"]} align-self-center`}
          >
            {mediaContent && mediaContent === "image" ? (
              <img src={floorPlanImage} alt="floor plan" />
            ) : (
              <video src={floorPlanImage} controls />
            )}
          </div>
        ) : (
          <div
            className={`${styles["invest-about-property__img-container"]} align-self-center`}
          >
            <img
              className={styles['invest-about-property__img-container__watermark']}
              src="/img/watermart.svg" alt="watermark"
            />
          </div>
        )}
      </div>
    );
  });
