/* eslint-disable no-restricted-globals */
import { useState, useEffect, MutableRefObject } from "react";
import { useHistory } from "react-router";

import { TContext } from "../components/anchor-context";
import { TStore } from "../components/anchor-provider";

// Hook listening for hash change
export function useHash(
  ref: MutableRefObject<TStore>,
  setBlockScrollEvet
): [string, TContext["setHash"]] {
  const [hash, setHash] = useState("");
  const history = useHistory();


  const changeHash = (newHash: string) => {
    setHash(newHash);
    history.replace({ pathname: history.location.pathname, search: history.location.search, hash: newHash.substr(1) });
  };

  useEffect(() => {
    const onHashChange = (event?: HashChangeEvent | PopStateEvent) => {
      setHash(location.hash);

      // Small trick to avoid handling native scroll event on
      // hash change causing a second scroll
      if (event && event.type === "hashchange") {
        setBlockScrollEvet(true);
        setTimeout(() => {
          setBlockScrollEvet(false);
        }, 1000);
      }
    };

    addEventListener("hashchange", onHashChange, false);
    addEventListener("popstate", onHashChange, false);

    // Can't set it directly because of mismatch when using
    // Universal Rendering (server-side doesn't get the hash from requests)
    onHashChange();

    return () => {
      removeEventListener("hashchange", onHashChange, false);
      removeEventListener("popstate", onHashChange, false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [hash, changeHash];
}
