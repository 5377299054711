import { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading";
import { PropertySubHeader } from 'new-architecture/modules/property-sub-header';
import { useGetPropertyByIdQuery } from 'api/property-teaser';
import { HeaderContext, SubheaderPortal } from 'new-architecture/modules/header';
import { PropertyInvestment } from 'new-architecture/modules/property-investment';
import { NavigationBar } from 'new-architecture/modules/property-investment';
import { PropertySectionAnchor } from '../../constants/property-section-anchor';
import { isEmptySection, getNonEmptySections } from '../../helpers/section-validation';
import { AnchorProvider, AnchorSection } from 'new-architecture/modules/anchor-navigation';
import { PropertyRiskLevel } from 'new-architecture/modules/property-risk-level';
import { PropertyPartners } from 'new-architecture/modules/property-partners';
import { PropertySecurityInfo } from 'new-architecture/modules/property-security-info';
import { PropertyDataRoom } from 'new-architecture/modules/property-data-room';
import { PropertyPerformanceReport } from 'new-architecture/modules/property-performance-report';
import { useGetEducationMaterialBySearchQuery } from 'api/estating-academy';
import { useSession } from 'context/session';
import { PropertyEducationAndResearch } from 'new-architecture/modules/property-education-and-research';
import { PropertyDisclaimer } from 'new-architecture/modules/property-disclaimer';
import { PropertyPartner } from 'new-architecture/modules/property-partner';
import { InvestmentOpportunityLocation } from 'new-architecture/modules/property-investment/components/investment-opportunity-location';
import { Helmet } from 'react-helmet';
import { useQuery } from 'hooks/use-query';
import { TeaserPreview } from 'new-architecture/modules/teaser-preview';
import { getTeaserDownloadUrl } from 'api/teaser';
import { PropertyTeaserType } from 'store/properties/types';
import {
  useTrackOpportunityDetailsTeaserPreviewCloseMutation,
  useTrackOpportunityDetailsTeaserPreviewOpenMutation,
  useTrackOpportunityDetailsTeaserPreviewPageChangeMutation 
} from 'api/analitics';
import { v4 } from 'uuid';
import { useTolgee } from '@tolgee/react';

export const Property: FC<{}> = memo(() => {
  const { id } = useParams<{ id: string }>();
  const { containerHeight } = useContext(HeaderContext);
  const { user } = useSession();
  const tolgee = useTolgee();

  const { data: property, isLoading } = useGetPropertyByIdQuery({ id, params: `lang=${tolgee.getPendingLanguage()}` });
  const { data: educationMaterial, isLoading: isMaterialLoading } = useGetEducationMaterialBySearchQuery({ 
    whoAreYou: user?.whoAreYou!,
    tags: property?.tags!
  });

  const helmet = useMemo(() => {
    if (!property) {
      return null;
    }

    return (
      <Helmet>
        <title>{`${property.name} - Estating`}</title>
        {property.description && 
          <meta name="description" content={`${property.description.name} | ${property.description.organisation}`} />
        }
      </Helmet>
    )
  }, [property]);

  const sectionsDescription: Array<{ id: PropertySectionAnchor, section: JSX.Element }> = useMemo(() => {
    if (!property) {
      return [];
    }

    return [
      {
        id: PropertySectionAnchor.INVESTMENT,
        section: <PropertyInvestment property={property} />
      },
      {
        id: PropertySectionAnchor.PARTNER,
        section: <PropertyPartner property={property} />,
      },
      {
        id: PropertySectionAnchor.LOCATION,
        section: <InvestmentOpportunityLocation property={property} />,
      },
      {
        id: PropertySectionAnchor.RISK,
        section: <PropertyRiskLevel property={property} />
      },
      {
        id: PropertySectionAnchor.ROLES,
        section: <PropertyPartners property={property} />
      },
      {
        id: PropertySectionAnchor.SECURITY_INFORMATION,
        section: <PropertySecurityInfo property={property} />
      },
      {
        id: PropertySectionAnchor.DATA_ROOM,
        section: <PropertyDataRoom property={property} />
      },
      {
        id: PropertySectionAnchor.INVESTOR_REPORT,
        section: <PropertyPerformanceReport property={property} />
      },
      {
        id: PropertySectionAnchor.EDUCATION_AND_RESEARCH,
        section: <PropertyEducationAndResearch materials={property.academyMaterials}/>
      },
      {
        id: PropertySectionAnchor.DISCLAIMER,
        section: <PropertyDisclaimer disclaimers={property.disclaimers.map(({ value }) => value)} />
      }
    ]
  }, [property]);

  const query = useQuery();
  const history = useHistory();

  const previewedTeaserData = useMemo(() => {
    const previewedTeaserId = query.get('teaser-preview');
    if (!previewedTeaserId) {
      return null;
    }

    return {
      teaserUrl: getTeaserDownloadUrl(previewedTeaserId),
    }
  }, [query]);

  const onClosePreview = useCallback(() => {
    query.delete('teaser-preview');
    history.push({ search: query.toString() });
  }, [history, query]);

  const sessionId = useRef(v4());

  const generateNewSessionId = useCallback(() => {
    sessionId.current = v4();
  }, []);

  const [trackTeaserOpen] = useTrackOpportunityDetailsTeaserPreviewOpenMutation();
  const [trackTeaserClose] = useTrackOpportunityDetailsTeaserPreviewCloseMutation();
  const [trackTeaserPageChange] = useTrackOpportunityDetailsTeaserPreviewPageChangeMutation();

  const onTeaserPreviewOpen = useCallback((teaser: PropertyTeaserType, page: number) => {
    trackTeaserOpen({
      opportunity: teaser.name,
      opportunityId: teaser._id,
      page: String(page),
      sessionId: sessionId.current
    });
  }, [trackTeaserOpen]);

  const onTeaserPreviewClose = useCallback((teaser: PropertyTeaserType, page: number) => {
    trackTeaserClose({
      opportunity: teaser.name,
      opportunityId: teaser._id,
      page: String(page),
      sessionId: sessionId.current
    });

    generateNewSessionId();
  }, [generateNewSessionId, trackTeaserClose]);

  const onTeaserPreviewPageChange = useCallback((teaser: PropertyTeaserType, page: number) => {
    trackTeaserPageChange({
      opportunity: teaser.name,
      opportunityId: teaser._id,
      page: String(page),
      sessionId: sessionId.current
    });
  }, [trackTeaserPageChange]);

  if (isLoading || isMaterialLoading) {
    return <Loading/>
  }

  if (!property || !educationMaterial) {
    return null;
  }

  return (
    <>
      {helmet}
      <AnchorProvider offset={containerHeight}>
        <SubheaderPortal>
          <PropertySubHeader property={property} />
          <NavigationBar anchors={getNonEmptySections(property)} />
        </SubheaderPortal>

        <main>
          {sectionsDescription.filter(section => !isEmptySection(section.id, property)).map(({ id, section }) => (
            <AnchorSection
              id={id}
              style={{ scrollMarginTop: `${containerHeight - 1}px` }}
              key={id}
            >
              {section}
            </AnchorSection>
          ))}
        </main>
        
        {
          previewedTeaserData &&
          <TeaserPreview
            isOpen={!!previewedTeaserData}
            onClose={onClosePreview}
            file={previewedTeaserData.teaserUrl}
            mimeType={'application/pdf'}
            title={''}

            property={property}
            onPropertyClose={onTeaserPreviewClose}
            onPropertyOpen={onTeaserPreviewOpen}
            onPropertyPageChange={onTeaserPreviewPageChange}
          />
        }
      </AnchorProvider>
    </>
  );
});

