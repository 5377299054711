import { FC, memo, useContext, useMemo } from 'react';
import { AnchorContext, AnchorLink } from 'new-architecture/modules/anchor-navigation';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface NavigationBarProps {
  anchors: Array<string>;
}

export const NavigationBar: FC<NavigationBarProps> = memo(({ anchors }) => {
  const { hash } = useContext(AnchorContext);

  const parsedHash = useMemo(() => decodeURI(hash.slice(1)) , [hash]);
  const { t: tOpportunityDetailPageAnchorNavigation } = useTranslate('opportunity_detail_page.anchor_navigation');

  return (
    <div className={styles['navigation-bar']}>
      <Container>
        <ul className={styles['navigation-bar__list']}>
          {anchors.map((value) => (
            <AnchorLink
              role="button"
              key={value}
              href={`#${value}`}
            >
              <li 
                className={
                  classNames(
                    styles['navigation-bar__list-item'],
                    {[styles['navigation-bar__list-item_active']]: parsedHash === value},
                  )
                }
              >
                {tOpportunityDetailPageAnchorNavigation(value)}
              </li>
            </AnchorLink>
          ))}
          <div className={styles['navigation-bar__underline']}/>
        </ul>
      </Container>
    </div>
  )
});
