import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserAPI from "../../api/user";
import { AuditLog } from "../../store/properties/types";
import "./InteractionsTable.scss";
import dayjs from "dayjs";
import { getBaseUrl } from "../../app-config";
import { useSession } from "../../context/session";
import ArrowDownTray from "../../components/Icons/ArrowDownTray";
import urlJoin from 'url-join';
import { useTranslate } from "@tolgee/react";

export default function MyInteractionList(props: { type: AuditLog["action"] }) {
  const user = useSession();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<AuditLog[]>([]);
  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');

  const loadInteractionsLogs = async () => {
    setLoading(true);
    const auditLogs = await UserAPI.getAuditLogs(props.type);
    setItems(auditLogs);
    setLoading(false);
  };

  useEffect(() => {
    loadInteractionsLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.type === "OPPORTUNITY_SHARED" && (
        <p>
          {tPrivateAreaMyAccount('private_area.my_account.please_find_below_the_list')}
        </p>
      )}
      <a
        className="ml-auto d-flex mb-3"
        style={{ width: "fit-content" }}
        href={urlJoin(getBaseUrl(), `/user/auditLog/${props.type}/download?token=${
          user.getToken()
        }`)}
      >
        {tPrivateAreaMyAccount('private_area.my_account.download')}
        <ArrowDownTray className="ml-2" width={18} height={18} />
      </a>
      <table className="interactions">
        <thead>
          <tr>
            <th>
              {tPrivateAreaMyAccount('private_area.my_account.share_table.opportunity_name')}
            </th>
            {props.type === "OPPORTUNITY_SHARED" &&
              <th>
                {tPrivateAreaMyAccount('private_area.my_account.share_table.shared_to')}
              </th>
            }
            <th>
              {tPrivateAreaMyAccount('private_area.my_account.share_table.date')}
            </th>
            <th>
              {tPrivateAreaMyAccount('private_area.my_account.share_table.time')}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={4}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only ">
                  {tPrivateAreaMyAccount('private_area.my_account.share_table.loading')}
                  </span>
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            items.map((item) => (
              <tr key={item._id}>
                <td>
                  <Link
                    to={`/properties/${item.changes[0].values.opportunity._id}`}
                  >
                    {item.changes[0].values.opportunity.name}
                  </Link>
                </td>
                {props.type === "OPPORTUNITY_SHARED" && (
                  <td>{item.changes[0].values.sharedWith}</td>
                )}
                <td>{dayjs(item.date).format("DD.MM.YY")}</td>
                <td>{dayjs(item.date).format("HH:mm")}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
