import React from "react";
import fbLogo from "../assets/facebook.svg";
import googleLogo from "../assets/google.svg";
import Button from "../components/Button";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { getFacebookAppId, getGoogleClientId } from "../app-config";
import { UserAPI } from "../api/user";
import history from "../history-entity";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { User, UserLocal } from "../context/types";
import { useTranslate } from "@tolgee/react";

type SocialLoginProps = {
  success: ({ userOptions }: { userOptions: UserLocal }) => void;
  error: (err: Error) => void;
  showTerms?: boolean;
};

enum LoginPlatFormEnum {
  facebook = "facebook",
  google = "google",
  apple = "apple",
}

type LoginPlatforms = "facebook" | "google" | "apple" | "";

const SocialLogin: React.FC<SocialLoginProps> = ({
  success,
  error,
  showTerms = false,
}) => {
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [loginPlatform, setLoginPlatform] = React.useState<LoginPlatforms>("");

  const loginWithFacebook = (results: ReactFacebookLoginInfo) => {
    const { email, id, accessToken, name } = results;

    if (accessToken) {
      setLoginLoading(true);
      setLoginPlatform("facebook");
      UserAPI.authSocial({
        platform: "facebook",
        userId: id,
        token: accessToken,
        email: email || "",
      })
        .then((existsUser) => {
          const { notExists, ...user } = existsUser || {};
          setLoginLoading(false);
          if (!notExists) {
            success({
              userOptions: user,
            });
          } else {
            const user: Partial<User & { fromSocial: boolean }> = {};
            if (!!name && name.indexOf(" ") !== -1) {
              [user.firstName, user.lastName] = name.split(" ");
            }
            user.email = email || existsUser.email;
            user.facebookUserId = id;
            user.fromSocial = true;

            history.push("/signup", { user });
          }
        })
        .catch((e) => {
          error(new Error(e.message || "Fail to sign up with facebook"));
          setLoginLoading(true);
        });
    }
  };

  const loginWithGoogle = (results: any) => {
    const { profileObj, accessToken } = results;
    if (accessToken) {
      setLoginLoading(true);
      setLoginPlatform("google");
      UserAPI.authSocial({
        platform: "google",
        email: profileObj.email || "",
        userId: profileObj.googleId || "",
        token: accessToken || "",
      })
        .then((existsUser) => {
          const { notExists } = existsUser || {};

          setLoginLoading(false);
          if (!notExists) {
            success({
              userOptions: existsUser,
            });
          } else {
            const user: Partial<User & { fromSocial: boolean }> = {};
            user.firstName = profileObj.givenName || "";
            user.lastName = profileObj.familyName || "";
            user.email = profileObj.email || existsUser.email || "";
            user.googleUserId = profileObj.googleId || "";
            user.fromSocial = true;
            history.push("/signup", { user });
          }
        })
        .catch((e) => {
          error(new Error(e.message || "Fail to sign up with google"));
          setLoginLoading(false);
        });
    } else {
      setLoginLoading(false);
    }
  };

  const { t: tPublicArea } = useTranslate('public_area');

  return (
    <>
      <GoogleLogin
        clientId={getGoogleClientId()}
        onSuccess={loginWithGoogle}
        render={(renderProps) => {
          return (
            <Button
              className={`btn-white border btn-block mt-3`}
              label={tPublicArea('public_area.continue_with_google')}
              onClick={renderProps.onClick}
              iconStyles={{
                position: "absolute",
                left: "10%",
              }}
              buttonAttributes={{
                disabled: loginLoading,
              }}
              icon={
                loginLoading && loginPlatform === LoginPlatFormEnum.google ? (
                  <div
                    className="spinner-border small btn-spinner-border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <img src={googleLogo} alt="google logo" />
                )
              }
            />
          );
        }}
      />
      <FacebookLogin
        appId={getFacebookAppId()}
        fields="name,email,picture"
        callback={loginWithFacebook}
        render={(renderProps) => (
          <Button
            className={`btn-white border btn-block my-2`}
            label={tPublicArea('public_area.continue_with_facebook')}
            onClick={renderProps.onClick}
            buttonAttributes={{
              disabled: loginLoading,
            }}
            iconStyles={{
              position: "absolute",
              left: "10%",
            }}
            icon={
              loginLoading && loginPlatform === LoginPlatFormEnum.facebook ? (
                <div
                  className="spinner-border small btn-spinner-border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <img src={fbLogo} alt="facebook logo" />
              )
            }
          />
        )}
        icon={<img src={fbLogo} alt="facebook logo" />}
      />
      {showTerms ? (
        <>
          <hr />
          <div style={{ fontSize: 12 }} className="text-center mx-auto w-75">
            <span>
              {tPublicArea('public_area.by_creating_account1')}
              {" "}
              <a
                target="_blank"
                className="alert-link"
                href="https://www.estating.com/terms-of-use"
                rel="noreferrer"
              >
                {tPublicArea('public_area.by_creating_account2')}
              </a>
              {", "}
              <a
                target="_blank"
                className="alert-link"
                href="https://www.estating.com/privacy-policy"
                rel="noreferrer"
              >
                {tPublicArea('public_area.by_creating_account3')}
                  {" "}
                  {tPublicArea('public_area.by_creating_account4')}
              </a>
              {" "}
              <a
                target="_blank"
                className="alert-link"
                href="https://www.estating.com/cookie-policy"
                rel="noreferrer"
              >
                {tPublicArea('public_area.by_creating_account5')}
              </a>
            </span>
          </div>
          <hr />
        </>
      ) : null}
    </>
  );
};

export default SocialLogin;
