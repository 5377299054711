import { useCallback, useEffect, useState } from "react";

export const useBanner = () => {
  const [isBannerShow, _setIsBannerShow] = useState(false);

  useEffect(() => {
    const serializedValue = localStorage.getItem('isBannerShow');

    if(!serializedValue) {
      _setIsBannerShow(true);
      return;
    } 

    const { isBannerShow } = JSON.parse(serializedValue);
    _setIsBannerShow(isBannerShow);
  }, []);

  const setIsBannerShow = useCallback((value: boolean) => {
    _setIsBannerShow(value);
    localStorage.setItem('isBannerShow', JSON.stringify({ isBannerShow: value }));
  }, []);

  return { isBannerShow, setIsBannerShow };
}