import { RefObject, useCallback, useEffect } from "react";

export const useOutsideClick = (ref: RefObject<HTMLElement>, onClick: () => void) => {
  
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
      onClick();
    }
  }, [onClick, ref]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);
}