import { FC, memo } from "react";
import { InvestmentTitle } from "../investment-title";
import { ReactComponent as LampSvg } from "assets/lamp.svg";
import { InvestmentList } from "../investment-list";
import { InvestmentBox } from "../investment-box";
import { useTranslate } from "@tolgee/react";

import styles from "./style.module.scss";

interface InvestmentStrategyProps {
  values: Array<string>;
}

export const InvestmentStrategy: FC<InvestmentStrategyProps> = memo(({ values }) => {
  const { t: tOpportunityDetailPageInvest } = useTranslate('opportunity_detail_page.investment')

  return (
    <InvestmentBox>
      <InvestmentTitle title={tOpportunityDetailPageInvest('opportunity_detail_page.investment.investment_title')} icon={<LampSvg />} />
      <div className={styles.strategy__subcontainer}>
        <div className={styles.strategy__subheader}>
          {tOpportunityDetailPageInvest('opportunity_detail_page.investment.subheader_strategy')}
        </div>
        <div className={styles["strategy__list-container"]}>
          <InvestmentList list={values} />
        </div>
      </div>
    </InvestmentBox>
  );
});

InvestmentStrategy.displayName = "InvestmentStrategy";
