import { memo, FC, useContext, useRef, useState, useEffect } from 'react'
import styled from 'styled-components';
import { Quarter } from './quarter';
import { TableContext } from '../../index';
import { gapBetweenMonthColumns, propertyNameColumnPaddingRight, propertyNameColumnWidth, tablePaddingX } from '../../utils/constants';


interface TableBackgroundProps {
  height: number;
}

export const Background: FC<TableBackgroundProps> = memo(({ height }) => {
  const { backgroundColumnsDescription } = useContext(TableContext);

  const mainBackground = useRef<HTMLDivElement | null>(null);
  const [backgroundWidth, setBackgroundWidth] = useState(0);

  useEffect(() => {
    setBackgroundWidth(mainBackground.current?.clientWidth ?? 0);
  }, [mainBackground.current?.clientHeight]);

  return (
    <>
      <FirstColumnBackgroundContainer height={height} width={backgroundWidth}>
        <FirstColumnBackground height={height} />
      </FirstColumnBackgroundContainer>
      <BackgroundContainer height={height} ref={mainBackground} >
        {backgroundColumnsDescription.map(([quarterName, monthsDescription ]) => (
          <Quarter name={quarterName} monthsDescription={monthsDescription} key={quarterName} />
        ))}
      </BackgroundContainer>
    </>
  )
})

interface FirstColumnBackgroundProps {
  height: number;
}

const FirstColumnBackgroundContainer = styled.div<{height: number, width: number}>`
  position: absolute;
  top: 0;
  left: 0;

  min-height: ${({ height }) => height}px;
  height: 100%;
  width: ${({ width }) => width}px;
`

const FirstColumnBackground = styled.div<FirstColumnBackgroundProps>`
  position: sticky;
  left: 0;
  
  width: ${propertyNameColumnWidth}px;
  min-height: ${({ height }) => height}px;
  height: 100%;

  background-color: #F9FCFD;

  z-index: 3;
`;

interface BackgroundContainerProps {
  height: number;
}

const BackgroundContainer = styled.div<BackgroundContainerProps>`
  position: absolute;
  top: 0;
  left: 0;

  min-height: ${({ height }) => height}px;
  height: 100%;

  padding-left: ${tablePaddingX + propertyNameColumnWidth + propertyNameColumnPaddingRight}px;

  display: flex;
  grid-gap: ${gapBetweenMonthColumns}px;
`;