import React, { useMemo, useState } from "react";
import { UserLocal } from "../../context/types";
import Button from "../../components/Button";
import ChangePassword from "./ChangePassword";
import ChangeAccountInfo from "./ChangeAccountInfo";
import { _validateEmail } from "helpers/helpers";
import { UserAPI } from "../../api/user";
import AdditionalInformation from "./AdditionalInformation";
import CompleteYourProfile from "./CompleteYourProfile";
// import DeleteAccount from "./DeleteAccount";
import { privateApi } from "../../api/estatingApi";
import history from "../../history-entity";
import PersonalSVG from "../../assets/personal.svg";
import ShareSVG from "../../assets/share-circle.svg";
import DollarSVG from "../../assets/dollar-circle.svg";
import AdditionalInforSVG from "../../assets/additional_info.svg";
import ChangePwdSVG from "../../assets/lock.svg";
import { useSession } from "../../context/session";
import IncompleteProfileBadge from "../../components/IncompleteProfileBadge";
import MyInteractionList from "./MyInteractionList";
import { WhoAreYou } from "../../types/user/who-are-you";
import { Helmet } from "react-helmet";
import { useTranslate } from "@tolgee/react";

type MyAccountProps = {};

const MyAccount: React.FC<MyAccountProps> = () => {
  const session = useSession();
  const user = session.user!;
  const { t: tTitle } = useTranslate('title');

  const [changedUser, setChangedUser] = useState<UserLocal>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    whoAreYou: user.whoAreYou,
    country: user.country,
    phoneNumber: user.phoneNumber,
    lang: user.lang
  });

  const [changePassword, setChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [userChangeMessage, setUserChangeMessage] = useState("");
  const [pwdChangeMessage, setPwdChangeMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  const MyAccountTabType = useMemo(() => Object.freeze({
    PERSONAL_INFORAMTION: tTitle('title.personal_information', 'Personal Information'),
    ADDITIONAL_INFORMATION: tTitle('title.additional_information', 'Additional Information'),
    MY_SHARE_LIST: tTitle('title.my_share_list', 'My Share List'),
    MY_INVEST_LIST: tTitle('title.my_invest_list', 'My Invest List'),
    CHANGE_PASSWORD: tTitle('title.change_password', 'Change Password'),
    NULL: ''
  }), [tTitle]);

  const [currentTab, setCurrentTab] = useState<keyof typeof MyAccountTabType>('NULL');

  const helmet = useMemo(() => {
    const title = MyAccountTabType[currentTab];

    if (!title) {
      return null;
    }

    return (
      <Helmet>
        <title>{`${title} - Estating`}</title>
      </Helmet>
    )

  }, [MyAccountTabType, currentTab]);

  const [isAnyTabActive, setAnyTabActive] = useState(false);

  const onChange = (value: any, args: { key: keyof UserLocal }) => {
    const { key } = args;

    setChangedUser((changedUser) => {
      return { ...changedUser, [key]: value };
    });
  };

  const _isValidUserInfo = () => {
    const { firstName, lastName, email } = changedUser;

    return !!firstName && !!lastName && _validateEmail(email);
  };

  const _isValidUserPassword = () => {
    const { newPassword, confirmNewPassword } = changePassword;

    return newPassword.length >= 8 && newPassword === confirmNewPassword;
  };

  const isValidUserInfo = _isValidUserInfo();
  const isValidUserPassword = _isValidUserPassword();

  const changeUserInfo = () => {
    if (!isValidUserInfo) {
      return false;
    }

    setLoading(true);
    setUserChangeMessage("");
    UserAPI.changeUser({ ...user, ...changedUser })
      .then((changedUser) => {
        session.setUser(changedUser);
        session.loadSession();
        setHasError(false);
        setUserChangeMessage("Successfully changed!");
      })
      .catch((error) => {
        setHasError(true);
        setUserChangeMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setUserChangeMessage("");
        }, 3000);
      });
  };

  const changeUserPassword = () => {
    if (!isValidUserPassword) {
      return false;
    }

    const { currentPassword, newPassword } = changePassword;
    setLoading(true);
    setPwdChangeMessage("");
    UserAPI.changePassword(currentPassword, newPassword)
      .then(() => {
        setHasError(false);
        setPwdChangeMessage("Successfully changed!");
        setChangePassword({
          currentPassword: "",
          confirmNewPassword: "",
          newPassword: "",
        });
      })
      .catch((error) => {
        setHasError(true);
        setPwdChangeMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setPwdChangeMessage("");
        }, 3000);
      });
  };

  const _isChanged = () => {
    return (
      user.firstName !== changedUser.firstName ||
      user.lastName !== changedUser.lastName ||
      user.email !== changedUser.email ||
      user.country !== changedUser.country ||
      user.phoneNumber !== changedUser.phoneNumber
    );
  };

  const logOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      const response = await privateApi.post("/user/signout");

      if (response.status === 200) {
        session.logout();
        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  let isPersonalInformationComplete = false;
  let isAdditionalInformationComplete = false;
  if (user?.profileCompletness) {
    isPersonalInformationComplete = user.profileCompletness.personal.missedFields === 0;
    if (user.whoAreYou === 'Financial Advisor') {
      isAdditionalInformationComplete = user.profileCompletness.additional.totalFields - user.profileCompletness.additional.missedFields >= 20;
    } else {
      isAdditionalInformationComplete = true;
    }
  }

  const { t: tPrivateAreaMyAccount } = useTranslate('private_area.my_account');

  return (
    <div className="d-md-flex d-xl-flex my-6 px-sm-6 account">
      {helmet}
      <div className="px-0 mb-5 my-account px-lg-0 px-sm-0 px-3 mb-5">
        <h1 className="font-weight-bold">{tPrivateAreaMyAccount('private_area.my_account.title')}</h1>
        <h4 className="font-weight-bold mt-5">{tPrivateAreaMyAccount('private_area.my_account.personal_settings')}</h4>
        <div className="p-0 profile-nav-card left-side-fixed-width">
          <ul className="nav nav-tabs">
            <li className="nav-item py-2 position-relative px-2 border border-top-radius d-flex justify-content-between align-items-center">
              <a
                className="nav-link"
                id="tabs-personal-info-tab"
                data-toggle="tab"
                href="#tabs-personal-info"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setAnyTabActive(true);
                  setCurrentTab('PERSONAL_INFORAMTION')
                }}
              >
                <img src={PersonalSVG} alt="" />
                <span className="ml-2">
                  {tPrivateAreaMyAccount('private_area.my_account.personal_information')}
                </span>
                
              </a>
              <span className="active-sign"></span>
              {!isPersonalInformationComplete && (
                <IncompleteProfileBadge id="personal-information-badge"/>
              )}
            </li>

            {/*----------------- Additional Information Start -----------------*/}

            <li className="nav-item px-2 py-2 position-relative border-bottom border-left border-right border-bottom-radius py-2 d-flex justify-content-between align-items-center">
              <a
                className="nav-link"
                id="tabs-additional-info-tab"
                data-toggle="tab"
                href="#tabs-additional-info"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setAnyTabActive(true);
                  setCurrentTab('ADDITIONAL_INFORMATION')
                }}
              >
                <img src={AdditionalInforSVG} alt="" />
                <span className="ml-2">
                  {tPrivateAreaMyAccount('private_area.my_account.additional_information')}
                </span>
              </a>
              <span className="active-sign"></span>
              {!isAdditionalInformationComplete && (
                <IncompleteProfileBadge id="additional-information-badge"/>
              )}
            </li>

            {/*----------------- Additional Information end -----------------*/}

            {/*----------------- My Interactions Start -----------------*/}

            <h4 className="font-weight-bold mt-5">
              {tPrivateAreaMyAccount('private_area.my_account.my_interactions')}
            </h4>

            <li className="nav-item py-2 position-relative px-2 border border-top-radius d-flex justify-content-between align-items-center">
              <a
                className="nav-link"
                id="tabs-my-share-info-tab"
                data-toggle="tab"
                href="#tabs-my-share-info"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setAnyTabActive(true);
                  setCurrentTab('MY_SHARE_LIST')
                }}
              >
                <img src={ShareSVG} width={24} height={24} alt="" />
                <span className="ml-2">
                  {tPrivateAreaMyAccount('private_area.my_account.my_share_list')}
                </span>
              </a>
              <span className="active-sign"></span>
            </li>

            <li className="nav-item px-2 py-2 position-relative border-bottom border-left border-right border-bottom-radius py-2 d-flex justify-content-between align-items-center">
              <a
                className="nav-link"
                id="tabs-my-invest-info-tab"
                data-toggle="tab"
                href="#tabs-my-invest-info"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setAnyTabActive(true);
                  setCurrentTab('MY_INVEST_LIST')
                }}
              >
                <img src={DollarSVG} width={24} height={24} alt="" />
                <span className="ml-2">
                  {tPrivateAreaMyAccount('private_area.my_account.my_invest_list')}
                </span>
              </a>
              <span className="active-sign"></span>
            </li>


            {/*----------------- My Interactions End -----------------*/}

            <h4 className="font-weight-bold mt-5">
              {tPrivateAreaMyAccount('private_area.my_account.security')}
            </h4>

            {/*----------------- Change Password end -----------------*/}

            <li className="nav-item d-flex px-2 py-2 position-relative border border-bottom-radius border-top-radius justify-content-between align-items-center">
              <a
                className="nav-link"
                id="tabs-change-password-tab"
                data-toggle="tab"
                href="#tabs-change-password"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setAnyTabActive(true);
                  setCurrentTab('CHANGE_PASSWORD')
                }}
              >
                <img src={ChangePwdSVG} alt="" />
                <span className="ml-2">
                  {tPrivateAreaMyAccount('private_area.my_account.button.change_password')}
                </span>
              </a>
              <span className="active-sign"></span>
            </li>

            {/*----------------- Change Password end -----------------*/}

            {/*----------------- Delete Account start -----------------*/}

            {/*<li className="nav-item d-flex py-2 d-flex border-bottom border-left border-right border-bottom-radius justify-content-between align-items-center">*/}
            {/*    <a className="nav-link"*/}
            {/*       id="tabs-change-password-tab"*/}
            {/*       data-toggle="tab"*/}
            {/*       href="#tabs-delete-account" role="tab"*/}
            {/*       aria-selected="true"*/}
            {/*       onClick={() => setAnyTabActive(true)}*/}
            {/*    >*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"*/}
            {/*             fill="none" stroke="currentColor" strokeLinecap="round"*/}
            {/*             strokeLinejoin="round" className="feather feather-user-x">*/}
            {/*            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>*/}
            {/*            <circle cx="8.5" cy="7" r="4"/>*/}
            {/*            <line x1="18" y1="8" x2="23" y2="13"/>*/}
            {/*            <line x1="23" y1="8" x2="18" y2="13"/>*/}
            {/*        </svg>*/}
            {/*        <span className='ml-4'>Delete Account</span>*/}
            {/*    </a>*/}
            {/*    <span className="active-sign"></span>*/}
            {/*</li>*/}

            {/*----------------- Delete Account end -----------------*/}
          </ul>
          <button className="btn btn-light" onClick={logOut}>
            <svg
              width="12"
              height="15"
              viewBox="0 0 12 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.14483 14.3333H9.93793C10.555 14.3333 11.0552 13.7364 11.0552 13V2.33333C11.0552 1.59695 10.555 1 9.93793 1H7.14483"
                stroke="#1B2633"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.2356 10.3333L1.00111 7.66667L3.2356 5"
                stroke="#1B2633"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.00004 7.66797H7.70349"
                stroke="#1B2633"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-1">
              {tPrivateAreaMyAccount('private_area.my_account.logout')}
            </span>
          </button>
          <p className="mt-2">
            {tPrivateAreaMyAccount('private_area.my_account.to_close_account')}
            {" "}
            <a href="mailto:info@estating.com">info@estating.com</a>.
          </p>
        </div>
      </div>
      <div className="col-xl-6 px-3">
        <div
          className={`tab-content px-0 ${!isAnyTabActive ? "mt-0" : "mt-6"}`}
        >
          {!isAnyTabActive && (
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 564 483"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.00108674 377.572C-0.110744 351.449 8.41131 326.023 24.237 305.264C40.0626 284.504 62.3014 269.58 87.482 262.82L90.7253 261.917L181.54 237.63L272.264 213.163L362.988 188.786C362.985 214.852 354.401 240.189 338.569 260.871C322.737 281.552 300.539 296.422 275.417 303.177L272.264 303.99L181.54 328.367L90.7253 353.105L0.00108674 377.572Z"
                fill="#1389A1"
                fillOpacity="0.05"
              />
              <path
                d="M484.524 294.208C484.521 320.224 475.921 345.513 460.057 366.155C444.193 386.797 421.952 401.639 396.779 408.381L393.62 409.192L302.535 433.523L211.63 457.853L120.726 482.094C120.729 456.077 129.329 430.788 145.193 410.146C161.057 389.505 183.299 374.662 208.471 367.92L211.63 367.109L302.535 342.689L393.62 318.448L484.524 294.118V294.208Z"
                fill="#1389A1"
                fillOpacity="0.05"
              />
              <path
                d="M563.118 0C563.114 26.0165 554.512 51.3053 538.644 71.9472C522.776 92.5891 500.529 107.431 475.351 114.173L472.19 115.074L381.083 139.405L199.319 187.976C199.313 161.944 207.922 136.638 223.81 115.992C239.697 95.3455 261.973 80.5142 287.176 73.8026L290.246 72.9916L381.173 48.6611L472.281 24.2404L563.118 0Z"
                fill="#1389A1"
                fillOpacity="0.05"
              />
            </svg>
          )}
          <div
            className="tab-pane fade show col-xl-9 col-lg-10 col-md-12 col-12 px-0"
            id="tabs-personal-info"
            role="tabpanel"
          >
            <div className="d-flex justify-content-end">
              {_isChanged() ? (
                <Button
                  label={tPrivateAreaMyAccount('private_area.my_account.reset')}
                  className="btn-sm"
                  onClick={() => setChangedUser({ ...user })}
                  styles={{ height: "auto" }}
                />
              ) : null}
            </div>
            {userChangeMessage ? (
              <div
                className={`alert text-center text-dark ${
                  hasError ? "alert-danger" : "alert-success"
                }`}
                style={{
                  position: "sticky",
                  top: "8rem",
                  zIndex: 10,
                  width: "inherit",
                }}
              >
                {userChangeMessage}
              </div>
            ) : null}
            <ChangeAccountInfo
              firstName={{
                value: changedUser.firstName,
                callback: (value) => onChange(value, { key: "firstName" }),
              }}
              lastName={{
                value: changedUser.lastName,
                callback: (value) => onChange(value, { key: "lastName" }),
              }}
              email={{
                value: changedUser.email,
                callback: (value) => onChange(value, { key: "email" }),
              }}
              phoneNumber={{
                value: changedUser.phoneNumber,
                callback: (value) => onChange(value, { key: "phoneNumber" }),
              }}
              country={{
                selected: changedUser.country,
                onChange: (e) => onChange(e.target.value, { key: "country" }),
              }}
              whoAreYou={{
                value: user.whoAreYou,
              }}
              financialAdvisor={user.financialAdvisor}
            />

            <Button
              label={tPrivateAreaMyAccount('private_area.my_account.common.button.save')}
              onClick={changeUserInfo}
              className={`btn-block mb-2 ${
                loading ? "is-loading is-loading-primary" : ""
              }`}
              buttonAttributes={{
                disabled: !isValidUserInfo,
              }}
              invalid={!isValidUserInfo}
            />
          </div>

          {/*--------------------- Additional Information start ---------------------*/}
          <div
            className="tab-pane fade show"
            id="tabs-additional-info"
            role="tabpanel"
          >
            {user.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ? (
              <AdditionalInformation />
            ) : (
              <CompleteYourProfile
                userId={user._id || ""}
                showSkipBtn={false}
              />
            )}
          </div>

          {/*--------------------- Additional Information end ---------------------*/}

          {/*--------------------- My Share List start ---------------------*/}
          <div
            className="tab-pane fade show"
            id="tabs-my-share-info"
            role="tabpanel"
            style={{overflowX: 'scroll'}}
          >
            <MyInteractionList type="OPPORTUNITY_SHARED"/>
          </div>

          {/*--------------------- My Share List end ---------------------*/}

          {/*--------------------- My Invest List start ---------------------*/}
          <div
            className="tab-pane fade show"
            id="tabs-my-invest-info"
            role="tabpanel"
          >
            <MyInteractionList type="INVESTMENT_ASK"/>
          </div>

          {/*--------------------- My Invest List end ---------------------*/}

          {/*--------------------- Change password start ---------------------*/}

          <div
            className="tab-pane fade show col-xl-9 col-lg-10 col-md-12 col-12 px-0"
            id="tabs-change-password"
            role="tabpanel"
          >
            {pwdChangeMessage ? (
              <div
                className={`alert text-dark ${
                  hasError ? "alert-danger" : "alert-success"
                }`}
                style={{
                  position: "fixed",
                  width: "auto",
                  top: "6rem",
                }}
              >
                <p className="text-center mb-0">{pwdChangeMessage}</p>
              </div>
            ) : null}
            <ChangePassword
              currentPassword={{
                value: changePassword.currentPassword,
                inputProps: {
                  id: "current-pwd-account",
                  autoComplete: "current-pwd",
                },
                callback: (value) => {
                  setChangePassword((changePassword) => {
                    changePassword.currentPassword = value;
                    return { ...changePassword };
                  });
                },
              }}
              newPassword={{
                value: changePassword.newPassword,
                inputProps: {
                  id: "new-pwd-account",
                  autoComplete: "new-pwd",
                },
                callback: (value) => {
                  setChangePassword((changePassword) => {
                    changePassword.newPassword = value;
                    return { ...changePassword };
                  });
                },
              }}
              confirmNewPassword={{
                value: changePassword.confirmNewPassword,
                inputProps: {
                  id: "confirm-new-pwd-account",
                  autoComplete: "confirm-new-pwd",
                },
                callback: (value) => {
                  setChangePassword((changePassword) => {
                    changePassword.confirmNewPassword = value;
                    return { ...changePassword };
                  });
                },
              }}
            />
            <Button
              label={tPrivateAreaMyAccount('private_area.my_account.common.button.change_password')}
              buttonAttributes={{
                disabled: !isValidUserPassword,
              }}
              className={`btn-block ${
                loading ? "is-loading is-loading-primary" : ""
              }`}
              onClick={changeUserPassword}
              invalid={!isValidUserPassword}
            />
          </div>
          {/*--------------------- Change password end ---------------------*/}

          {/*--------------------- Delete Account start ---------------------*/}

          {/*<div className="tab-pane fade show"*/}
          {/*     id="tabs-delete-account"*/}
          {/*     role="tabpanel">*/}
          {/*    <DeleteAccount/>*/}
          {/*</div>*/}

          {/*--------------------- Delete Account end ---------------------*/}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
