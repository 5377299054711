import { FC, memo, useCallback, ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components';
import goToFirstPageSvg from 'assets/ic_skip_previous_24px.svg';
import goToPrevPageSvg from 'assets/ic_play_arrow_24px.svg';
import goToLastPageSvg from 'assets/ic_skip_next_24px.svg';
import scalePlusSvg from 'assets/ic_add_circle_24px.svg';
import scaleMinusSvg from 'assets/ic_remove_circle_24px.svg';
import Select from '../Select';

const scaleValues: Array<number> = [];

for (let i = 0.5; i < 2.1; i+=0.1) {
  scaleValues.push(Math.round(i * 10) / 10);
}

const asotiativeValues = scaleValues.reduce((acum, current) => {
  acum[`${String(Math.round(current * 100 * 100) / 100)} %`] =  current;
  acum[current] = `${String(Math.round(current * 100 * 100) / 100)} %`;

  return acum;
}, {});

const scaleSelectOptions = scaleValues.map((value) => `${String(Math.round(value * 100 * 100) / 100)} %`);


interface PaginationProps {
  goToFirstPage: () => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  goToLastPage: () => void;
  goToPage: (page: number) => void;
  height: number;
  totalPages: number;
  currentPage: number;
  decreaseScale: () => void;
  increaseScale: () => void;
  scale: number;
  setScale: (number) => void;
}

export const Pagination: FC<PaginationProps> = memo(({ 
  height,
  goToFirstPage,
  goToPrevPage,
  goToNextPage,
  goToLastPage,
  goToPage,
  totalPages,
  currentPage,
  decreaseScale,
  increaseScale,
  scale,
  setScale,
}) => {
  const [ currentPageInputValue, setCurrentPageInputValue ] = useState(String(currentPage));

  useEffect(() => {
    setCurrentPageInputValue(String(currentPage));
  }, [currentPage]);

  const onPageChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (value === '') {
      setCurrentPageInputValue(value);
      return;
    }

    const contertedValue = Number(value);

    if (Number.isNaN(contertedValue) || contertedValue < 1 || contertedValue > totalPages) {
      return;
    }
    
    setCurrentPageInputValue(value);
    goToPage(contertedValue - 1);
  }, [goToPage, totalPages]);

  return (
    <PaginationContainer height={height}>
      <div className='d-flex align-items-center justify-content-center'>
        <PaginationButton onClick={goToFirstPage}>
          <img className='w-100 h-100' src={goToFirstPageSvg} alt="first page icon" />
        </PaginationButton>
        <PaginationButton onClick={goToPrevPage}>
          <img style={{transform: 'rotateY(180deg)'}} className='w-100 h-100' src={goToPrevPageSvg} alt="prev page icon" />
        </PaginationButton>
        <PaginationButton onClick={goToNextPage}>
          <img className='w-100 h-100' src={goToPrevPageSvg} alt="next page icon" />
        </PaginationButton>
        <PaginationButton onClick={goToLastPage}>
          <img className='w-100 h-100' src={goToLastPageSvg} alt="last page icon" />
        </PaginationButton>
      </div>
      <div className='d-flex justify-content-center align-items-center mx-1' style={{ height, gridGap: "5px" }}>
        <input
          style={{ 
            aspectRatio: "1",
            height,
            textAlign: "center",
            padding: "0.5rem",
            fontSize: "11px"
          }}
          value={currentPageInputValue}
          onChange={(event) => {
            onPageChange(event)
          }}
          onBlur={() => setCurrentPageInputValue(String(currentPage))}
          className="form-control input-theme-height"
        />
        <div>of</div>
        <div>{totalPages}</div>
      </div>
      <div className='d-flex align-items-center justify-content-center mx-1'>
        <PaginationButton onClick={decreaseScale}>
          <img className='w-100 h-100' src={scaleMinusSvg} alt="scale plus icon" />
        </PaginationButton>
        <PaginationButton onClick={increaseScale}>
          <img className='w-100 h-100' src={scalePlusSvg} alt="scale plus icon" />
        </PaginationButton>
      </div>
      <Select
        selected={asotiativeValues[scale]}
        onChange={({ target }) => setScale(asotiativeValues[target.value])}
        values={scaleSelectOptions}
        style={{
          height,
          fontSize: "11px",
          paddingLeft: "0.4rem",
          marginLeft: "0.4rem",
        }}
        containerClasses='mt-1 mb-1'
      />
    </PaginationContainer>
  );
});



const PaginationContainer = styled.div<{ height: number }>`
  min-height: ${({height}) => height}px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const PaginationButton = styled.div`
  cursor: pointer;

  height: 24px;

  aspect-ratio: 1;
`;
