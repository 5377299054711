import { ChangeEvent, FC, memo } from 'react'
import { Criterias } from 'new-architecture/modules/properties-sub-header/components/criterias';
import { CriteriaIconProps } from 'new-architecture/ui/criteria-icon';
import { CriteriaTip } from './criteria-tip';
import { SearchInput } from './search-input';
import { SearchTags } from './search-tags';
import { TagDescription } from '../types/tag-description';
import classNames from 'classnames';

import styles from './style.module.scss'


interface SubHeaderProps {
  criterias: Array<CriteriaIconProps & { criteriaKey: string }>;
  selectedCriteriaKey: string;
  onSelectCritery: (key: string) => void;
  searchValue: string;
  onChangeSearchValue: (event: ChangeEvent<HTMLInputElement>) => void;
  tags: Array<TagDescription>;
  onAddTag: (tag: TagDescription) => void;
  onRemoveTag: (tag: TagDescription) => void;
  onRemoveAllTags: () => void;
}

export const PropertiesSubHeader: FC<SubHeaderProps> = memo(({
  criterias,
  selectedCriteriaKey,
  onSelectCritery,
  searchValue,
  onChangeSearchValue,
  tags,
  onRemoveTag,
  onRemoveAllTags,
  onAddTag
}) => {
  const criteriasWrapperClass = classNames('mt-2 mt-md-0 flex-grow-1 flex-md-grow-0 overflow-auto', styles.hide_scrollbar);

  return (
      <div className="d-flex flex-column-reverse flex-md-row">
        <div className="mt-2 mt-sm-4 mt-md-0 mr-md-4 d-flex flex-column flex-sm-row-reverse flex-md-column-reverse justify-content-md-end text-nowrap">
          <div className="ml-sm-7 ml-md-0 d-sm-flex mt-md-2 flex-sm-column-reverse">
            <CriteriaTip />
          </div>
          <div className={criteriasWrapperClass}>
            <Criterias
              criterias={criterias}
              selectedCriteriaKey={selectedCriteriaKey}
              onSelect={onSelectCritery}
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <SearchInput
            value={searchValue}
            onChange={onChangeSearchValue}
            inputProps={{
              onKeyDown: (e) => {
                if (e.keyCode === 13) {
                  onAddTag({ label: searchValue, value: searchValue });
                }
              },
              
            }}
            containerClassName="bg-white"
          />
          <div className='mt-1'>
          <SearchTags
            tags={tags}
            onRemoveTag={onRemoveTag}
            onRemoveAllTags={onRemoveAllTags}
          />
          </div>
        </div>
      </div>
  )
});