import { FC, memo, useMemo } from "react";
import { PropertySubsectionTitle } from "../../property-performance-report/components/property-subsection-title";
import { PropertyTeaserType } from "@/store/properties/types";
import { ReactComponent as AgreeHands } from "assets/agree-hands.svg";
import { Container } from "react-bootstrap";

import styles from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

type Props = {
  property: PropertyTeaserType;
};

export const PropertyPartner: FC<Props> = memo(({ property }) => {
  const { text, image } = useMemo(() => {
    const { securityAndRoles } = property;
    const { roles, aboutThePartner } = securityAndRoles;
    const { text, image } = aboutThePartner || {};

    return { roles, text, image };
  }, [property]);

  const { t: tOpportunityDetailPagePartner } = useTranslate('opportunity_detail_page.partner');

  return (
    <Container className='mt-4'>
      {(text?.length || image) &&
        <div className={styles['wrapper__box']}>
          <PropertySubsectionTitle
            icon={<AgreeHands />}
            title={tOpportunityDetailPagePartner('opportunity_detail_page.partner')}
          />
          {image && (
            <div className={styles["wrapper__about-the-partner-image-container"]}>
              <img alt={"Partner logo"} src={image} />
            </div>
          )}
          {text && (
            <div
              className={styles["wrapper__about-the-partner-wysiwyg-container"]}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      }
    </Container>
  );
});

PropertyPartner.displayName = "PropertyPartner";