import { FC, memo } from 'react';
import { ReactComponent as Cross } from 'assets/ic_round-close.svg';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface ContactEstatingModalHeaderProps {
  onClose: () => void;
}

export const ContactEstatingModalHeader: FC<ContactEstatingModalHeaderProps> = memo(({ onClose }) => {
  const { t: tContactModal } = useTranslate('modal');

  return (
    <div className={styles['modal-header']}>
      <div className={styles['modal-header__title']}>
        {tContactModal('modal.contact_estating')}
      </div>
      <div
        className={styles['modal-header__close-button']}
        onClick={onClose}
        role="button"
      >
        <Cross />
      </div>
    </div>
  );
});