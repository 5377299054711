import { useState, useEffect, memo, PropsWithChildren, FC } from "react";
import { createPortal } from "react-dom";

export const Portal: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const [container] = useState(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);
  
  return createPortal(children, container);
});
