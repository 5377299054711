import { memo, FC, useMemo, useContext } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';
import { TableContext } from '../..';
import { Event } from '../../../../store/properties/types';
import { eventHeight, eventStatsColors } from '../../utils/constants';
import { calculateEventDuration, calculateEventXPosition } from '../../utils/helpers/calculations';
import { eventDescriptionPopover } from './event-description-popover';
import dayjs from 'dayjs';
import { useTranslate } from '@tolgee/react';

interface TimelineEventProps {
  event: Event;
}

export const TimelineEvent: FC<TimelineEventProps> = memo(({ event }) => {
  const { dateOneMonthBeforeStart } = useContext(TableContext);
  const positionX = useMemo(() => 
    calculateEventXPosition(event, dateOneMonthBeforeStart)
  , [
    event,
    dateOneMonthBeforeStart
  ]);

  const eventLength = useMemo(() => 
    calculateEventDuration(event)
  , [event]);

  const { t: tEnums } = useTranslate('enums');

  const eventName = useMemo(() => {
    return tEnums(`enums.subscription_status.${event.subscriptionStatus}`) || event.subscriptionStatus
  }, [event.subscriptionStatus, tEnums]);

  const popoverProps = useMemo(() => {
    const { startDate, endDate } =  event;
    return ({
      eventName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate)
    });
  }, [event, eventName]);

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="auto"
      overlay={eventDescriptionPopover(popoverProps)}
    >
      <EventContainer
        width={eventLength}
        positionX={positionX}
        backgrounColor={eventStatsColors[event.subscriptionStatus]}
      >
        <TextContainer>
          {eventName}
        </TextContainer>
      </EventContainer>
    </OverlayTrigger>
  )
});

interface EventComponentProps {
  positionX: number;
  width: number;
  backgrounColor: string;
}

const TextContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: calc(100% - 20px);
`;

const EventContainer = styled.div<EventComponentProps>`
  position: absolute;

  left: ${({ positionX }) => positionX}px;

  height: ${eventHeight}px;
  width: ${({width}) => width}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({backgrounColor}) => backgrounColor};
  color: white;

  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);

  z-index: 1;

  cursor: pointer;
`
