import { TagDescription } from 'store/search';
import { FC, memo, useCallback, useMemo } from 'react';
import { AllowedCountries, CategoryReadable, InvestmentStrategyReadable, PropertyObj, SubscriptionStatus } from 'store/properties/types';
import { PropertyCardDetails } from '../property-card-details';
import { PropertyStatus } from '../property-status';
import { PropertyCardSaveButton } from '../property-card-save-buttom';
import { useBookmarkPropertyMutation } from 'api/property-teaser';
import { trackPropertyReaction } from 'helpers/tracking';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import styles from './style.module.scss';

interface PropertyCardProps {
  propertyDescription: PropertyObj;
  onTagClick: (tag: TagDescription) => void;
}

export const PropertyCard: FC<PropertyCardProps> = memo(({
  propertyDescription,
  onTagClick,
}) => {
  const { property, isBookmarked } = propertyDescription;
  const { assets, options, locations, name, summary, _id, specialLocationDescription } = property;
  const { status, investmentStrategy, category } = options;
  const history = useHistory();

  const cardImageSrc = useMemo(() => assets[0] || null, [assets]);
  const tags = useMemo<Array<TagDescription>>(() => {
    const out: Array<TagDescription> = [];

    if (investmentStrategy) {
      out.push({
        label: InvestmentStrategyReadable[investmentStrategy],
        value: investmentStrategy
      });
    }

    if (category) {
      out.push({
        label: CategoryReadable[category],
        value: category
      });
    }

    if (locations) {
      const collisionObject = {};

      locations.forEach((location) => {
        const target = location.coords.country;

        if (!target || Object.prototype.hasOwnProperty.call(collisionObject, target)) {
          return;
        }

        collisionObject[target] = null;
        out.push({
          label: AllowedCountries[target],
          value: target
        });
      });
    }

    return out;
  }, [investmentStrategy, category, locations]);

  const onPropertyClick = useCallback(() => {
    history.push(`/opportunity/${_id}`);
  }, [history, _id]);

  const [bookmark, { isLoading: isBookmarkPending }] = useBookmarkPropertyMutation();

  const handleBookmark = useCallback(async () => {
    await bookmark(_id);
    
    if (!isBookmarked) {
      trackPropertyReaction("bookmark", "bookmark");
    }

  }, [_id, bookmark, isBookmarked]);

  return (
    <div className={
        classNames(
          styles.card,
          { [styles['card_closed-theme']]: status === SubscriptionStatus.CLOSED_TRANSACTION }
        )
      }
    >
      <div 
        className={styles['card__image-container']}
        role="button"
        onClick={onPropertyClick}
      >
        {
          cardImageSrc && 
          <img
            alt="Property"
            src={cardImageSrc}
          />
        }
        <div className={styles['card__status-container']}>
          <PropertyStatus status={status} />
        </div>
      </div>

      <div className={styles['card__content-container']}>
        <PropertyCardDetails
          specialLocationDescription={specialLocationDescription}
          onPropertyClick={onPropertyClick}
          locations={locations}
          name={name}
          summary={summary}
          options={options}
          tags={tags}
          onTagClick={onTagClick}
        />
      </div>
      <div className={styles['card__save-button-container']}>
        <PropertyCardSaveButton
          isBookmarked={isBookmarked}
          onClick={handleBookmark}
          isLoading={isBookmarkPending}
        />
      </div>
    </div>
  )
});
