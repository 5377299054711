import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import marker from "data/img/marker.png";
import { getGoogleMapApiKey } from "app-config";

const Marker: FC<any> = memo(() => {
  return (
    <div style={{position: 'absolute', transform: 'translate(-50%, -50%)'}}>
      <img 
        src={marker}
        height={70}
        alt='map marker'
      />
    </div>
  )
});

interface MapProps {
  coords: Array<{
    longitude: number,
    latitude: number
  }>,
  containerStyle?: React.CSSProperties
}

export const Map: FC<MapProps> = memo(({
  coords,
  containerStyle
}) => {
  const [containerSize, setContainerSize] = useState<{ width: number; height: number; } | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const resizehandler = useCallback((entries: Array<ResizeObserverEntry>) => {
    const { height, width } = entries[0].contentRect;
    setContainerSize({ width, height });
  }, []);

  useEffect(() => {
    let unsubscribe;

    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(resizehandler);
      

      resizeObserver.observe(containerRef.current);

      unsubscribe = () => {
        if (containerRef.current){
          resizeObserver.unobserve(containerRef.current);
        }
        resizeObserver.disconnect();
      }
    }

    return unsubscribe;
  }, [resizehandler]);

  const { center, zoom } = useMemo(() => {
    if (coords.length === 1) {
      return {
        center: {
          lat: coords[0].latitude,
          lng: coords[0].longitude,
        },
        zoom: 15
      }
    }

    const lats =  coords.map(({ latitude }) => latitude);
    const lngs =  coords.map(({ longitude }) => longitude);

    const maxlat = Math.max(...lats);
    const maxlng = Math.max(...lngs);
    
    const minlat = Math.min(...lats);
    const minlng = Math.min(...lngs);
  
    const sw = { lat: minlat, lng: minlng };
    const ne = { lat: maxlat, lng: maxlng };
      
    const bounds = { sw, ne };

    return fitBounds(bounds, { width: containerSize?.width || 0, height: containerSize?.height || 0 });
  }, [containerSize?.height, containerSize?.width, coords]);

  return (
    <div
      className="w-100 h-100"
      style={containerStyle}
      ref={containerRef}
    >
      <GoogleMapReact
        bootstrapURLKeys={{key: getGoogleMapApiKey()}}
        defaultZoom={15}
        zoom={zoom - 1}
        defaultCenter={center}
        center={center}
      >
        {coords.map(({ latitude, longitude }, index) => (
          <Marker
            key={latitude + index}
            lat={latitude}
            lng={longitude}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
});
