import { UserAdditionalInformation } from "../screens/Account/CompleteYourProfile";
import { WhoAreYou } from "../types/user/who-are-you";

export enum HaveFinancialAdvisorEnum {
  YES = "Yes",
  NO = "No",
}

export enum HearAboutFromEnum {
  Investor = "Investor",
  FinancialAdvisor = "Financial Advisor",
  Advertisement = "Advertisement",
  Linkedin = "Linkedin",
  EstatingTeamMember = "Estating Team Member",
  PersonalOrProfessionalNetwork = "Personal or Professional Network",
}

type HearAboutFrom =
  | "Investor"
  | "Financial Advisor"
  | "Advertisement"
  | "Linkedin"
  | "Estating Team Member"
  | "Personal or Professional Network"
  | string;

export interface User {
  googleUserId?: string;
  facebookUserId?: string;
  _id?: string;
  appleId?: string;
  linkedinUserId?: string;
  financialAdvisor?: string;
  haveFinancialAdvisor?: HaveFinancialAdvisorEnum | string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  whoAreYou: WhoAreYou | string;
  country: string;
  hearAboutFrom: HearAboutFrom;
}

export interface ProfileCompletness {
  personal: {
    missedFields: number
    totalFields: number
  },
  additional: {
    missedFields: number
    totalFields: number
  }
}

export interface UserLocal {
  firstName: string;
  lastName: string;
  email: string;
  whoAreYou: WhoAreYou | string;
  country: string;
  phoneNumber: string;
  hearAboutFrom?: string;
  _id?: string;
  additionalInformation?: UserAdditionalInformation;
  financialAdvisor?: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
  profileCompletness?: ProfileCompletness;
  is_accepted?: boolean;
  lang: string;
}
