import { FC, memo, useMemo } from "react";

import style from './style.module.scss';

type PropertyRiskImagesProps = {
  images: Array<string>
}

export const  PropertyRiskImages:FC<PropertyRiskImagesProps> = memo(({ images }) => {
  const imageSrc = useMemo(() => images[0], [images]);

  return (
    <>
      {
        imageSrc && (<div className={style['risk-image__container']}>
        <div className={style['risk-image__box']}>
          <img src={imageSrc} alt="risk" />
        </div>
      </div>)
      }
    </>
  );
})