import { FormEvent, useEffect, useState, FC, memo, useCallback, useMemo } from "react";
import catalystLogo from "assets/catalyst-logo.png";
import Input from "components/Input";
import Button from "components/Button";
import { UserLocal } from "context/types";
import history from "history-entity";
import { UserAPI } from "api/user";
import { Link, Redirect } from "react-router-dom";
import SocialLogin from "components/SocialLogin";
import { useSession } from "context/session";
import { MobileLanguageSelector } from "new-architecture/components/mobile-language-selector";
import { useTolgee, useTranslate } from "@tolgee/react";

export const Login: FC<{}> = memo(() => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | {
    message: string;
    status?: string;
  }>(null);
  const { user, setUser } = useSession();
  const tolgee = useTolgee();

  const [, setReRenderTrigger] = useState(false);
  const onLanguageChange = useCallback(async (newLanguage: string) => {
    await tolgee.changeLanguage(newLanguage)
    // small trick to trigger re-render
    setReRenderTrigger((prev) => !prev);
  }, [tolgee]);

  useEffect(() => {
    const sessionLastPageSeen = localStorage.getItem("sessionLastPageSeen");
    if (!!user) {
      tolgee.changeLanguage(user.lang);
      if (!!sessionLastPageSeen) {
        const pageToRedirect = sessionLastPageSeen.replace(
          window.location.origin,
          ""
        );
        localStorage.removeItem("sessionLastPageSeen");
        history.push(pageToRedirect);
      } else {
        history.push("/opportunities");
      }
    }
  }, [user, tolgee]);

  const { t: tPublicArea } = useTranslate('public_area');

  const errorMessageText = useMemo(() => {
    switch (errorMessage?.message) {
      case 'Invalid username or password':
        return tPublicArea('public_area.wrong_email_password');
      case 'Something went wrong':
        return tPublicArea('public_area.something_went_wrong');
      default:
        return null;
    }
  }, [errorMessage?.message, tPublicArea]);

  const login = async (e: FormEvent) => {
    e.preventDefault();

    if (email && password) {
      setErrorMessage(null);
      setLoginLoading(true);
      try {
        const user: UserLocal = await UserAPI.logIn(email, password);
        if (user) {
          setUser(user);
        }
      } catch (e) {
        // @ts-ignore
        setErrorMessage(e);
        setLoginLoading(false);
      }
    }
  };

  return (
    <div className="d-flex flex-wrap vh-100 home">
      <div className="left-side mb-6">
        {!!user ? (
          <Redirect to="/" />
        ) : (
          <div className="left-side-content mx-auto col-xl-8 col-lg-8 col-10">
            <img
              src={catalystLogo}
              className="mx-auto d-block text-center w-100 h-auto"
              alt="Estating logo"
            />

            <div className="login-block pt-5">
              <h1 className="mb-6">
                {tPublicArea('public_area.login')}
              </h1>
              <form onSubmit={login}>
                <Input
                  label={tPublicArea('public_area.email')}
                  id="logInEmail"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  inputAttributes={{
                    placeholder: "ex. email@gmail.com",
                    type: "email",
                    value: email,
                    required: true,
                  }}
                  labelStyles={{
                    fontSize: "0.813rem",
                  }}
                />
                <Input
                  label={tPublicArea('public_area.password')}
                  id="logInPassword"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  containerClasses="mb-2"
                  inputAttributes={{
                    placeholder: "******",
                    type: "password",
                    value: password,
                    required: true,
                    autoComplete: "password-login",
                  }}
                  labelStyles={{
                    fontSize: "0.813rem",
                  }}
                />
                <Link to="/forgot-password" className="alert-link">
                  <p className="h4 font-weight-bold text-right mb-5">
                    {tPublicArea('public_area.forgot_password')}
                  </p>
                </Link>
                {errorMessage && errorMessageText && (
                  <div className={`alert mb-0 alert-danger`}>
                    <span>{errorMessageText}</span>
                  </div>
                )}
                <Button
                  className={`btn-primary btn-block  mt-3 ${
                    loginLoading ? "is-loading is-loading-primary" : ""
                  }`}
                  buttonAttributes={{
                    disabled: loginLoading,
                    type: "submit",
                  }}
                  label={tPublicArea('public_area.login')}
                />
              </form>
              <div className="horizontal-line-divider">
                {tPublicArea('public_area.or')}
              </div>

              <SocialLogin
                success={({ userOptions }) => {
                  setUser(userOptions);
                }}
                error={(e) => setErrorMessage(e)}
                showTerms={true}
              />

              <p className="font-weight-bold text-center mt-4">
                {tPublicArea('public_area.dont_have_account')}
                {" "}
                <Link
                  to={"/signup"}
                  className="alert-link ml-2 font-weight-bold"
                >
                  {tPublicArea('public_area.sign_up')}
                </Link>
              </p>

              <MobileLanguageSelector
                availableLanguages={[{ value: 'en', label: 'EN'}, { value: 'es', label: 'ES' }]}
                onLanguageSelect={onLanguageChange}
                selectedLanguage={tolgee.getPendingLanguage()}
              />
            </div>
          </div>
        )}
      </div>
      <div
        className="right-side"
        style={{
          backgroundImage: "url(img/home-bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-opacity" />
        <p>
          {tPublicArea('public_area.the_new_way_of_accessing')}
        </p>
      </div>
    </div>
  );
});
