import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { dealsReducer } from "./properties/reducers";
import { educationMaterialsReducer } from "./estating-academy/reducers";
import { combineReducers } from "redux";
import { propertyTeaserApi } from "api/property-teaser";
import { educationMaterialGroupsApi } from "api/estating-academy";
import { searchSlice } from "./search";
import { performanceReportReducer, performanceReportSlice, performanceReportApi } from 'new-architecture/modules/property-performance-report';
import { persistStore } from 'redux-persist';
import { propertyShareApi } from "new-architecture/modules/property-share-modal";
import { propertyInvestApi } from "new-architecture/modules/property-invest-modal";
import { contactEstatingApi } from "new-architecture/modules/contact-estating-modal";
import { analiticsApi } from "api/analitics";
import { userApi } from "api/user";

const rootReducer = combineReducers({
  properties: dealsReducer,
  estatingMaterials: educationMaterialsReducer,
  search: searchSlice.reducer,

  [performanceReportSlice.name]: performanceReportReducer,

  [performanceReportApi.reducerPath]: performanceReportApi.reducer,

  [propertyTeaserApi.reducerPath]: propertyTeaserApi.reducer,
  [educationMaterialGroupsApi.reducerPath]: educationMaterialGroupsApi.reducer,

  [propertyShareApi.reducerPath]: propertyShareApi.reducer,

  [propertyInvestApi.reducerPath]: propertyInvestApi.reducer,

  [contactEstatingApi.reducerPath]: contactEstatingApi.reducer,
  [analiticsApi.reducerPath]: analiticsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(
      propertyTeaserApi.middleware,
      educationMaterialGroupsApi.middleware,
      performanceReportApi.middleware,
      propertyShareApi.middleware,
      propertyInvestApi.middleware,
      contactEstatingApi.middleware,
      analiticsApi.middleware,
      userApi.middleware,
    );
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
