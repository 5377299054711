import { FC, memo, useCallback, useState } from "react";
import { ReactComponent as CameraSvg } from "assets/camera.svg";
import classNames from "classnames";
// import Gallery from '../../../../../modal/Gallery';

import styles from "./styles.module.scss";
import { PropertyGalleryModal } from "new-architecture/modules/property-gallery-modal";
import { useTranslate } from "@tolgee/react";

const propertyAllPhotosButtonClass = classNames(
  "d-flex align-items-center",
  styles["photos-button__container"]
);

interface PropertyCardAllPhotosButtonProps {
  assets: Array<string>;
  name: string;
}

export const PropertyCardAllPhotosButton: FC<PropertyCardAllPhotosButtonProps> =
  memo(({ assets, name }) => {
    const [isGalleryModalShowing, setGalleryModalShowing] = useState(false);

    const openGalleryModal = useCallback(() => {
      setGalleryModalShowing(true);
    }, []);

    const closeGalleryModal = useCallback(() => {
      setGalleryModalShowing(false);
    }, []);

    const { t: tOpportunityDetailPageCard } = useTranslate('opportunity_detail_page.card');

    return (
      <>
        <div
          className={styles["photos-button"]}
          role="button"
          data-toggle="modal"
          data-target="#galleryModal"
          onClick={openGalleryModal}
        >
          <div className={propertyAllPhotosButtonClass}>
            <div className="mr-1">
              <CameraSvg />
            </div>
            <span className={styles["photos-button__text"]}>
              {tOpportunityDetailPageCard('opportunity_detail_page.card.all_photos_button')}
            </span>
          </div>
        </div>
          <PropertyGalleryModal
            assets={assets}
            isOpen={isGalleryModalShowing}
            closeModal={closeGalleryModal}
          />
      </>
    );
  });

PropertyCardAllPhotosButton.displayName = "PropertyCardAllPhotosButton";
