import { FC, memo, useCallback, useMemo } from "react";
import { Popup } from "new-architecture/components/pop-up";
import { PropertyInvestModalHeader } from "./property-share-modal-header";
import { PropertyShareModalBody } from "./property-share-modal-body";
import { PropertyShareModalFooter } from "./property-share-modal-footer";
import { useFormik } from "formik";
import { ShareMessageOptions } from "../types/index";
import { FormikValues } from "../types/formik-values";
import { trackPropertyReaction } from "helpers/tracking";
import { useSharePropertyMutation } from "../store";
import CircleFilledIcon from "assets/circle-filled.svg";
import { QueryStatus } from "@reduxjs/toolkit/dist/query/react";

import styles from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

interface PropertyShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  options: ShareMessageOptions;
};

export const PropertyShareModal: FC<PropertyShareModalProps> = memo(({ 
  isOpen,
  onClose,
  options
}) => {
  const { propertyImages, teaserName, documents, fromEmail, propertyId } = options;

  const [shareProperty, { isLoading, isSuccess, error, status, reset }] = useSharePropertyMutation();
  
  const initialValues = useMemo<FormikValues>(() => {
    const { toEmail, subject, message, attachTeaser } = options;

    return ({
      toEmail: toEmail || '',
      otherEmails: [],
      images: [],
      subject,
      message,
      attachments: [],
      attachTeaser: Boolean(attachTeaser),
    });
  }, [options]);

  const isRequestSended = useMemo(() =>
    status === QueryStatus.fulfilled ||
    status === QueryStatus.rejected
  , [status]);

  const formik = useFormik({
    initialValues,
    onSubmit(values) {
      if (isLoading) {
        return;
      }

      if (isRequestSended) {
        reset();
        return;
      }

      trackPropertyReaction('share', "send");
      shareProperty({ 
        ...values,
        fromEmail,
        type: 'share',
        propertyId
      });
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const { values, setFieldValue, handleChange, submitForm, resetForm } = formik;

  const closeModal = useCallback(() => {
    onClose();
    reset();
    resetForm();
  }, [onClose, reset, resetForm]);

  const { t: tEmailTemplates } = useTranslate('email_templates');

  return (
    <Popup
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className={styles['modal-container']}>
        <div className={styles['modal-container__header']}>
          <PropertyInvestModalHeader onClose={closeModal} />
        </div>
        <div className={styles['modal-container__body']}>
          { isRequestSended ?
            <div
              className={`alert ${
                isSuccess ? "alert-info" : "alert-danger"
              } d-flex justify-content-center align-items-center py-4 m-4`}
            >
              {isSuccess ? (
                <img src={CircleFilledIcon} className="mr-3" alt="" />
              ) : null}
              <h4 className="mb-0">
                {isSuccess ? tEmailTemplates('email_templates.email_sent') : (error as any)?.data?.message}
              </h4>
            </div>
            :
            <PropertyShareModalBody
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              propertyImages={propertyImages}
              teaserName={teaserName}
              documents={documents}
            />
          }
        </div>
        <div className={styles['modal-container__footer']}>
          <PropertyShareModalFooter
            onClose={closeModal}
            onConfirm={submitForm}
            isLoading={isLoading}
            isSended={isRequestSended}
          />
        </div>
      </div>
    </Popup>
  );
});
