import { useSession } from "./context/session";
import { memo, useEffect } from "react";
import { setTrackUserId } from "./helpers/tracking";
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { AppRouter } from "new-architecture/app-router";

dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

export const App = memo(() => {
  const session = useSession();

  useEffect(() => {
    setTrackUserId(session.user?._id);
  }, [session.user]);

  useEffect(() => {
    (async () => {
      const pdfjs = await import('pdfjs-dist/build/pdf');
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    })();
  }, []);

  if (session.loading) {
    return (
      <div className="d-flex justify-content-center mt-8 align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only ">Verifying...</span>
        </div>
      </div>
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <AppRouter />
    </StyleSheetManager>
  );
})
