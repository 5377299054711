import { FC, memo, useMemo } from "react";
import { PropertySecutityInfoTitle } from "./property-secutity-info-title";
import { Container } from "react-bootstrap";
import { PropertyTeaserType } from "store/properties/types";
import { PropertySecurityInfoItems } from "./property-security-info-items";
import { useSession } from 'context/session';
import { WhoAreYou } from "types/user/who-are-you";

import style from "./style.module.scss";
import { useTranslate } from "@tolgee/react";

type PropertySecurityInfoProps = {
  property: PropertyTeaserType;
}

const emptyValue = 'N/A';

export const PropertySecurityInfo: FC<PropertySecurityInfoProps> = memo(
  ({ property }) => {
    const { securityAndRoles: { security = [] }, options } = property;
    const { user } = useSession();
    const { t: tOpportunityCard } = useTranslate('opportunity_card');

    const values = useMemo(() => {
      const out = [...security];
      
      const isVerifiedFinancialAdvisor = user?.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR;

      out.push(
        {
          name: 'ISIN',
          value: isVerifiedFinancialAdvisor && options?.isin ? options.isin : emptyValue
        }
      );

      out.push(
        {
          name: tOpportunityCard('opportunity_card.relinc_price'),
          // value: isVerifiedFinancialAdvisor && options?.relincPrice ? options.relincPrice : emptyValue
          value: user ? options.relincPrice || emptyValue : emptyValue
        }
      );

      return out;
    }, [options.isin, options.relincPrice, security, tOpportunityCard, user]);

    return (
      <div className="bg-bgLight">
        <div>
          <Container>
            <div className={style["security-info__container"]}>
              <PropertySecutityInfoTitle />
              <PropertySecurityInfoItems info={values} />
            </div>
          </Container>
        </div>
      </div>
    );
  }
);
