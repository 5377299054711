import { useCallback, useMemo, useState, memo, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPropertyTeasersCountQuery, useGetPropertyTeasersQuery } from "api/property-teaser";
import { PropertyOpportunityTypeEnum } from "store/properties/types";
import { ReactComponent as AllDealsSvg } from "assets/all-deals.svg";
import { ReactComponent as PropertyInvesmentsSvg } from "assets/property-investments.svg";
import { ReactComponent as LPInvestmentsSvg } from "assets/lp-investments.svg";
import { ReactComponent as MyListSvg } from "assets/my-list.svg";
import PropertyListEmpty from "components/Properties/ListEmpty";
import useDebounce from "components/Hooks/useDebounce";
import { addSearchTag, removeSearchTag, TagDescription, removeAllSearchTags } from "store/search";
import { selectSearchTags } from "store/search/selectors";
import { CriteriaIconProps } from "new-architecture/ui/criteria-icon";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { PropertiesCardsList } from "new-architecture/modules/properties-cards-list";
import { PropertiesSubHeader } from "new-architecture/modules/properties-sub-header";
import { WhoAreYou } from "types/user/who-are-you";
import FinancialAdvisorListEmpty from "components/Properties/FinancialAdvisorListEmpty";
import { useSession } from "context/session";
import { useTolgee, useTranslate } from "@tolgee/react";

import styles from './style.module.scss';

const criteriasLineClass = classNames('d-xs-none', styles.criterias_line);

export const Properties: React.FC<{}> = memo(() => {
  const dispatch = useDispatch();
  const { user } = useSession();
  const tolgee = useTolgee();

  const tagsDescription = useSelector(selectSearchTags);
  const tagsValues = useMemo(() =>  tagsDescription.map(({ value }) => value), [tagsDescription]);
  const addTag = useCallback((tag: TagDescription) => {
    dispatch(addSearchTag(tag));
    setSearchValue('')
  }, [dispatch]);
  const removeTag = useCallback((tag: TagDescription) => {
    dispatch(removeSearchTag(tag));
  }, [dispatch]);
  const removeAllTags = useCallback(() => {
    dispatch(removeAllSearchTags());
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState<string>('');
  const onChangeSearchValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [propertyStatus, setPropertyStatus] = useState<string>();
  const [propertyType, setPropertyType] = useState<PropertyOpportunityTypeEnum>();
  const [bookmarkedFilter, setBookmarkedFilter] = useState<boolean>(false);

  const { data: matchedProperties, /*isError: isErrorMatchedProperties, error: errorMatchedProperties,*/ isLoading: isLoadingMatchedProperties } = useGetPropertyTeasersQuery({
    opportunityType: propertyType,
    bookmarked: bookmarkedFilter,
    status: propertyStatus,
    search: debouncedSearchValue ? debouncedSearchValue : undefined,
    tags: tagsValues,
    lang: tolgee.getPendingLanguage()
  });

  const { 
    data: properyTeaserCountResult,
    isError: isErrorTeaserCountResult,
    /*error: errorTeaserCountResult,*/
    isLoading: isLoadingTeaserCountResult
  } = useGetPropertyTeasersCountQuery({});

  const { t: tHomapageSearch } = useTranslate('homepage.search');

  const propertyFilters = useMemo(() => {
    return isErrorTeaserCountResult || isLoadingTeaserCountResult ? [] : 
    [
      {
        name: tHomapageSearch('homepage.search.all_deals'),
        key: "all-deals",
        counter: properyTeaserCountResult.all,
        icon: <AllDealsSvg />,
      },
      {
        name: tHomapageSearch('homepage.search.property_investments'),
        key: "property-investments",
        counter: properyTeaserCountResult.propInvestments,
        icon: <PropertyInvesmentsSvg />,
      },
      {
        name: tHomapageSearch('homepage.search.lp_investments'),
        key: "lp-investments",
        counter: properyTeaserCountResult.lpInvestments,
        icon: <LPInvestmentsSvg />,
      },
      /*{
        name: "Private Equity Investments",
        key: "private-equity-investments",
        counter: properyTeaserCountResult.privateEquityInvestment,
        icon: <PrivatePropertyInvestmentsSvg />,
      },*/
      {
        name: tHomapageSearch('homepage.search.saved_items'),
        key: "my-list",
        counter: properyTeaserCountResult.bookmarked,
        icon: <MyListSvg />,
      },
    ]
  }, [isErrorTeaserCountResult, isLoadingTeaserCountResult, tHomapageSearch, properyTeaserCountResult]);

  const mappedStats = useMemo<Array<CriteriaIconProps & { criteriaKey: string }>>(() => propertyFilters.map(({ counter, name, key, icon }) => ({
    count: counter,
    name,
    criteriaKey: key,
    icon
  })), [propertyFilters]);

  const [topFilterKey, setTopFilterKey] = useState('all-deals');

  const onChangeFilter = (key: string) => {
    let type: PropertyOpportunityTypeEnum | undefined = undefined;
    let bookmark = false;
    let status: string | undefined = undefined;

    setTopFilterKey(key);

    switch (key) {
      case 'lp-investments':
        type = PropertyOpportunityTypeEnum.LP_INVESTMENTS;
      break;

      case 'property-investments':
        type = PropertyOpportunityTypeEnum.PROPERTY_INVESTMENTS;
      break;

      case 'private-equity-investments':
        type = PropertyOpportunityTypeEnum.PRIVATE_EQUITY_INVESTMENT
      break;

      case 'my-list':
        bookmark = true;
      break;
      
      case 'search-mandates':
        status = 'Search Mandate';
      break;
    }

    setBookmarkedFilter(bookmark);
    setPropertyType(type);
    setPropertyStatus(status);
  };

  const { t: tSearchNotFound } = useTranslate('homepage.search');

  const NoResults = () => {
    let text = tSearchNotFound("homepage.search.investment_opportunities");
    if (topFilterKey === "property-investments") {
      text = tSearchNotFound('homepage.search.property_investments');
    } else if (topFilterKey === "lp-investments") {
      text = tSearchNotFound('homepage.search.lp_investments');
    } else if (topFilterKey === "my-list") {
      text = tSearchNotFound('homapage.search.investment_opportunities_in_your_list');
    } else if (topFilterKey === "search-mandates") {
      text = tSearchNotFound('homepage.search.search_mandates');
    }

    return (
      <div className="col-8 col-md-6 col-xl-4 mx-auto mt-7 d-flex align-items-center flex-column">
        <p className="text-center">
          {tSearchNotFound('homapage.search.not_match.your_search')}
          {' '}
          <b className="text-lowercase">{text}</b>
          {' '}
          {tSearchNotFound('homepage.search.we_have')}
        </p>
        <p className="text-center">
          {tSearchNotFound('homepage.search.suggestions')}
          <ul className="text-left">
            <li>{tSearchNotFound('homepage.search.make_sure')}</li>
            <li>{tSearchNotFound('homepage.search.try_different')}</li>
            <li>{tSearchNotFound('homepage.search.try_fewer')}</li>
          </ul>
        </p>
      </div>
    );
  };

  return (
    <Container>
      <div className={styles.subheader_wrapper}>
        <div className={criteriasLineClass} />
          <PropertiesSubHeader
            selectedCriteriaKey={topFilterKey}
            criterias={mappedStats}
            onSelectCritery={onChangeFilter}
            onChangeSearchValue={onChangeSearchValue}
            searchValue={searchValue}
            tags={tagsDescription}
            onRemoveTag={removeTag}
            onRemoveAllTags={removeAllTags}
            onAddTag={addTag}
          />
        </div>
      {
        (isLoadingMatchedProperties || isLoadingTeaserCountResult ? 
          (
            <div className="d-flex justify-content-center mt-8 align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only ">Loading...</span>
              </div>
            </div>
          )
          :
          (
            (properyTeaserCountResult?.all ? 
              (
                (matchedProperties?.length ?
                  (
                    <div className="mt-3">
                      <PropertiesCardsList
                        properties={matchedProperties}
                        onTagClick={addTag}
                      />
                    </div>
                  )
                  :
                  (
                    <NoResults />
                  )
                )
              )
              :
              (
                user!.whoAreYou === WhoAreYou.FINANCIAL_ADVISOR ? <FinancialAdvisorListEmpty/> : <PropertyListEmpty/>
              )
            )
          )  
        )
      }
    </Container>
  );
});
