import { memo, FC, ReactElement } from 'react';
import classNames from "classnames";

import style from './style.module.scss';

export interface CriteriaIconProps {
  count: number;
  name: string;
  icon: ReactElement;
  selected?: boolean;
  onClick?: () => void;
}

export const CriteriaIcon: FC<CriteriaIconProps> = memo(({
  count,
  name,
  icon,
  selected,
  onClick
}) => {
  return (
    <div 
      className={`d-flex flex-column pointer align-items-center cursor-pointer ${classNames({[style.active]: selected})}`}
      onClick={onClick}
    >
      <div className={`${style.svg_container}`}>
        {icon}
      </div> 
      <div className={`mt-2 text-uppercase  ${style.name}`}>
        {name} ({count})
      </div>
    </div>
  )
});
