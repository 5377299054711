import { memo, useMemo } from 'react';
import { ReactComponent as InfoSVG } from 'assets/icon-info.svg';
import { OverlayTrigger } from 'react-bootstrap';
import { descriptionPopover } from 'new-architecture/components/description-popover';
import { investmentTypeDescription } from '../../constants/investment-type-secription';
import { useTranslate } from '@tolgee/react';

import style from './style.module.scss';

export const CriteriaTip = memo(() => {
  const { t: tInvestmentType } = useTranslate('homepage.search.investment_types')
  const descriptions = useMemo(() => Object.keys(investmentTypeDescription).map((key) => ({
    title: tInvestmentType(key),
    description: tInvestmentType(investmentTypeDescription[key]),
  })), [tInvestmentType]);

  const { t: tFaq } = useTranslate('faq');

  return (
    <div 
      className={`d-flex align-items-center text-uppercase ${style.container}`}
      role="button"
    >
      {tFaq('faq.investment_types')}
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="auto"
        overlay={
          descriptionPopover({
            descriptions
          })
        }
      >
        <InfoSVG />
      </OverlayTrigger>
    </div>
  );
});
