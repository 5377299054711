import classNames from 'classnames';
import { DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react';

import styles from './style.module.scss';

interface InputProps {
  label?: string;
  inputAttributes?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'className' | 'style'>;
  error?: string;
  touched?: boolean;
}

export const Input: FC<InputProps> = memo(({
  label,
  inputAttributes,
  error,
  touched
}) => {
  return (
    <label className={styles['input-container']}>
      { label && 
        <div className={styles['input-container__label']} role="button">
          {label}
        </div>
      }
      <input
        className={
          classNames(
            styles.input,
            'form-control',
            {
              [styles['input_error']]: error && touched,
              [styles['input_valid']]: !error && touched
            }
            
          )
        }
        {...inputAttributes}
      />
    </label>
  );
});
