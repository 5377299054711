import React from 'react';

type TermsOfUseProps = {}

const TermsOfUse: React.FC<TermsOfUseProps> = () => {

    return (
        <div className='container py-6'>
            <h1 className='font-weight-bold mb-5 text-primary text-center'>GENERAL TERMS OF USE </h1>
            <div className="scope-of-app">
                <span className="latest_update_txt">Latest update: 2<span>nd</span> September 2021</span>
                <h2 className='my-4 text-primary'>1. SCOPE OF APPLICATION</h2>
                <p>These terms of use (the <span className="font-weight-bolder">"Terms of Use"</span>) govern the use of
                    the Website and the Application (as
                    defined <br/>below) and are entered into by and between:
                </p>
                <ul className="ml-2 scope-of-app-ul">
                    <li className="mt-3">
                        Estating Property Vault S.A. a public limited company (société anonyme) incorporated under
                        the laws of
                        Luxembourg and registered with the Trade and Companies Register of Luxembourg under number
                        B247799,
                        with registered office at 4, rue du Fort Wallis L-2714 Luxembourg, Grand Duchy of Luxembourg
                    </li>
                    <li className="mt-3">
                        (referred to as “EPV”, “we” or “us”);
                    </li>
                    <li className="mt-3">
                        and
                    </li>
                    <li className="mt-3">
                        the User (as defined below, hereafter the “User” or “you”). If you are representing and/or
                        working for a company on behalf of which you enter into the Terms of Use, the terms “User” and
                        “you” in these Terms of Use refer to you and the company you represent and/or work for jointly.
                    </li>
                </ul>
                <p>The User and EPV are hereinafter together referred to as the “Parties” and individually as a
                    “Party”.</p>
                <p>These Terms of Use complement the Privacy Policy. </p>
                <p>The User may contact EPV during business hours to the following e-mail address [info@estating.com] or
                    by <br/> mail to EPV’s registered office. </p>
                <p>
                    Some of those clauses included in the Terms of Use only apply to the User when he/she is a
                    Consumer.These clauses are specifically highlighted.
                </p>
            </div>

            <div className="definition">
                <h2 className='my-4 text-primary'>2. DEFINITIONS</h2>
                <p>
                    <span className="font-weight-bolder">Account{" "}</span>
                    means user account created by the User via registration on the Application and enabling
                    him/her to access and use the Services on the Application.
                </p>
                <p>
                    <span className="font-weight-bolder">Affiliate{" "}</span>
                    means user account created by the User via registration on the Application and enabling
                    him/her to access and use the Services on the Application.
                </p>
                <p>
                    <span className="font-weight-bolder">Anti-Corruption Laws{" "}</span>
                    means all (national and international) applicable anti-corruption, anti-bribery andanti-money
                    laundering laws and regulations.
                </p>
                <p>
                    <span className="font-weight-bolder">Application{" "}</span>
                    means the application provided by EPV either via mobile application downloaded with AppStore or
                    Google Play, or via the web browser application
                    <a href="https://app.estating.com/" target="_blank" rel="noreferrer"
                       className="text-primary">{" "}www.app.estating.com</a>.
                </p>
                <p>
                    <span className="font-weight-bolder">Confidential Information{" "}</span>
                    elating to a Party means any information (verbal, visual or written) relating to the disclosing
                    Party
                    (Disclosing Party) that is not generally known outside of this Party and acquired directly or
                    indirectly by another Party (the Receiving Party) during the conclusion or in the course of the
                    performance of these Terms of Use, including, where appropriate and without limitation, information
                    relating to theDisclosing Party’s intellectual property, research, technology and know-how, business
                    plans, licenses, data, patent disclosures, patent applications, structures, models, techniques,
                    processes, compositions, compounds,third party confidential information, software, algorithms,
                    current and future products and services, business and financial information, client or shareholder
                    information, procurement requirements, manufacturing, business forecasts, marketing information, the
                    list of distributors, information concerning accounts or funds managed, as well as any information
                    that has been labelled or identified as “Confidential” or that theReceiving Party ought reasonably
                    to have known was confidential at the time of disclosure. User Content is not deemed to be
                    Confidential Information to the extent that it does not allow the identification of the User or any
                    of the User’s clients.
                </p>
                <p>
                    <span className="font-weight-bolder">Consumer{" "}</span>
                    means any natural person who acts for purposes that do not fall within the scope of his commercial,
                    industrial, artisanal or liberal activity according to the Luxembourg Consumer Code (Code de
                    la Consommation).
                </p>
                <p>
                    <span className="font-weight-bolder">Date of Acceptance{" "}</span>
                    means the date on which the User has accepted the Terms of Use pursuant to article 3, in Luxembourg
                    local time.
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">Intellectual Property Rights{" "}</span>
                    mean all intellectual property rights in, to and in relation to (i) the Website and the Application
                    )
                    and (ii) data made available through the Website and/or the Application, in particular but without
                    limitation any present or future ideas and rights to inventions (whether or not patentable or
                    reduced to practice), patents, all copyright and author’s rights and rights in software (including
                    without limitation moral rights, as well as the rights of reproduction, distribution, communication
                    to the public, renting and lending), trademarks (whether registered and unregistered), service
                    marks,
                    brand names, product names,logos, slogans, trade names, company names and reputation, domain names,
                    database right, rights in confidential and/or proprietary information (including without limitation
                    know-how and trade secrets), rights in software (including without limitation algorithms, APIs,
                    source, executable and object code, apparatus,circuit designs and assemblies), network
                    configurations and architectures, concepts, marketing and development plans, methods and any other
                    intellectual property rights, in each case whether registered or unregistered and including all
                    applications for and renewals or extensions of such rights, and all similar or equivalent rights or
                    forms of protection in any part of the world. For the avoidance of doubt, IntellectualProperty
                    Rights also includes any programming fixes, updates, upgrades, modifications,
                    enhancements, improvements, configurations, extensions, additions and derivative works of the
                    foregoing and related documentation.
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">Personal Data{" "}</span>
                    means information relating to an identified or identifiable natural person, pursuant to
                    theRegulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the
                    {" "}<span className="font-weight-bolder text-dark">GDPR{" "}</span>).
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">Privacy Policy{" "}</span>
                    means EPV’s privacy policy available at [ <span className="dot"> </span> ].
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">Professional{" "}</span>
                    means any natural or legal person, whether public or private, who acts, including through another
                    person acting in his/her name or on his/her behalf, for the purposes falling within the scope of
                    his/her commercial, industrial activity, artisanal or liberal, according to the Luxembourg Consumer
                    Code (Code dela Consommation).
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">Services{" "}</span>
                    means the provision of the Application enabling the offering of a global digital
                    marketplace for real estate properties, connecting investors and sellers via fractional ownership
                    protected inside the regulated financial system.
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">User{" "}</span>
                    means a Consumer or a Professional, independently of his/her quality of investor, property
                    seller, financial assistant, and other function, who uses the Website and/or the Application.
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">User Content{" "}</span>
                    means any data and/or information in the form of text, image, video, audio, 3D-files or
                    any another format provided by the User to be shown or published on his/her Account and/or on the
                    Platform.
                </p>
                <p>
                    <span className="font-weight-bolder text-dark">User ID{" "}</span>
                    means the personal username used for identification by the User and his/her Account on
                    theApplication and the password protecting the access to the Account.
                </p>
                <p>
                    <span className="font-weight-bolder">Website{" "}</span>
                    means the website of EPV accessible via the link
                    <a href="https://www.estating.com/" target="_blank" rel="noreferrer"
                       className="text-primary">{" "}www.estating.com</a>.
                </p>
            </div>

            <div className="acceptance">
                <h2 className='my-4 text-primary'>3. ACCEPTANCE</h2>
                <p>
                    The User should read these Terms of Use carefully before using the Website and/or the Application.
                </p>
                <p>
                    As regards the Website, by the sole use of the Website, you acknowledge and agree to have read,
                    understood and agreed to be bound by the Terms of Use without limitation or reservation.
                </p>
                <p>
                    In view of the use of the Application, by clicking on the “I have read, understood and accepted the
                    Terms ofUse” button, the User acknowledges and agrees to have read, understood and agreed to be
                    bound by theTerms of Use without any limitation or reservation.
                </p>
                <p>
                    In case the User disagrees with the Terms of Use, he/she shall not consult and/or use the
                    Application, Website or any of the Services provided.
                </p>
            </div>

            <div className="account-creation">
                <h2 className='my-4 text-primary'>4. ACCOUNT CREATION</h2>
                <p>
                    In order to access the Application and benefit from the Services, the User must register on the
                    Website by creating an Account that will be protected by a personal username and password (i.e. the
                    User ID).
                </p>
                <p>
                    To create an Account and use the Application, the User agrees to provide true, accurate, up-to-date
                    and complete information during the registration process, and to notify EPV immediately of any
                    changes thereof, in order for the registration data to be truthful, accurate, complete and up-to-date
                    at all times
                </p>
                <p>
                    The User acknowledges that his/her Account is strictly personal and that he/she may not create more
                    than one Account to access and/or use the Website and/or the Application, either with the same User
                    ID or with a different User ID.
                </p>
                <p>
                    The User is liable for all access and use of the Application made with his/her User ID, and is
                    responsible for taking all appropriate measures to keep his/her User ID confidential.
                </p>
                <p>
                    Furthermore, the User undertakes to take all appropriate measures to prevent harmful agents or
                    components from entering his/her or our systems and to prevent any unauthorized or unlawful or
                    infringing access or use of the Website and/or Application through his/her User ID.
                </p>
                <p>
                    The User agrees not to give access to his/her Account to any third party.
                </p>
                <p>
                    EPV may not be held liable for any unauthorized or unlawful or infringing access or use of the
                    Website and/or Application through a User ID.
                </p>
            </div>

            <div className="conditions-of-use-warranties">
                <h2 className='my-4 text-primary'>5. CONDITIONS OF USE AND WARRANTIES</h2>
                <p>
                    The access to the Website and/or Application is free of charge. All costs incurred by the User to
                    access theWebsite and/or Application (device, software, Internet connection, etc.) shall be borne by
                    the User.
                </p>
                <p>
                    The Website and/or Application is directed to adults only and is not directed to children under the
                    age of 18.By accessing or using the Website and/or Application and thereby agreeing to the Terms of
                    Use, the User represents and warrants to EPV that he/she is at least 18 years of age. EPV reserves
                    the right to ask for any justification of age.

                </p>
                <p>
                    The User shall use the Website and/or Application in accordance with the following conditions:
                </p>
                <ul className="conditions-of-use-warranties-ul ml-2">
                    <li className="mt-3">
                        The access to and use of the Website and/or Application is for strictly personal use only. The
                        User agrees not to use this Website and/or Application and the information or data contained
                        therein for
                    </li>
                    <li className="mt-3">
                        The access to and use of the Website and/or Application is for strictly personal use only. The
                        User agrees not to use this Website and/or Application and the information or data contained
                        therein for his/her own commercial, political or advertising purposes and for any form of
                        commercial solicitation, including the sending of unsolicited e-mail.
                    </li>
                </ul>
                <p>
                    Additionally, the User shall not:
                </p>
                <ul className="conditions-of-use-warranties-ul ml-2">
                    <li className="mt-3">
                        attempt to circumvent any security measures or technical limitations of the Website and/or
                        theApplication;
                    </li>
                    <li className="mt-3">
                        use the Website and/or the Application in a way which harms EPV’s interests, any of our
                        affiliates or other users of EPV, the Website and/or the Application (including, without
                        limitation, by introducing viruses, trojans, worms, logic bombs or other material which is
                        malicious or technologically harmful or by attacking the Website or the Application via a
                        denial-of-service attack or a distributed denial-of-serve attack);
                    </li>
                    <li className="mt-3">
                        use the Website and/or Application and the information or data contained therein to cause
                        nuisance, annoyance or inconvenience, and shall not harm the Website and/or Application in any
                        way whatsoever;
                    </li>
                    <li className="mt-3">
                        use the Website and/or the Application in a manner which would violate any applicable laws,
                        rules or regulations of governments having jurisdiction or cause a breach of any agreements the
                        User has with any third parties or unreasonably interfere with other of our customers' use of
                        our
                        Website and/or the Application.
                    </li>
                    <li className="mt-3">
                        forge headers or otherwise manipulate identifiers (including without limitation URLs) in order
                        to disguise the origin of any User Content or Intellectual Property Rights transmitted through
                        theWebsite and/or the Application;
                    </li>
                    <li className="mt-3">
                        create an application that may be used to violate the Terms of Use;
                    </li>
                    <li className="mt-3">
                        adversely affect us or our name, reputation, image or goodwill in connection with your use of
                        theWebsite and/or the Application; and/or
                    </li>
                    <li className="mt-3">
                        without our express permission, use manual or automated software, devices, scripts, robots or
                        other means or processes to access, crawl, scrape, copy or duplicate any content on the Website
                        and/or theApplication.
                    </li>
                </ul>
                <p>
                    In the event of any breach of any of the foregoing warranties, in addition to any other remedies
                    available at law or in equity, we reserve the right to suspend or terminate the Terms of Use with
                    immediate effect and/or your use of the Website and/or the Application in accordance with Clause 15.
                </p>
            </div>


            <div className="epv-warranties-liability">
                <h2 className='my-4 text-primary'>6. EPV WARRANTIES AND LIABILITY </h2>
                <p>
                    The Website and the Application are made available on an “as is” basis.
                </p>
                <p>
                    EPV does not warrant that the Website and the Application will operate error-free or in an
                    uninterrupted manner or that the Website and the Application content shall meet the User’s
                    requirements or expectations or that it will fit any particular purpose.
                </p>
                <p>
                    The content accessed through use of the Website and the Application is provided for general
                    information only and is not intended to be used as the sole basis for any decision. It is not
                    intended to amount to advice(of any nature) on which you should rely. As a Professional, you are
                    liable for any action on the basis of content accessed through use of the Website and/or
                    Application.
                </p>
                <p>
                    EPV make reasonable commercial efforts to ensure content accessible through the Website, the
                    Application or the Services is up to date and accurate. However, because EPV obtains the content
                    from
                    a number of different sources (including information provided by you) we do not endorse, support,
                    represent, warrant or guarantee the completeness, truthfulness, accuracy, or reliability of any
                    content accessed or accessible using the Services. You understand that by using the Services, you
                    may
                    be exposed to content that might be inaccurate or deceptive.
                </p>
                <p>
                    If you do become aware of any inaccurate or incorrect content accessed or accessible using the
                    Services (in particular pertaining to yourself or other individuals) please let us know at
                    [data.protection@estating.com]and we will use our reasonable endeavours to investigate your concern
                    and, where appropriate and possible, correct inaccurate data.
                </p>
                <p>
                    We assume no responsibility for the content of websites linked on the Website and/or Application.
                    Such links should not be interpreted as endorsement by us of those linked websites and we will not
                    be
                    liable for any loss or damage that may arise from your use of them.
                </p>
                <p>
                    In no event shall we be liable to you for any loss or damage (whether direct or indirect), including
                    but not limited to loss of profits or revenue or data, resulting from any business interruption, any
                    loss of anticipated savings, any loss of goodwill, opportunity or reputation, arising under or in
                    connection with: (i) use of, or inability to use the Website or the Application; or (ii) use of or
                    reliance on any Data accessed via the Website or the Application.
                </p>
                <p>
                    We will not be liable for any loss or damage caused by a virus, distributed denial-of-service
                    attack, or other technologically harmful material that may infect your computer equipment, computer
                    programs, data or other proprietary material due to your use of the Website and/or Application, or
                    on
                    any website linked to theWebsite and/or Application.
                </p>
                <p>
                    Nothing in the Terms of Use shall be construed as excluding or limiting our liability in case of
                    gross negligence or wilful misconduct, or any liability which cannot be excluded or limited under
                    applicable law.
                </p>
            </div>

            <div className="security-measures">
                <h2 className='my-4 text-primary'>7. SECURITY MEASURES</h2>
                <p>
                    Security and confidentiality issues have been taken into account by EPV when developing the Website
                    and the Application. In case the information available on the Website and/or the Application and/or
                    the data transmitted by or to the User would, despite the technical protection measures implemented
                    by EPV, be intercepted, decrypted, transmitted to or used by any third party, altered, modified or
                    deleted, EPV declines any liability in relation to direct or indirect consequences which would
                    result
                    therefrom for the User.
                </p>
                <p>
                    EPV declines any liability in relation to the direct or indirect consequences:
                </p>
                <p>
                    <span className="mr-4">(a)</span>
                    of problems relating to the accessibility or availability of the
                    Website and/or the Application for reasons beyond the control of EPV; and
                </p>
                <p>
                    <span className="mr-4">(b)</span>
                    of problems relating to the incompatibility of the Website and/or
                    the Application with the User's IT equipment, its configuration, its Internet connection or in case
                    of incapacity of the User's equipment to retrieve the information available on the Website and/or
                    the
                    Application in an appropriate manner and/or to transmit the information relating to the User; and
                </p>
                <p>
                    <span className="mr-4">(c)</span>
                    of any damages caused to the User's hardware or software configuration, as well as of any loss
                    of data resulting from the use or the inability to use the Website and/or the Application; and
                </p>
                <p>
                    <span className="mr-4">(d)</span>
                    of the use of electronic communication means (including but not limited to e-mail) for purposes
                    of communication between EPV and the User which would contain computer viruses or be intercepted
                    or altered by third parties.

                </p>
                <p>
                    The User accepts that any use of the Website and the Application will be under his/her own
                    responsibility.
                </p>
                <p>
                    EPV reserves the right to modify the Website and/or the Application, to modify or delete any
                    information available on the Website and/or Application or to cease its publication at any time.
                </p>
                <p>
                    EPV will in no event be held liable for any direct or indirect damages resulting from the use of the
                    Website and/or the Application or of information available on the Website and/or the Application, in
                    particular any operating, financial or commercial loss or loss of programmes and/or of data, in
                    particular in the User's information system.
                </p>
            </div>

            <div className="maintenance">
                <h2 className='my-4 text-primary'>8. MAINTENANCE</h2>
                <p>
                    EPV shall use reasonable efforts to ensure that maintenance of the Website and the Application,
                    which may require interruption of your access to the Website and/or the Application or any material
                    part of it, shall not be performed during business hours provided that we may interrupt access to
                    the
                    Services at any time to perform essential emergency maintenance.
                </p>
                <p>
                    During maintenance periods, we may, at our discretion, upgrade versions, install error corrections
                    and apply patches to the Website or the Application or any part of it.
                </p>
                <p>
                    Should the Website or the Application include a defect, you may at any time file error reports by
                    contacting our helpdesk at [info@estating.com].

                </p>
                <p>
                    The Website and the Application may include software, data and information provided to us by third
                    parties, and therefore the correction of errors and resolution of defects and other problems may
                    require third party action and not be entirely within our control. We may not be liable for any
                    negative consequences that may arise from third party software.
                </p>
            </div>

            <div className="intellectual-property">
                <h2 className='my-4 text-primary'>9. INTELLECTUAL PROPERTY</h2>
            </div>

            <div className="epv-intellectual-property-rights">
                <h2 className='my-4 text-primary'>9.1 EPV Intellectual Property Rights</h2>
                <p>
                    Subject to the limited rights expressly granted to the User in clause 9.2 below, the User
                    expressly acknowledges and agrees that we hold and reserve all of our Intellectual Property Rights
                    as
                    defined.
                </p>
                <p>
                    EPV hereby grants the User a limited, non-exclusive, revocable, non-sublicensable and
                    non-transferable right to access and use the Intellectual Property Rights for the sole and exclusive
                    purpose of accessing and using the Website or the Application under the conditions set forth in the
                    Terms of Use. All rights not expressly granted to you under the Terms of Use are reserved by us
                    (and/or our licensors).
                </p>
                <p>
                    You agree that the Website or the Application and the Intellectual Property Rights are EPV’s sole
                    property or, where appropriate, the property of EPV’s licensor and that you will not (without
                    express
                    written consent from EPV):
                </p>
                <p>
                    (i) create derivative works incorporating any of the elements of, or based on, the Intellectual
                    Property Rights except to the extent such derivative works are an essential technical or management
                    function of your use of the Platform and Services;
                </p>
                <p>
                    (ii) reproduce the Intellectual Property Rights, sell or assign, license or disclose or otherwise
                    transfer or make available the Intellectual Property Rights in any form to any third party;
                </p>
                <p>
                    (iii) remove or alter any proprietary notices or marks on the Website or the Application; or
                </p>
                <p>
                    (iv) copy, distribute, communicate, modify, translate, reuse, disassemble, decompile, reverse
                    compile, reverse engineer, frame, mirror or otherwise access in any way the source codes of any of
                    the IntellectualProperty Rights or any portion thereof except and only to the extent that such
                    activity is expressly permitted by applicable law notwithstanding this limitation or the Terms of
                    Use.
                </p>
                <p>
                    You must not under any circumstances (i) use information or content from the Website or the
                    Application to build a database for resale, or for access by, you or any third party in competition
                    with us; (ii) authorise or, by failure to exercise commercially reasonable efforts to protect
                    information from the Platform, permit such information to be made available in any way to a third
                    party, including any third party that resells data in competition with us.
                </p>
            </div>

            <div className="right-of-reference-use-of-logo">
                <h2 className='my-4 text-primary'>9.2 Right of reference and use of logo</h2>
                <p>
                    logoYou hereby expressly grant us with the right to reference your trade name or the name of your
                    company in marketing, sales, financial, and public relations materials and other communications,
                    including for business development purposes, solely to identify you as an EPV client.
                </p>
                <p>
                    You hereby expressly grant us with a non-exclusive, royalty free, license to use your companies’
                    logo(s) in our company’s advertising, literature and websites solely in connection with the
                    marketing
                    and promotion of the Website and/or the Application, worldwide and for the duration of the Terms of
                    Use.
                </p>
                <p>
                    We shall not be entitled to use your trade name or logo or your company’s name or logo otherwise
                    than as expressly stated herein, without your prior written permission.
                </p>
            </div>

            <div className="third-party-services">
                <h2 className='my-4 text-primary'>10.THIRD PARTY SERVICES</h2>
                <p>
                    During and in the context of the use of the Website or the Application, the Parties may access, use
                    or interact with third party services.
                </p>
                <p>
                    These Terms of Use do not apply on third party services, of which the uses are governed by their
                    respective terms and policies.
                </p>
            </div>

            <div className="third-party-claims">
                <h2 className='my-4 text-primary'>11. THIRD PARTY CLAIMS</h2>
                <p>
                    In the event that you are notified by a third party that such party claims rights in the Website or
                    theApplication or that the use of the Website or the Application infringes the rights of such third
                    party, you will immediately notify us and at our request immediately cease to use the Website or the
                    Application.
                </p>
                <p>
                    You will defend, indemnify and hold us (including our directors and employees) harmless from and
                    against any liability, costs (including without limitation any legal or other professional costs),
                    expenses, losses,damages (including any direct, indirect or consequential losses, loss of profit,
                    loss of business opportunity, loss of reputation), interest and penalties suffered or incurred by us
                    arising from or in any way related to (i)your use of the Website and/or the Application (except to
                    the extent such claims arise directly from our breach of applicable law); and/or (ii) your breach of
                    the Terms of Use.
                </p>
            </div>

            <div className="anti-corruption">
                <h2 className='my-4 text-primary'>12. ANTI-CORRUPTION, ANTI-BRIBERY, AND ANTI-MONEY LAUNDERING</h2>
                <p>
                    In the context of the use of the Platform, each Party agrees and undertakes to respect and comply
                    with all theAnti-Corruption Laws applicable.
                </p>
                <p>
                    Each Party warrants that it has taken reasonable measures to ensure that itself, its directors,
                    officers, employees, agents, subcontractors or any other third party under its control or influence,
                    comply with all of the Anti-Corruption Laws applicable.
                </p>
                <p>
                    Each Party undertakes to comply, without undue delay, with all reasonable requests made by the other
                    Party in the context of its compliance to the Anti-Corruption Laws (including but not limited to the
                    performance ofKYC checks), and to promptly report to the other Party any activity, request or demand
                    received for any undue financial or other advantage in the context of the use of the Platform, and
                    to
                    take the appropriate steps in regards to the Anti-Corruption Laws.
                </p>
                <p>
                    If a Party brings evidence that the other Party has been engaging in material or several repeated
                    breaches of the Anti-Corruption Laws it will notify the latter Party accordingly and require such
                    Party to take the necessary remedial action in a reasonable time and to inform it about such action,
                    if the applicable laws and regulations so permit.
                </p>
                <p>
                    Any proven and serious breach of or non-compliance with the provisions of this article by each Party
                    are cause for immediate termination without notice, without prejudice of any outstanding obligations
                    from theParty in breach or non-compliance, it being understood that all amounts or obligations
                    contractually due at the time of suspension or termination of the use of the Platform will remain
                    due
                    and payable, as far as permitted by Luxembourg law.
                </p>
            </div>

            <div className="confidentiality">
                <h2 className='my-4 text-primary'>13. CONFIDENTIALITY</h2>
                <p>
                    The Parties acknowledge that they each might receive or have access to Confidential Information from
                    the other Party, and agree to provide all reasonable efforts to keep the information confidential
                    and
                    to prevent it from being accessed by unauthorized persons.
                </p>
                <p>
                    The Parties agree not to disclose the Confidential Information to any other person of their staff or
                    affiliated person unless strictly necessary within the context of the execution of these Terms of
                    Use. The Parties will ensure that each recipient of the Confidential Information in accordance with
                    this provision complies with the Terms of Use.
                </p>
                <p>
                    The Receiving Party may not without permission of the Disclosing Party release any
                    ConfidentialInformation to any person or entity, other than, on a “need to know” basis in the scope
                    of its rights and obligations under these Terms of Use, to its personnel or members of management or
                    to its accountants, attorneys, sub-contractors and other professional advisers, all of whom are to be
                    bound by confidentiality obligations not less strict than those set out herein.
                </p>
                <p>
                    The confidentiality obligations set out in this clause does not apply to information that:
                </p>
                <p>
                    (i) at the time of disclosure is generally known or publicly available, or after disclosure becomes
                    generally known or publicly available through no fault of the Receiving Party; or that
                </p>
                <p>
                    (ii) the Receiving Party rightfully obtained from a third party that is entitled to disclose the
                    information and that is not under a confidentiality obligation to the Disclosing party in respect of
                    such information.
                </p>
                <p>
                    Each Party must preserve the confidentiality of Confidential Information with the same level of
                    protection it uses to protect its own confidential information but in no case any less than
                    reasonable care.
                </p>
                <p>
                    This clause stays in effect for five years after termination of these Terms of Use.
                </p>
            </div>

            <div className="data-protection">
                <h2 className='my-4 text-primary'>14. DATA PROTECTION</h2>
                <p>
                    The User should review EPV’s Website Privacy Policy to understand how and why User data is processed
                    byEPV. The User is informed that Estating Property Vault S.A is the data controller.
                </p>
                <p>
                    In the event that the User processes any Personal Data for his/her own use in the context of his/her
                    access or use of the Website and/or Application, the User acknowledges that he/she acts as an
                    independent controller, within the meaning of the GDPR for said Personal Data, and undertakes to
                    comply with the GDPR at all times.
                </p>
            </div>

            <div className="term-termination">
                <h2 className='my-4 text-primary'>15. TERM, TERMINATION AND AMENDMENT OF THE TERMS OF USE</h2>
            </div>

            <div className="term">
                <h2 className='my-4 text-primary'>15.1 Term</h2>
                <p>
                    The Terms of Use will be in force, in principle, for an indefinite period from the Date of
                    Acceptance.
                </p>
            </div>

            <div className="termination">
                <h2 className='my-4 text-primary'>15.2 Termination</h2>
                <p>
                    The Parties may terminate the Terms of Use at any time and for any reason. Termination of the Terms
                    of Use(or of any element of it) will not affect any rights, obligations or liabilities of either
                    Party which have accrued before termination (or which are expressly stated in the Terms of Use to
                    continue to have effect beyond termination). Your entitlement and licence to use the Website and the
                    Application will cease on termination of the Terms of Use and you must completely delete all
                    electronic copies of all or part of theWebsite and the Application or of the Services resident in
                    your systems or elsewhere.
                </p>
                <p>
                    We may terminate these Terms of Use, without reason and without recourse to the courts, by written
                    notice sent to the User via e-mail. The Terms of Use must then be terminated following a thirty (30)
                    calendar days’notice as from the date of said written notice.
                </p>
                <p>
                    The User may terminate the Terms of Use at any time, without reason, by deleting his or her Account
                    or by addressing a request by email at the following address: [data.protection@estating.com]. We
                    will
                    revert to you shortly and terminate your Account accordingly. Termination will be effective ten (10)
                    calendar days from the date of your request.
                </p>
                <p>
                    If the User is a Consumer, the User acknowledges that, within the 14 days after the Date of
                    Acceptance of the Terms of Use, the User has the right to withdraw from the Terms of Use by simple
                    written request (email or mail) to EPV.
                </p>
                <p>
                    In the event of material breach of the Terms of Use, EPV is entitled to suspend the User’s Account,
                    remove the concerned User Content or terminate the Terms of Use with immediate effect as from the
                    date of notification of such breach by us to the User, without recourse to the courts (de plein
                    droit). The User expressly acknowledges and agrees that any violation by the User of Our
                    Intellectual
                    Property Rights, any infringement by the User of the rights of any third party while accessing
                    and/or
                    using the Platform or theServices, or the applicable Anti-Corruption Laws, or any dissemination of
                    User Content that is related to or promotes illegal, illicit or immoral activities will constitute a
                    material breach of the Terms of Use.
                </p>
            </div>

            <div className="consequences-of-termination">
                <h2 className='my-4 text-primary'>15.3 Consequences of termination</h2>
                <p>
                    In the event of termination for any reason, the User acknowledges that we will be entitled to delete
                    the User related Account and any User Content and that as such, the User may no longer access the
                    Website and/or the Application.
                </p>
                <p>
                    Until the end of the notice of termination period, the User may retrieve and download the documents
                    andUser Content made accessible through the Account. The User acknowledges that after termination
                    becomes effective, we have no obligation whatsoever to provide the User with a copy of any data,
                    including the UserContent that may no longer be accessible to the User as a result of the
                    termination of the Terms of Use.
                </p>
            </div>

            <div className="amendment-of-the-term-of-use">
                <h2 className='my-4 text-primary'>15.4 Amendment of the Terms of Use</h2>
                <p>
                    We reserve the right, at any time and at our sole discretion, to amend the provisions of the Terms
                    of Use or to change, suspend or discontinue the Website, the Application or the related Services (or
                    any part thereof).We may also impose limits on certain features and Services or restrict your access
                    to parts or all of theWebsite or the Application without notice or liability.
                </p>
                <p>
                    You will be informed of any modification of the Terms of Use via an appropriate method of
                    information, such as e-mail, and will be deemed to have accepted any of these modifications in the
                    event you continue accessing or using the Website or the Application after thirty (30) calendar days
                    as from the date of our notification of the modification without having terminated the Terms of Use
                    in the conditions set out in clause 15. above. The Terms of Use, as modified, will then be
                    applicable
                    to the access and use of thePlatform.
                </p>
                <p>
                    If you do not wish to be bound by any modification of the Terms of Use, you undertake to
                    immediately delete your Account and stop accessing and using the Website and the Application.
                </p>
            </div>

            <div className="miscellaneous">
                <h2 className='my-4 text-primary'>16. MISCELLANEOUS</h2>
            </div>

            <div className="notices">
                <h2 className='my-4 text-primary'>16.1 Notices</h2>
                <p>
                    General notices, demands and requests of the User to us will be made at the following e-mail
                    address:[info@estating.com]. Notices, demands and requests related to the Terms of Use or the
                    Privacy Policy will be made at the e-mail address: [data.protection@estating.com].
                </p>
            </div>

            <div className="entire-agreement">
                <h2 className='my-4 text-primary'>16.2 Entire agreement</h2>
                <p>
                    The Terms of Use, and other applicable contracts concluded between the Parties represents the
                    entire agreement between the Parties relating to the exact subject matter hereof and supersedes all
                    prior agreements, covenants, arrangements, communications, representations or warranties, whether
                    oral or written, by any officer, agent, employee or representative of either of the Parties as well
                    as applicable non-mandatory local laws and international regulations.
                </p>
            </div>

            <div className="force-majeure">
                <h2 className='my-4 text-primary'>16.3 Force Majeure</h2>
                <p>
                    Neither Party will be responsible or liable for any failure to perform its obligations due to proven
                    causes beyond its reasonable control, including but not limited to acts of God, war, riots,
                    terrorist
                    acts, embargoes,acts of military authorities, fires, floods, earthquakes, accidents, strikes,
                    pandemics, failure of any communications services for the duration of any such circumstances or
                    cause.
                </p>
            </div>

            <div className="assignment">
                <h2 className='my-4 text-primary'>16.4 Assignment</h2>
                <p>
                    We are entitled to assign, transfer or otherwise sub-contract any or all of our rights or
                    obligations under theTerms of Use to a third party. In case of sub-contracting, we will remain
                    responsible for the acts and omissions of the sub-contractor to the same extent as if it had carried
                    out the obligations itself pursuant to theTerms of Use.
                </p>
            </div>

            <div className="severability">
                <h2 className='my-4 text-primary'>16.5 Severability</h2>
                <p>
                    If any provision of the Terms of Use is held to be invalid or unenforceable in whole or in part by
                    the competent courts, then such provision must be construed, as closely as possible, to reflect the
                    intentions of the invalid or unenforceable provision and the validity of the other provisions of the
                    Terms of Use will not be affected thereby.
                </p>
            </div>

            <div className="no-agency">
                <h2 className='my-4 text-primary'>16.6 No agency</h2>
                <p>
                    Nothing in the Terms of Use will be construed as creating a partnership or joint venture of any kind
                    between the Parties or as constituting either Party as the agent of the other Party for any purpose
                    whatsoever and neither Party will have the authority or power to bind the other Party or to contract
                    in the name of or create a liability against the other Party in any way or for any purpose.
                </p>
            </div>

            <div className="applicable-law-jurisdiction">
                <h2 className='my-4 text-primary'>APPLICABLE LAW AND JURISDICTION</h2>
                <p>
                    The Terms of Use and any dispute or claim arising out of or in connection with it or its subject
                    matter or formation will be governed by and construed in accordance with Luxembourg law.
                </p>
                <p>
                    The Parties irrevocably agree that the courts of the city of Luxembourg, Grand Duchy of Luxembourg,
                    will have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection
                    with the Terms ofUse or its subject matter or formation.
                </p>
            </div>
        </div>
    );
};

export default TermsOfUse;
