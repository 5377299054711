import accountSymbol from "assets/accountSymbol.svg";
import accountSymbolWhite from "assets/accountSymbolWhite.svg";
import accountSymbolWithNotification from "assets/accountSymbolWithNotification.svg";
import history from 'history-entity';
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { useSession } from "context/session";
import { _gerPrefix } from "helpers/get-prefix";
import Button from "components/Button";
import IncompleteProfileBadge from "components/IncompleteProfileBadge";
import { useTranslate } from "@tolgee/react";

import './style.scss';

interface DropdownItem {
  className: string;
  name: string;
  link: string;
  icon?: string;
  target?: string;
  onClick?: () => void;
}

interface DropDownHeaderProps {
  dropDowntItems: Array<DropdownItem>;
};

export const DropDownHeader: FC<DropDownHeaderProps> = memo(({ dropDowntItems }) => {
  const { user } = useSession();
  let isProfileComplete = false;

  const { t: tPrivateArea } = useTranslate('private_area');

  return (
    <div className="dropdown layout-header d-none d-md-flex d-flex align-items-center justify-content-center ml-md-auto h-100">
      <div
        id="dropdownMenuButton"
        className="h-100 d-flex align-items-center"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
        fontSize: "16px",
        height: "34px",
        }}
      >
        <div className="d-flex align-items-center">
          <span className="d-none d-md-inline text-gray">
            {user?.firstName} {user?.lastName}
          </span>

          {!isProfileComplete ? 
            (
              <img
                className="ml-2"
                src={accountSymbolWithNotification}
                height={25}
                style={{ aspectRatio: "1/1" }}
                alt="Account Symbol"
              />
            ) 
              :
            (
              <img
                  className="ml-3"
                  src={accountSymbol}
                  height={25}
                  style={{ aspectRatio: "1/1" }}
                  alt="Account Symbol"
              />
            )
          }
        </div>
      </div>

      <div
        className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="d-flex justify-content-end cursor-pointer px-3">
          <span className="mx-3">
              {user?.firstName} {user?.lastName}
          </span>
          <img src={accountSymbol} alt="Account Symbol" />
        </div>
        <div className="p-4 dropdown-content">
          <div className="profile-dropdown-header d-flex justify-content-between align-items-center">
            <div className="profile-thumb profile-thumb-big font-weight-bold mr-4">
              {_gerPrefix(user?.firstName, user?.lastName)}
            </div>
            <div>
              <p className="font-weight-bold my-0">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="text-muted my-0">
                {user?.email}
              </p>
            </div>
          </div>
          <hr />
          <div className="col-8 px-0 mx-auto">
            <Button
              styles={{
                height: "auto",
              }}
              onClick={() => {
                history.push("/account");
              }}
              className="w-100 p-2"
              icon={<img src={accountSymbolWhite} alt="Account Symbol" />}
              iconStyles={{
                marginRight: "1rem",
              }}
            >
              <span>
                {tPrivateArea('private_area.button.view_account')}
              </span>
              {!isProfileComplete && (
                <IncompleteProfileBadge
                  id="vide-account-profile-badge"
                  style={{
                    color: "#aee0ea",
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              )}
            </Button>
            {dropDowntItems.map(({ className, name, link, icon, target, onClick }) => 
              (
                target ? 
                (
                  <a
                    key={name}
                    className={className}
                    href={link}
                    target={target}
                  >
                    {icon && <img src={icon} alt={name} />}
                    <span className="ml-2">{name}</span>
                  </a>
                )
                :
                (
                  <Link
                    key={name}
                    className={className}
                    to={link}
                    onClick={onClick}
                  >
                    {icon && <img src={icon} alt={name} />}
                    <span className="ml-2">{name}</span>
                  </Link>
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
});