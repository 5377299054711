import { memo, FC, useCallback } from 'react';
import { TagDescription } from '../store/search';
import classNames from 'classnames';
import style from './selectedTags.module.scss';
import closeSVG from 'assets/close.svg';

interface SelectedTagsProps {
  tags: Array<TagDescription>;
  onRemoveTag: (tag: TagDescription) => void;
}

const boxClass = classNames(style.tags, "d-flex bg-white justify-content-center");
const flexBoxClass = classNames(style.tags__box, "d-flex flex-wrap");

export const SelectedTags: FC<SelectedTagsProps> = memo(({
  tags,
  onRemoveTag
}) => {
  const onRemove = useCallback((tag: TagDescription) => onRemoveTag(tag), [onRemoveTag]);
  return (
    <div className={flexBoxClass}>
      {tags.map((tag, index) => {
        return (
          <div
            className={boxClass}
            key={tag.label + index}
          >
            <span className={style.tags__text} style={{ wordBreak: "break-word" }}>
              {tag.label}
            </span>
            <div className='cursor-pointer'>
            <img src={closeSVG} alt='close'  onClick={() => onRemove(tag)} />
            </div>
          </div>
        );
      })}
    </div>
  )
});