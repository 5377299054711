import { FC, memo } from 'react';
import { ReactComponent as Cross } from 'assets/ic_round-close.svg';

import styles from './style.module.scss';
import { useTranslate } from '@tolgee/react';

interface PropertyInvestModalHeaderProps {
  onClose: () => void;
}

export const PropertyInvestModalHeader: FC<PropertyInvestModalHeaderProps> = memo(({ onClose }) => {
  const { t: tShareModal } = useTranslate('modal');
  
  return (
    <div className={styles['modal-header']}>
      <div className={styles['modal-header__title']}>
        {tShareModal('modal.share_opportunity')}
      </div>
      <div
        className={styles['modal-header__close-button']}
        onClick={onClose}
        role="button"
      >
        <Cross />
      </div>
    </div>
  );
});