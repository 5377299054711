import { FC, memo } from "react";
import { ReactComponent as ArrowSvg } from "assets/arrowDown.svg";
import classNames from "classnames";
import { RiskDocuments } from "../../types/risk-documenst/risk-documents";
import { useTranslate } from "@tolgee/react";

import styles from "./style.module.scss";

type PropertyRiskFilesProps = {
  documents: Array<RiskDocuments>;
};

const buttonBoxClass = classNames(
  "d-flex align-items-center cursor-pointer",
  styles["risk-files__button-box"]
);
const riskWrapperClass = classNames(
  "d-flex flex-column",
  styles["risk-files__wrapper"]
);

const documentNameClass = classNames(
  "d-flex text-capitalize",
  styles["risk-files__name"]
);

export const PropertyRiskFiles: FC<PropertyRiskFilesProps> = memo(({ documents }) => {
  const { t: tOpportunityDetailPageRisk } = useTranslate('opportunity_detail_page.risk');

  return (
    <div className={riskWrapperClass}>
      {documents.map(({ name, url }) => (
        <div className={styles["risk-files__box"]} key={name}>
          <div className={documentNameClass}>{name}</div>
          <a href={url}>
            <div className={buttonBoxClass}>
              <ArrowSvg />
              <span className={styles["risk-files__button-text"]}>
                {tOpportunityDetailPageRisk('opportunity_detail_page.risk.download_document_button')}
              </span>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
});
